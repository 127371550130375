import withTrans from '@/components/common/hocs/withTrans';
import GroupButton from '@/components/common/pages/GroupButton';
import InformationResident from '@/components/common/pages/InformationResident';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { STATUS_APPLICATION_ACCEPTED } from '@/constants/appConstants';
import { useGetDetailResident } from '@/hooks/useGetResidents';
import useGetApplications from '@/hooks/userGetApplications';
import { PageProps } from '@/interfaces/props/page';
import { cn } from '@/lib/utils';
import { columns } from '@/pages/application/ApplicationColumns';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';

const IPApplicationDetail = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { user } = useAuthenStore();
    const { id } = useParams();
    const { dataSource, isLoading } = useGetApplications({ page: 1, perPage: 50, residentId: id, userId: user.id });
    const { resident } = useGetDetailResident({ id, userId: user.id });

    const onDetailApplication = (key: string) => {
        navigate(`${key}`);
    };

    const onEdit = (id: string) => {
        navigate(`edit/${id}`);
    };

    const columnsTable = columns({
        t,
        onDetail: onDetailApplication,
        columnsKey: ['detail', 'code', 'latest_updated_at', 'status', 'app_service_ip', 'description'],
        onEdit,
        isEdit: true,
        showFull: true,
    });

    const onBack = () => {
        navigate('/welfare/commissioner/applications');
    };

    const onCreateAppRequest = () => {
        navigate('create');
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    className="py-6"
                    routes={[{ path: '', breadcrumbName: `${t('application_accept.ip_detail')}` }]}
                />
            }
            isDownLoadCsv={false}
            textBack={t('go_back')}
            onBack={onBack}
            pageLoading={isLoading}
        >
            <InformationResident data={resident} />
            <div className="flex flex-col mt-4 gap-4 cursor-default">
                <div>
                    <Label className="text-pc font-medium">{t('resident.created_app_service')}</Label>
                    {[
                        STATUS_APPLICATION_ACCEPTED.PENDING,
                        STATUS_APPLICATION_ACCEPTED.CONFIRMED,
                        STATUS_APPLICATION_ACCEPTED.RESPONDING,
                    ].includes(resident?.last_welfare_app_status) ||
                    (STATUS_APPLICATION_ACCEPTED.COMPLETED === resident?.last_welfare_app_status &&
                        resident?.emergency_device &&
                        resident?.welfare_taxi) ? null : (
                        <Button size="sm" className="text-pc ml-4 w-[100px]" onClick={onCreateAppRequest}>
                            {t('application_accept.create_app')}
                        </Button>
                    )}
                </div>

                <GroupButton
                    buttons={[
                        {
                            label: t('application_accept.taxi'),
                            className: cn('hover:cursor-default py-[30px]', resident?.welfare_taxi && 'bg-lime-500'),
                        },
                        {
                            label: t('application_accept.emergency_device'),
                            className: cn(
                                'hover:cursor-default py-[30px]',
                                resident?.emergency_device && 'bg-lime-500'
                            ),
                        },
                        {
                            label: t('habitat'),
                            className: cn('hover:cursor-default py-[30px]', resident?.habitat && 'bg-lime-500'),
                        },
                        {
                            label: t('evacuation_operations'),
                            className: cn(
                                'hover:cursor-default py-[30px]',
                                resident?.evacuation_operations && 'bg-lime-500'
                            ),
                        },
                    ]}
                />
            </div>
            <div className="mt-6">
                <Label className="text-pc font-medium">{t('application_accept.app_res_history')}</Label>
                <TableComponent
                    className="mt-4"
                    loading={isLoading}
                    columns={columnsTable}
                    dataSource={dataSource}
                    pagination={false}
                />
            </div>
        </PageComponent>
    );
};

export default withTrans(observer(IPApplicationDetail));
