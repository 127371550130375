import { useEffect, useState, useRef } from 'react';

const useCountdown = (totalSeconds: number) => {
    const [counter, setCounter] = useState(totalSeconds);

    useEffect(() => {
        setCounter(totalSeconds);
    }, [totalSeconds]);

    const intervalId = useRef<ReturnType<typeof setInterval>>();

    useEffect(() => {
        intervalId.current = setInterval(() => {
            counter > 0 && setCounter((counter: number) => counter - 1);
        }, 1000);
        // Clear interval when count to zero
        if (counter === 0) clearInterval(intervalId.current);
        // Clear interval when unmount
        return () => clearInterval(intervalId.current);
    }, [counter]);

    return getReturnValues(counter);
};

const getReturnValues = (counter: number) => {
    // calculate time left
    const days = Math.floor(counter / (60 * 60 * 24)) ?? 0;
    const hours = Math.floor((counter % (60 * 60 * 24)) / (60 * 60)) ?? 0;
    const minutes = Math.floor((counter % (60 * 60)) / 60) ?? 0;
    const seconds = Math.floor(counter % 60) ?? 0;
    return { days, hours, minutes, seconds, counter };
};

export default useCountdown;
