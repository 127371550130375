import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import en from '@/modules/i18n/lang/en.json';
import jp from '@/modules/i18n/lang/jp.json';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'jp',
        debug: true,
        resources: {
            en: {
                translation: en,
            },
            jp: {
                translation: jp,
            },
        },
    });

export default i18n;
