import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import withTrans from '@/components/common/hocs/withTrans';
import { PageProps } from '@/interfaces/props/page';
import { Button } from '@/components/ui/button';

const LinkExpired = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();

    const handleTryAgain = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="flex h-screen flex-col items-center justify-center gap-6 sm:gap-9 max-w-[750px] m-auto whitespace-pre-line p-4">
            <div className="text-title font-bold">{t('app_title')}</div>
            <div className="text-center mt-4">{t('link_expired_description')}</div>
            <Button type="submit" className="mt-6 w-[300px] md:w-[431px]" onClick={handleTryAgain} size="xxl">
                {t('try_again')}
            </Button>
        </div>
    );
};
export default withTrans(observer(LinkExpired));
