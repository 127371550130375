import FormInputCheckbox from '@/components/common/form/FormInputCheckbox';
import FormInputDate from '@/components/common/form/FormInputDate';
import FormInputText from '@/components/common/form/FormInputText';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import FormSelect from '@/components/common/form/FormSelect';
import { FormItem, FormLabel } from '@/components/ui/form';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import useGetUsers from '@/hooks/useGetUsers';
import dayjs from '@/lib/dayjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMaskito } from '@maskito/react';
import { optionInsurance, optionMyNumber } from '@/constants/appConstants';
import { convertOptions } from '@/lib/helper';
import { SelectOptionsItem } from '@/interfaces/common/global';
import EmergencyForm from '@/pages/resident/form/EmergencyForm';
import MedicalForm from '@/pages/resident/form/MedicalForm';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

type Props = {
    type?: 'created' | 'edit';
    user?: { id?: string; name?: string };
};

const ResidentForm = ({ user, type = 'created' }: Props) => {
    const { t } = useTranslation();
    const { watch, trigger } = useFormContext();

    const { options, setScrollOption, onHandleSearch, isLoading } = useGetUsers({
        role: APP_PERMISSION.COMMISSIONER,
    });

    const defaultOption: SelectOptionsItem = {
        value: user?.id,
        label: user?.name,
    };

    const inputMyNumberRef = useMaskito({ options: optionMyNumber });
    const inputInsuranceRef = useMaskito({ options: optionInsurance });

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (
                name?.includes('service_name_1') ||
                name?.includes('service_name_2') ||
                name?.includes('service_name_3')
            ) {
                trigger(['service']);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
            <div className="col-span-1 flex flex-col 2xl:gap-y-4 ">
                <FormSelect
                    className="sm:flex-wrap 2xl:flex-nowrap sm:gap-0 2xl:gap-6"
                    isLoading={isLoading}
                    name="civil"
                    options={convertOptions({ options, defaultOption })}
                    label={t('select_civil')}
                    disabled={type === 'edit'}
                    labelClass="min-w-[170px]"
                    setScrollOption={setScrollOption}
                    handleSearch={onHandleSearch}
                />
                <FormInputText
                    className="sm:flex-wrap 2xl:flex-nowrap sm:gap-0 2xl:gap-6"
                    name="name"
                    labelClass="min-w-[170px]"
                    label={t('resident.name')}
                    placeholder={t('common.MSG_001', { field: t('resident.name') })}
                    maxLength={255}
                />
                <FormInputDate
                    className="sm:flex-wrap 2xl:flex-nowrap sm:gap-0 2xl:gap-6"
                    name="birthday"
                    label={t('resident.birthday')}
                    disabledDate={(d) => d.isAfter(dayjs())}
                    labelClass="min-w-[170px]"
                />
                <FormInputText
                    className="sm:flex-wrap 2xl:flex-nowrap sm:gap-0 2xl:gap-6"
                    name="address"
                    labelClass="min-w-[170px]"
                    label={t('resident.address')}
                    placeholder={t('common.MSG_001', { field: t('resident.address') })}
                    maxLength={255}
                />
                <EmergencyForm
                    label={t('emergency_info.person_1')}
                    prefixName="emergency_contact1"
                    labelClass="min-w-[170px]"
                    required={true}
                />
                <EmergencyForm
                    label={t('emergency_info.person_2')}
                    prefixName="emergency_contact2"
                    labelClass="min-w-[170px]"
                    required={false}
                />
            </div>
            <div className="col-span-1 flex flex-col 2xl:gap-y-4">
                <MedicalForm label={t('medical_info.title')} labelClass="min-w-[170px]" required={true} />
                <FormInputText
                    ref={inputInsuranceRef}
                    name="insured_number"
                    className="sm:flex-wrap 2xl:flex-nowrap sm:gap-0 2xl:gap-6"
                    labelClass="min-w-[170px]"
                    label={t('resident.insurance_number')}
                    placeholder={t('common.MSG_001', { field: t('resident.insurance_number') })}
                    maxLength={255}
                    required={false}
                />
                <FormInputText
                    ref={inputMyNumberRef}
                    name="my_number"
                    className="sm:flex-wrap 2xl:flex-nowrap sm:gap-0 2xl:gap-6"
                    labelClass="min-w-[170px]"
                    label={t('resident.my_phone')}
                    placeholder={t('common.MSG_001', { field: t('resident.my_phone') })}
                    maxLength={255}
                    required={true}
                />
                <FormItem>
                    <div className="flex flex-wrap 2xl:gap-6 mt-4 2xl:mt-0 2xl:flex-nowrap items-center ">
                        <FormLabel className="text-pc min-w-[170px]">{t('resident.created_app_service')}</FormLabel>
                        <div className="grid grid-cols-2 gap-4 w-full mt-4">
                            <FormInputCheckbox
                                name="welfare_taxi"
                                label={t('resident.taxi')}
                                disabled
                                className="col-span-1"
                            />
                            <FormInputCheckbox
                                className="col-span-1"
                                name="emergency_device"
                                label={t('resident.emergency_device')}
                                disabled
                            />
                            <FormInputCheckbox name="habitat" label={t('habitat')} disabled className="col-span-1" />
                            <FormInputCheckbox
                                className="col-span-1"
                                name="evacuation_operations"
                                label={t('evacuation_operations')}
                                disabled
                            />
                        </div>
                    </div>
                </FormItem>
                <FormInputTextarea
                    className="sm:flex-wrap 2xl:flex-nowrap sm:gap-0 2xl:gap-6"
                    labelClass="min-w-[170px] !mt-0"
                    required={false}
                    name="remark"
                    label={t('resident.remarks')}
                    placeholder={t('common.MSG_001', { field: t('resident.remarks') })}
                    rows={5}
                    maxLength={255}
                />
            </div>
        </div>
    );
};

export default observer(ResidentForm);
