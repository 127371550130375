import withTrans from '@/components/common/hocs/withTrans';
import { Outlet } from 'react-router';

const AuthLayout = () => {
    return (
        <div className="min-h-screen flex justify-center items-center">
            <Outlet />
        </div>
    );
};

export default withTrans(AuthLayout);
