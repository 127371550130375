import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import useGetDataReport from '@/hooks/useGetDataReport';
import { ApplicationType, ReportPageProps, ReportType } from '@/interfaces/report';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useCsvStore } from '@/store/csv/CsvStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { SURVEY_TYPE } from '@/constants/appConstants';
import { convertFileNameCsv } from '@/lib/helper';
import { applicationColumns } from '@/pages/reports/application/components/ApplicationColumns';
import { CreateFileSurveyCSV, GetSurveyList } from '@/modules/services/survey.service';

const ApplicationReportList = (props: ReportPageProps) => {
    const { t } = props;
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const [page, setPage] = useState(1);
    const type = 'application';
    const surveyType = SURVEY_TYPE.APPLICATION;

    const { isDownLoadCsv, dataSource, total, isLoading } = useGetDataReport({
        page,
        userId: user?.id,
        callApi: GetSurveyList,
        surveyType: surveyType,
    });
    const onDownloadCsvApplication = async () => {
        try {
            await CreateFileSurveyCSV(surveyType);
            addQueue({
                flag: 'survey',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName: t('report.application_title'),
                }),
                url: `users/export_csv_survey?survey_type=${surveyType}`,
                urlDownload: `users/export_csv_survey/download?survey_type=${surveyType}`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onDetail = (record: ApplicationType) => {
        navigate(`${record.key}`);
    };

    const onChangeTable: TableProps<ReportType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };
    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t(`report.${type}_title`) }]} className="py-6" />}
            isDownLoadCsv={isDownLoadCsv}
            onDownLoadCsv={onDownloadCsvApplication}
        >
            <TableComponent
                loading={isLoading}
                columns={applicationColumns({ t, onDetail, title: type })}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(ApplicationReportList));
