import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { useState } from 'react';
import { columnsTableIPSurvey, columnsTableIPSurvey61 } from '@/pages/survey/ipad/components/IPSurveysListColumns';
import { QuestionSurveyDetailType } from '@/interfaces/survey';
import { GetSurveyList } from '@/modules/services/survey.service';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useQuery } from '@tanstack/react-query';
import { TableProps } from 'antd';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { SURVEY_TYPE } from '@/constants/appConstants';
import ParticipantForm from './components/ParticipantForm';
import useRefreshData from '@/hooks/useRefreshData';

const IPSurveyList = (props: PageProps) => {
    const { t } = props;
    const { isLoading, setLoading } = useGlobalStore();
    const [visible, setVisible] = useState(false);
    const [currentKey, setCurrentKey] = useState(null);
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const surveyType = searchParams.get(`survey_type`) || SURVEY_TYPE.GENERAL;

    const { refreshDataList, page, setPage } = useRefreshData({
        queryKey: ['getIPSurveys', surveyType],
    });

    const { data, isFetching } = useQuery({
        queryKey: ['getIPSurveys', surveyType, page],
        queryFn: () => getIPSurveys(page),
    });
    const onChangeTable: TableProps<QuestionSurveyDetailType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };
    const getIPSurveys = async (page: number): Promise<{ dataSource: QuestionSurveyDetailType[]; total: number }> => {
        if (user.id) {
            try {
                setLoading(true);
                const response = await GetSurveyList({ page, per_page: 10, survey_type: surveyType });
                const { data, meta }: any = response;
                const newData = data.map((item: any) => ({
                    ...item,
                    key: item.id,
                    number_respondent: `${item.answer_count}/${item.max_answer_number}`,
                }));
                return { dataSource: newData, total: meta.total_count };
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };
    const onAnswer = (key: string) => {
        const item = data.dataSource.find((el: QuestionSurveyDetailType) => {
            return el.id === key;
        });
        navigate(`${item.id}`);
    };

    const onSetting = (key: string) => {
        setVisible(true);
        setCurrentKey(key);
    };

    const onCancel = () => {
        setVisible(false);
        setCurrentKey(null);
    };

    const columns =
        surveyType === SURVEY_TYPE.SURVEY_61
            ? columnsTableIPSurvey61({ t, onAnswer, onSetting })
            : columnsTableIPSurvey({ t, onAnswer });

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName:
                                surveyType === SURVEY_TYPE.SURVEY_61
                                    ? t('survey.list_61_survey')
                                    : `${t('survey.question')}${t('list')}`,
                        },
                    ]}
                    className="py-6"
                />
            }
            isDownLoadCsv={false}
            pageLoading={isLoading}
        >
            <TableComponent
                loading={isFetching}
                columns={columns}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
            <ParticipantForm
                visible={visible}
                surveyId={currentKey}
                onCancel={onCancel}
                refreshData={refreshDataList}
            />
        </PageComponent>
    );
};
export default withTrans(observer(IPSurveyList));
