/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
export const REGEX_EMAIL =
    /^(?=.{1,320}@)((?:[a-zA-Z0-9!$%&*+\/=?^`{|}~]+(?:\-[a-zA-Z0-9!$%&*+\/=?^_`{|}~]+)*(?:\_[a-zA-Z0-9!$%&*+\/=?^_`{|}~]+)*(?:\.[a-zA-Z0-9!$%&*+\/=?^_`{|}~]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"))@(?=.{1,320}$)(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
// export const ALPHA_NUMERIC_REGEX = /[a-zA-Z0-9]/g;
export const ALPHA_NUMERIC_REGEX = /[^a-zA-Z0-9]/g;
export const SPECIAL_CHARACTERS = /[\\\/:"?*<>| ]/g;
export const MY_NUMBER = /(\d{4})(\d{4})(\d{4})/;
export const INSURANCE_NUMBER = /(\d{4})(\d{6})(\d{1})/;
export const HOME_PHONE = /(\d{4})(\d{3})(\d{3})/;
export const NUMBER = /^[1-9][0-9]*$/;
export const NUMBER_PASSWORD = /^[0-9]*$/;
export const NOT_NUMBER = /[^0-9]/g;
