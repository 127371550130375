import { Params } from '@/interfaces/common/global';
import { ProgressDataSubmit, ReportReqType } from '@/interfaces/report';
import { apiServiceWithAuth, apiServiceWithAuthFormData } from '@/modules/http/apiServiceWithAuth';

export const getContactReportList = async (params?: Params) => {
    return await apiServiceWithAuth.get(`users/report_contacts`, { params });
};

export const createContacReport = async (payload: { report_contact: ReportReqType }) => {
    return await apiServiceWithAuth.post('/users/report_contacts', payload);
};

export const updateContacReport = async (id: string, payload: { report_contact: ReportReqType }) => {
    return await apiServiceWithAuth.patch(`/users/report_contacts/${id}`, payload);
};

export const getContactReportDetail = async (id: string) => {
    return await apiServiceWithAuth.get(`/users/report_contacts/${id}`);
};

export const createFileContactReportCsv = async () => {
    return await apiServiceWithAuth.post(`users/export_csv_report_contact`);
};

export const createProgressReportContact = async (contactId: string, body: ProgressDataSubmit) => {
    return await apiServiceWithAuth.post(`users/report_contacts/${contactId}/progresses`, body);
};

export const getDetailProgressReportContact = async (contactId: string, progressId: string) => {
    return await apiServiceWithAuth.get(`users/report_contacts/${contactId}/progresses/${progressId}`);
};

export const updateProgressReportContact = async (contactId: string, progressId: string, body: ProgressDataSubmit) => {
    return await apiServiceWithAuth.patch(`users/report_contacts/${contactId}/progresses/${progressId}`, body);
};

export const deleteProgressReportContact = async (contactId: string, progressId: string) => {
    return await apiServiceWithAuth.delete(`users/report_contacts/${contactId}/progresses/${progressId}`);
};

export const createFileContactProgressCsv = async (contactId: string) => {
    return await apiServiceWithAuth.post(`users/report_contacts/${contactId}/export_csv_report_contact_progress`);
};

export const getContactReportListByUser = async (userId: string, params?: Params) => {
    return await apiServiceWithAuth.get(`users/${userId}/report_contacts`, { params });
};
export const uploadFiles = async (body: FormData) => {
    return await apiServiceWithAuthFormData.post(`/attachments`, body);
};