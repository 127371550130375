import { Label } from '@/components/ui/label';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { Button } from '@/components/ui/button';
import { SelectComponent } from '@/components/common/form/components/SelectComponent';
import { SelectOptionsItem } from '@/interfaces/common/global';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { ScrollOptions } from '@/interfaces/resident';

type TableBarProps = PropsWithChildren<{
    title?: string;
    hasSelect?: boolean;
    isCreate?: boolean;
    isDownLoadCsv?: boolean;
    options?: SelectOptionsItem[];
    onChangeSelect?: (value: string) => void;
    onDownLoadCsv?: () => void;
    onCreate?: () => void;
    handleSearch?: (value: string) => void;
    isLoad?: boolean;
    textCreate?: string;
    setScrollOption?: Dispatch<SetStateAction<ScrollOptions>>;
}>;

const TableBar = (props: TableBarProps & WithTransProps) => {
    const {
        t,
        title,
        onChangeSelect,
        onDownLoadCsv,
        onCreate,
        children,
        handleSearch,
        setScrollOption,
        isLoad,
        textCreate,
        options = [],
        isDownLoadCsv = false,
        isCreate = false,
        hasSelect = true,
    } = props;

    return (
        <div className="pt-2 pb-6 flex flex-wrap justify-between items-center gap-2 ">
            <div>
                {hasSelect && (
                    <div className="flex flex-wrap gap-4 items-center">
                        <Label className="text-xl font-normal">{title || t('select_civil')}</Label>
                        <SelectComponent
                            isLoad={isLoad}
                            options={options}
                            handleSearch={handleSearch}
                            onChangeSelect={onChangeSelect}
                            setScrollOption={setScrollOption}
                        />
                    </div>
                )}
            </div>
            <div className="flex gap-4 justify-center">
                {isDownLoadCsv && (
                    <Button
                        variant="outline"
                        size="xl"
                        className="text-ip h-[42px] w-[44] border-black "
                        onClick={() => onDownLoadCsv?.()}
                    >
                        {t('home.download_csv')}
                    </Button>
                )}
                {isCreate && (
                    <Button
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-[44] border-black"
                        onClick={onCreate}
                    >
                        {textCreate || t('create')}
                    </Button>
                )}
                {children}
            </div>
        </div>
    );
};

export default withTrans(TableBar);
