import { observer } from 'mobx-react-lite';
import FormInputText from '@/components/common/form/FormInputText';
import FormInputDate from '@/components/common/form/FormInputDate';
import dayjs from '@/lib/dayjs';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import QuestionFormContent from '@/pages/categories/application/Form/QuestionFormContent';

const ApplicationCategoryForm = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <div className="flex flex-col gap-y-4 max-w-[1100px]">
            <FormInputText
                name="title"
                maxLength={255}
                placeholder={t('common.MSG_001', { field: t('category.application.form.title') })}
                label={t('category.application.form.title')}
                labelClass="min-w-[165px]"
            />
            <FormInputDate
                name="due_date"
                label={t('category.application.form.deadline')}
                disabledDate={(d) => !!d.isBefore(dayjs())}
                labelClass="min-w-[165px]"
            />
            <QuestionFormContent name="questions" control={control} />
        </div>
    );
};

export default observer(ApplicationCategoryForm);
