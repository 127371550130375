import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { ReportType } from '@/interfaces/report';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { columnsMeetingAttendance } from '@/pages/ipad-meeting/components/Columns';
import { PageProps } from '@/interfaces/props/page';
import { getListMeetings } from '@/modules/services/meeting.service';
import { useQuery } from '@tanstack/react-query';
import { FORMAT_TIME } from '@/constants/appConstants';
import dayjs from '@/lib/dayjs';

const IPMeetingAttendanceList = (props: PageProps) => {
    const { t } = props;
    const { user } = useAuthenStore();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const getDataMeetingLists = async (page: number): Promise<{ dataSource: any[]; total: number }> => {
        if (user.id) {
            try {
                const response = await getListMeetings({ page, per_page: 10 });
                const { data, meta }: any = response;
                const newData = data.map((item: any) => ({
                    ...item,
                    key: item?.id,
                    start_time: item.start_time ? dayjs(item.start_time).format(FORMAT_TIME) : '',
                    end_time: item.end_time ? dayjs(item.end_time).format(FORMAT_TIME) : '',
                    attendances: item?.attendances?.filter((item: any) => item?.user?.id === user.id) || [],
                }));

                return { dataSource: newData, total: meta.total_count };
            } catch (error) {
                return { dataSource: [], total: 0 };
            }
        }
        return null;
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDataMeetingLists', user, page],
        queryFn: () => getDataMeetingLists(page),
    });
    const onEdit = (key: string) => {
        navigate(`${key}/edit`);
    };

    const onDetail = (key: string) => {
        navigate(`${key}`);
    };

    const onCreate = (key: string) => {
        navigate(`${key}/create`);
    };

    const onChangeTable: TableProps<ReportType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t(`attendance_meeting.list`) }]} className="py-6" />}
            isDownLoadCsv={false}
        >
            <TableComponent
                className="mt-4"
                loading={isLoading}
                columns={columnsMeetingAttendance({ t, onEdit, onDetail, onCreate })}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(IPMeetingAttendanceList));
