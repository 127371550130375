import { ReactNode, useEffect, useState } from 'react';

import { Card, CardContent } from '@/components/ui/card';
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
    type CarouselApi,
} from '@/components/ui/carousel';
import { ImageType } from '@/interfaces/report';
import { cn } from '@/lib/utils';
import { AspectRatio } from '@/components/ui/aspect-ratio';
import { Image } from 'antd';

export type RenderFooterProps<T> = {
    data: T[];
    current?: number;
    count?: number;
    handleSelect: (index: number) => void;
};

export type CarouselComponentProps<T> = {
    data: T[];
    wrapClass?: string;
    carouselClass?: string;
    blank?: ReactNode;
    isShowNext?: boolean;
    isShowPrevious?: boolean;
    className?: string;
    renderItem?: (item: T) => ReactNode;
    renderFooter?: ({ data, current, count, handleSelect }: RenderFooterProps<T>) => ReactNode;
};

const CarouselComponent = <T extends ImageType>({
    blank,
    wrapClass,
    carouselClass,
    renderItem,
    renderFooter,
    className,
    isShowNext = true,
    isShowPrevious = true,
    data = [],
}: CarouselComponentProps<T>) => {
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!api) {
            return;
        }

        setCount(api.scrollSnapList().length);
        setCurrent(api.selectedScrollSnap() + 1);

        api.on('select', () => {
            setCurrent(api.selectedScrollSnap() + 1);
        });
    }, [api]);

    const handleSelect = (index: number) => {
        api.scrollTo(index);
    };

    if (!data.length) {
        return (
            <>
                {blank || (
                    <Card className="w-[100%] xl:w-[90%]">
                        <CardContent className="flex justify-center items-center h-full">
                            <AspectRatio ratio={16 / 6}>
                                <Image
                                    alt="default"
                                    wrapperClassName="h-full w-full"
                                    className="!h-full !object-contain"
                                    src={'/assets/default.jpg'}
                                    preview={false}
                                    fallback={'/assets/default.jpg'}
                                />
                            </AspectRatio>
                        </CardContent>
                    </Card>
                )}
            </>
        );
    }

    return (
        <div className={cn('w-full flex flex-col items-center', wrapClass)}>
            <Carousel setApi={setApi} className={cn('w-full text-center', carouselClass)}>
                <CarouselContent>
                    {data.map((item, index) => (
                        <CarouselItem key={item.id || index}>
                            <Card className={cn('h-full', className)}>
                                {renderItem?.(item) || (
                                    <CardContent className="flex aspect-square items-center justify-center p-6">
                                        <span className="text-4xl font-semibold">{index + 1}</span>
                                    </CardContent>
                                )}
                            </Card>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                {isShowPrevious && <CarouselPrevious />}
                {isShowNext && <CarouselNext />}
            </Carousel>
            {renderFooter?.({ data, current, count, handleSelect })}
        </div>
    );
};

export { CarouselComponent };
