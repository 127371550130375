import { PAGE_SIZE } from '@/constants/appConstants';
import { Params } from '@/interfaces/common/global';
import { ReportType } from '@/interfaces/report';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';

interface UseGetDataReportProps {
    page?: number;
    perPage?: number;
    userId?: string;
    surveyType?:string;
    callApi: (params?: Params) => Promise<AxiosResponse<any, any>>;
}

const useGetDataReport = <T extends ReportType>({
    page = 1,
    perPage = PAGE_SIZE,
    userId,
    callApi,
    surveyType
}: UseGetDataReportProps) => {
    const getDataList = async (
        page: number,
        callApi: (params?: Params) => Promise<AxiosResponse<any, any>>
    ): Promise<{ dataSource: T[]; total: number }> => {
        if (!userId) return { dataSource: [], total: 0 };

        try {
            const res = await callApi({ page, per_page: perPage, survey_type: surveyType });
            const { data, meta }: any = res;
            return {
                dataSource: data.map((item: T) => ({ ...item, key: item.id })),
                total: meta.total_count,
            };
        } catch (error) {
            return { dataSource: [], total: 0 };
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDataReportComList', page, callApi],
        queryFn: () => getDataList(page, callApi),
    });

    const tableMeta = useMemo(() => {
        return {
            isDownLoadCsv: !!data?.dataSource?.length,
            dataTable: data?.dataSource || [],
            records: data?.total || 0,
        };
    }, [data]);

    return {
        dataSource: tableMeta.dataTable,
        total: tableMeta.records,
        isDownLoadCsv: tableMeta.isDownLoadCsv,
        isLoading,
    };
};

export default useGetDataReport;
