import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import { PageProps } from '@/interfaces/props/page';
import { convertFileNameCsv } from '@/lib/helper';
import {
    CreateFileVisitReportCSV,
    GetDetailVisitReport,
    GetListVisitReportHistory,
} from '@/modules/services/report.service';
import { DataType, columnSituation, columns } from '@/pages/visit-report/VisitReportColumns';
import { useCsvStore } from '@/store/csv/CsvStore';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const VisitReportDetail = (props: PageProps) => {
    const { t } = props;
    const { addQueue } = useCsvStore();
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get(`userId`);

    const getDetailVisitedReport = async (): Promise<DataType[]> => {
        if (id) {
            try {
                const { data } = await GetDetailVisitReport(userId, id);
                const newData = [
                    {
                        ...data,
                        key: data.id,
                        application_service: {
                            welfare_taxi: data.welfare_taxi,
                            emergency_device: data.emergency_device,
                        },
                    },
                ];

                return newData;
            } catch (error) {
                return [];
            }
        }
        return null;
    };

    const getListHistoryVisit = async (): Promise<{ dataSource: DataType[] }> => {
        if (userId && id) {
            try {
                const response: any = await GetListVisitReportHistory(userId, id, { is_all: true });
                const newData = response.data.map((item: any) => ({
                    ...item,
                    key: item.id,
                }));
                return { dataSource: newData };
            } catch (error) {
                return { dataSource: [] };
            }
        }

        return null;
    };
    const { isFetching: isLoad, data } = useQuery({
        queryKey: ['getDetailVisitReports', userId, id],
        queryFn: getDetailVisitedReport,
    });
    const { isFetching: isLoading, data: historyReport } = useQuery({
        queryKey: ['getListHistoryVisit', userId, id],
        queryFn: () => getListHistoryVisit(),
    });

    const onDownloadCsv = async () => {
        try {
            await CreateFileVisitReportCSV(userId, id);
            addQueue({
                flag: 'visit_report',
                status: 'pending',
                url: `users/${userId}/residents/${id}/export_csv_visit_report`,
                fileName: convertFileNameCsv({
                    prefixName: `${t('visit_report.title')}${t('detail')}`,
                    subName: data?.[0]?.name,
                }),
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: `${t('visit_report.title')}${t('detail')}` }]} />}
            isDownLoadCsv={!!historyReport?.dataSource?.length}
            onDownLoadCsv={onDownloadCsv}
            textBack={t('go_back')}
            onBack={() => navigate('/welfare/visit-reports')}
        >
            <div className="pb-4">
                {t('select_civil')} <span className="ml-4">{data?.[0]?.visitor_name}</span>
            </div>
            <TableComponent
                loading={isLoad}
                columns={columns({
                    t,
                    columnsKey: ['code', 'name', 'age', 'address', 'application_service'],
                    showFull: true,
                })}
                dataSource={data}
                pagination={false}
            />
            <TableComponent
                className="mt-8"
                loading={isLoading}
                columns={columnSituation({
                    t,
                    showFull: true,
                })}
                dataSource={historyReport?.dataSource || []}
                pagination={false}
            />
        </PageComponent>
    );
};

export default withTrans(observer(VisitReportDetail));
