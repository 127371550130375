import { AttachmentType } from '@/interfaces/common/global';
import { apiServiceWithAuth, apiServiceWithAuthFormData } from '@/modules/http/apiServiceWithAuth';

export const uploadFileAttachment = async (payload: FormData) => {
    return await apiServiceWithAuthFormData.post(`attachments`, payload);
};

export const deleteFileAttachment = async (id: string,params?: { type: AttachmentType }) => {
    return await apiServiceWithAuth.delete(`attachments/${id}`, { params });
};
