import { observer } from 'mobx-react-lite';
import { Button } from '@/components/ui/button';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import TableComponent from '@/components/common/table/TableComponent';
import { useTranslation } from 'react-i18next';
import { processColumns } from '@/pages/application/ApplicationColumns';
import { STATUS_APPLICATION_ACCEPTED } from '@/constants/appConstants';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { PlusIcon } from '@radix-ui/react-icons';
import ProgressForm from '@/components/common/pages/ProgressForm';
import { useRef } from 'react';
import { AxiosResponse } from 'axios';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { toast } from '@/components/ui/use-toast';
import { useParams } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { handleErrorNotFound } from '@/lib/helper';

type Props = {
    className?: string;
    isLoading?: boolean;
    data?: any;
    status?: string;
    refreshData?: () => void;
    onRemoveProccess?: (typeId: string, progressId: string) => Promise<AxiosResponse<any, any>>;
    onGetDetailData?: (typeId: string, progressId: string) => Promise<AxiosResponse<any, any>>;
    onCreateData?: (typeId: string, body: any) => Promise<AxiosResponse<any, any>>;
    onUpdateData?: (typeId: string, progressId: string, body: any) => Promise<AxiosResponse<any, any>>;
};

const ProcessComponent = ({
    className,
    isLoading,
    data,
    status,
    onRemoveProccess,
    refreshData,
    onGetDetailData,
    onCreateData,
    onUpdateData,
}: Props & WithTransProps) => {
    const { t } = useTranslation();
    const { setLoading } = useGlobalStore();
    const { user } = useAuthenStore();
    const formRef = useRef(null);
    const { id: typeId } = useParams();

    const onAddProgress = (id?: string, status?: string) => {
        formRef.current?.handleOpen(id, status);
    };

    const progressesData = data?.progresses?.map((item: any) => ({ ...item, key: item.id })) || [];

    const onInitPriority = () => {
        const progressesHasPriority =
            data?.progresses?.filter((item: any) => {
                return item && item.priority;
            }) || [];
        return progressesHasPriority[0]?.priority || null;
    };

    const initPriority = onInitPriority();

    const onRemove = async (id: string) => {
        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });
            if (res) {
                setLoading(true);
                await onRemoveProccess(typeId, id);
                refreshData?.();
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_036',
                callback: () => refreshData?.(),
                defaultMessage: 'common.MSG_041',
            });
        } finally {
            setLoading(false);
        }
    };

    const colums = processColumns({
        t,
        title: { email: user.email, status: data?.status || '' },
        data: progressesData,
        onEdit: onAddProgress,
        onDelete: onRemove,
        showFull: true,
    });

    return (
        <>
            <div className={cn('pt-6 md:pt-12', className)}>
                <span className="text-pc font-medium">{t('application_accept.process_report_list')}</span>
                <TableComponent
                    className="mt-4"
                    loading={isLoading}
                    columns={colums}
                    dataSource={progressesData}
                    pagination={false}
                />
                {![STATUS_APPLICATION_ACCEPTED.COMPLETED, STATUS_APPLICATION_ACCEPTED.REJECTED].includes(status) && (
                    <div className="flex justify-center py-4">
                        <Button size="icon" onClick={() => onAddProgress()} className="rounded-[50%]">
                            <PlusIcon height={28} width={28} />
                        </Button>
                    </div>
                )}
            </div>
            <ProgressForm
                ref={formRef}
                refreshData={refreshData}
                status={data?.status || ''}
                onGetInitData={onGetDetailData}
                onCreateData={onCreateData}
                onUpdateData={onUpdateData}
                initPriority={initPriority}
                progressesData={progressesData}
            />
        </>
    );
};

export default withTrans(observer(ProcessComponent));
