import { APP_PERMISSION } from '@/constants/permissionConfig';

export const PATH = {
    LOGIN: '/login',
    HOME: '/',
    PROFILE: '/profile',
    UPDATE_PASSWORD: '/update-password',
};

export const backLink = {
    [APP_PERMISSION.MAYOR]: '/communication/mayor',
    [APP_PERMISSION.REPRESENTATIVE]: '/communication/representative',
};

export const manualLink = {
    mayor: '/manual/communication_mayor_councillor_manual.pdf',
    representative: '/manual/communication_mayor_councillor_manual.pdf',
    communication: '/manual/communication_municipality_manual.pdf',
    welfare: '/manual/welfare_system_municipality_manual.pdf',
    commissioner: '/manual/welfare_system_welfare_manual.pdf',
};
