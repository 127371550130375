import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogClose,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button, VariantButton } from '@/components/ui/button';
import { Cross2Icon } from '@radix-ui/react-icons';
import { createRef, forwardRef, memo, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModalConfirmType = {
    message?: string;
    title?: string;
    textOk?: string;
    textCancel?: string;
    type?: 'default' | 'delete' | 'error';
};

const TYPES: { [key: string]: string } = {
    default: 'default',
    delete: 'destructive',
};

const ModalConfirm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('default');
    const [title, setTitle] = useState('');
    const [textOk, setTextOk] = useState<string>(t('yes'));
    const [textCancel, setTextCancel] = useState<string>(t('no'));

    const [promiseCallback, setPromiseCallback] = useState(null);

    const onConfirm = () => {
        promiseCallback.resolve(true);
        onResetState();
    };

    const onCancel = () => {
        promiseCallback.resolve(false);
        onResetState();
    };

    const onResetState = () => {
        setVisible(false);
        setTitle('');
        setType('default');
        setTextOk(t('yes'));
        setTextCancel(t('no'));
        setMessage('');
    };

    useImperativeHandle(ref, () => ({
        open({ message, title, type, textOk, textCancel }: ModalConfirmType) {
            setMessage(message);
            setTitle(title);
            setVisible(true);
            setType(type || 'default');
            textOk && setTextOk(textOk);
            textCancel && setTextCancel(textCancel);
            return new Promise((resolve, reject) => {
                setPromiseCallback({ resolve, reject });
            });
        },
    }));

    return (
        <AlertDialog open={visible}>
            <AlertDialogContent>
                <AlertDialogClose>
                    <Button variant="ghost" size="icon" className="h-[30px] w-[30px]" onClick={onCancel}>
                        <Cross2Icon height={24} width={24} className="opacity-80" />
                    </Button>
                </AlertDialogClose>
                <AlertDialogHeader className="gap-4">
                    <AlertDialogTitle className="text-[23px]">{title}</AlertDialogTitle>
                    <AlertDialogDescription className="text-pc">{message}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="mt-6 text-center flex-row justify-center gap-3">
                    {type !== 'error' && (
                        <AlertDialogCancel onClick={onCancel} className="text-pc min-w-[120px] mt-0">
                            {textCancel}
                        </AlertDialogCancel>
                    )}
                    <AlertDialogAction
                        onClick={onConfirm}
                        className={'text-pc min-w-[120px]'}
                        variant={TYPES[type] as VariantButton['variant']}
                    >
                        {textOk}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
});

export default ModalConfirm;

type BasePopupRef = {
    open: (data: ModalConfirmType) => string | undefined;
};
export const modalConfirmRef = createRef<BasePopupRef>();
export const PopupConfirm = memo(() => <ModalConfirm ref={modalConfirmRef} />);
export const showModalConfirm = (data: ModalConfirmType): Promise<unknown> => {
    return Promise.resolve(modalConfirmRef.current?.open(data));
};
