import FormInputText from '@/components/common/form/FormInputText';
import { Button } from '@/components/ui/button';
import { FormField } from '@/components/ui/form';
import { MAX_OPTIONS, OPTION_ITEM } from '@/constants/appConstants';
import { cn } from '@/lib/utils';
import { PlusIcon, XCircle, MinusIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
};

const QuestionOptionForm = ({ name }: Props) => {
    const { control, watch, setError, clearErrors, getValues } = useFormContext();
    const { t } = useTranslation();

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    useEffect(() => {
        const subscription = watch((_, { name: fieldName }) => {
            if (fieldName?.includes(name)) {
                const value = getValues(name);
                const isError = !value.some((item: any) => !!item.file);
                isError
                    ? setError(name, {
                          message: t('common.MSG_001', { field: t('category.application.form.option_placeholder') }),
                      })
                    : clearErrors(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const addOptions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (fields.length < MAX_OPTIONS) {
            append({
                content: '',
            });
        }
    };

    const removeOptions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
        e.preventDefault();
        if (fields.length > OPTION_ITEM) {
            remove(index);
        }
    };

    return (
        <FormField
            name={name}
            control={control}
            render={({ fieldState: { error } }) => {
                const message = error?.root?.message || '';

                return (
                    <div className="sm:ml-[172px]">
                        <div className="  mt-5 gap-y-4 gap-x-6 grid grid-cols-1 md:grid-cols-2 ">
                            {fields.map((item, index) => (
                                <div key={item.id} className="col-span-1 flex items-center w-full">
                                    <FormInputText
                                        key={item.id}
                                        name={`${name}.${index}.content`}
                                        className="w-full"
                                        inputClassName={cn('h-[44px]', message && 'border-destructive')}
                                        placeholder={`${t('category.application.form.option_placeholder')}`}
                                        maxLength={20}
                                        showError={false}
                                        sufixInput={(field) => {
                                            return field.value ? (
                                                <Button
                                                    variant="ghost"
                                                    size="icon"
                                                    className="h-[30px] w-[30px]"
                                                    onClick={() => field.onChange('')}
                                                >
                                                    <XCircle height={20} width={20} className="opacity-80" />
                                                </Button>
                                            ) : (
                                                <div className="h-[30px]" />
                                            );
                                        }}
                                    />
                                    {fields.length > OPTION_ITEM && (
                                        <Button
                                            variant="outline"
                                            size="icon"
                                            onClick={(e) => removeOptions(e, index)}
                                            className="rounded-[50%] ml-2 h-[30px] w-[32px]"
                                        >
                                            <MinusIcon height={15} width={15} />
                                        </Button>
                                    )}
                                </div>
                            ))}
                        </div>
                        {error && <p className="font-medium text-destructive text-error">{message}</p>}
                        {fields.length < MAX_OPTIONS && (
                            <div className="mt-4 flex justify-center">
                                <Button variant="outline" size="icon" onClick={addOptions} className="rounded-[50%]">
                                    <PlusIcon height={20} width={20} />
                                </Button>
                            </div>
                        )}
                    </div>
                );
            }}
        />
    );
};

export default QuestionOptionForm;
