/* eslint-disable no-irregular-whitespace */
import type { TableProps } from 'antd/es/table';
import { ApplicationDetailType } from '@/interfaces/report';
import { ColumnsProps } from '@/interfaces/common/global';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE } from '@/constants/appConstants';

export const ApplicationColumnsDetail = ({
    t,
    columnsKey,
    title,
}: ColumnsProps<any>): TableProps<ApplicationDetailType>['columns'] => {
    const allColumns: TableProps<ApplicationDetailType>['columns'] = [
        {
            title: t('report.application.detail.respondent'),
            dataIndex: 'respondent',
            key: 'respondent',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return record?.user?.name;
            },
        },
        {
            title: t('report.application.detail.answerDateAndTime'),
            dataIndex: 'submit_date',
            key: 'submit_date',
            width: 70,
            align: 'center',
            render: (_, record) => {
                return <span>{dayjs(record.submit_date).format(FORMAT_DATE)}</span>;
            },
        },
        {
            title: <div className="text-center">{`${t('survey.question')}1　${title?.question1 || ''}`}</div>,
            dataIndex: 'answer1',
            key: 'answer1',
            width: 200,
            render: (value) => <div className="whitespace-break-spaces">{value}</div>,
        },
        {
            title: <div className="text-center">{`${t('survey.question')}2　${title?.question2 || ''}`}</div>,
            dataIndex: 'answer2',
            key: 'answer2',
            width: 200,
            render: (value) => <div className="whitespace-break-spaces">{value}</div>,
        },
        {
            title: <div className="text-center">{`${t('survey.question')}3　${title?.question3 || ''}`}</div>,
            dataIndex: 'answer3',
            key: 'answer3',
            width: 200,
            render: (value) => <div className="whitespace-break-spaces">{value}</div>,
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
