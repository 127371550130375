import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import UploadImage from '@/components/ui/upload-image';
import { IUploadFile } from '@/interfaces/common/upload';

type Props = {
    name: string;
    classContainer?: string;
    classLabel?: string;
    className?: string;
    required?: boolean;
    showError?: boolean;
    hasError?: boolean;
    handleUpload?: any;
    showPopupDelete?: boolean;
    label?: React.ReactNode;
    handleClearFile?: (file: IUploadFile) => void;
    attachmentType?: string;
    attachmentIndex?: number;
};

const FormUploadImage = ({
    name,
    className,
    classContainer,
    classLabel,
    label,
    handleUpload,
    handleClearFile,
    showPopupDelete,
    hasError = false,
    showError = true,
    required = false,
    attachmentType,
    attachmentIndex,
}: Props) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-2 space-y-0', className)}>
                    {label && (
                        <FormLabel
                            className={cn('text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px] ', classLabel)}
                            required={required}
                        >
                            {label}
                        </FormLabel>
                    )}
                    <div className="space-y-2 w-full">
                        <FormControl>
                            <UploadImage
                                hasError={hasError || !!error}
                                handleClearFile={handleClearFile}
                                value={field.value}
                                classContainer={classContainer}
                                onChange={async (event) => {
                                    await handleUpload(event);
                                }}
                                showPopupDelete={showPopupDelete}
                                attachmentType={attachmentType}
                                attachmentIndex={attachmentIndex}
                            />
                        </FormControl>
                        {showError && <FormMessage className="text-error" />}
                    </div>
                </FormItem>
            )}
        />
    );
};

export default FormUploadImage;
