import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditResidentSchema } from '@/schema/residentSchema';
import { ResidentReq } from '@/interfaces/resident';
import { ResidentService } from '@/modules/services';
import { useAuthenStore } from '@/store/auth/AuthStore';
import dayjs from '@/lib/dayjs';
import ResidentForm from '@/pages/resident/form/ResidentForm';
import { useEffect } from 'react';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { handleErrorMessage, handleErrorNotFound, removeSpaceMyNumber, removeStringPhone } from '@/lib/helper';
import { useQueryClient } from '@tanstack/react-query';

export const initData: z.infer<any> = {
    civil: undefined,
    name: undefined,
    birthday: undefined,
    address: undefined,
    emergency_device: undefined,
    evacuation_operations: undefined,
    habitat: undefined,
    my_number: undefined,
    remark: undefined,
    welfare_taxi: undefined,
    family_hospital: undefined,
    care_manager: undefined,
    service: {
        service_name_1: undefined,
        service_name_2: undefined,
        service_name_3: undefined,
    },
    note: undefined,
    insured_number: undefined,
    emergency_contact1: {
        name: undefined,
        phone: undefined,
        home_phone: undefined,
        relationship: undefined,
        address: undefined,
    },
    emergency_contact2: {
        name: undefined,
        phone: undefined,
        home_phone: undefined,
        relationship: undefined,
        address: undefined,
    },
};

const CreateResident = (props: PageProps) => {
    const { t } = props;
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = location.state || {};
    const { isLoading, setLoading } = useGlobalStore();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (userId) {
            form.setValue('civil', userId);
        }
    }, [userId]);

    const form = useForm<any>({
        resolver: zodResolver(EditResidentSchema, { async: false }),
        defaultValues: {
            ...initData,
        },
        mode: 'all',
    });

    const onBack = () => {
        navigate(`/welfare/residents`);
    };

    const onSubmit = async () => {
        if (!user.id) return;
        try {
            setLoading(true);
            const req = form.getValues();

            const my_number = removeSpaceMyNumber(req.my_number);
            const insured_number = removeSpaceMyNumber(req.insured_number);

            const body: ResidentReq = {
                resident: {
                    ...req,
                    my_number,
                    service_name_1: req.service?.service_name_1 || '',
                    service_name_2: req.service?.service_name_2 || '',
                    service_name_3: req.service?.service_name_3 || '',
                    insured_number,
                    birthday: dayjs(req.birthday).format('YYYY-MM-DD'),
                    emergency_contacts: [
                        {
                            ...req.emergency_contact1,
                            phone: removeStringPhone(req.emergency_contact1.phone),
                            home_phone: removeStringPhone(req.emergency_contact1.home_phone),
                        },
                        {
                            ...req.emergency_contact2,
                            phone: removeStringPhone(req.emergency_contact2.phone),
                            home_phone: removeStringPhone(req.emergency_contact2.home_phone),
                        },
                    ],
                },
            };

            const res = await ResidentService.CreateResident(req.civil, body);
            if (res) {
                toast({ description: t('common.MSG_015'), variant: 'success' });
                onBack();
            }
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_064',
                callback: () => {
                    form.setValue('civil', undefined);
                    queryClient.fetchQuery({
                        queryKey: ['getOptionUsers', '', 1],
                    });
                },
            });

            if (isNotFound) return;
            const message = error?.errors?.resident?.[0] || '';
            handleErrorMessage({
                error: message,
                message: t(message.includes('common') ? message : `common.${message}`, {
                    field: t('resident.my_phone'),
                }),
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('resident.title')}${t('resident.register')}` }]}
                    className="py-2"
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <ResidentForm />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(CreateResident));
