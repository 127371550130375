import { Button } from '@/components/ui/button';
import { FormField, FormMessage } from '@/components/ui/form';
import { LoadingComponent } from '@/components/ui/loading';
import useGetComCategories, { CateType } from '@/hooks/useGetCommunicationCategory';
import { cn } from '@/lib/utils';
import { CategoryService } from '@/modules/services';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    pageType: string;
    setHiddenNextBtn: (data: boolean) => void;
    setHasChild: (data: boolean) => void;
}

const SelectedCategory = (props: Props) => {
    const { t } = useTranslation();
    const { pageType, setHiddenNextBtn, setHasChild } = props;
    const lastItemRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(1);
    const {
        formState: {
            errors: { category_id: category },
        },
        clearErrors,
        setValue,
        watch,
        control,
    } = useFormContext();
    const selectedCate = watch('category_id');

    const { data, meta, isLoading } = useGetComCategories({
        page,
        type: pageType as CateType,
        orderBy: 'created_at desc',
    });

    const onScroll = useCallback(() => {
        const newPage = page + 1;
        const pageNum = Math.ceil(meta / 20);
        if (newPage <= pageNum) {
            setPage(newPage);
        }
    }, [page, meta]);

    useEffect(() => {
        setHiddenNextBtn(!data.length);
        if (lastItemRef.current && data.length) {
            const observer = new IntersectionObserver(
                (entry) => {
                    if (entry[0].isIntersecting) {
                        onScroll();
                    }
                },
                {
                    threshold: 1.0,
                }
            );
            observer.observe(lastItemRef.current);
            return () => {
                if (lastItemRef.current) {
                    observer.unobserve(lastItemRef.current);
                }
            };
        }
    }, [lastItemRef, data.length, onScroll]);

    const hasChild = async (categoryId: string) => {
        if (!categoryId) {
            setHasChild(false);
            return;
        }
        try {
            const { data }: any = await CategoryService.getChildrenCategoryList({
                type: pageType as any,
                page: 1,
                per_page: 1,
                category_id: categoryId,
            });

            if (data.length > 0) {
                setHasChild(true);
            } else {
                setHasChild(false);
            }
        } catch (error) {
            setHasChild(false);
        }
    };

    return (
        <div>
            <div
                className={`flex justify-start items-center w-full flex-wrap overflow-y-auto `}
                style={{
                    maxHeight: 'calc(100vh - 420px)',
                }}
            >
                {data.map(({ content, id }, i) => (
                    <div key={i} className="w-full sm:w-[50%] px-[16px] py-[16px]">
                        <Button
                            variant="outline"
                            size="xxl"
                            className={cn(`
                                w-full h-[78px] text-pc whitespace-break-spaces
                                ${category?.message ? '!border-destructive' : ''}
                                ${selectedCate === id ? '!bg-[#84cc16]' : 'hover:!bg-transparent'}
                            `)}
                            onClick={() => {
                                setValue('category_id', selectedCate === id ? '' : id);
                                clearErrors('category_id');
                                setValue('category_child_id', null);
                                hasChild(id);
                            }}
                            children={content}
                        />
                    </div>
                ))}
                {!data.length && (
                    <span className="py-[100px] w-full text-center text-pc">
                        {t('application_register.empty_category')}
                    </span>
                )}
                <div ref={lastItemRef}></div>
            </div>
            <div className={`flex items-center ${data.length === 1 ? 'justify-center' : 'justify-start'} w-full`}>
                <FormField
                    name={'category_id'}
                    control={control}
                    render={() => <FormMessage className="text-error w-[500px] px-3" />}
                />
            </div>
            {isLoading && <LoadingComponent />}
        </div>
    );
};
export default SelectedCategory;
