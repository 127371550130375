import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import MeetingForm from '@/pages/meeting/form/MeetingForm';
import { MeetingSchema } from '@/schema/meetingSchema';
import { toast } from '@/components/ui/use-toast';
import dayjs from '@/lib/dayjs';
import { createMeeting } from '@/modules/services/meeting.service';
import { FORMAT_DATE_REQ, FORMAT_TIME } from '@/constants/appConstants';
import { handleErrorMessage } from '@/lib/helper';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const CreateMeeting = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const utcOffset = dayjs().utcOffset();
    const [isLoading, setLoading] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const form = useForm<z.infer<typeof MeetingSchema>>({
        resolver: zodResolver(MeetingSchema),
        defaultValues: {
            name: undefined,
            meeting_date: undefined,
            start_time: undefined,
            end_time: undefined,
            content: undefined,
            user_ids: [],
        },
        mode: 'onChange',
    });
    const onBackMenu = () => {
        navigate(`/communication/meeting`);
    };
    const onSubmit = async () => {
        try {
            setLoading(true);
            const data = form.getValues();

            const meeting = {
                name: data.name,
                meeting_date: dayjs(data.meeting_date).format(FORMAT_DATE_REQ),
                start_time: dayjs(data.start_time).utcOffset(utcOffset).format(FORMAT_TIME),
                end_time: data.end_time ? dayjs(data.end_time).utcOffset(utcOffset).format(FORMAT_TIME) : null,
                content: data.content,
                user_ids: data.user_ids,
            };

            // !data.end_time && delete meeting.end_time;
            const body = {
                meeting,
            };
            await createMeeting(body);
            toast({
                description: t('common.MSG_015'),
                variant: 'success',
            });
            onBackMenu();
        } catch (error) {
            const message = error?.errors?.meeting?.[0] || '';
            handleErrorMessage({
                error: message,
                callback: () => {
                    if (message.includes('MSG_072')) {
                        // form.setValue('user_ids', []);
                        queryClient.refetchQueries({ queryKey: ['getUserList'] });
                    }
                },
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('meeting.create_new_meeting') }]} />}
            pageFooter={<></>}
            pageLoading={isLoading}
        >
            <Form {...form}>
                <MeetingForm />
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-[19px] h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            </Form>
        </PageComponent>
    );
};
export default withTrans(observer(CreateMeeting));
