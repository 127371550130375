import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE_TIME, STATUS_ACTION, STATUS_APPLICATION_ACCEPTED } from '@/constants/appConstants';
import { ColumnsProps } from '@/interfaces/common/global';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { ProgessDataType } from '@/interfaces/application';
import { renderApplicationServiceColumns, renderEachAppServiceColumns } from '@/components/common/table/Columns';

export type DataType = {
    key: string;
    code: string;
    description: string;
    age: number;
    latest_updated_at: string;
    priority: string;
    visitor_id: string;
    visitor_name: string;
    reason: string;
    status: string;
    id: string;
    welfare_taxi: boolean;
    emergency_device: boolean;
    habitat: boolean;
    evacuation_operations: boolean;
    situation_content: string;
    resident_code: string;
    resident: {
        id: string;
        code: string;
        name: string;
        age: string;
        address: string;
    };
};

export const columns = ({
    t,
    onDetail,
    columnsKey,
    onEdit,
    isEdit = false,
    showFull,
}: ColumnsProps<DataType> & { isEdit?: boolean }): TableProps<DataType>['columns'] => {
    const allColumns: TableProps<DataType>['columns'] = [
        {
            title: t(isEdit ? 'application_accept.operation' : 'application_accept.detail'),
            dataIndex: 'detail',
            key: 'detail',
            width: 90,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="default"
                            className="m-1"
                            onClick={() => {
                                onDetail?.(record.key);
                            }}
                        >
                            {t('detail')}
                        </Button>
                        {isEdit && record.status === STATUS_APPLICATION_ACCEPTED.PENDING && (
                            <Button
                                variant="update"
                                className="bg-green-500 border-grey m-1"
                                onClick={() => {
                                    onEdit?.(record.key);
                                }}
                            >
                                {t('edit')}
                            </Button>
                        )}
                    </>
                );
            },
        },
        {
            title: t('application_accept.register_no'),
            dataIndex: 'code',
            key: 'code',
            width: 80,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('application_accept.applicant')}</div>,
            dataIndex: 'visitor_name',
            key: 'visitor_name',
            width: 100,
            render: (value) => {
                if (showFull) return <div className="whitespace-break-spaces">{value}</div>;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('application_accept.lastest_date'),
            dataIndex: 'latest_updated_at',
            key: 'latest_updated_at',
            width: 110,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE_TIME) : ''),
        },
        {
            title: t('application_accept.status'),
            dataIndex: 'status',
            key: 'status',
            width: 100,
            align: 'center',
            render: (value) => t(value),
        },
        {
            title: t('application_accept.resident_no'),
            dataIndex: 'resident_code',
            key: 'resident_code',
            width: 90,
            align: 'center',
            render: (_, record) => record.resident.code,
        },
        {
            title: <div className="text-center">{t('application_accept.name')}</div>,
            dataIndex: 'resident_name',
            key: 'resident_name',
            width: 100,
            render: (_, record) => {
                if (showFull) return <div className="whitespace-break-spaces">{record.resident.name}</div>;
                return (
                    <TooltipComponent title={record.resident.name}>
                        <div className="ellipsis whitespace-break-spaces">{record.resident.name}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('application_accept.age'),
            dataIndex: 'resident_age',
            key: 'resident_age',
            width: 75,
            align: 'center',
            render: (_, record) => record.resident.age,
        },
        {
            title: <div className="text-center">{t('application_accept.address')}</div>,
            dataIndex: 'resident_address',
            key: 'resident_address',
            width: 180,
            render: (_, record) => {
                if (showFull) return <div className="whitespace-break-spaces">{record.resident.address}</div>;
                return (
                    <TooltipComponent title={record.resident.address}>
                        <div className="ellipsis whitespace-break-spaces">{record.resident.address}</div>
                    </TooltipComponent>
                );
            },
        },
        ...renderApplicationServiceColumns(t),
        ...renderEachAppServiceColumns(t),
        {
            title: t('app_service'),
            key: 'app_service_pc',
            width: 100,
            render: (_: boolean, record: any) => {
                const text = [
                    record?.welfare_taxi ? t('taxi') : '',
                    record?.emergency_device ? t('emergency_device') : '',
                    record?.habitat ? t('habitat') : '',
                    record?.evacuation_operations ? t('evacuation_operations') : '',
                ]
                    .filter((item: string) => item)
                    .join('、');
                return (
                    <TooltipComponent title={text}>
                        <div className="ellipsis whitespace-break-spaces">{text}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('app_service'),
            key: 'app_service_ip',
            width: 110,
            render: (_: boolean, record: any) => {
                return (
                    <div className="flex flex-col gap-1 ">
                        {record?.welfare_taxi ? <span>{t('taxi')}</span> : ''}
                        {record?.emergency_device ? <span>{t('emergency_device')}</span> : ''}
                        {record?.habitat ? <span>{t('habitat')}</span> : ''}
                        {record?.evacuation_operations ? <span>{t('evacuation_operations')}</span> : ''}
                    </div>
                );
            },
        },
        {
            title: t('application_accept.priority'),
            dataIndex: 'priority',
            key: 'priority',
            width: 80,
            align: 'center',
            render: (value) => t(value),
        },
        {
            title: <div className="text-center">{t('application_accept.latest_support')}</div>,
            dataIndex: 'description',
            key: 'description',
            width: 190,
            render: (value) => {
                if (showFull) return <div className="whitespace-break-spaces">{value}</div>;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const processColumns = ({
    t,
    onEdit,
    columnsKey,
    onDelete,
    title,
    data,
    showFull,
    titleColumns,
}: ColumnsProps<ProgessDataType> & { titleColumns?: string }): TableProps<ProgessDataType>['columns'] => {
    const allColumns: TableProps<ProgessDataType>['columns'] = [
        {
            title: t(titleColumns || 'application_accept.lastest_date'),
            dataIndex: 'latest_updated_at',
            key: 'latest_updated_at',
            width: 100,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE_TIME) : ''),
        },
        {
            title: columnsKey ? t('name') : t('application_accept.creator'),
            dataIndex: 'creator',
            key: 'creator',
            width: 100,
            render: (_, record) => {
                if (showFull) return <span className="whitespace-break-spaces">{record.operator.name}</span>;
                return (
                    <TooltipComponent title={record.operator.name}>
                        <div className="ellipsis whitespace-normal">{record.operator.name}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: columnsKey ? t('application_accept.progress_content') : t('application_accept.content'),
            dataIndex: 'description',
            key: 'description',
            width: 200,
            render: (value) => {
                if (showFull) return <span className="whitespace-break-spaces">{value}</span>;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-normal">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('application_accept.status'),
            dataIndex: 'status',
            key: 'status',
            width: 100,
            align: 'center',
            render: (value) => t(value),
        },
        {
            title: t('application_accept.urgency'),
            dataIndex: 'priority',
            key: 'priority',
            width: 100,
            align: 'center',
            render: (value) => t(value),
        },
        {
            title: t('application_accept.operation'),
            dataIndex: 'operation',
            key: 'operation',
            width: 120,
            align: 'center',
            render: (_, record, index) => {
                if (STATUS_ACTION.includes(title.status) || record.operator.email !== title.email) {
                    return null;
                }

                if (index !== data.length - 1) {
                    return null;
                }

                return (
                    <>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey mr-2"
                            onClick={() => {
                                onEdit?.(record.key, index > 0 ? data[index - 1].status : '');
                            }}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            variant="delete"
                            className="bg-red-500 border-grey"
                            onClick={() => {
                                onDelete?.(record.key);
                            }}
                        >
                            {t('delete')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
