import { PAGE_SIZE } from '@/constants/appConstants';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { ScrollOptions } from '@/interfaces/resident';
import { debounce } from 'lodash';
import { CategoriesType, Result, SelectOptionsItem } from '@/interfaces/common/global';
import { CATEGORY_TABS, CategoryType } from '@/interfaces/category';
import { CategoryService } from '@/modules/services';

type GetCategoriesProps = {
    page?: number;
    perPage?: number;
    type?: CategoriesType;
    orderBy?: string;
};

export const useGetCategoryOptions = ({ page = 1, perPage = PAGE_SIZE, type, orderBy }: GetCategoriesProps) => {
    const [results, setResults] = useState<Result>({ options: [], total: 0 });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [scrollOption, setScrollOption] = useState<ScrollOptions>({
        page: page,
        total: 0,
        perPage: perPage,
    });

    const onHandleSearch = debounce((value: string) => {
        setSearchTerm(value);
        setScrollOption((prev) => ({
            ...prev,
            page: page,
            perPage: perPage,
        }));
    }, 500);

    const getListPhrasesOptions = async () => {
        try {
            const { data, meta }: any = await CategoryService.getCategory({
                type,
                per_page: scrollOption.perPage,
                page: scrollOption.page,
                order_by: orderBy,
                ...(searchTerm && { content: searchTerm }),
            });
            const dataResponse = data.map((item: any) => ({
                value: item.id,
                label: item.content,
            })) as SelectOptionsItem[];

            setScrollOption((prev) => ({ ...prev, total: meta.total_count }));
            setResults((prev) => {
                return {
                    ...prev,
                    options: scrollOption.page === 1 ? dataResponse : [...prev.options, ...dataResponse],
                };
            });

            return true;
        } catch (error) {
            setResults({
                total: 0,
                options: [],
            });
            return false;
        }
    };

    const { isFetching: isLoading } = useQuery({
        queryKey: ['getCategoryOptions', searchTerm, scrollOption.page, type],
        queryFn: () => getListPhrasesOptions(),
    });

    return {
        options: results.options,
        total: results.total,
        onHandleSearch,
        setScrollOption,
        isLoading,
    };
};

type CategoryReq = {
    type?: CATEGORY_TABS;
    page?: number;
    perPage?: number;
    userId?: string;
    categoryType?: CategoriesType;
    orderBy?: string;
    categoryId?: string;
    isByUser?: boolean;
};

const useGetCategories = ({
    userId,
    orderBy,
    categoryType = 'Request',
    page = 1,
    type = CATEGORY_TABS.PARENT,
    perPage = PAGE_SIZE,
}: CategoryReq) => {
    const getCategoryList = async (
        page: number,
        type: CATEGORY_TABS,
        categoryType: CategoriesType
    ): Promise<{ dataSource: CategoryType[]; total: number }> => {
        if (!userId) return;

        try {
            const res =
                type === CATEGORY_TABS.PARENT
                    ? await CategoryService.getCategory({
                          type: categoryType,
                          page,
                          per_page: perPage,
                          order_by: orderBy,
                      })
                    : await CategoryService.getChildrenCategoryList({
                          type: categoryType,
                          page,
                          per_page: perPage,
                          //   order_by: orderBy,
                      });
            const { data, meta }: any = res;
            return {
                dataSource: data.map((item: CategoryType) => ({ ...item, key: item.id })),
                total: meta.total_count,
            };
        } catch (error) {
            return { dataSource: [], total: 0 };
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getCategoryList', page, categoryType, type],
        queryFn: () => getCategoryList(page, type, categoryType),
    });

    const tableMeta = useMemo(() => {
        return {
            isDownLoadCsv: !!data?.dataSource?.length,
            dataTable: data?.dataSource || [],
            records: data?.total || 0,
            type: categoryType,
        };
    }, [data, categoryType]);

    return {
        dataSource: tableMeta.dataTable,
        total: tableMeta.records,
        isDownLoadCsv: tableMeta.isDownLoadCsv,
        isLoading,
        type: tableMeta.type,
    };
};

export const useGetCategoryChildOptions = ({
    orderBy,
    categoryType = 'Request',
    page = 1,
    perPage = PAGE_SIZE,
    categoryId,
    isByUser = false,
}: CategoryReq) => {
    const [results, setResults] = useState<Result>({ options: [], total: 0 });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [scrollOption, setScrollOption] = useState<ScrollOptions>({
        page: page,
        total: 0,
        perPage: perPage,
    });
    const mountRef = useRef(false);

    const onHandleSearch = debounce((value: string) => {
        setSearchTerm(value);
        setScrollOption((prev) => ({
            ...prev,
            page: page,
            perPage: perPage,
        }));
    }, 500);

    const getListCategoryChildOptions = async (categoryId?: string) => {
        try {
            if (!categoryId) {
                setResults({
                    total: 0,
                    options: [],
                });
                setScrollOption({
                    page: 1,
                    total: 0,
                    perPage: perPage,
                });
                return false;
            }
            const { data, meta }: any = await CategoryService.getChildrenCategoryList({
                type: categoryType,
                per_page: scrollOption.perPage,
                page: scrollOption.page,
                order_by: orderBy,
                category_id: categoryId,
                is_by_user: isByUser,
                ...(searchTerm && { content: searchTerm }),
            });

            if (data.length && mountRef.current === false) {
                mountRef.current = true;
            }

            const dataResponse = data.map((item: any) => ({
                value: item.id,
                label: item.content,
            })) as SelectOptionsItem[];

            setScrollOption((prev) => ({ ...prev, total: meta.total_count }));
            setResults((prev) => {
                return {
                    ...prev,
                    options: scrollOption.page === 1 ? dataResponse : [...prev.options, ...dataResponse],
                };
            });

            return true;
        } catch (error) {
            setResults({
                total: 0,
                options: [],
            });
            return false;
        }
    };

    const { isFetching: isLoading } = useQuery({
        queryKey: ['getCategoryChildOptions', searchTerm, scrollOption.page, categoryType, categoryId],
        queryFn: () => getListCategoryChildOptions(categoryId),
    });

    return {
        options: results.options,
        total: results.total,
        onHandleSearch,
        setScrollOption,
        isLoading,
        mountRef,
    };
};

export default useGetCategories;
