

export enum SITUATION_ENUM {
    NORMAL = 'normal',
    ABNORMAL = 'abnormal'
}
export interface VisitorData {
    id: string;
    visitor_id: string;
    visitor_name: string;
    visit_date: string;
    situation: SITUATION_ENUM;
    code: string;
    name: string;
    age: number;
    birthday: string;
    address: string;
    my_number: string;
    situation_content: string;
    remark: string;
    welfare_app_id: string | null;
    welfare_taxi: string | null;
    emergency_device: string | null;
    last_welfare_app_latest_updated_at: string | null;
    last_welfare_app_status: string | null;
}
export type VisitReport = Pick<VisitorData, 'visit_date' | 'situation' | 'situation_content'>
export type VisitReportUpdate = {
    visit_report: VisitReport;
}