'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import * as z from 'zod';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import withTrans from '@/components/common/hocs/withTrans';
import { useLocation, useNavigate } from 'react-router';
import FormInputText from '@/components/common/form/FormInputText';
import { useTranslation } from 'react-i18next';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { toast } from '@/components/ui/use-toast';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useEffect, Fragment } from 'react';
import { UpdateUser } from '@/modules/services/user.service';
import { UpdateProfileSchema } from '@/schema/authSchema';
import { observer } from 'mobx-react-lite';
import { renderLabelRole } from '@/components/common/pages/Element';
import { APP_PERMISSION } from '@/constants/permissionConfig';

const ProfileFormPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isLoading, setLoading, previous, setPrevious } = useGlobalStore();
    const { user, updateUser, isIpad } = useAuthenStore();
    const { pathname } = useLocation();

    const form = useForm<z.infer<typeof UpdateProfileSchema>>({
        resolver: zodResolver(UpdateProfileSchema),
        defaultValues: {
            user_name: '',
            email: '',
            roles: [],
        },
        mode: 'all',
    });

    const { fields } = useFieldArray({
        control: form.control,
        name: 'roles',
    });

    useEffect(() => {
        if (user.id) {
            form.reset({
                user_name: user.name,
                email: user.email,
                roles: Object.keys(user.role)
                    .filter((item: 'communication' | 'welfare') => user.role[item].length)
                    .map((item: 'communication' | 'welfare') => ({
                        tool: t(`home.${item}`),
                        role:
                            (user.role[item]?.[0] === APP_PERMISSION.MAYOR
                                ? user.district
                                    ? `${user.district} `
                                    : ''
                                : '') + t(`role_text.${user.role[item]?.[0]}`),
                    })),
            });
        }
    }, [user]);

    const onSubmit = async () => {
        try {
            setLoading(true);
            const data = form.getValues();
            const newData = {
                email: data.email,
                roles: user.roles,
                profile: {
                    name: data.user_name,
                    district: user.district,
                },
            };
            await UpdateUser(user.id, { user: newData });
            updateUser(data.user_name);
            toast({ description: t('common.MSG_017'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    const changePassword = () => {
        setPrevious({ updatePass: pathname });
        navigate('/update-password');
    };

    const onBack = () => {
        navigate(previous.profile);
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: t('profile') }]}
                    className="py-6"
                    extra={
                        <div>
                            <Button variant="default" size="xl" className="text-ip h-[42px]" onClick={changePassword}>
                                {t('change_password')}
                            </Button>
                        </div>
                    }
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    {!isIpad && (
                        <Button
                            variant="default"
                            size="xl"
                            className="text-ip h-[42px]  w-44"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={isLoading}
                        >
                            {t('update')}
                        </Button>
                    )}

                    <Button variant="outline" size="xl" className="text-ip h-[42px]  w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <div className="flex flex-col justify-center gap-y-4 max-w-[1000px]">
                    <FormInputText
                        labelClass="min-w-[300px]"
                        name="user_name"
                        placeholder={t('common.MSG_001', { field: t('account_name') })}
                        label={t('account_name')}
                        maxLength={255}
                        disabled={isIpad}
                    />
                    {fields.map((field, index) => {
                        return (
                            <Fragment key={field.id}>
                                <FormInputText
                                    required={false}
                                    labelClass="min-w-[300px]"
                                    name={`roles.${index}.tool`}
                                    disabled={true}
                                    placeholder={t('account_role')}
                                    label={renderLabelRole(index + 1, t('system'), 'ml-1')}
                                />
                                <FormInputText
                                    required={false}
                                    labelClass="min-w-[300px]"
                                    name={`roles.${index}.role`}
                                    disabled={true}
                                    placeholder={t('account_role')}
                                    label={renderLabelRole(index + 1, t('role_acc'), 'ml-1')}
                                />
                            </Fragment>
                        );
                    })}
                    <FormInputText
                        required={false}
                        labelClass="min-w-[300px]"
                        name="email"
                        disabled={true}
                        placeholder={t('common.MSG_001', { field: t('account_email') })}
                        label={t('account_email')}
                    />
                </div>
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(ProfileFormPage));
