import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { PageProps } from '@/interfaces/props/page';
import { columnsApplication } from './components/ColumnsApplication';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TableProps } from 'antd';
import { Category, CategoryType } from '@/interfaces/category';
import { toCategoryType } from '@/lib/utils';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { toast } from '@/components/ui/use-toast';
import { useCsvStore } from '@/store/csv/CsvStore';
import { convertFileNameCsv, handleErrorMessage, handleErrorNotFound } from '@/lib/helper';
import { DeleteCategoryApplication } from '@/modules/services/category.service';
import useRefreshData from '@/hooks/useRefreshData';
import { PAGE_SIZE, SURVEY_TYPE } from '@/constants/appConstants';
import { SurveyDataType } from '@/interfaces/survey';
import { CreateFileSurveyCSV, GetSurveyList } from '@/modules/services/survey.service';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const ApplicationCategoryList = (props: PageProps) => {
    const { t } = props;
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const type = 'application';
    const { setLoading } = useGlobalStore();
    const surveyType = SURVEY_TYPE.APPLICATION;
    const { refreshDataList, page, setPage } = useRefreshData({
        queryKey: ['getCategoryApplicationList', user, surveyType],
    });

    const getCategoryApplicationList = async (
        page: number
    ): Promise<{ dataSource: SurveyDataType[]; total: number }> => {
        if (user.id) {
            try {
                const response = await GetSurveyList({ page, per_page: PAGE_SIZE, survey_type: surveyType });
                const { data, meta }: any = response;
                const newData = data.map((item: any) => ({
                    ...item,
                    key: item.id,
                    number_respondent: `${item.answer_count}/${item.max_answer_number}`,
                }));
                return { dataSource: newData, total: meta.total_count };
            } catch (error) {
                return { dataSource: [], total: 0 };
            }
        }
        return null;
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getCategoryApplicationList', user, surveyType, page],
        queryFn: () => getCategoryApplicationList(page),
        placeholderData: keepPreviousData,
    });

    const onEdit = (key: string) => {
        navigate(`${key}/edit`);
    };

    const onDelete = async (key: string) => {
        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });
            if (res) {
                setLoading(true);
                await DeleteCategoryApplication(key);
                refreshDataList();
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_063',
                callback: () => refreshDataList(),
            });
            if (isNotFound) return;
            handleErrorMessage({
                error: error?.errors?.survey?.[0] || '',
                callback: (msg) => msg.includes('MSG_063') && refreshDataList(),
                defaultMessage: 'common.MSG_041',
            });
        } finally {
            setLoading(false);
        }
    };

    const onChangeTable: TableProps<CategoryType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    const onDownloadCsv = async () => {
        try {
            await CreateFileSurveyCSV(surveyType, true);
            addQueue({
                flag: `category_${type}`,
                status: 'pending',
                fileName: convertFileNameCsv({ prefixName: t(`category.application_list`) }),
                url: `users/export_csv_survey?survey_type=${surveyType}`,
                urlDownload: `users/export_csv_survey/download?survey_type=${surveyType}`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };
    const onDetail = (key: string) => {
        navigate(`${key}`);
    };
    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t(`category.application_list`) }]} />}
            isDownLoadCsv={!!data?.dataSource?.length}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableBar
                hasSelect={false}
                isCreate
                onCreate={() => navigate(`create`)}
                textCreate={t('category.application.create_new')}
            />
            <TableComponent
                loading={isLoading}
                columns={columnsApplication({ t, onDetail, onEdit, onDelete, type: toCategoryType(type) as Category })}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};
export default withTrans(observer(ApplicationCategoryList));
