import { calculatorPage } from '@/lib/helper';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const useRefreshData = ({ queryKey }: { queryKey: any[] }) => {
    const queryClient = useQueryClient();
    const [page, setPage] = useState(1);

    const refreshDataList = async (query: any[] = [], callbackFn?: (page: number) => void, queryFn?: any) => {
        const { total }: { total: number } = await queryClient.fetchQuery({
            queryKey: [...queryKey, page, ...query],
            queryFn,
        });
        const newPage = calculatorPage(total, page);
        setPage(newPage);
        callbackFn?.(page);
    };

    return {
        refreshDataList,
        page,
        setPage,
        queryClient,
    };
};

export default useRefreshData;
