import { FormField, FormLabel, FormMessage } from '@/components/ui/form';
import { ControllerRenderProps, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { CheckedState } from '@radix-ui/react-checkbox';
import React, { useRef } from 'react';
import TooltipComponent from '@/components/common/pages/ToolTip';
import * as LabelPrimitive from '@radix-ui/react-label';
import { useTranslation } from 'react-i18next';

type Option = {
    label: string;
    value: string;
    checked?: boolean;
    disabled?: boolean;
};

type Props = {
    className?: string;
    onCheckboxChange?: (
        checked: CheckedState,
        field: Record<'id', string> & Option,
        fieldContext: ControllerRenderProps<FieldValues, `${string}[${number}].checked`>
    ) => void;
    classNameCheckBox?: string;
    containerClass?: string;
    classNameLabel?: string;
    options?: Option[];
    name: string;
};

type LabelWithTooltipProps = React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    required?: boolean;
    tooltipContent?: React.ReactNode;
};

const LabelWithTooltip = ({ className, htmlFor, children, tooltipContent }: LabelWithTooltipProps) => {
    const label = useRef<HTMLLabelElement>(null);
    //const isOverflow = useIsOverflow(label);

    const renderLabel = (
        <FormLabel ref={label} htmlFor={htmlFor} className={className}>
            {children}
        </FormLabel>
    );

    return <TooltipComponent title={tooltipContent}>{renderLabel}</TooltipComponent>;
};

const FormInputCheckboxGroup = ({
    name,
    className,
    classNameLabel,
    classNameCheckBox,
    onCheckboxChange,
    options = [],
    containerClass,
}: Props) => {
    const { control } = useFormContext();
    const { t } = useTranslation();
    const { append, fields, remove } = useFieldArray({
        name,
        control,
    });

    React.useEffect(() => {
        append(options);

        return () => {
            remove(options.map((_, index) => index));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const withContainerClass = cn(
        'grid grid-cols-2 xl:grid-cols-4 select-grid-6 gap-y-2 gap-x-4 md:gap-x-10',
        containerClass
    );
    const withFieldClass = cn(`col-span-1 flex gap-2 items-start`, className);

    return (
        <div className={withContainerClass}>
            {options.length ? (
                <>
                    {fields.map((field: Record<'id', string> & Option, index) => {
                        return (
                            <div className={withFieldClass} key={field.id}>
                                <FormField
                                    name={`${name}[${index}].checked`}
                                    control={control}
                                    render={({ field: formField }) => (
                                        <>
                                            <Checkbox
                                                className={classNameCheckBox}
                                                id={formField.name}
                                                checked={formField.value}
                                                onCheckedChange={(checked) => {
                                                    formField.onChange(checked);
                                                    onCheckboxChange?.(checked, field, formField);
                                                }}
                                                disabled={field.disabled}
                                            />
                                            <LabelWithTooltip
                                                htmlFor={formField.name}
                                                className={cn('text-error font-normal', classNameLabel)}
                                                tooltipContent={field.label}
                                            >
                                                {field.label}
                                            </LabelWithTooltip>
                                        </>
                                    )}
                                />
                            </div>
                        );
                    })}
                </>
            ) : (
                <div className="col-span-2 xl:col-span-6 text-center">{t('no_data')}</div>
            )}

            <div className="col-span-2 xl:col-span-4">
                <FormMessage className="text-error" />
            </div>
        </div>
    );
};

export default FormInputCheckboxGroup;
