import { FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import DatePicker, { DatePickerProps } from 'antd/es/date-picker';
import { useFormContext } from 'react-hook-form';
import ConfigProvider from 'antd/es/config-provider';
import { FORMAT_TIME } from '@/constants/appConstants';
import { configDateTime } from '@/lib/helper';
import { FormatType } from '@/interfaces/common/global';
import { cn } from '@/lib/utils';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'dayjs/locale/ja';

type Props = {
    name: string;
    label?: string;
    labelClass?: string;
    placeholder?: string;
    showError?: boolean;
    required?: boolean;
    disabled?: boolean;
    format?: FormatType;
    extra?: React.ReactNode;
    className?: string;
    allowClear?: boolean;
    onChangeTime?: any;
    disabledTime?: any;
    inputClassName?: string;
    showNow?: boolean;
};

const FormInputDateTime = ({
    name,
    placeholder,
    extra,
    label,
    format,
    labelClass,
    className,
    inputClassName,
    allowClear = true,
    showError = true,
    disabled = false,
    required = true,
    onChangeTime,
    showNow,
    disabledTime,
    ...props
}: Props & DatePickerProps) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            disabled={disabled}
            render={({ field: { value, onChange, ...rest }, fieldState }) => (
                <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-6', className)}>
                    {label && (
                        <FormLabel
                            className={cn('text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px]', labelClass)}
                            required={required}
                        >
                            {label}
                        </FormLabel>
                    )}
                    <div className="w-full">
                        <ConfigProvider theme={configDateTime}>
                            <DatePicker
                                {...props}
                                locale={locale}
                                size="large"
                                picker={'time'}
                                className={cn(
                                    'w-full sm:w-[250px] h-[50px] pl-3 text-left font-normal text-ip border-grey',
                                    inputClassName
                                )}
                                value={value || null}
                                changeOnBlur={true}
                                showNow={showNow}
                                allowClear={allowClear}
                                format={format ?? FORMAT_TIME}
                                status={fieldState.error ? 'error' : ''}
                                placeholder={placeholder || 'HH:MM'}
                                onChange={async (value) => {
                                    if (value) {
                                        const reversedValue = onChangeTime ? await onChangeTime(value) : value;
                                        onChange(reversedValue);
                                    } else {
                                        onChange('');
                                    }
                                }}
                                renderExtraFooter={null}
                                {...rest}
                                hideDisabledOptions={true}
                                disabledMinutes={disabledTime}
                            />
                        </ConfigProvider>
                        {showError && <FormMessage className="text-error" />}
                    </div>
                    {extra}
                </FormItem>
            )}
        />
    );
};

export default FormInputDateTime;
