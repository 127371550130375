'use client';

import { Dispatch, SetStateAction, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { CaretSortIcon, Cross2Icon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useTranslation } from 'react-i18next';
import { SelectOptionsItem } from '@/interfaces/common/global';
import TooltipComponent from '@/components/common/pages/ToolTip';
import SelectedContent from '@/components/common/form/components/SelectedContent';
import { ScrollOptions } from '@/interfaces/resident';

type SelectComponentProps = {
    options?: SelectOptionsItem[];
    label?: string;
    defaultValue?: string;
    disabled?: boolean;
    hasError?: boolean;
    hasSearch?: boolean;
    className?: string;
    onChangeSelect?: (value: string | SelectOptionsItem) => void;
    handleSearch?: (value: string) => void;
    hasGetDataOptions?: boolean;
    showValue?: boolean;
    isLoad?: boolean;
    setScrollOption?: Dispatch<SetStateAction<ScrollOptions>>;
    width?: string;
};

const SelectComponent = forwardRef(function SelectComponent(
    {
        defaultValue,
        label,
        onChangeSelect,
        disabled,
        className,
        options = [],
        handleSearch,
        setScrollOption,
        isLoad,
        width,
        hasError = false,
        hasSearch = true,
        hasGetDataOptions = false,
        showValue = true,
    }: Readonly<SelectComponentProps>,
    ref
) {
    const [open, setOpen] = useState(false);
    const [option, setOption] = useState<SelectOptionsItem>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (defaultValue) {
            const option = options?.find((item) => item.value === defaultValue);
            setOption(option);
        } else {
            setOption(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const onClearValue = () => setOption(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                onClearValue,
            };
        },
        []
    );

    const handleOpenChange = (value: boolean) => {
        setOpen(value);
        handleSearch?.('');
    };

    return (
        <Popover open={open} onOpenChange={handleOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    size="lg"
                    onClick={(e) => disabled && e.preventDefault()}
                    className={cn(
                        'w-full sm:w-[360px] justify-between text-[19px] disabled:opacity-1 group h-[50px]',
                        hasError ? 'border-destructive' : 'border-grey ',
                        disabled && 'cursor-default bg-gray-200 hover:bg-gray-200',
                        width,
                        className
                    )}
                >
                    {showValue && option?.value ? (
                        <TooltipComponent title={option?.label} antdTooltip>
                            <div className="max-w-[300px] whitespace-break-spaces ellipsis">{option?.label}</div>
                        </TooltipComponent>
                    ) : (
                        <span className="text-muted-foreground truncate">
                            {label || t('common.MSG_002', { field: t('select_civil') })}
                        </span>
                    )}

                    <Cross2Icon
                        className={cn(
                            'shrink-0 opacity-50 h-6 w-6 hidden',
                            showValue && option?.value && !disabled && 'block'
                        )}
                        onClick={(e: any) => {
                            onClearValue();
                            onChangeSelect('');
                            handleSearch?.('');
                            e.stopPropagation();
                        }}
                    />
                    <CaretSortIcon
                        className={cn('ml-2 h-7 w-7 shrink-0 opacity-50', showValue && option?.value && 'hidden')}
                    />
                </Button>
            </PopoverTrigger>
            <PopoverContent className={cn('w-[350px] p-0', width)}>
                <SelectedContent
                    showValue={showValue}
                    isLoading={isLoad}
                    isShowSearch={hasSearch}
                    value={option?.value}
                    options={options}
                    setValue={setOption}
                    onChange={onChangeSelect}
                    setOpen={setOpen}
                    handleSearch={handleSearch}
                    setScrollOption={setScrollOption}
                    hasGetDataOptions={hasGetDataOptions}
                />
            </PopoverContent>
        </Popover>
    );
});

export { SelectComponent };
