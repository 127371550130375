import { ApplicationReqType, ProcessReqType } from '@/interfaces/application';
import { Params } from '@/interfaces/common/global';
import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';

export const GetApplicationAcceptList = async (userId: string, params?: Params & { resident_id?: string }) => {
    return await apiServiceWithAuth.get(`users/${userId}/residents/welfare_apps`, { params });
};

export const GetAllApplicationList = async (params?: Params) => {
    return await apiServiceWithAuth.get(`users/residents/welfare_apps`, { params });
};

export const GetDetailApplicationByResident = async (id: string, residentId: string) => {
    return await apiServiceWithAuth.get(`users/residents/${residentId}/welfare_apps/${id}`);
};

export const GetDetailApplication = async (id: string) => {
    return await apiServiceWithAuth.get(`users/residents/welfare_apps/${id}`);
};

export const CreateProcessReport = async (
    userId: string,
    residentId: string,
    welfareId: string,
    body: ProcessReqType
) => {
    return await apiServiceWithAuth.post(
        `users/${userId}/residents/${residentId}/welfare_apps/${welfareId}/progresses`,
        body
    );
};

export const UpdateProcessReport = async (welfareId: string, processId: string, body: ProcessReqType) => {
    return await apiServiceWithAuth.patch(`users/residents/welfare_apps/${welfareId}/progresses/${processId}`, body);
};

export const GetDetailProcessReport = async (welfareId: string, processId: string) => {
    return await apiServiceWithAuth.get(`users/residents/welfare_apps/${welfareId}/progresses/${processId}`);
};

export const DeleteProcessReport = async (welfareId: string, processId: string) => {
    return await apiServiceWithAuth.delete(`users/residents/welfare_apps/${welfareId}/progresses/${processId}`);
};

export const CreateFileApplicationCSV = async (userId: string) => {
    return await apiServiceWithAuth.post(`users/residents/export_csv_welfare_app`, {
        visitor_id: userId,
    });
};

export const CreateFileProcessCSV = async (userId: string) => {
    return await apiServiceWithAuth.post(`users/residents/welfare_apps/${userId}/export_csv_progress`);
};

export const createRequestApplication = async (userId: string, id: string, body: ApplicationReqType) => {
    return await apiServiceWithAuth.post(`users/${userId}/residents/${id}/welfare_apps`, body);
};

export const updateRequestApplication = async (id: string, body: ApplicationReqType) => {
    return await apiServiceWithAuth.patch(`users/residents/welfare_apps/${id}`, body);
};
