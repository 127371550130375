import { FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import DatePicker, { DatePickerProps } from 'antd/es/date-picker';
import { useFormContext } from 'react-hook-form';
import dayjs from '@/lib/dayjs';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'dayjs/locale/ja';
import ConfigProvider from 'antd/es/config-provider';
import { FORMAT_DATE, FORMAT_DATE_REQ } from '@/constants/appConstants';
import { configDateTime, disableMinMaxDate } from '@/lib/helper';
import { FormatType } from '@/interfaces/common/global';
import { cn } from '@/lib/utils';
import { Dayjs } from 'dayjs';

type Props = {
    name: string;
    label?: string;
    labelClass?: string;
    placeholder?: string;
    showError?: boolean;
    required?: boolean;
    disableMinMaxDate?: any;
    disabled?: boolean;
    format?: FormatType;
    picker?: 'date | time';
    extra?: React.ReactNode;
    disabledDate?: (value: Dayjs) => boolean;
    className?: string;
    dateClass?: string;
};

const FormInputDate = ({
    name,
    placeholder,
    extra,
    label,
    disabledDate,
    format,
    labelClass,
    className,
    showError = true,
    disabled = false,
    required = true,
    dateClass,
    ...props
}: Props & DatePickerProps) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            disabled={disabled}
            render={({ field: { value, onChange, ...rest }, fieldState }) => (
                <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-6', className)}>
                    {label && (
                        <FormLabel
                            className={cn('text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px]', labelClass)}
                            required={required}
                        >
                            {label}
                        </FormLabel>
                    )}
                    <div className="w-full">
                        <ConfigProvider theme={configDateTime}>
                            <DatePicker
                                {...props}
                                size="large"
                                disabledDate={(d) => {
                                    return disabledDate?.(d) || disableMinMaxDate(d);
                                }}
                                className={cn('w-full sm:w-[250px] h-[50px] pl-3 text-left font-normal text-ip ', dateClass)}
                                value={value ? dayjs(value) : null}
                                changeOnBlur={true}
                                locale={locale}
                                format={format ?? FORMAT_DATE}
                                status={fieldState.error ? 'error' : ''}
                                placeholder={placeholder || FORMAT_DATE}
                                onChange={(value) => onChange(value ? dayjs(value).format(FORMAT_DATE_REQ) : undefined)}
                                onBlur={(value) => onChange(value ? dayjs(value).format(FORMAT_DATE_REQ) : undefined)}
                                {...rest}
                            />
                        </ConfigProvider>
                        {showError && <FormMessage className="text-error" />}
                    </div>
                    {extra}
                </FormItem>
            )}
        />
    );
};

export default FormInputDate;
