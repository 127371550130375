export const validateToken = (token: string | undefined) => {
    try {
        const result = parseJwt(token);
        const exp = result?.exp || (0 as number);
        if (Date.now() >= Number(exp) * 1000) {
            return false;
        }
    } catch (error) {
        return false;
    }
    return true;
};

export function parseJwt<T extends object = { [k: string]: string | number }>(token: string): T | undefined {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}
