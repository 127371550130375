import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { observer } from 'mobx-react-lite';
import { TOKEN_NAME } from '@/constants/appConstants';
import { validateToken } from '@/lib/token';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { GetProfileUser } from '@/modules/services/auth.service';
import { useQuery } from '@tanstack/react-query';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { UserType } from '@/interfaces/auth';

type PrivateRouterProps = {
    element: JSX.Element;
};

const PrivateRoute = ({ element }: PrivateRouterProps & WithTransProps) => {
    const [authToken] = useLocalStorage(TOKEN_NAME, null, { raw: true });
    const { isAuthenticated, setUser, clearData } = useAuthenStore();

    const authenticated = validateToken(authToken) || isAuthenticated;

    const getProfile = async () => {
        if (authenticated) {
            try {
                const { data } = await GetProfileUser();
                if (data) {
                    const user: UserType = {
                        ...data,
                    };
                    setUser(user);
                    return user;
                }
            } catch (error) {
                clearData();
                return false;
            }
        }
    };

    useQuery({ queryKey: ['getProfile'], queryFn: getProfile, refetchOnWindowFocus: true });

    if (!authenticated) {
        localStorage.clear();
        return <Navigate to="/login" replace={true} state={{ from: window.location.pathname }} />;
    }

    return element;
};

export default withTrans(observer(PrivateRoute));
