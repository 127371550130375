import { z } from 'zod';
import i18n from '@/modules/i18n';
import { REGEX_EMAIL } from '@/lib/regex';

const { t } = i18n;
export const EditAccountSchema = z
    .object({
        name: z.any(),
        email: z.any(),
        communication: z.any(),
        welfare: z.any(),
        profile: z.any(),
        roles: z.any(),
        district: z.any(),
    })
    .superRefine((val, ctx) => {
        if (!val.name) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['name'],
                message: t('common.MSG_001', { field: t('account.create_new_account.name') }),
            });
        }
        if (!val.email) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['email'],
                message: t('common.MSG_001', { field: t('account.create_new_account.email') }),
            });
        }
        const isRegexEmail = REGEX_EMAIL.test(val.email);
        if (!isRegexEmail) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['email'],
                message: t('common.MSG_003'),
            });
        }

        const iscommunication = Object.keys(val.communication).some((item: string) => val.communication[item] === true);
        const isWelfare = Object.keys(val.welfare).some((item: string) => val.welfare[item] === true);

        if (!iscommunication && !isWelfare) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['roles'],
                message: t('common.MSG_002', { field: t('account.create_new_account.role') }),
            });
        }

        const isMyor = val.communication.mayor;
        const district = val.district;

        if (isMyor && !district) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['district'],
                message: t('common.MSG_002', {
                    field: t('account.create_new_account.communication_system.district'),
                }),
            });
        }
    });
