import { TYPE_ANSWER } from '@/constants/appConstants';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import QuestionOptionForm from '@/pages/survey/question/form/QuestionOptionForm';
import FormSelect from '@/components/common/form/FormSelect';

type QuestionTypeFormProps = {
    name: string;
    numberQuestion: number;
};

const QuestionTypeForm = ({ name, numberQuestion }: QuestionTypeFormProps) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const options = [
        {
            value: 'text',
            label: t('survey.text'),
        },
        {
            value: 'multiple_choice',
            label: t('survey.multiple_choice'),
        },
    ];

    const type = useWatch({
        control,
        name: `${name}.question_type`,
    });

    return (
        <>
            <FormSelect
                className="mt-4"
                name={`${name}.question_type`}
                options={options}
                label={
                    <>
                        <span className="rounded-[50%] border px-[6px] border-ring text-[14px] flex items-center h-[22px]">
                            {numberQuestion}
                        </span>
                        {t('survey.answer')}
                    </>
                }
                placeholder={t('common.MSG_002', { field: t('survey.answer') })}
                hasSearch={false}
            />
            {type === TYPE_ANSWER.MULTIPLE_CHOICE && <QuestionOptionForm name={`${name}.options`} />}
        </>
    );
};

export default QuestionTypeForm;
