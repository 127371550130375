import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useQuery } from '@tanstack/react-query';
import { TableProps } from 'antd';
import { useNavigate } from 'react-router';
import { SURVEY_TYPE } from '@/constants/appConstants';
import { columnsTableIPComApplication } from '@/pages/ipad-reports/IPComApplication/components/IPComApplicationListColumns';
import { ApplicationType } from '@/interfaces/report';
import { getReportApplicationList } from '@/modules/services/request.service';
import { useState } from 'react';

const IPComApplicationList = (props: PageProps) => {
    const { t } = props;
    const { isLoading, setLoading } = useGlobalStore();
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const surveyType = SURVEY_TYPE.APPLICATION;

    const [page, setPage] = useState(1);

    const { data, isFetching } = useQuery({
        queryKey: ['getComApplications', surveyType, page],
        queryFn: () => getComApplications(page),
    });
    const onChangeTable: TableProps<ApplicationType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };
    const getComApplications = async (page: number): Promise<{ dataSource: ApplicationType[]; total: number }> => {
        if (user.id) {
            try {
                setLoading(true);
                const response = await getReportApplicationList({ page, per_page: 10, survey_type: surveyType });
                const { data, meta }: any = response;
                const newData = data.map((item: any) => ({
                    ...item,
                    key: item.id,
                    number_respondent: `${item.answer_count}/${item.max_answer_number}`,
                }));
                return { dataSource: newData, total: meta.total_count };
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };
    const onAnswer = (key: string) => {
        const item = data.dataSource.find((el: ApplicationType) => {
            return el.id === key;
        });
        navigate(`${item.id}`);
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: t('report.ip_application_title'),
                        },
                    ]}
                    className="py-6"
                />
            }
            isDownLoadCsv={false}
            pageLoading={isLoading}
        >
            <TableComponent
                loading={isFetching}
                columns={columnsTableIPComApplication({ t, onAnswer })}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};
export default withTrans(observer(IPComApplicationList));
