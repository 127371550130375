import React from 'react';
import { makeAutoObservable } from 'mobx';
import { LocationType } from '@/interfaces/common/global';
import { makePersistable } from 'mobx-persist-store';

class GlobalStore {
    isLoading = false;
    isSearch = false;
    location: LocationType = null;
    module = '';
    previous = {
        profile: '/',
        updatePass: '/',
    };

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'GlobalStore',
            properties: ['module', 'previous'],
            storage: window.localStorage,
        });
    }

    setLoading = (isLoad: boolean) => {
        this.isLoading = isLoad;
    };

    setLocation = (data: LocationType) => {
        this.location = data;
    };

    setModule = (data: string) => {
        this.module = data;
    };

    setIsSearch = (isSearching: boolean) => {
        this.isSearch = isSearching;
    };

    setPrevious = (data: { [key: string]: string }) => {
        this.previous = {
            ...this.previous,
            ...data,
        };
    };
}

export const globalStoreInternal = new GlobalStore();
const storeContext = React.createContext(globalStoreInternal);
export const useGlobalStore = (): GlobalStore => React.useContext(storeContext);
