import { cn } from '@/lib/utils';
import { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { RenderFooterProps } from '@/components/common/pages/CarouseComponent';
import { ImageType } from '@/interfaces/report';
import { ConfigProvider, Image } from 'antd';
import { Eye } from 'lucide-react';
import { AspectRatio } from '@/components/ui/aspect-ratio';

export const WrapItem = ({ children, className }: PropsWithChildren & { className?: string }) => {
    return <div className={cn('grid grid-cols-1 xl:grid-cols-7 xl:gap-x-6 gap-y-6 mt-6', className)}>{children}</div>;
};
export const Item = ({
    data = '',
    label,
    children,
    labelClass,
    className,
}: { data?: string; label?: ReactNode; labelClass?: string; className?: string } & PropsWithChildren) => {
    return (
        <div className={cn('flex flex-wrap md:flex-nowrap gap-6', className)}>
            {label && <span className={cn('min-w-[180px] xl:min-w-[170px] font-medium', labelClass)}>{label}</span>}
            {children ||
                (data && (
                    <div className="w-full py-2 px-2 border border-grey rounded-md overflow-hidden text-ellipsis whitespace-break-spaces">
                        {data || ''}
                    </div>
                ))}
        </div>
    );
};

export const renderItem = ({
    label,
    value,
    wrapClass,
    labelClass,
    className,
}: {
    label: string;
    value: string | number;
    wrapClass?: string;
    labelClass?: string;
    className?: string;
}) => {
    return (
        <div className={cn('flex flex-col gap-2', wrapClass)}>
            <span className={cn('text-pc font-medium', labelClass)}>{label}</span>
            <span className={cn('text-pc break-all whitespace-break-spaces', className)}>{value || ''}</span>
        </div>
    );
};

export const WrapInformationResident = ({ children, className }: PropsWithChildren & { className?: string }) => {
    return <div className={cn('flex flex-col justify-around gap-4', className)}>{children}</div>;
};

export const renderFooterCarouse = ({ data, current, handleSelect }: RenderFooterProps<ImageType>) => {
    const handleOnError = (e: SyntheticEvent<HTMLImageElement, any>) => {
        const target = e.target as HTMLImageElement;
        target.src = '/assets/default.jpg';
    };
    return (
        <div className="flex gap-2 w-full justify-center mt-2">
            {data.map((item, index) => {
                return (
                    <img
                        alt="default"
                        src={item?.url || '/assets/default.jpg'}
                        className={cn(
                            'w-[60px] h-[60px] object-cover rounded-sm cursor-pointer p-1 ',
                            current === index + 1 && 'border border-grey'
                        )}
                        onClick={() => handleSelect(index)}
                        key={index}
                        loading="lazy"
                        onError={handleOnError}
                    />
                );
            })}
        </div>
    );
};

export const renderItemCarouse = (item: ImageType) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: 'rgba(0, 0, 0, 0.9)',
                },
            }}
        >
            <AspectRatio ratio={16 / 9}>
                <Image
                    alt="default"
                    wrapperClassName="h-full"
                    className="!h-full !object-contain"
                    src={item?.url || '/assets/default.jpg'}
                    preview={{
                        mask: <Eye className="w-8 h-8" />,
                    }}
                    fallback={'/assets/default.jpg'}
                />
            </AspectRatio>
        </ConfigProvider>
    );
};

export const renderLabel = (index: number, label: string, className?: string) => {
    return (
        <>
            <span
                className={cn(
                    'rounded-[50%] border px-[6px] border-ring text-[14px] flex items-center h-[22px] ',
                    className
                )}
            >
                {index}
            </span>
            {label}
        </>
    );
};

export const renderLabelRole = (index: number, label: string, className?: string) => {
    return (
        <>
            {label}
            <span
                className={cn(
                    'rounded-[50%] border px-[6px] border-ring text-[14px] flex items-center h-[22px] ',
                    className
                )}
            >
                {index}
            </span>
        </>
    );
};

export const renderText = (label: string) => {
    return <span className="break-all w-full">{label || ''}</span>;
};

export const ColumnsToolTip = ({ value, className }: { value: string; className?: string }) => {
    return (
        <TooltipComponent title={value}>
            <div className={cn('ellipsis whitespace-break-spaces', className)}>{value}</div>
        </TooltipComponent>
    );
};
