import { useEffect } from 'react';

const defaultOption = {
    threshold: 1.0,
};

const useInfinityScroll = (elementRef: any, cb: () => void, isSearching: boolean,options = defaultOption) => {
    useEffect(() => {
        if (elementRef.current) {
            const observer = new IntersectionObserver((entry) => {
                if (entry[0].isIntersecting) {
                    cb();
                }
            }, options);
            observer.observe(elementRef.current);
            return () => {
                if (elementRef.current) {
                    observer.unobserve(elementRef.current);
                }
            };
        }
    }, [elementRef, isSearching]);
};

export default useInfinityScroll;
