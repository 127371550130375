import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { TFunction } from 'react-i18next';
import { ResidentType } from '@/interfaces/resident';
import TooltipComponent from '@/components/common/pages/ToolTip';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE_TIME } from '@/constants/appConstants';
import { renderEachAppServiceColumns } from '@/components/common/table/Columns';
import { convertStringMyNumber } from '@/lib/helper';

type ColumnsProps = {
    t: TFunction;
    onDetail?: (key: string, visitorId?: string, visitorName?: string) => void;
    onEdit?: (key: string, visitorId: string, visitorName: string) => void;
    onDelete?: (data: ResidentType) => void;
    columnsKey?: string[];
    showFull?: boolean;
};

export const columns = ({
    t,
    onEdit,
    columnsKey,
    onDelete,
    showFull,
}: ColumnsProps): TableProps<ResidentType>['columns'] => {
    const allColumns: TableProps<ResidentType>['columns'] = [
        {
            title: <div className="text-center">{t('visit_report.select_civil')}</div>,
            dataIndex: 'visitor_name',
            key: 'visitor_name',
            width: 100,
            align: 'start',
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('resident.no'),
            dataIndex: 'code',
            key: 'code',
            width: 80,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('resident.name')}</div>,
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (value) => {
                if (showFull) return value;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('resident.age'),
            dataIndex: 'age',
            key: 'age',
            width: 80,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('resident.address')}</div>,
            dataIndex: 'address',
            key: 'address',
            width: 150,
            render: (value) => {
                if (showFull) return value;
                return (
                    <TooltipComponent antdTooltip={false} title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('resident.my_phone'),
            dataIndex: 'my_number',
            key: 'my_number',
            align: 'center',
            width: 135,
            render: (value) => convertStringMyNumber(value),
        },
        ...renderEachAppServiceColumns(t),
        {
            title: <div className="text-center">{t('resident.remarks')}</div>,
            dataIndex: 'remark',
            key: 'remark',
            width: 150,
            render: (value) => {
                if (showFull) return value;
                return (
                    <TooltipComponent title={value} antdTooltip={false}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 150,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey mr-2"
                            onClick={() => {
                                onEdit?.(record.key, record.visitor_id, record.visitor_name);
                            }}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            variant="delete"
                            className="bg-red-500 border-grey"
                            onClick={() => {
                                onDelete?.(record);
                            }}
                        >
                            {t('delete')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const columnsCommissionner = ({
    t,
    onEdit,
    onDetail,
    columnsKey,
    showFull,
}: ColumnsProps): TableProps<ResidentType>['columns'] => {
    const allColumns: TableProps<ResidentType>['columns'] = [
        {
            title: t('action'),
            dataIndex: 'detail',
            key: 'detail',
            width: 90,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="default"
                            className="m-1"
                            onClick={() => {
                                onDetail?.(record.key);
                            }}
                        >
                            {t('detail')}
                        </Button>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey m-1"
                            onClick={() => {
                                onEdit?.(record.key, record.visitor_id, record.visitor_name);
                            }}
                        >
                            {t('edit')}
                        </Button>
                    </>
                );
            },
        },
        {
            title: t('visit_report.no'),
            dataIndex: 'code',
            key: 'code',
            width: 110,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('name')}</div>,
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (value) => {
                if (showFull) return value;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('resident.age'),
            dataIndex: 'age',
            key: 'age',
            width: 60,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('resident.address')}</div>,
            dataIndex: 'address',
            key: 'address',
            width: 200,
            render: (value) => {
                if (showFull) return value;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const columnsResidentReport = ({
    t,
    onDetail,
    columnsKey,
    showFull,
}: ColumnsProps): TableProps<ResidentType>['columns'] => {
    const allColumns: TableProps<ResidentType>['columns'] = [
        {
            title: t('resident.report'),
            dataIndex: 'report',
            key: 'report',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return (
                    <Button
                        variant="default"
                        onClick={() => {
                            onDetail?.(record.key);
                        }}
                    >
                        {t('resident.report')}
                    </Button>
                );
            },
        },
        {
            title: t('visit_report.detail'),
            dataIndex: 'detail',
            key: 'detail',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return (
                    <Button
                        variant="default"
                        onClick={() => {
                            onDetail?.(record.key);
                        }}
                    >
                        {t('detail')}
                    </Button>
                );
            },
        },
        {
            title: t('resident.latest_report_date'),
            dataIndex: 'visit_date',
            key: 'visit_date',
            width: 90,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE_TIME) : ''),
        },
        {
            title: t('visit_report.no'),
            dataIndex: 'code',
            key: 'code',
            width: 90,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('name')}</div>,
            dataIndex: 'name',
            key: 'name',
            width: 140,
            render: (value) => {
                if (showFull) return value;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('visit_report.age'),
            dataIndex: 'age',
            key: 'age',
            width: 55,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('visit_report.address')}</div>,
            dataIndex: 'address',
            key: 'address',
            width: 160,
            render: (value) => {
                if (showFull) return value;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        ...renderEachAppServiceColumns(t),
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
