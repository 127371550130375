import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import MenuCommon from '@/components/common/menu';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router';
import GeneralContacts from '@/pages/dashboad-menu/components/GeneralContacts';
import { MENU_TOOL } from '@/constants/menu';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { COMPANY_PHONE, DIVISION_PHONE } from '@/constants/appConstants';
import React, { useEffect, useState } from 'react';
import { MenuButtonProps } from '@/interfaces/common/menu';
import { cn } from '@/lib/utils';

type DashboardMenu = {
    module: 'communication' | 'welfare';
};

const DashboardMenu = ({ module, t }: DashboardMenu & WithTransProps) => {
    const navigate = useNavigate();
    const { isIpad, user } = useAuthenStore();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsFirstLoad(false);
        }, 700);

        return () => {
            setIsFirstLoad(true)
        };
    }, []);
    const currR = user?.role?.[module][0];

    const menuItems = MENU_TOOL[module]?.menu?.[currR] || [];

    const onBack = () => {
        navigate('/');
    };

    const handleClick = (path: string) => () => {
        navigate(path);
    };

    const renderMenuItems = (isIpad: boolean) => {
        const WrapItem = isIpad ? React.Fragment : 'div';
        const WrapProps = !isIpad && { className: 'flex lg:gap-4 flex-wrap lg:flex-nowrap flex-col' };
        return <WrapItem {...WrapProps}>{menuItems.map((element: any) => renderButton(element, isIpad))}</WrapItem>;
    };

    const renderButton = (menus: MenuButtonProps, isIpad: boolean) => {
        const WrapItem = isIpad ? React.Fragment : MenuCommon;
        const WrapProps = !isIpad && { menuTitle: t(menus.label) };
        const WrapButton = isIpad ? 'div' : React.Fragment;

        return (
            <WrapItem {...WrapProps} key={menus.key}>
                {menus.children.map((el) => (
                    <WrapButton
                        {...(user?.roles && isIpad
                            ? el.col === 2
                                ? { className: 'col-span-2' }
                                : { className: 'max-[530px]:col-span-2 ' }
                            : {})}
                        key={el.key}
                    >
                        <Button
                            variant="outline"
                            size="xxl"
                            className={cn(
                                'border-black',
                                isIpad ? 'w-full h-[115px] text-title' : 'w-full sm:w-[260px] h-[78px]',
                                isFirstLoad ? ' pointer-events-none' : 'pointer-events-auto'
                            )}
                            onClick={handleClick(el.path)}
                        >
                            <div>
                                <div className="text-sm">{t(el.description)}</div>
                                <div>{t(el.label)}</div>
                            </div>
                        </Button>
                    </WrapButton>
                ))}
            </WrapItem>
        );
    };

    const renderContact = (isIpad?: boolean) => {
        const WrapItem = isIpad ? 'div' : React.Fragment;
        const props = isIpad && { className: 'col-span-2 ' };

        return (
            <WrapItem {...props}>
                {isIpad && (
                    <GeneralContacts
                        label={t('contact.point')}
                        name={t('contact.division')}
                        contact={DIVISION_PHONE}
                        className="pt-4 lg:pt-6"
                    />
                )}
                <GeneralContacts
                    className="pt-4 lg:pt-6"
                    label={isIpad ? t('contact.trouble_counter') : t('contact.general_counter')}
                    name={t('contact.company')}
                    contact={COMPANY_PHONE}
                />
            </WrapItem>
        );
    };

    const renderBackButton = (isIpad?: boolean) => (
        <div className={cn('w-full text-center', isIpad ? 'col-span-2' : 'py-4')}>
            <Button variant="default" size="xxl" className="text-ip w-full sm:w-44" onClick={onBack}>
                {t('go_back')}
            </Button>
        </div>
    );
    return (
        <div
            className={cn(
                'px-4 lg:px-16 py-8 flex flex-col min-h-[calc(100vh-210px)] justify-between m-auto',
                isIpad ? 'min-h-[calc(100vh-280px)]' : ''
            )}
        >
            <div className={cn(isIpad ? 'max-w-[1024px] grid grid-cols-2 gap-4 sm:gap-[20px] lg:gap-8' : '')}>
                {renderMenuItems(isIpad)}
                {renderBackButton(isIpad)}
            </div>
            {renderContact(isIpad)}
        </div>
    );
};
export default withTrans(DashboardMenu);
