import { observer } from 'mobx-react-lite';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { cn } from '@/lib/utils';
import React from 'react';

type ButtonItem = {
    label: string;
    onClick?: () => void;
    className?: string;
};

type Props = {
    buttons: ButtonItem[];
};

const GroupButton = (props: Props & WithTransProps) => {
    const { t, buttons } = props;

    const renderButton = ({ label, onClick, className }: ButtonItem) => {
        return (
            <span
                onClick={() => onClick?.()}
                className={cn(
                    'text-title rounded-[14px] border-solid col-span-1 hover:cursor-pointer py-[60px] text-center align-middle border-[1px] border-grey',
                    className
                )}
            >
                {label || t('visit_report.normal')}
            </span>
        );
    };

    return (
        <div className="grid grid-cols-2 gap-6">
            {buttons.map((item) => (
                <React.Fragment key={item.label}>{renderButton(item)}</React.Fragment>
            ))}
        </div>
    );
};

export default withTrans(observer(GroupButton));
