import { observer } from 'mobx-react-lite';
import FormSelect from '@/components/common/form/FormSelect';
import FormInputText from '@/components/common/form/FormInputText';
import { FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import FormInputCheckbox from '@/components/common/form/FormInputCheckbox';
import { useTranslation } from 'react-i18next';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { checkRoleByUser } from '@/lib/helper';
import { ROLE_PERMISSION } from '@/constants/permissionConfig';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetDataDistricts } from '@/hooks/useGetUsers';

type Props = {
    type?: 'created' | 'edit';
    data?: any;
};

const AccountForm = ({ type = 'created', data }: Props) => {
    const { t } = useTranslation();
    const { control, watch, clearErrors, setValue } = useFormContext();
    const { user } = useAuthenStore();
    const role = checkRoleByUser(user);
    const { options, onHandleSearch, isLoading } = useGetDataDistricts();

    const [isLocalGovCommunication, setLocalGovCommunication] = useState(false);
    const [isMayor, setMayor] = useState(false);
    const [isRepresentative, setRepresentative] = useState(false);
    const [isLocalGovWelfare, setLocalGovWelfare] = useState(false);
    const [isCommissioner, setIsCommissioner] = useState(false);
    const [isLocalGovCommunicationChecked, setLocalGovCommunicationChecked] = useState(false);
    const [isMayorChecked, setMayorChecked] = useState(false);
    const [isRepresentativeChecked, setRepresentativeChecked] = useState(false);
    const [isLocalGovWelfareChecked, setLocalGovWelfareChecked] = useState(false);
    const [isCommissionerChecked, setIsCommissionerChecked] = useState(false);

    useEffect(() => {
        const subscription = watch((value, { name: fieldName }) => {
            if (fieldName?.includes('communication') || fieldName?.includes('welfare')) {
                const iscommunication = Object.keys(value.communication).some(
                    (item: string) => value.communication[item] === true
                );
                const isWelfare = Object.keys(value.welfare).some((item: string) => value.welfare[item] === true);
                (iscommunication || isWelfare) && clearErrors('roles');
                fieldName.includes('communication') && !value.communication.mayor && clearErrors('district');
                if (
                    (fieldName.includes('communication.local_gov_communication') ||
                        fieldName.includes('communication.representative') ||
                        fieldName.includes('communication.mayor')) &&
                    !value.communication.mayor &&
                    !!value.district
                ) {
                    setValue('district', null);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const isRoleMayor = watch('communication.mayor');

    const checkDisableByRole = () => {
        if (role === 'R2') {
            setIsCommissioner(true);
            setLocalGovWelfare(true);
        } else if (role === 'R3') {
            setLocalGovCommunication(true);
            setMayor(true);
            setRepresentative(true);
        }
    };

    const clearDisable = () => {
        setLocalGovWelfare(false);
        setIsCommissioner(false);
        setLocalGovCommunication(false);
        setMayor(false);
        setRepresentative(false);
    };
    useEffect(() => {
        checkDisableByRole();
    });
    const disableCheckboxBySelect = (checked: any, field: any) => {
        const permissions = {
            [ROLE_PERMISSION.LOCAl_GOV_COMMUNICATION]: [
                setMayor,
                setRepresentative,
                setIsCommissioner,
                setLocalGovCommunicationChecked,
            ],
            [ROLE_PERMISSION.MAYOR]: [setLocalGovCommunication, setRepresentative, setLocalGovWelfare, setMayorChecked],
            [ROLE_PERMISSION.REPRESENTATIVE]: [
                setLocalGovCommunication,
                setMayor,
                setLocalGovWelfare,
                setRepresentativeChecked,
            ],
            [ROLE_PERMISSION.LOCAL_GOV_WELFARE]: [
                setMayor,
                setRepresentative,
                setIsCommissioner,
                setLocalGovWelfareChecked,
            ],
            [ROLE_PERMISSION.COMMISSIONER]: [setLocalGovWelfare, setLocalGovCommunication, setIsCommissionerChecked],
        };
        const setters = permissions[field.name || field];

        const clearDisableConditions = {
            [ROLE_PERMISSION.LOCAl_GOV_COMMUNICATION]: {
                setter: setLocalGovCommunicationChecked,
                dependentCheck: !isLocalGovWelfareChecked,
            },
            [ROLE_PERMISSION.MAYOR]: {
                setter: setMayorChecked,
                dependentCheck: !isCommissionerChecked,
                dependentAction: setRepresentative,
            },
            [ROLE_PERMISSION.REPRESENTATIVE]: {
                setter: setRepresentativeChecked,
                dependentCheck: !isCommissionerChecked,
                dependentAction: setMayor,
            },
            [ROLE_PERMISSION.LOCAL_GOV_WELFARE]: {
                setter: setLocalGovWelfareChecked,
                dependentCheck: !isLocalGovCommunicationChecked,
            },
            [ROLE_PERMISSION.COMMISSIONER]: {
                setter: setIsCommissionerChecked,
                dependentCheck: !isMayorChecked && !isRepresentativeChecked,
            },
        };

        if (checked && setters) {
            setters.forEach((setter) => setter(true));
        } else if (!checked && field.name && clearDisableConditions[field.name]) {
            const { setter, dependentCheck, dependentAction } = clearDisableConditions[field.name];
            setter(false);
            if (dependentCheck) {
                clearDisable();
            } else if (dependentAction) {
                dependentAction(false);
            }
        }
    };
    const disableSelectByUser = () => {
        if (data.name) {
            const role = { ...data.communication, ...data.welfare };
            for (const key in role) {
                if (Object.hasOwnProperty.call(role, key)) {
                    const value = role[key];
                    disableCheckboxBySelect(value || false, `communication.${key}`);
                    disableCheckboxBySelect(value || false, `welfare.${key}`);
                }
            }
        }
    };

    useMemo(() => {
        if (type === 'edit') {
            disableSelectByUser();
        }
    }, [data]);

    return (
        <div className="flex flex-col gap-y-4 max-w-[1000px] mt-4">
            <FormField
                control={control}
                name="roles"
                render={() => (
                    <div className="flex flex-wrap md:flex-nowrap gap-4">
                        <div>
                            <FormLabel className="text-[21px] min-w-[208px]" required>
                                {t('account.create_new_account.role')}
                            </FormLabel>
                        </div>
                        <div className="gap-2 sm:flex-nowrap items-center sm:gap-6 w-full">
                            <FormLabel className="text-[21px] min-w-[200px]">
                                {t('account.create_new_account.communication_system.title')}
                            </FormLabel>
                            <div className=" gap-4 w-full mt-4 mb-4">
                                <FormItem>
                                    <FormInputCheckbox
                                        onCheckboxChange={disableCheckboxBySelect}
                                        disabled={isLocalGovCommunication}
                                        name="communication.local_gov_communication"
                                        label={t('account.create_new_account.communication_system.local_government')}
                                    />
                                    <div className="flex flex-wrap  justify-between w-full mt-4 gap-4">
                                        <FormInputCheckbox
                                            onCheckboxChange={disableCheckboxBySelect}
                                            disabled={isMayor}
                                            name="communication.mayor"
                                            label={t('account.create_new_account.communication_system.mayor')}
                                        />
                                        <FormSelect
                                            className="sm:max-h-[28px]"
                                            name="district"
                                            required={!!isRoleMayor}
                                            placeholder={t('common.MSG_002', {
                                                field: t('account.create_new_account.communication_system.district'),
                                            })}
                                            options={options}
                                            label={t('account.create_new_account.communication_system.district')}
                                            disabled={!isRoleMayor}
                                            handleSearch={onHandleSearch}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                    <FormInputCheckbox
                                        onCheckboxChange={disableCheckboxBySelect}
                                        disabled={isRepresentative}
                                        className="mt-4"
                                        name="communication.representative"
                                        label={t('account.create_new_account.communication_system.representative')}
                                    />
                                </FormItem>
                            </div>

                            <FormLabel className="text-[21px] min-w-[200px]">
                                {t('account.create_new_account.welfare_system.title')}
                            </FormLabel>
                            <div className=" gap-4 w-full mt-4">
                                <FormInputCheckbox
                                    onCheckboxChange={disableCheckboxBySelect}
                                    disabled={isLocalGovWelfare}
                                    name="welfare.local_gov_welfare"
                                    label={t('account.create_new_account.welfare_system.local_government')}
                                />
                                <FormInputCheckbox
                                    onCheckboxChange={disableCheckboxBySelect}
                                    disabled={isCommissioner}
                                    className="mt-4"
                                    name="welfare.commissioner"
                                    label={t('account.create_new_account.welfare_system.commissioner')}
                                />
                            </div>
                            <FormMessage className="text-error" />
                        </div>
                    </div>
                )}
            />
            <FormInputText
                required={true}
                name="name"
                labelClass="min-w-[200px]"
                label={t('account.create_new_account.name')}
                placeholder={t('common.MSG_001', { field: t('account.create_new_account.name') })}
                maxLength={255}
            />
            <FormInputText
                required={true}
                name="email"
                labelClass="min-w-[200px]"
                label={t('account.create_new_account.email')}
                disabled={type === 'edit'}
                placeholder={t('common.MSG_001', { field: t('account.create_new_account.email') })}
                maxLength={255}
            />
        </div>
    );
};
export default observer(AccountForm);
