import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { GetUserById, UpdateUser } from '@/modules/services/user.service';
import { toast } from '@/components/ui/use-toast';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { EditAccountSchema } from '@/schema/accountSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import AccountForm from '@/pages/account/form/AccountForm';
import { useQuery } from '@tanstack/react-query';
import { ERROR_RESPONSE } from '@/constants/appConstants';
import { handleErrorNotFound } from '@/lib/helper';

const UpdateAccount = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { isLoading, setLoading } = useGlobalStore();
    const onBackMenu = () => {
        navigate(`/accounts`);
    };

    const form = useForm<z.infer<typeof EditAccountSchema>>({
        resolver: zodResolver(EditAccountSchema),
        mode: 'all',
    });

    const transformArrayToObject = (keys: string[]): Record<string, boolean> => {
        return keys.reduce((acc: any, key) => {
            acc[key] = true;
            return acc;
        }, {});
    };

    const getDetailAccount = async () => {
        try {
            setLoading(true);
            const res = await GetUserById(id);
            const communication = transformArrayToObject(res.data.roles.communication);
            const welfare = transformArrayToObject(res.data.roles.welfare);
            form.reset({
                ...res.data,
                communication: communication,
                welfare: welfare,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const { isFetching: isLoad } = useQuery({
        queryKey: ['getDetailAccount', id],
        queryFn: () => {
            if (id) return getDetailAccount();
        },
    });

    const onSubmit = async () => {
        if (!id) return;
        try {
            setLoading(true);
            const data = form.getValues();
            const role_communication = Object.keys(data.communication).filter(
                (key) => data.communication[key] === true
            );
            const role_welfare = Object.keys(data.welfare).filter((key) => data.welfare[key] === true);
            const body = {
                user: {
                    email: data.email,
                    profile: {
                        name: data.name,
                        district: data.district,
                    },
                    roles: [...role_communication, ...role_welfare],
                },
            };
            await UpdateUser(id, body);
            toast({
                description: t('common.MSG_017'),
                variant: 'success',
            });
            onBackMenu();
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_069',
                callback: () => onBackMenu(),
            });
            if (isNotFound) return;
            const roles_message = error?.errors?.roles?.[0];
            if (roles_message === ERROR_RESPONSE.CANNOT_UPDATE_ROLES) {
                toast({ description: t('common.MSG_068'), variant: 'destructive' });
                return;
            }
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            pageLoading={isLoad}
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('account.edit_title') }]} />}
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-[19px] h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('update')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <AccountForm type="edit" data={form.getValues()} />
            </Form>
        </PageComponent>
    );
};
export default withTrans(observer(UpdateAccount));
