import withTrans from '@/components/common/hocs/withTrans';
import { cn } from '@/lib/utils';
import { Label } from '@radix-ui/react-label';

const GeneralContacts = ({
    className,
    label,
    name,
    contact,
}: {
    label: string;
    name: string;
    contact: string;
    className?: string;
}) => {
    return (
        <div className={cn('flex flex-wrap items-center gap-3 pt-8', className)}>
            <Label className="text-center border px-12 bg-lime-500 py-1 border-slate-400 text-[17px] text-white min-w-[220px]">
                {label}
            </Label>
            <Label>{name}</Label>
            <Label>{contact}</Label>
        </div>
    );
};

export default withTrans(GeneralContacts);
