import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { useNavigate, useParams } from 'react-router';
import dayjs from '@/lib/dayjs';
import { getMeetingDetail } from '@/modules/services/meeting.service';
import { useQuery } from '@tanstack/react-query';
import { FORMAT_DATE, FORMAT_TIME } from '@/constants/appConstants';
import { Item, renderText } from '@/components/common/pages/Element';
import { MeetingType } from '@/interfaces/meeting';
import { Textarea } from '@/components/ui/textarea';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { backLink } from '@/constants/path';

const AttendanceDetail = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuthenStore();
    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDetailMeeting'],
        queryFn: () => {
            if (id) return getDetailMeetingById();
        },
    });

    const getDetailMeetingById = async (): Promise<MeetingType> => {
        try {
            const response = await getMeetingDetail(id);
            const { data }: any = response;
            return {
                attendances: data?.attendances?.filter((item: any) => item?.user?.id === user.id) || [],
                meeting_date: dayjs(data.meeting_date).format(FORMAT_DATE) || '',
                name: data.name || '',
                start_time: data.start_time ? dayjs(data.start_time).format(FORMAT_TIME) : '',
                end_time: data.end_time ? dayjs(data.end_time).format(FORMAT_TIME) : '',
                content: data.content || '',
            };
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const onBackMenu = () => {
        navigate(`${backLink[user?.role?.communication?.[0]] || ''}/attendance-meetings`);
    };

    return (
        <PageComponent
            pageLoading={isLoading}
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('attendance_meeting.title_detail') }]} />}
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button size="xl" className="text-[19px] h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <div className="flex flex-col gap-6 mt-6 ">
                <Item className="whitespace-break-spaces" label={t('attendance_meeting.meeting_name')}>
                    {renderText(data?.name || '')}
                </Item>
                <Item label={t('attendance_meeting.date_time')}>
                    {renderText(
                        `${data?.meeting_date || ''} ${data?.start_time || ''}${
                            data?.end_time ? ' ~ ' + data.end_time : ''
                        }`
                    )}
                </Item>
                <Item label={t('attendance_meeting.content')}>
                    <Textarea
                        value={data?.content}
                        readOnly={true}
                        rows={10}
                        className="text-pc disabled:cursor-text"
                    />
                </Item>
            </div>
        </PageComponent>
    );
};
export default withTrans(observer(AttendanceDetail));
