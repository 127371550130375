import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { TFunction } from 'react-i18next';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE } from '@/constants/appConstants';
import { getLookupLabel, WasAnswered } from '@/modules/functions/LookupUtils';
import TooltipComponent from '@/components/common/pages/ToolTip';
import {ApplicationType} from "@/interfaces/report";

type ColumnsProps = {
    t: TFunction;
    onAnswer?: (key: string) => void;
    onSetting?: (key: string) => void;
    columnsKey?: string[];
    showFull?: boolean;
};

export const columnsTableIPComApplication = ({
    t,
    onAnswer,
    columnsKey,
    showFull,
}: ColumnsProps): TableProps<ApplicationType>['columns'] => {
    const allColumns: TableProps<ApplicationType>['columns'] = [
        {
            title: t('report.ip_application.answer'),
            dataIndex: 'was_answered',
            key: 'was_answered',
            width: 100,
            align: 'center',
            render: (data, record) => {
                const now = dayjs(dayjs().startOf('day'));
                const dueDate = dayjs(record?.due_date).endOf('day');

                if (!data && record?.questions.length > 0 && !now.isAfter(dueDate)) {
                    return <Button onClick={() => onAnswer?.(record.id)}>{t('survey.ipad.answer')}</Button>;
                }
                return null;
            },
        },
        {
            title: t('report.ip_application.answer_status'),
            dataIndex: 'was_answered',
            key: 'was_answered',
            width: 120,
            align: 'center',
            render: (data) => getLookupLabel(WasAnswered, data, ''),
        },
        {
            title: t('report.ip_application.answer_deadline'),
            dataIndex: 'due_date',
            key: 'due_date',
            width: 120,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE) : ''),
        },
        {
            title: t('report.ip_application.application_content'),
            dataIndex: 'title',
            key: 'title',
            width: 250,
            render: (value) => {
                if (showFull) return <div className="whitespace-break-spaces">{value}</div>;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces max-w-[350px] lg:max-w-[600px] xl:max-w-fit">
                            {value}
                        </div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

