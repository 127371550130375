import FormInputDate from '@/components/common/form/FormInputDate';
import FormInputText from '@/components/common/form/FormInputText';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import GroupButton from '@/components/common/pages/GroupButton';
import { FormItem, FormLabel } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import dayjs from '@/lib/dayjs';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MedicalForm from '@/pages/resident/form/MedicalForm';
import EmergencyForm from '@/pages/resident/form/EmergencyForm';
import { useEffect } from 'react';

const IPResidentForm = () => {
    const { t } = useTranslation();
    const { getValues, watch, trigger } = useFormContext();
    const data = getValues();

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (
                name?.includes('service_name_1') ||
                name?.includes('service_name_2') ||
                name?.includes('service_name_3')
            ) {
                trigger(['service']);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className="flex flex-col gap-y-4 max-w-[1000px]">
            <FormInputText
                name="name"
                className="flex-col items-start sm:gap-0 "
                labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
                label={t('name')}
                placeholder={t('common.MSG_001', { field: t('name') })}
                maxLength={255}
            />
            <FormInputDate
                name="birthday"
                className="flex-col items-start sm:gap-0"
                label={t('resident.birthday')}
                disabledDate={(d) => d.isAfter(dayjs())}
                labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
            />
            <FormInputText
                name="address"
                className="flex-col items-start sm:gap-0"
                labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
                label={t('resident.address')}
                placeholder={t('common.MSG_001', { field: t('resident.address') })}
                maxLength={255}
            />
            <EmergencyForm
                isVertical={true}
                label={t('emergency_info.person_1')}
                labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
                prefixName="emergency_contact1"
                className="2xl:flex-wrap 2xl:gap-0"
                required={true}
            />
            <EmergencyForm
                isVertical={true}
                label={t('emergency_info.person_2')}
                labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
                prefixName="emergency_contact2"
                className="2xl:flex-wrap 2xl:gap-0"
                required={false}
            />
            <MedicalForm
                isVertical={true}
                label={t('medical_info.title')}
                labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
                className="2xl:flex-wrap 2xl:gap-0"
                required={true}
            />

            <FormItem>
                <div className="flex flex-wrap gap-2 sm:flex-nowrap items-start sm:gap-0 flex-col mt-2">
                    <FormLabel className="text-pc min-w-[170px]">{t('resident.created_app_service')}</FormLabel>
                    <div className="w-full mt-4">
                        <GroupButton
                            buttons={[
                                {
                                    label: t('resident.taxi'),
                                    className: cn(
                                        'py-[30px] hover:cursor-default',
                                        data?.welfare_taxi && 'bg-lime-500'
                                    ),
                                },
                                {
                                    label: t('resident.emergency_device'),
                                    className: cn(
                                        'py-[30px] hover:cursor-default',
                                        data?.emergency_device && 'bg-lime-500'
                                    ),
                                },
                                {
                                    label: t('habitat'),
                                    className: cn('py-[30px] hover:cursor-default', data?.habitat && 'bg-lime-500'),
                                },
                                {
                                    label: t('evacuation_operations'),
                                    className: cn(
                                        'py-[30px] hover:cursor-default',
                                        data?.evacuation_operations && 'bg-lime-500'
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </FormItem>
            <FormInputTextarea
                required={false}
                name="remark"
                className="flex-col items-start sm:gap-0"
                labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
                label={t('resident.remarks')}
                placeholder={t('common.MSG_001', { field: t('resident.remarks') })}
                rows={5}
                maxLength={255}
            />
        </div>
    );
};

export default observer(IPResidentForm);
