import { useTranslation } from 'react-i18next';
import QuestionFormContent from '@/pages/survey/question/form/QuestionFormContent';
import { useFormContext } from 'react-hook-form';
import FormInputDate from '@/components/common/form/FormInputDate';
import FormInputText from '@/components/common/form/FormInputText';
import dayjs from '@/lib/dayjs';

const QuestionFormContainer = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="flex flex-col gap-y-4 max-w-[1100px]">
            <FormInputText
                name="title"
                maxLength={255}
                placeholder={t('common.MSG_001', { field: t('survey.title') })}
                label={t('survey.title')}
                labelClass="min-w-[165px]"
            />
            <FormInputDate
                name="due_date"
                label={t('survey.deadline')}
                disabledDate={(d) => !!d.isBefore(dayjs())}
                labelClass="min-w-[165px]"
            />
            <QuestionFormContent name="questions" control={control} />
        </div>
    );
};

export default QuestionFormContainer;
