import { PAGE_SIZE } from '@/constants/appConstants';
import { ResidentData } from '@/interfaces/resident';
import { convertDataApplication } from '@/lib/helper';
import { GetDetailVisitReport, GetResidents, GetVisitedReportList } from '@/modules/services/report.service';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';

export const useGetDetailResident = ({ id, userId }: { id: string; userId: string }) => {
    const getDetailResident = async () => {
        try {
            const { data }: AxiosResponse<ResidentData> = await GetDetailVisitReport(userId, id);
            return data;
        } catch (error) {
            return null;
        }
    };

    const { isFetching: isLoading, data: resident } = useQuery({
        queryKey: ['getDetailResident', id],
        queryFn: () => {
            if (id) return getDetailResident();
        },
    });

    return { resident, isLoading };
};

const useGetResidents = ({
    page = 1,
    perPage = PAGE_SIZE,
    userId,
    orderBy,
}: {
    page?: number;
    perPage?: number;
    userId?: string;
    orderBy?: string;
}) => {
    const getListResident = async () => {
        try {
            const { meta, data }: any = userId
                ? await GetVisitedReportList(userId, {
                      page,
                      per_page: perPage,
                      ...(orderBy ? { order_by: orderBy } : {}),
                  })
                : await GetResidents({ page, per_page: perPage });
            const dataMappingTable = data.map((e: ResidentData) => convertDataApplication(e));
            return { data: dataMappingTable, meta };
        } catch (error) {
            return {
                data: [],
                meta: { total_record: 0 },
            };
        }
    };

    const { data, isFetching: isLoading } = useQuery({
        queryKey: ['getResidentList', page, userId],
        queryFn: () => getListResident(),
    });

    const tableMeta = useMemo(() => {
        return {
            isDownLoadCsv: !!data?.data?.length,
            dataTable: data?.data || [],
            records: data?.meta.total_count || 0,
        };
    }, [data]);

    return {
        dataSource: tableMeta.dataTable,
        isDownLoadCsv: tableMeta.isDownLoadCsv,
        total: tableMeta.records,
        isLoading,
    };
};

export default useGetResidents;
