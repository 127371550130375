import dayjs from '@/lib/dayjs';
import heic2any from 'heic2any';
import Compress from 'compress.js';
import { MAX_SIZE_FILE_CONVERTED } from '@/constants/appConstants';

const makeRandomFileName = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};

export const formatFileName = (file: File) => {
    const extension = /[.]/.exec(file?.name) ? /[^.]+$/.exec(file?.name) : 'jpg';
    const patten = dayjs().format('YYYYMMDD_HHmmss');
    return `${patten}.${extension}`;
};

export const isHeic = (file: File) => {
    const extension = /[.]/.exec(file?.name) ? /[^.]+$/.exec(file?.name) : undefined;
    return extension[0] && extension[0].toLowerCase().trim() === 'heic';
};

export const handleValidateAttachments = (dataFiles: any) => {
    const isExistedImgInvalid = Object.values(dataFiles).some((item: any) => {
        return item?.fileList?.[0] && item.fileList[0]?.status !== 'done';
    });

    const isEmptyImg =
        Object.values(dataFiles).filter((item: any) => {
            return item.fileList && item.fileList.length > 0;
        }).length === 0;
    return {
        isExistedImgInvalid,
        isEmptyImg,
    };
};

export const handleConvertHEICToJEPG = async (file: File) => {
    let fileType = file.type;

    if (isHeic(file)) {
        fileType = 'image/jpeg';
        const blob = new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
        const fileContent = await heic2any({
            blob: blob, // the HEIC file buffer
            toType: fileType, // output format
            quality: 0.8, // the jpeg compression quality, between 0 and 1
        });
        const fileName = file.name.toLowerCase().replace('.heic', '.jpeg');
        const newFile = await compressImg(new File([fileContent as Blob], fileName, { type: fileType }));
        return { fileContent: newFile, fileName, fileType };
    } else {
        const newFile = await compressImg(file);
        return { fileContent: newFile, fileName: file.name || `${makeRandomFileName(20)}.jpeg`, fileType };
    }
};

export const compressImg = async (file: File) => {
    const compress = new Compress();
    if (file.size <= MAX_SIZE_FILE_CONVERTED * 1024 * 1024) {
        return file;
    }
    try {
        const newFile = await compress.compress([file], {
            size: MAX_SIZE_FILE_CONVERTED, // the max size in MB,
            quality: 0.75, // the quality of the image, max is 1
            resize: false, // defaults to true, set false if you do not want to resize the image width and height
        });
        return Compress.convertBase64ToFile(newFile[0].data, newFile[0].ext);
    } catch (e) {
        return file;
    }
};
