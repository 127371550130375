import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import { PhrasesType } from '@/interfaces/phrases';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { columns } from '@/pages/phrases/components/Columns';
import TableBar from '@/components/common/table/TableBar';
import useGetPhrases from '@/hooks/useGetPhrases';
import { deletePhrases } from '@/modules/services/category.service';
import { PhrasesComponentType } from '@/interfaces/common/global';
import useRefreshData from '@/hooks/useRefreshData';
import { handleErrorNotFound } from '@/lib/helper';

const PhrasesComponent = (props: { type: PhrasesComponentType } & WithTransProps) => {
    const { t, type } = props;
    const navigate = useNavigate();
    const { user } = useAuthenStore();
    const { setLoading } = useGlobalStore();
    const { refreshDataList, page, setPage } = useRefreshData({ queryKey: ['getPhrasesList'] });
    const { dataSource, total, isLoading } = useGetPhrases({ page, userId: user?.id, type });

    const onEdit = (key: string) => {
        navigate(`${key}`);
    };

    const onDelete = async (key: string) => {
        if (!user.id) return;

        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });
            if (res) {
                setLoading(true);
                await deletePhrases(type, key);
                refreshDataList();
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_025',
                callback: () => refreshDataList(),
                defaultMessage: 'common.MSG_041',
            });
        } finally {
            setLoading(false);
        }
    };

    const onCreate = () => {
        navigate(`create`);
    };

    const onChangeTable: TableProps<PhrasesType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: `${t('fixed_phrases')}${t('list')}` }]} />}
            isDownLoadCsv={false}
        >
            <TableBar hasSelect={false} isCreate onCreate={onCreate} textCreate={t('create_new')} />
            <TableComponent
                loading={isLoading}
                columns={columns({ t, onEdit, onDelete })}
                dataSource={dataSource}
                pagination={{
                    total: total,
                    current: page,
                }}
                onChange={onChangeTable}
            />
        </PageComponent>
    );
};

export default withTrans(observer(PhrasesComponent));
