import { observer } from 'mobx-react-lite';
import { Form, FormLabel } from '@/components/ui/form';
import useGetUsers from '@/hooks/useGetUsers';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import { PAGE_SIZE, SUPPORT_CSV, MAX_SIZE_FILE_CSV } from '@/constants/appConstants';
import FormSelect from '@/components/common/form/FormSelect';
import { useForm } from 'react-hook-form';
import FormUploadDragDrop from '@/components/common/form/FormUploadDragDrop';
import { useTranslation } from 'react-i18next';
import { ImportCsvResidentSchema } from '@/schema/residentSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useGlobalStore } from '@/store/global/GlobalStore';
import ModalComponent from '@/components/common/pages/ModalComponent';
import { toast } from '@/components/ui/use-toast';
import { downloadTemplate, importCsvResidents, getErrorCsvResidents } from '@/modules/services/report.service';
import { downloadFile } from '@/lib/download';
import { useEffect, useRef, useState } from 'react';
import { handleErrorNotFound } from '@/lib/helper';
import { useQueryClient } from '@tanstack/react-query';

type ImportCsvProps = {
    isShown: boolean;
    handleClose: () => void;
    refreshData: () => void;
};

const ImportCsvComponent = ({ isShown, handleClose, refreshData }: ImportCsvProps) => {
    const { t } = useTranslation();
    const { isLoading, setLoading } = useGlobalStore();
    const [data, setData] = useState<any>(null);
    const intervalRef = useRef<string | number | NodeJS.Timeout>(null);
    const queryClient = useQueryClient();

    const {
        options,
        setScrollOption,
        onHandleSearch,
        isLoading: isLoad,
    } = useGetUsers({
        role: APP_PERMISSION.COMMISSIONER,
        perPage: PAGE_SIZE,
    });

    const form = useForm<z.infer<typeof ImportCsvResidentSchema>>({
        resolver: zodResolver(ImportCsvResidentSchema),
        defaultValues: {
            civil: undefined,
            file: undefined,
        },
        mode: 'onChange',
    });

    useEffect(() => {
        if (data?.finished_at) {
            setLoading(false);
            if (!data.messages) {
                toast({ description: t('common.MSG_027'), variant: 'success' });
                onCancel();
                refreshData();
            } else {
                intervalRef.current && clearInterval(intervalRef.current);
            }
        }
    }, [data]);

    const onSubmit = async (value: z.infer<typeof ImportCsvResidentSchema>) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', value.file);
            await importCsvResidents(value.civil, formData);

            intervalRef.current = setInterval(async () => {
                const { data } = await getErrorCsvResidents(value.civil);
                if (data) setData(data);
            }, 3000);
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_064',
                defaultMessage: 'common.MSG_054',
                callback: () => {
                    form.setValue('civil', undefined);
                    queryClient.fetchQuery({
                        queryKey: ['getOptionUsers', '', 1],
                    });
                },
            });
            setLoading(false);
        }
    };

    const onCancel = () => {
        form.reset({
            civil: undefined,
            file: undefined,
        });
        setData(null);
        clearInterval(intervalRef.current);
        handleClose();
    };

    const downLoad = async () => {
        try {
            const file: any = await downloadTemplate({ template: 'resident' });
            downloadFile({ file, fileName: 'import_resident_template.csv' });
        } catch (error) {
            console.log(error);
        }
    };

    const handleClearMessage = () => {
        setData(null);
    };

    const renderForm = () => (
        <Form {...form}>
            <div className="flex flex-col gap-6">
                <FormSelect
                    isLoading={isLoad}
                    name="civil"
                    options={options}
                    label={t('select_civil')}
                    labelClass="min-w-[170px]"
                    setScrollOption={setScrollOption}
                    handleSearch={onHandleSearch}
                />
                <div className="flex flex-col items-start sm:gap-6 space-y-0">
                    <div className="flex flex-row justify-between w-full">
                        <FormLabel className="text-pc" required>
                            {t('csv.csv_import')}
                        </FormLabel>
                        <span className="underline cursor-pointer" onClick={downLoad}>
                            {t('csv.csv_download_form')}
                        </span>
                    </div>
                    <FormUploadDragDrop
                        name="file"
                        allowType={SUPPORT_CSV}
                        maxFileSize={MAX_SIZE_FILE_CSV}
                        type="csv"
                        handleChangeFile={handleClearMessage}
                    />
                    {data?.messages?.length && (
                        <ul className="w-full border border-grey rounded-md h-[200px] overflow-y-scroll !list-disc list-inside ">
                            {data.messages.map((item: string, index: string) => (
                                <li className="pl-4 p-2 text-destructive break-all" key={index}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </Form>
    );

    return (
        <ModalComponent
            visible={isShown}
            title={t('welfare.registration_visited_destinations')}
            content={renderForm()}
            onCancel={onCancel}
            onOk={form.handleSubmit(onSubmit)}
            okText={t('csv.upload')}
            cancelText={t('csv.cancel')}
            loadingBtn={isLoading}
        />
    );
};

export default observer(ImportCsvComponent);
