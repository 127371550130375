import withTrans from '@/components/common/hocs/withTrans';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SelectedCategory from './SelectedCategory';
import { CateEnum } from '@/hooks/useGetCommunicationCategory';
import { useNavigate, useParams } from 'react-router';
import ReasonDetail from './ReasonDetail';
import { FormProvider, useForm } from 'react-hook-form';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { toast } from '@/components/ui/use-toast';
import {
    CATEGORIES,
    FORMAT_DATE_TIME_SECONDS,
    REGISTER_APPLICATION_STEP,
    STATUS_APPLICATION_ACCEPTED,
} from '@/constants/appConstants';
import {
    createApplicationReport,
    getReportApplicationDetail,
    updateApplicationReport,
} from '@/modules/services/application.report.service';
import dayjs from '@/lib/dayjs';
import { useTranslation } from 'react-i18next';
import { createContacReport, getContactReportDetail, updateContacReport } from '@/modules/services/contact.service';
import LocationInfo from '@/pages/communication/components/LocationInfo';
import { createRequestReport, getRequestReportDetail, updateRequestReport } from '@/modules/services/request.service';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { Error, FormData, Props } from '@/interfaces/communication';
import ImageUploadPreview from '@/pages/communication/components/ImageUploadPreview';
// import { handleValidateAttachments } from '@/lib/img-converter';
import { useQueryClient } from '@tanstack/react-query';
import { PopupError, showModalErrorLocation } from '@/components/common/pages/ModalErrorLocation';
import { checkIsTablet, getCoords, handleErrorMessage } from '@/lib/helper';
import { useAuthenStore } from '@/store/auth/AuthStore';
import SelectedChildCategory from './SelectedChildCategory';

const typeUpload = {
    [CATEGORIES.CONTACT]: 'contact',
    [CATEGORIES.REQUEST]: 'request',
    [CATEGORIES.COMMUNICATION_APP]: 'communication_app',
};

const type = {
    [CATEGORIES.CONTACT]: 'contact',
    [CATEGORIES.REQUEST]: 'request',
    [CATEGORIES.COMMUNICATION_APP]: 'application',
};

const AddEditCommunication = ({ pageType, pageRole = 'mayor' }: Props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const { setLoading } = useGlobalStore();
    const { user } = useAuthenStore();
    const [progressId, setProgressId] = useState<string>(null);
    const [hiddenNextBtn, setHiddenNextBtn] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const [showPopupOverrideMap, setShowPopupOverrideMap] = useState<string>('');
    const [location, setLocation] = useState<google.maps.LatLngLiteral>();
    const [loadedMap, setLoadedMap] = useState<boolean>(false);
    const [hasChild, setHasChild] = useState<boolean>(false);

    const method = useForm<FormData>({
        defaultValues: async () => initDataForm(),
        mode: 'onChange',
    });

    const { watch, getValues, clearErrors, setError, setValue } = method;
    const allFields = watch();

    const dataFiles = useMemo(() => {
        return {} as any;
    }, []);
    const [step, setStep] = useState<number>(REGISTER_APPLICATION_STEP.SELECT_CATEGORY);
    const imageRef = useRef<any>();

    const initDataForm = useCallback(async () => {
        try {
            if (id) {
                const { data }: any = await getDetailById(id);
                if (data.status !== STATUS_APPLICATION_ACCEPTED.PENDING) {
                    navigate('/not-permission');
                    return;
                }
                setProgressId(data?.progresses?.[0].id || null);
                data?.category_child?.id && setHasChild(true);
                return {
                    assignee_id: user.id,
                    category_id: data?.category?.id,
                    category_child_id: data?.category_child?.id,
                    content: data.content,
                    longitude: data?.longitude,
                    latitude: data?.latitude,
                    address: data.address,
                    attachment_ids: data.attachments.map((e: any) => e.id),
                    attachments: data.attachments,
                    ...(pageType !== CateEnum.COMMUNICATION_APP && {
                        category_child_id: data?.category_child?.id || '',
                    }),
                } as FormData;
            } else {
                return {
                    assignee_id: user.id,
                    category_id: '',
                    category_child_id: undefined,
                    content: '',
                    attachments: null,
                    longitude: null,
                    latitude: null,
                    attachment_ids: [],
                    ...(pageType !== CateEnum.COMMUNICATION_APP && { category_child_id: '' }),
                } as FormData;
            }
        } catch (error) {
            console.log(error);
        }
    }, [id]);

    const stepHeader = useMemo(() => {
        const arrStep = [
            `${t('application_register.select_category')}`,
            `${t('application_register.select_child_category')}`,
            `${t('application_register.step_detail')}`,
            `${t('application_register.step_upload')}`,
            `${t('application_register.step_select_position')}`,
        ];

        return arrStep.find((_, i) => i + 1 === step);
    }, [step, allFields?.category_id]);

    useEffect(() => {
        const subscription = watch((value, { name: fieldName }) => {
            if (fieldName?.includes('attachments')) {
                const isError = !value.attachments.some((item: any) => !!item.id);

                isError
                    ? setError('attachments', {
                          message: t('common.MSG_061'),
                      })
                    : clearErrors('attachments');
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        checkLocationPermisstion();
    }, []);

    const checkLocationPermisstion = async () => {
        try {
            let permission = {} as { long: number; lat: number };
            const isTablet = checkIsTablet();
            if (isTablet) {
                permission = await getCoords();
            }
            const result = await navigator.permissions.query({ name: 'geolocation' });
            if (result.state === 'denied' || (isTablet && !permission?.long)) {
                showPopup();
            }
        } catch (error) {
            showPopup();
        }
    };

    const showPopup = async () => {
        await showModalErrorLocation({
            title: t('msg_error_location.title'),
            message: t('msg_error_location.content'),
            type: 'error',
        });
    };

    const onNextStep = () => {
        let error = {} as Error;
        let attachmentStatus: any;
        // validate step
        switch (step) {
            case REGISTER_APPLICATION_STEP.SELECT_CATEGORY:
                if (!allFields.category_id) {
                    error = {
                        field: 'category_id',
                        jpField: t('application_register.category_field'),
                    };
                }
                break;
            case REGISTER_APPLICATION_STEP.SELECT_CHILD_CATEGORY:
                if (!allFields?.category_child_id) {
                    error = {
                        field: 'category_child_id',
                        jpField: t('application_register.category_child_field'),
                    };
                }
                break;
            case REGISTER_APPLICATION_STEP.ENTER_REASON:
                if (!allFields.content) {
                    error = {
                        field: 'content',
                        jpField: t('application_register.text_detail_field'),
                    };
                }
                break;
            // case REGISTER_APPLICATION_STEP.UPLOAD_IMAGES:
            //     attachmentStatus = handleValidateAttachments(dataFiles);
            //     if (attachmentStatus.isEmptyImg) {
            //         error = {
            //             field: 'attachments',
            //             jpField: '',
            //         };
            //     }
            //     break;
            case REGISTER_APPLICATION_STEP.MARK_MAP:
                onSubmit();
                break;
            default:
                break;
        }

        //show error step or when uploaded file invalid
        if (attachmentStatus?.isExistedImgInvalid) {
            return;
        } else if (error.field) {
            method.setError(
                error.field,
                {
                    type: 'focus',
                    message: getMessageValidate(error),
                },
                { shouldFocus: true }
            );
        } else {
            if (
                pageType !== CateEnum.COMMUNICATION_APP &&
                step === REGISTER_APPLICATION_STEP.SELECT_CATEGORY &&
                !hasChild &&
                allFields.category_id
            ) {
                setStep(step < REGISTER_APPLICATION_STEP.MARK_MAP ? step + 2 : step);
            } else {
                setStep(step < REGISTER_APPLICATION_STEP.MARK_MAP ? step + 1 : step);
            }
        }
    };

    const getMessageValidate = (error: Error) => {
        const { field, jpField } = error;
        let msg = '';
        if (field === 'content') {
            msg = 'common.MSG_001';
        } else if (field === 'category_id') {
            msg = 'common.MSG_002';
        } else if (field === 'category_child_id') {
            msg = 'common.MSG_002';
        } else {
            msg = 'common.MSG_061';
        }
        return t(msg, { field: jpField });
    };

    const onSubmit = async () => {
        try {
            if (!getValues('latitude') || !getValues('longitude')) {
                await showModalErrorLocation({
                    title: t('msg_error_location.title'),
                    message: t('msg_error_location.content'),
                    type: 'error',
                });
                return;
            }
            setLoading(true);
            const data: FormData = method.getValues();
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { attachments, ...dataClone } = data;
            const attachmentIds: string[] = [];
            Object.keys(dataFiles).forEach(function (key) {
                if (dataFiles[Number(key)]) {
                    const fileList = dataFiles[Number(key)].fileList;
                    if (fileList && fileList.length > 0 && fileList[0]?.response?.id) {
                        attachmentIds.push(fileList[0].response.id);
                    }
                }
            });
            const dataSubmit = {
                ...dataClone,
                progress: id
                    ? { latest_updated_at: dayjs().format(FORMAT_DATE_TIME_SECONDS), id: progressId }
                    : {
                          description: '新規登録',
                          latest_updated_at: dayjs().format(FORMAT_DATE_TIME_SECONDS),
                      },
                attachment_ids: attachmentIds,
            };
            if (id) {
                await onEdit(dataSubmit);
            } else {
                await onCreate(dataSubmit);
            }
        } catch (error) {
            const path = getRedirectPath();
            if (id) {
                const isError = handleErrorMessage({
                    error: error?.errors?.[`report_${type[pageType]}`]?.[0] || '',
                    callback: () => navigate(path),
                });

                if (isError) return;
            } else {
                const messageCategory = error?.errors?.category?.[1] || '';
                if (messageCategory?.includes('MSG')) {
                    toast({
                        description: t(
                            messageCategory.includes('common') ? messageCategory : `common.${messageCategory}`
                        ),
                        variant: 'destructive',
                    });
                    setStep(REGISTER_APPLICATION_STEP.SELECT_CATEGORY);
                    setValue('category_id', undefined);
                    queryClient.refetchQueries({ queryKey: ['getCategories'] });
                    return;
                }
            }
            toast({ description: t('common.MSG_066'), variant: 'destructive' });
            navigate(path);
        } finally {
            setLoading(false);
        }
    };

    const getDetailById = async (id: string) => {
        if (pageType === CateEnum.COMMUNICATION_APP) {
            return await getReportApplicationDetail(id);
        }
        if (pageType === CateEnum.CONTACT) {
            return await getContactReportDetail(id);
        }
        if (pageType === CateEnum.REQUEST) {
            return await getRequestReportDetail(id);
        }
    };

    const onCreate = async (dataSubmit: any) => {
        if (pageType === CateEnum.COMMUNICATION_APP) {
            await createApplicationReport({ report_application: dataSubmit });
        }
        if (pageType === CateEnum.CONTACT) {
            await createContacReport({ report_contact: dataSubmit });
        }
        if (pageType === CateEnum.REQUEST) {
            await createRequestReport({ report_request: dataSubmit });
        }
        const path = getRedirectPath();
        toast({ description: t('common.MSG_015'), variant: 'success' });
        navigate(path);
    };

    const onEdit = async (dataSubmit: any) => {
        if (pageType === CateEnum.COMMUNICATION_APP) {
            await updateApplicationReport(id, { report_application: dataSubmit });
        }
        if (pageType === CateEnum.CONTACT) {
            await updateContacReport(id, { report_contact: dataSubmit });
        }
        if (pageType === CateEnum.REQUEST) {
            await updateRequestReport(id, { report_request: dataSubmit });
        }
        const path = getRedirectPath();
        toast({ description: t('common.MSG_017'), variant: 'success' });
        navigate(path);
    };

    const getRedirectPath = () => {
        let path = '';
        switch (pageType) {
            case CateEnum.COMMUNICATION_APP:
                path = 'applications';
                break;
            case CateEnum.CONTACT:
                path = 'contacts';
                break;
            case CateEnum.REQUEST:
                path = 'requests';
                break;
        }
        if (pageRole === 'mayor') {
            return `/communication/mayor/${path}`;
        } else {
            return `/communication/representative/${path}`;
        }
    };

    const onPrevStep = () => {
        if (step === REGISTER_APPLICATION_STEP.SELECT_CATEGORY) {
            const path = id ? getRedirectPath() : '/communication';
            navigate(path);
        } else if (step === REGISTER_APPLICATION_STEP.ENTER_REASON && !hasChild) {
            setStep(step - 2);
        } else {
            setStep(step - 1);
        }
    };

    const getHeaderPage = useMemo(() => {
        switch (pageType) {
            case CateEnum.COMMUNICATION_APP:
                return id ? t('application_register.edit_application') : t('application_register.register_application');
            case CateEnum.CONTACT:
                return id ? t('mayor.edit_contact') : t('mayor.contact');
            case CateEnum.REQUEST:
                return id ? t('representative.edit_request') : t('representative.request');
        }
    }, []);

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: getHeaderPage }]} className="py-6" />}
            pageFooter={
                <div className="flex justify-center items-center w-full gap-6">
                    {!hiddenNextBtn && (
                        <Button
                            type="submit"
                            variant="default"
                            size="xl"
                            className="text-ip h-[42px] w-44"
                            onClick={onNextStep}
                        >
                            {step !== REGISTER_APPLICATION_STEP.MARK_MAP ? t('next') : id ? t('update') : t('register')}
                        </Button>
                    )}
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onPrevStep}>
                        {t('back')}
                    </Button>
                </div>
            }
        >
            <FormProvider {...method}>
                <div className="w-full flex justify-center items-center">
                    <div className="w-[1024px] mb-7">
                        <div className="flex justify-center items-center gap-4 mb-7">
                            <Label className="text-label">STEP {step > 2 ? (hasChild ? step : step - 1) : step}</Label>
                            <Label className="text-label">{stepHeader}</Label>
                        </div>
                        {step === REGISTER_APPLICATION_STEP.SELECT_CATEGORY && (
                            <SelectedCategory
                                key={'1'}
                                pageType={pageType}
                                setHiddenNextBtn={setHiddenNextBtn}
                                setHasChild={setHasChild}
                            />
                        )}
                        {step === REGISTER_APPLICATION_STEP.SELECT_CHILD_CATEGORY && (
                            <SelectedChildCategory key={'1'} pageType={pageType} />
                        )}
                        {step === REGISTER_APPLICATION_STEP.ENTER_REASON && <ReasonDetail key={'2'} />}
                        {step === REGISTER_APPLICATION_STEP.UPLOAD_IMAGES && (
                            <ImageUploadPreview
                                key={'3'}
                                ref={imageRef}
                                dataFiles={dataFiles}
                                attachmentType={typeUpload[pageType]}
                            />
                        )}
                        {step === REGISTER_APPLICATION_STEP.MARK_MAP && (
                            <LocationInfo
                                key={'4'}
                                showPopupOverrideMap={showPopupOverrideMap}
                                setShowPopupOverrideMap={setShowPopupOverrideMap}
                                location={location}
                                setLocation={setLocation}
                                loadedMap={loadedMap}
                                setLoadedMap={setLoadedMap}
                            />
                        )}
                    </div>
                </div>
            </FormProvider>
            <PopupError />
        </PageComponent>
    );
};
export default withTrans(observer(AddEditCommunication));
