import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from '@/lib/dayjs';
import { QuestionsSchema } from '@/schema/surveySchema';
import { FORMAT_DATE, FORMAT_DATE_REQ, SURVEY_TYPE, TYPE_ANSWER } from '@/constants/appConstants';
import { GetDetailQuestionSurvey, UpdateQuestionSurvey } from '@/modules/services/survey.service';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { toast } from '@/components/ui/use-toast';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { QuestionSurveyDetailType } from '@/interfaces/survey';
import { convertDataQuestionSurvey, handleErrorMessage, handleErrorNotFound } from '@/lib/helper';
import QuestionFormContainer from '@/pages/survey/question/form/QuestionFormContainer';

const EditQuestion = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const { id } = useParams();

    const form = useForm<QuestionSurveyDetailType>({
        resolver: zodResolver(QuestionsSchema),
        defaultValues: {
            id: undefined,
            title: undefined,
            due_date: undefined,
            questions: [
                {
                    title: undefined,
                    question_type: 'text',
                    options: [
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                    ],
                },
            ],
        },
        mode: 'onChange',
        shouldFocusError: true,
    });

    const onSubmit = async (value: any) => {
        try {
            setLoading(true);
            const newData = {
                ...value,
                due_date: dayjs(value.due_date).format(FORMAT_DATE_REQ),
                questions: value.questions.map((item: any) => ({
                    ...item,
                    options:
                        item.question_type === TYPE_ANSWER.TEXT ? [] : item.options.filter((el: any) => !!el.content),
                })),
            };

            await UpdateQuestionSurvey(id, { survey: newData });
            toast({
                description: t('common.MSG_017'),
                variant: 'success',
            });
            onBackMenu();
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_063',
                callback: () => onBackMenu(),
            });
            if (isNotFound) return;

            handleErrorMessage({
                error: error?.errors?.survey?.[0] || '',
                callback: (msg) => msg.includes('MSG_063') && onBackMenu(),
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    const getDetailQuestionSurvey = async () => {
        try {
            setLoading(true);
            const res = await GetDetailQuestionSurvey(id);
            const { data }: { data: QuestionSurveyDetailType } = res;

            if (
                data.answer_count !== 0 ||
                !dayjs().startOf('day').isBefore(dayjs(data?.due_date, FORMAT_DATE).endOf('day'))
            ) {
                navigate('/not-permission');
            }
            const newData = convertDataQuestionSurvey(data);
            if (data) {
                form.reset(newData);
            }
            return data;
        } catch (error) {
            console.log(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const onBackMenu = () => {
        navigate(
            data?.survey_type === SURVEY_TYPE.SURVEY_61
                ? `/welfare/questions?survey_type=${SURVEY_TYPE.SURVEY_61}`
                : `/welfare/questions`
        );
    };

    const { isFetching: isLoad, data } = useQuery({
        queryKey: ['getDetailQuestionSurvey', id],
        queryFn: () => {
            if (id) return getDetailQuestionSurvey();
        },
        placeholderData: keepPreviousData,
    });

    return (
        <PageComponent
            pageLoading={isLoad}
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: t(
                                data?.survey_type === SURVEY_TYPE.SURVEY_61
                                    ? 'survey.edit_61_question'
                                    : 'survey.edit_question'
                            ),
                        },
                    ]}
                    className="py-6"
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px]  w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('update')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px]  w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <QuestionFormContainer />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(EditQuestion));
