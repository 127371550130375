import React, { useEffect, useReducer } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import Header from '@/components/common/header';
import { Component } from '@/interfaces/common/header';
import { AuthService } from '@/modules/services';
import { useLocalStorage } from 'react-use';
import { TOKEN_NAME } from '@/constants/appConstants';
import { LogOut, User } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { toast } from '@/components/ui/use-toast';
import withTrans from '@/components/common/hocs/withTrans';
import { PageProps } from '@/interfaces/props/page';
import { PATH } from '@/constants/path';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { KeyMenu, MODULE_MENU } from '@/constants/menu';
import useDownLoadCsv from '@/hooks/useDowloadCsv';
import { useCsvStore } from '@/store/csv/CsvStore';
import { cn } from '@/lib/utils';

type HeaderState = {
    isShow?: boolean;
    type?: 'row' | 'col';
};

const MODULES = Object.keys(MODULE_MENU).map((key: KeyMenu) => MODULE_MENU[key]);

const MainLayout = (props: PageProps) => {
    const { t } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setAuthToken] = useLocalStorage(TOKEN_NAME, null, { raw: true });
    const { isIpad, clearData } = useAuthenStore();
    const { setLoading, setPrevious } = useGlobalStore();
    const location = useLocation();
    const { module, setModule } = useGlobalStore();
    const { queue, removeQueue } = useCsvStore();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [header, setHeader] = useReducer(
        (prev: HeaderState, next: HeaderState) => {
            return { ...prev, ...next };
        },
        {
            isShow: true,
            type: 'row',
        }
    );

    useDownLoadCsv(queue, removeQueue);

    useEffect(() => {
        if (pathname) {
            const paths = pathname.split('/');
            const currModule = paths.length >= 2 ? paths[1] : '';
            currModule !== module && MODULES.includes(currModule) && currModule && setModule(currModule);
            setHeader({
                isShow: pathname !== PATH.HOME,
                type:
                    isIpad &&
                    [MODULE_MENU.WELFARE, MODULE_MENU.COMMUNICATION].includes(currModule) &&
                    paths.length === 2
                        ? 'col'
                        : 'row',
            });
        }
    }, [pathname, isIpad, module, setModule]);

    const changeHeader = () => {
        if (pathname === PATH.PROFILE || pathname === PATH.UPDATE_PASSWORD) {
            return t('app_title');
        }
        switch (module) {
            case MODULE_MENU.WELFARE: {
                return t('committee_system_header');
            }
            case MODULE_MENU.COMMUNICATION: {
                return t('communication_tool_header');
            }
            case MODULE_MENU.ACCOUNT: {
                return t('account_list_header');
            }
            default:
                return t('app_title');
        }
    };

    const onLogout = async () => {
        try {
            await AuthService.Logout();
            clearData();
            navigate('/login');
            toast({ description: t('common.MSG_009'), variant: 'success' });
        } catch (error) {
            clearData();
            navigate('/login');
            toast({ description: t('common.MSG_009'), variant: 'success' });
        }
    };

    useEffect(() => {
        const storedAuthToken = localStorage.getItem(TOKEN_NAME);
        setAuthToken(storedAuthToken);
    });

    useEffect(() => {
        setLoading(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => setLoading(false), 500);
    }, [location]);

    const onClickLogout = async () => {
        const data = await showModalConfirm({ title: t('logout_title'), message: t('logout_message') });
        if (data) {
            onLogout();
        }
    };

    const components: Component[] = [
        {
            title: 'profile',
            onClick: () => {
                setPrevious({ profile: pathname });
                navigate('/profile');
            },
            children: (
                <div className="flex">
                    <User className="mr-2 h-4 w-4" />
                    {t('profile')}
                </div>
            ),
        },
        {
            title: 'logout',
            onClick: onClickLogout,
            children: (
                <div className="flex">
                    <LogOut className="mr-2 h-4 w-4" />
                    {t('logout')}
                </div>
            ),
        },
    ];

    return (
        <React.Fragment>
            {header.isShow && (
                <Header
                    headerTitle={changeHeader()}
                    components={components}
                    onLogout={onClickLogout}
                    type={header.type}
                />
            )}
            <main
                className={cn(
                    'px-4',
                    header.isShow && header.type === 'row' ? 'pt-[65px] md:pt-[70px] lg:pt-[80px] xl:pt-[100px]' : '',
                    isIpad ? 'max-w-[1300px] m-auto' : ''
                )}
            >
                <Outlet />
            </main>
        </React.Fragment>
    );
};

export default withTrans(observer(MainLayout));
