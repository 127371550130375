import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGlobalStore } from '@/store/global/GlobalStore';
import ApplicationCategoryForm from '@/pages/categories/application/components/ApplicationForm';
import { QuestionsSchema } from '@/schema/surveySchema';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE_REQ, SURVEY_TYPE, TYPE_ANSWER } from '@/constants/appConstants';
import { CreateQuestionSurvey } from '@/modules/services/survey.service';

const CreateRequest = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const surveyType = SURVEY_TYPE.APPLICATION;

    const formApplication = useForm<z.infer<typeof QuestionsSchema>>({
        resolver: zodResolver(QuestionsSchema),
        defaultValues: {
            title: undefined,
            due_date: undefined,
            questions: [
                {
                    title: undefined,
                    question_type: 'text',
                    options: [
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                    ],
                },
            ],
        },
        mode: 'onChange',
        shouldFocusError: true,
    });

    const onBackMenu = () => {
        navigate(`/communication/categories/application`);
    };

    const onSubmit = async (req: any) => {
        try {
            setLoading(true);
            const newData = {
                ...req,
                due_date: dayjs(req.due_date).format(FORMAT_DATE_REQ),
                answer_request_date: dayjs().format(FORMAT_DATE_REQ),
                survey_type: surveyType,
                questions: req.questions.map((item: any) => ({
                    ...item,
                    options:
                        item.question_type === TYPE_ANSWER.TEXT ? [] : item.options.filter((el: any) => !!el.content),
                })),
            };
            await CreateQuestionSurvey({ survey: newData });
            toast({
                description: t('common.MSG_015'),
                variant: 'success',
            });
            onBackMenu();
        } catch (error) {
            toast({
                description: t('common.MSG_038'),
                variant: 'destructive',
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <PageComponent
            title={
                <PageTitle routes={[{ path: '', breadcrumbName: t(`category.application_new`) }]} className="py-6" />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        onClick={formApplication.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...formApplication}>
                <ApplicationCategoryForm />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(CreateRequest));
