import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { TFunction } from 'react-i18next';
import { AccountDataType } from '@/interfaces/account';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import { ColumnsToolTip } from '@/components/common/pages/Element';

type ColumnsProps = {
    t: TFunction;
    onEdit?: (key: string) => void;
    onDelete?: (key: string) => void;
    onRestore?: (key: string) => void;
    columnsKey?: string[];
    showFull?: boolean;
};

export const columnsTableAccount = ({
    t,
    columnsKey,
    showFull,
    onEdit,
    onDelete,
    onRestore,
}: ColumnsProps): TableProps<AccountDataType>['columns'] => {
    const allColumns: TableProps<AccountDataType>['columns'] = [
        {
            title: t('account.account_list.account_table.account_no'),
            dataIndex: 'account_no',
            key: 'account_no',
            width: 80,
            align: 'center',
        },
        {
            title: t('account.account_list.account_table.name'),
            dataIndex: 'name',
            key: 'name',
            width: 120,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('account.account_list.account_table.email'),
            dataIndex: 'email',
            key: 'email',
            width: 250,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-normal">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('account.account_list.account_table.communication'),
            dataIndex: 'communication',
            key: 'communication',
            align: 'center',
            className: 'whitespace-pre-wrap',
            width: 200,
            render: (_, record) => {
                if (record?.communication) {
                    const role =
                        (record.communication === APP_PERMISSION.MAYOR
                            ? record.district
                                ? `${record.district} `
                                : ''
                            : '') + t(`account.account_list.communication.${record.communication}`);
                    return <ColumnsToolTip value={role} />;
                } else {
                    return <></>;
                }
            },
        },
        {
            title: t('account.account_list.account_table.welfare'),
            dataIndex: 'welfare',
            key: 'welfare',
            align: 'center',
            width: 200,
            className: 'whitespace-pre-wrap',
            render: (_, record) => {
                if (record?.welfare) {
                    return <> {t(`account.account_list.welfare.${record.welfare}`)}</>;
                } else {
                    return <></>;
                }
            },
        },
        {
            title: t('account.account_list.account_table.status'),
            dataIndex: 'was_deleted',
            key: 'was_deleted',
            width: 120,
            align: 'center',
            render: (value) => {
                return value ? t('inactive') : t('active');
            },
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 200,
            align: 'center',
            render: (_, record) => {
                if (record.was_deleted) {
                    return (
                        <Button
                            variant="default"
                            onClick={() => {
                                onRestore?.(record.id);
                            }}
                        >
                            {t('account.account_list.account_table.restore')}
                        </Button>
                    );
                }
                return (
                    <>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey mr-2"
                            onClick={() => {
                                onEdit?.(record.id);
                            }}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            variant="delete"
                            className="bg-red-500 border-grey"
                            onClick={() => {
                                onDelete?.(record.id);
                            }}
                        >
                            {t('delete')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
