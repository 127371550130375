import { useState, forwardRef, SetStateAction, Dispatch } from 'react';
import { cn } from '@/lib/utils';
import { Eye, EyeOffIcon } from 'lucide-react';
import { cva, type VariantProps } from 'class-variance-authority';
import TooltipComponent from '@/components/common/pages/ToolTip';

const inputVariants = cva(
    'flex w-full select-none border rounded-md border-grey bg-background px-3 py-2 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-1 focus:border-foreground disabled:bg-gray-100 read-only:bg-gray-200',
    {
        variants: {
            size: {
                default: 'h10 px-3 py-2 text-sm',
                sm: 'h-10 rounded-md px-3 text-sm',
                lg: 'h-[50px] rounded-md px-3 text-ip',
                xl: 'h-16 rounded-xl px-3 text-pc',
                xxl: 'h-[70px] rounded-[14px] px-3 text-pc',
            },
        },
        defaultVariants: {
            size: 'default',
        },
    }
);

const iconVariants = cva('opacity-40 hover:opacity-90 mr-1', {
    variants: {
        size: {
            default: 'w-5 h-5',
            sm: 'w-5 h-5',
            lg: 'w-6 h-6',
            xl: 'w-6 h-6',
            xxl: 'w-7 h-7',
        },
    },
    defaultVariants: {
        size: 'default',
    },
});

export interface InputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
        VariantProps<typeof inputVariants> {
    label?: string;
    isError?: boolean;
    errorStr?: string;
    suffix?: React.ReactNode;
    handleSuffixClick?: () => void;
}

const renderPasswordSuffixIcon = (
    type: string,
    setType: Dispatch<SetStateAction<string>>,
    size: 'default' | 'sm' | 'lg' | 'xl' | 'xxl'
) => {
    return (
        <>
            {type === 'password' ? (
                <EyeOffIcon className={cn(iconVariants({ size }))} onClick={() => setType('text')} />
            ) : (
                <Eye className={cn(iconVariants({ size }))} onClick={() => setType('password')} />
            )}
        </>
    );
};

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, label, isError, errorStr, suffix, size, value, handleSuffixClick, ...props }, ref) => {
        const [typeInput, setTypeInput] = useState<string>(type);

        const suffixIcon =
            suffix || (type === 'password' ? renderPasswordSuffixIcon(typeInput, setTypeInput, size) : suffix);

        return (
            <div className="relative w-full ">
                {label ? (
                    <div className="mb-1">
                        <span className="font-semibold">{label}</span>
                        {props.required && <span className="text-primary-500 w-auto">* </span>}
                    </div>
                ) : null}

                <TooltipComponent antdTooltip={true} title={props.readOnly ? value : ''}>
                    <input
                        value={value || ''}
                        type={typeInput}
                        className={cn(inputVariants({ size, className: `${className} ${suffixIcon ? 'pr-11' : ''}` }))}
                        ref={ref}
                        {...props}
                    />
                </TooltipComponent>

                {suffixIcon && (
                    <span
                        className={'absolute right-1 top-1/2 transform -translate-y-1/2 cursor-pointer'}
                        onClick={handleSuffixClick}
                    >
                        {suffixIcon}
                    </span>
                )}
                {isError ? <p className="text-sm font-medium text-primary-500">{errorStr}</p> : null}
            </div>
        );
    }
);
Input.displayName = 'Input';

export { Input };
