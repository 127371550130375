import { SURVEY_TYPE } from '@/constants/appConstants';
import { Params } from '@/interfaces/common/global';
import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';

export const GetSurveyList = async (params?: Params & { survey_type?: string }) => {
    return await apiServiceWithAuth.get(`users/surveys`, { params });
};

export const CreateFileSurveyCSV = async (survey_type = SURVEY_TYPE.GENERAL, without_count = false) => {
    return await apiServiceWithAuth.post(`users/export_csv_survey`, { survey_type, without_count });
};

export const GetDetailSurveyResCSV = async (surveyId: string, params?: Params) => {
    return await apiServiceWithAuth.get(`users/surveys/${surveyId}/responses`, { params });
};

export const GetDetailSurveyById = async (surveyId: string) => {
    return await apiServiceWithAuth.get(`users/surveys/${surveyId}`);
};

export const CreateFileAnswerCSV = async (surveyId: string) => {
    return await apiServiceWithAuth.post(`users/surveys/${surveyId}/export_csv_survey_response`);
};

export const GetDetailAnswer = async (surveyId: string, params?: Params) => {
    return await apiServiceWithAuth.get(`users/surveys/${surveyId}/export_csv_survey_response`, { params });
};

export const CreateQuestionSurvey = async (data: any) => {
    return await apiServiceWithAuth.post(`users/surveys`, data);
};

export const GetDetailQuestionSurvey = async (surveyId: string) => {
    return await apiServiceWithAuth.get(`users/surveys/${surveyId}`);
};

export const DeleteSurvey = async (surveyId: string) => {
    return await apiServiceWithAuth.delete(`users/surveys/${surveyId}`);
};

export const UpdateQuestionSurvey = async (surveyId: string, data: any) => {
    return await apiServiceWithAuth.put(`users/surveys/${surveyId}`, data);
};
export const UpdateQuestionSurveysResponses = async (surveyId: string, data: any) => {
    return await apiServiceWithAuth.post(`users/surveys/${surveyId}/responses`, data);
};

export const GetParticipant = async (surveyId: string) => {
    return await apiServiceWithAuth.get(`users/surveys/${surveyId}/participation_status`);
};

export const UpdateParticipant = async (
    surveyId: string,
    data: {
        participation_status: {
            required_responses: number;
        };
    }
) => {
    return await apiServiceWithAuth.post(`users/surveys/${surveyId}/participation_status`, data);
};
