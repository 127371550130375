import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import useGetUsers from '@/hooks/useGetUsers';
import useGetApplications from '@/hooks/userGetApplications';
import { PageProps } from '@/interfaces/props/page';
import { CreateFileApplicationCSV } from '@/modules/services/application.service';
import { DataType, columns } from '@/pages/application/ApplicationColumns';
import { useCsvStore } from '@/store/csv/CsvStore';
import { TableProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { PAGE_SIZE } from '@/constants/appConstants';
import { convertFileNameCsv } from '@/lib/helper';

const ListApplicationAccept = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const [selectItem, setSelectItem] = useState(null);
    const [page, setPage] = useState(1);

    const { dataSource, total, isDownLoadCsv, isLoading } = useGetApplications({ page, userId: selectItem?.value });

    const { options, setScrollOption, onHandleSearch } = useGetUsers({
        role: APP_PERMISSION.COMMISSIONER,
        perPage: PAGE_SIZE,
    });

    const onDownloadCsv = async () => {
        try {
            await CreateFileApplicationCSV(selectItem?.value);
            addQueue({
                flag: 'application-accept',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName: `${t('application_accept.title')}${t('list')}`,
                    subName: selectItem?.label,
                }),
                url: `users/residents/export_csv_welfare_app`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onDetail = (key: string) => {
        navigate(`${key}`, {
            state: { userId: selectItem?.value, userName: selectItem?.label },
        });
    };

    const columnsTable = columns({
        t,
        onDetail,
        columnsKey: [
            'detail',
            'code',
            'visitor_name',
            'latest_updated_at',
            'status',
            'resident_code',
            'resident_name',
            'resident_age',
            'resident_address',
            'priority',
            'app_service_pc',
            'description',
        ],
    });
    const onChangeTable: TableProps<DataType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={
                <PageTitle routes={[{ path: '', breadcrumbName: `${t('application_accept.title')}${t('list')}` }]} />
            }
            isDownLoadCsv={isDownLoadCsv}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableBar
                options={options}
                onChangeSelect={(value) => {
                    const item = options.find((el) => el.value === value);
                    setSelectItem(item);
                    setPage(1);
                }}
                handleSearch={onHandleSearch}
                setScrollOption={setScrollOption}
            />
            <TableComponent
                loading={isLoading}
                columns={columnsTable}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(ListApplicationAccept));
