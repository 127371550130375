import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { ReportType } from '@/interfaces/report';
import dayjs from '@/lib/dayjs';
import { ColumnsProps } from '@/interfaces/common/global';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { FORMAT_DATE_TIME } from '@/constants/appConstants';

export const columnsContact = ({
    t,
    onEdit,
    columnsKey,
    onDetail,
    showFull,
}: ColumnsProps<ReportType>): TableProps<ReportType>['columns'] => {
    const allColumns: TableProps<ReportType>['columns'] = [
        {
            title: t('action'),
            dataIndex: 'detail',
            key: 'detail',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="default"
                            className="m-1"
                            onClick={() => {
                                onDetail?.(record.key);
                            }}
                        >
                            {t('detail')}
                        </Button>
                        {record.status === 'pending' && (
                            <Button
                                variant="update"
                                className="bg-green-500 border-grey m-1"
                                onClick={() => {
                                    onEdit?.(record.key);
                                }}
                            >
                                {t('edit')}
                            </Button>
                        )}
                    </>
                );
            },
        },
        {
            title: t('report.status'),
            dataIndex: 'status',
            key: 'status',
            width: 60,
            align: 'center',
            render: (value) => t(value),
        },
        // {
        //     title: t('report.urgency'),
        //     dataIndex: 'priority',
        //     key: 'priority',
        //     width: 60,
        //     align: 'center',
        //     render: (value) => t(value),
        // },
        {
            title: t('report.category'),
            dataIndex: 'category',
            key: 'category',
            width: 80,
            render: (_, record) => {
                const value = record?.category_child || record?.category;
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('report.content'),
            dataIndex: 'content',
            key: 'content',
            width: 150,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('report.report_date'),
            dataIndex: 'latest_updated_at',
            key: 'latest_updated_at',
            align: 'center',
            width: 70,
            render: (_, record) => {
                return <span>{dayjs(record.latest_updated_at).format(FORMAT_DATE_TIME)}</span>;
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
