import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@/components/ui/textarea';
import React from 'react';

type Props = {
    name: string;
    placeholder?: string;
    inputClassName?: string;
    className?: string;
    labelClass?: string;
    required?: boolean;
    disabled?: boolean;
    maxLength?: number;
    showError?: boolean;
    rows?: number;
    extra?: React.ReactNode;
    label?: React.ReactNode;
    autoFocus?: boolean;
};

const FormInputTextarea = React.forwardRef<HTMLTextAreaElement, Props>(
    (
        {
            name,
            rows,
            extra,
            label,
            className,
            labelClass,
            placeholder,
            maxLength,
            inputClassName,
            autoFocus,
            showError = true,
            disabled = false,
            required = true,
        },
        ref
    ) => {
        const { control } = useFormContext();
        const { t } = useTranslation();

        return (
            <FormField
                control={control}
                name={name}
                // disabled={disabled}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-6', className)}>
                            {label && (
                                <FormLabel
                                    className={cn(
                                        'text-pc flex min-w-fit items-center self-start area-label lg:h-[58px] md:h-[58px]',
                                        labelClass
                                    )}
                                    required={required}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <div className="w-full">
                                <FormControl>
                                    <Textarea
                                        ref={ref}
                                        {...field}
                                        rows={rows ?? 5}
                                        className={cn(
                                            'text-ip',
                                            error ? '!border-destructive' : 'border-grey',
                                            inputClassName
                                        )}
                                        placeholder={placeholder ?? t('common.MSG_001', { field: '' })}
                                        maxLength={maxLength ?? 255}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            field.onChange(value);
                                        }}
                                        onBlur={(event) => {
                                            const value = event.target.value;
                                            if (value?.trim() !== value) {
                                                field.onChange(value.trim());
                                            }
                                        }}
                                        readOnly={disabled}
                                        autoFocus={autoFocus}
                                    />
                                </FormControl>
                                {showError && <FormMessage className="text-error" />}
                            </div>
                            {extra}
                        </FormItem>
                    );
                }}
            />
        );
    }
);

export default FormInputTextarea;
