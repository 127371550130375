import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { PageProps } from '@/interfaces/props/page';
import {
    CreateFileProcessCSV,
    CreateProcessReport,
    DeleteProcessReport,
    GetDetailApplication,
    GetDetailProcessReport,
    UpdateProcessReport,
} from '@/modules/services/application.service';
import { DataType, columns } from '@/pages/application/ApplicationColumns';
import { useCsvStore } from '@/store/csv/CsvStore';
import { Label } from '@radix-ui/react-label';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { ProgessDataType } from '@/interfaces/application';
import { convertFileNameCsv } from '@/lib/helper';
import ProgressComponent from '@/components/common/pages/ProgressComponent';

const ApplicationDetail = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const { id } = useParams();
    const { user } = useAuthenStore();
    const queryClient = useQueryClient();

    const getApplicationDetail = async (
        id: string
    ): Promise<{
        dataResident: DataType[];
        progresses: ProgessDataType[];
        reason: string;
        residentId: string;
        status: string;
    }> => {
        try {
            const response = await GetDetailApplication(id);
            const { data }: any = response;
            const progresses = data.progresses?.map((item: any) => ({ ...item, key: item.id }));
            return {
                dataResident: [{ ...data, key: data.id }],
                progresses,
                reason: data.reason,
                residentId: data.resident.id,
                status: data.status,
            };
        } catch (error) {
            return null;
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getApplicationDetail', id],
        queryFn: () => getApplicationDetail(id),
    });

    const refreshData = () => {
        queryClient.refetchQueries({ queryKey: ['getApplicationDetail', id] });
    };

    const onDownloadCsv = async () => {
        try {
            await CreateFileProcessCSV(id);
            addQueue({
                flag: 'application-process',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName: t('application_accept.app_process'),
                    subName: data?.dataResident?.[0]?.resident?.name,
                }),
                url: `users/residents/welfare_apps/${id}/export_csv_progress`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const columnsTableResident = columns({
        t,
        columnsKey: ['resident_code', 'resident_name', 'resident_age', 'resident_address', 'application_service'],
        showFull: true,
    });

    const onBack = () => {
        navigate('/welfare/applications');
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('application_accept.app_process')}` }]}
                    className="py-6"
                />
            }
            isDownLoadCsv={!!data?.progresses?.length}
            onDownLoadCsv={onDownloadCsv}
            textBack={t('go_back')}
            onBack={onBack}
        >
            <TableComponent
                loading={isLoading}
                columns={columnsTableResident}
                dataSource={data?.dataResident || []}
                pagination={false}
            />
            <div className="pt-6 md:pt-12 max-w-[1000px] flex-wrap md:flex-nowrap flex gap-4">
                <Label className="text-pc w-[150px] font-medium">{t('application_accept.reason')}</Label>
                <Textarea
                    value={data?.reason || ''}
                    readOnly={true}
                    rows={5}
                    className="!text-ip disabled:cursor-text"
                />
            </div>
            <ProgressComponent
                status={data?.dataResident?.[0].status}
                data={data}
                onGetDetailData={GetDetailProcessReport}
                onRemoveProccess={DeleteProcessReport}
                onCreateData={(typeId: string, body: any) =>
                    CreateProcessReport(user.id, data?.residentId, typeId, body)
                }
                onUpdateData={UpdateProcessReport}
                refreshData={refreshData}
            />
        </PageComponent>
    );
};

export default withTrans(observer(ApplicationDetail));
