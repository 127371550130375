/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    ERROR_RESPONSE,
    FORMAT_DATE_TIME_SECONDS,
    MAX_DATE,
    MIN_DATE,
    OPTION_ITEM,
    PAGE_SIZE,
    PRIORITY,
    TYPE_ANSWER,
} from '@/constants/appConstants';
import { QuestionItem, QuestionSurveyDetailType, OptionItem } from '@/interfaces/survey';
import { Dayjs } from 'dayjs';
import dayjs from '@/lib/dayjs';
import i18n from '@/modules/i18n';
import { SelectOptionsItem } from '@/interfaces/common/global';
import { ResidentData } from '@/interfaces/resident';
import { APP_PERMISSION, GET_USER_BY_ROLE } from '@/constants/permissionConfig';
import { ImageType, ReportDetailType, ReportReqType } from '@/interfaces/report';
import { toast } from '@/components/ui/use-toast';
import { t } from 'i18next';
import { SPECIAL_CHARACTERS } from '@/lib/regex';

export const convertDetailSurvey = (data: any[] = []) => {
    const getAnswer = (answer: any) => {
        if (answer?.question?.question_type === TYPE_ANSWER.TEXT) {
            return answer?.text_answer || '';
        } else {
            return answer?.options?.map((option: any) => option.content).join(' / ') || '';
        }
    };

    const newData = data.map((item) => {
        const newAnswers = item?.answers?.map((answer: any, index: number) => ({
            [`question${index + 1}`]: answer?.question?.title || '',
            [`answer${index + 1}`]: getAnswer(answer),
        }));

        const transformedItem = newAnswers.reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});

        const { answers, ...rest } = item;

        return {
            ...rest,
            ...transformedItem,
        };
    });

    return newData;
};

export const convertDataQuestionSurvey = (data: QuestionSurveyDetailType) => {
    const convertQuestions = (questions: QuestionItem[]) => {
        if (!questions.length) {
            return [];
        }

        const convertOptions = (options: OptionItem[]) => {
            const newArr =
                OPTION_ITEM - options.length > 0
                    ? Array.from(Array(OPTION_ITEM - options.length).keys()).map(() => ({ content: undefined }))
                    : [];

            return [...options, ...newArr];
        };

        const newQ = questions.map((el) => ({ ...el, options: convertOptions(el.options) }));

        return newQ;
    };

    return {
        ...data,
        questions: convertQuestions(data.questions),
    };
};

export const disableMinMaxDate = (d: Dayjs) => {
    return d.isBefore(MIN_DATE) || d.isAfter(MAX_DATE);
};

export const configTable = {
    components: {
        Table: {
            colorBorderSecondary: 'hsl(0, 0%, 44%)',
            cellFontSize: 16,
            cellPaddingBlock: 16,
            cellPaddingInline: 8,
        },
    },
    token: {
        colorPrimary: 'hsl(222.2, 47.4%, 11.2%)',
    },
};

export const configDateTime = {
    components: {
        DatePicker: {
            colorBorder: 'hsl(0, 0%, 44%)',
            activeShadow: 'none',
        },
    },
    token: {
        colorPrimary: 'hsl(222.2, 47.4%, 11.2%)',
        colorTextPlaceholder: 'hsl(var(--muted-foreground))',
        controlHeightLG: 45.5,
        fontSizeLG: 19,
    },
};

export const convertOptionSelect = (options: string[] = []): SelectOptionsItem[] => {
    return options.map((item) => ({
        value: item,
        label: i18n.t(item),
    }));
};

export const optionPriority = Object.keys(PRIORITY).map((item: string) => ({
    value: PRIORITY[item],
    label: i18n.t(PRIORITY[item]),
}));

export const convertDataApplication = (item: ResidentData) => {
    return {
        ...item,
        key: item.id,
        application_service: {
            welfare_taxi: item.welfare_taxi,
            emergency_device: item.emergency_device,
        },
    };
};

export function checkRoleByUser(user: any) {
    const foundRole = Role.find(
        (role) => role.communication === user.role.communication[0] && role.welfare === user.role.welfare[0]
    );
    if (foundRole) {
        return foundRole.value;
    } else {
        return null;
    }
}

export const Role = [
    { value: 'R1', communication: APP_PERMISSION.LOCAl_GOV_COMMUNICATION, welfare: APP_PERMISSION.LOCAL_GOV_WELFARE },
    { value: 'R2', communication: APP_PERMISSION.LOCAl_GOV_COMMUNICATION, welfare: '' },
    { value: 'R3', communication: '', welfare: APP_PERMISSION.LOCAL_GOV_WELFARE },
    { value: 'R4', communication: APP_PERMISSION.MAYOR, welfare: '' },
    { value: 'R5', communication: APP_PERMISSION.MAYOR, welfare: APP_PERMISSION.COMMISSIONER },
    { value: 'R6', communication: '', welfare: APP_PERMISSION.COMMISSIONER },
    { value: 'R7', communication: APP_PERMISSION.REPRESENTATIVE, welfare: '' },
    { value: 'R8', communication: APP_PERMISSION.REPRESENTATIVE, welfare: APP_PERMISSION.COMMISSIONER },
];

export const convertOptionAnswer = (options: {
    [key: string]: boolean | undefined;
}): {
    id: string;
}[] => {
    if (!options) {
        return [];
    }

    const data = Object.entries(options)
        ?.filter(([_, value]) => value === true)
        .map(([id]) => ({ id }));

    return data;
};

export const convertDataReportDetail = (data: ReportDetailType) => {
    if (!data) {
        return false;
    }
    const convertImages = (images: ImageType[]) => {
        const newImage = images.map((item) => ({
            ...item,
            original_filename: item.name,
        }));
        const newArrLength = Math.max(OPTION_ITEM, newImage.length);
        const newArr = Array.from({ length: newArrLength }, (_, index) => {
            const item = newImage.find((i) => i.index === index);
            return { file: item ? [{ ...item }] : undefined };
        });

        newImage
            .filter((item) => item.index === null)
            .forEach((item) => {
                const index = newArr.findIndex((e) => e.file === undefined);
                newArr[index] = { file: [{ ...item }] };
            });

        return newArr;
    };
    const newData = {
        assignee_id: data.assignee?.id,
        category_id: data.category?.id,
        category_child_id: data.category_child?.id,
        content: data.content,
        google_map: {
            address: data.address,
            lng: data.longitude,
            lat: data.latitude,
        },
        attachments: convertImages(sortAttachments(data.attachments || [])),
        progresses: data.progresses,
    };

    return newData;
};

export const sortAttachments = (attachments: ImageType[] = []) => {
    return attachments.sort((a, b) => a.index - b.index);
};

export const removeSpecialCharacters = (text: string) => {
    const cleanedText = text.replace(SPECIAL_CHARACTERS, '');

    return cleanedText;
};

export const convertFileNameCsv = ({ prefixName, subName }: { prefixName: string; subName?: string }) => {
    const name = subName ? `${removeSpecialCharacters(subName)}_` : '';

    return `${prefixName}_${name}${dayjs().format('YYYYMMDDHHmmss')}.csv`;
};

export const convertFormData = (name: string, value: any, type: 'edit' | 'create' = 'create') => {
    const formData = new FormData();
    Object.keys(value).forEach((key: string) => {
        if (Array.isArray(value[key])) {
            key === 'attachments' &&
                value[key]
                    .filter((c: any) => !!c.file)
                    .forEach((e: any) => {
                        !e.file[0].id && formData.append(`${name}[${key}][]`, e.file[0]);
                    });

            key === 'deleteIds' &&
                value[key].length &&
                value[key].map((item: string) => formData.append(`${name}[delete_img_ids][]`, item));
        } else if (typeof value[key] === 'object' && value[key] !== null) {
            const { lng, lat, address } = value[key];

            formData.append(`${name}[longitude]`, lng);
            formData.append(`${name}[latitude]`, lat);
            formData.append(`${name}[address]`, address);
        } else {
            formData.append(`${name}[${key}]`, value[key]);
        }
    });

    formData.append(`${name}[progress][latest_updated_at]`, dayjs().format(FORMAT_DATE_TIME_SECONDS));
    type === 'create' && formData.append(`${name}[progress][description]`, '新規登録');

    return formData;
};

type K = keyof ReportReqType;

export const convertReportDataSub = ({
    name,
    value,
    progresses,
    type = 'create',
}: {
    name: string;
    value: any;
    type?: 'edit' | 'create';
    progresses?: any;
}) => {
    const formData: any = { attachment_ids: [], progress: { latest_updated_at: '' } };

    Object.keys(value).forEach((key: string) => {
        if (Array.isArray(value[key])) {
            key === 'attachments' &&
                value[key]
                    .filter((c: any) => !!c.file)
                    .forEach((e: any) => {
                        e.file[0].id && formData.attachment_ids.push(e.file[0].id);
                    });
        } else if (typeof value[key] === 'object' && value[key] !== null) {
            const { lng, lat, address } = value[key];
            formData.longitude = lng || null;
            formData.latitude = lat || null;
            formData.address = address || null;
        } else if (key) {
            formData[key as K] = value[key];
        }
    });

    formData.progress.latest_updated_at = dayjs().format(FORMAT_DATE_TIME_SECONDS);
    if (type === 'create') {
        formData.progress.description = '新規登録';
    } else {
        formData.progress.id = progresses?.[0]?.id;
    }

    return { [name]: formData as ReportReqType };
};

export const detectExtensionFile = (filename: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return filename.split('.').pop()!;
};

export const validateFile = (file: File, allowType: string[], maxFileSize: number, type: 'csv' | 'file' = 'file') => {
    if (!file) {
        return false;
    }
    const maxSize = maxFileSize * 1024 * 1024;

    const typeFile = detectExtensionFile(file.name);

    const fileTypeArr = file.type.split('/');

    if (!allowType.includes(fileTypeArr[fileTypeArr.length - 1]) && !allowType.includes(typeFile)) {
        toast({
            description: t(type === 'csv' ? 'common.MSG_049' : 'common.MSG_043'),
            variant: 'destructive',
        });

        return false;
    }
    if (file.size > maxSize) {
        toast({
            description: type === 'csv' ? t('common.MSG_053') : t('common.MSG_042', { number: maxFileSize }),
            variant: 'destructive',
        });

        return false;
    }

    return true;
};

export const convertOptions = ({
    options,
    defaultOption,
}: {
    options: SelectOptionsItem[];
    defaultOption: SelectOptionsItem;
}) => {
    if (!defaultOption?.value) {
        return options;
    }

    const isExist = options.findIndex((item) => item.value === defaultOption?.value);

    if (isExist >= 0) {
        return options;
    }

    return [defaultOption, ...options];
};

export const getPermissionQuery = (permissions: string[] = [], targetPermission: string) => {
    if (permissions.length === 0) {
        return '';
    }
    return permissions.reduce((query: string, item: string) => {
        const permission = GET_USER_BY_ROLE[item];
        return item === targetPermission ? query + permission : query;
    }, '');
};

export const convertStringMyNumber = (value = '', regex = /(\d{4})(\d{4})(\d{4})/) => {
    return value?.replace(regex, '$1 $2 $3');
};

export const convertStringPhone = (value = '', regex = /(\d{4})(\d{3})(\d{4})/) => {
    return value?.replace(regex, '$1-$2-$3');
};

export const removeSpaceMyNumber = (value = '') => {
    return value?.replace(/\s/g, '');
};

export const removeStringPhone = (value = '') => {
    return value?.replace(/-/g, '');
};

export const calculatorPageNumber = (total: number, page: number, pageSize = PAGE_SIZE) => {
    if (total <= pageSize) {
        return page;
    }
    return page === Math.ceil(total / pageSize) ? Math.ceil((total - 1) / pageSize) : page;
};

export const calculatorPage = (total = 0, page = 1, pageSize = PAGE_SIZE) => {
    if (total <= pageSize) {
        return 1;
    }

    if (page > Math.ceil(total / pageSize)) {
        return Math.ceil(total / pageSize);
    }

    return page;
};

export const checkIsTablet = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const getCoords = async () => {
    const pos: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return {
        long: pos?.coords?.longitude || null,
        lat: pos?.coords?.latitude || null,
    };
};

type ErrorMessageProps = {
    error: any;
    message?: string;
    defaultMessage?: string;
    callback?: (error?: string) => void;
};

export const handleErrorNotFound = ({ error, message, callback, defaultMessage }: ErrorMessageProps) => {
    if (error?.error?.reason === ERROR_RESPONSE.NOT_FOUND) {
        toast({ description: t(message), variant: 'destructive' });
        callback?.();
        return true;
    }
    defaultMessage && toast({ description: t(defaultMessage), variant: 'destructive' });
    return false;
};

export const handleErrorMessage = ({ error = '', message, callback, defaultMessage }: ErrorMessageProps) => {
    if (error.includes('MSG')) {
        toast({
            description: message || t(error.includes('common') ? error : `common.${error}`),
            variant: 'destructive',
        });
        callback?.(error);
        return true;
    }

    defaultMessage && toast({ description: t(defaultMessage), variant: 'destructive' });
    return false;
};
