import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { PageProps } from '@/interfaces/props/page';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { GetDetailQuestionSurvey } from '@/modules/services/survey.service';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { QuestionSurveyDetailType } from '@/interfaces/survey';
import { Item, renderLabel, renderText } from '@/components/common/pages/Element';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE, SURVEY_TYPE, TYPE_ANSWER } from '@/constants/appConstants';

const QuestionDetail = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { setLoading } = useGlobalStore();
    const { id } = useParams();

    const getDetailQuestionSurvey = async (): Promise<QuestionSurveyDetailType> => {
        try {
            setLoading(true);
            const res = await GetDetailQuestionSurvey(id);
            const { data }: { data: QuestionSurveyDetailType } = res;
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    };

    const onBackMenu = () => {
        navigate(
            data?.survey_type === SURVEY_TYPE.SURVEY_61
                ? `/welfare/questions?survey_type=${SURVEY_TYPE.SURVEY_61}`
                : `/welfare/questions`
        );
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDetailQuestionSurvey', id],
        queryFn: () => {
            if (id) return getDetailQuestionSurvey();
        },
        placeholderData: keepPreviousData,
    });

    return (
        <PageComponent
            pageLoading={isLoading}
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: t(
                                data?.survey_type === SURVEY_TYPE.SURVEY_61
                                    ? 'survey.detail_61_question'
                                    : 'survey.detail_question'
                            ),
                        },
                    ]}
                    className="py-6"
                />
            }
            textBack={t('go_back')}
            isDownLoadCsv={false}
            onBack={onBackMenu}
        >
            <div className="flex flex-col gap-7 mt-6">
                <Item className="whitespace-break-spaces" label={t('survey.title')}>
                    {renderText(data?.title)}
                </Item>
                <Item label={t('survey.deadline')}>
                    {renderText(data?.due_date ? dayjs(data?.due_date).format(FORMAT_DATE) : '')}
                </Item>
                {data?.questions?.map((item, index) => (
                    <div key={item?.id} className="flex flex-wrap flex-col gap-4 whitespace-break-spaces">
                        <Item
                            label={
                                <div className="flex items-center">
                                    {renderLabel(index + 1, t('survey.question_content'), 'mr-1')}
                                </div>
                            }
                        >
                            {renderText(item?.title)}
                        </Item>
                        <Item
                            label={
                                <div className="flex items-center">
                                    {renderLabel(index + 1, t('survey.answer'), 'mr-1')}
                                </div>
                            }
                        >
                            {renderText(t(`survey.${item?.question_type}`))}
                        </Item>
                        {item.question_type === TYPE_ANSWER.MULTIPLE_CHOICE && (
                            <div className="flex flex-wrap sm:pl-[204px] xl:pl-[194px] gap-4 ">
                                {item?.options?.map((el) => (
                                    <span
                                        key={el?.id}
                                        className="p-4 border border-grey rounded-md w-full md:w-[455px]"
                                    >
                                        {el?.content}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </PageComponent>
    );
};

export default withTrans(observer(QuestionDetail));
