import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import { SURVEY_TYPE } from '@/constants/appConstants';
import { PageProps } from '@/interfaces/props/page';
import { SurveyDataType } from '@/interfaces/survey';
import { convertFileNameCsv } from '@/lib/helper';
import { CreateFileSurveyCSV, GetSurveyList } from '@/modules/services/survey.service';
import { columns } from '@/pages/survey/SurveyColumns';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useCsvStore } from '@/store/csv/CsvStore';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TableProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const SurveyList = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const { user } = useAuthenStore();
    const [page, setPage] = useState(1);
    const [searchParams] = useSearchParams();
    const surveyType = searchParams.get(`survey_type`) || SURVEY_TYPE.GENERAL;

    const getSurveyList = async (page: number): Promise<{ dataSource: SurveyDataType[]; total: number }> => {
        if (user.id) {
            try {
                const response = await GetSurveyList({ page, per_page: 10, survey_type: surveyType });
                const { data, meta }: any = response;
                const newData = data.map((item: any) => ({
                    ...item,
                    key: item.id,
                    number_respondent: item.max_answer_number ? `${item.answer_count}/${item.max_answer_number}` : '',
                }));
                return { dataSource: newData, total: meta.total_count };
            } catch (error) {
                return { dataSource: [], total: 0 };
            }
        }
        return null;
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getSurveyList', user, page, surveyType],
        queryFn: () => getSurveyList(page),
        placeholderData: keepPreviousData,
    });

    const onDownloadCsv = async () => {
        try {
            await CreateFileSurveyCSV(surveyType);
            addQueue({
                flag: 'survey',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName:
                        surveyType === SURVEY_TYPE.SURVEY_61
                            ? t('welfare.61_survey_list_answer')
                            : `${t('survey.question_answer')}${t('list')}`,
                }),
                url: `users/export_csv_survey`,
                urlDownload: `users/export_csv_survey/download?survey_type=${surveyType}`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onDetail = (record: SurveyDataType) => {
        navigate(surveyType === SURVEY_TYPE.SURVEY_61 ? `survey_61/${record.key}` : `${record.key}`, {
            state: { title: record.title },
        });
    };

    const onChangeTable: TableProps<SurveyDataType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName:
                                surveyType === SURVEY_TYPE.SURVEY_61
                                    ? t('welfare.61_survey_list_answer')
                                    : `${t('survey.question_answer')}${t('list')}`,
                        },
                    ]}
                />
            }
            isDownLoadCsv={!!data?.dataSource?.length}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableComponent
                className="mt-2"
                loading={isLoading}
                columns={columns({
                    t,
                    onDetail,
                    columnsKey: ['detail', 'number_respondent', 'due_date', 'created_at', 'title'],
                })}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(SurveyList));
