import * as React from 'react';

import { cn } from '@/lib/utils';

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & { isView?: boolean };

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, isView, value, ...props }, ref) => {
    if (isView) {
        return <div className={cn('text-pc whitespace-break-spaces', className)}>{value || ''}</div>;
    }
    return (
        <textarea
            className={cn(
                'flex min-h-[80px] w-full rounded-md border border-grey bg-background px-3 py-2 text-sm  placeholder:text-muted-foreground focus-visible:outline-none focus:border-foreground disabled:cursor-not-allowed disabled:opacity-100 text-foreground ',
                className
            )}
            ref={ref}
            value={value}
            {...props}
        />
    );
});
Textarea.displayName = 'Textarea';

export { Textarea };
