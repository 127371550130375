import { z } from 'zod';
import { createStringSchema } from '@/schema/authSchema';
import i18n from '@/modules/i18n';

const { t } = i18n;

export const PhrasesSchema = z.object({
    content: createStringSchema('fixed_phrases'),
});

export const CategorySchema = z.object({
    content: createStringSchema('category.parent_category'),
    child_category: z.any(),
});

export const CategoryChildSchema = z.object({
    content: createStringSchema('category.child_category'),
    child_category: z.array(z.any()).nonempty({ message: t('common.MSG_002', { field: t('category.department') }) }),
});
