import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useNavigate } from 'react-router';
import dayjs from '@/lib/dayjs';
import { observer } from 'mobx-react-lite';
import withTrans from '@/components/common/hocs/withTrans';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { ForgotEmailSchema } from '@/schema/authSchema';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { toast } from '@/components/ui/use-toast';
import { ForgotPasswordApi } from '@/modules/services/auth.service';
import { useAuthenStore } from '@/store/auth/AuthStore';
import FormInputText from '@/components/common/form/FormInputText';

const ForgotPassword = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const { setTimeSendEmail } = useAuthenStore();

    const form = useForm<z.infer<typeof ForgotEmailSchema>>({
        resolver: zodResolver(ForgotEmailSchema),
        defaultValues: {
            email: undefined,
        },
        mode: 'onChange',
    });

    const onSubmit = async ({ email }: { email: string }) => {
        try {
            setLoading(true);
            await ForgotPasswordApi({ user: { email } });
            setTimeSendEmail(dayjs().unix().toString());
            navigate(`/forgot-password/success?email=${encodeURIComponent(email)}&time=${dayjs().unix()}`);
        } catch (error) {
            if (error && error.errors?.email?.[0] === 'not found') {
                toast({ description: t('common.MSG_010'), variant: 'destructive' });
                return;
            }
            toast({ description: t('common.MSG_100'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    const onSignIn = () => {
        navigate('/login');
    };

    return (
        <div className="flex h-screen flex-col items-center justify-center gap-6 sm:gap-9 max-w-[580px] m-auto whitespace-pre-line p-4">
            <div className="text-title font-bold">{t('app_title')}</div>
            <div className="text-center mt-6">{t('forgot_password_description')}</div>
            <Form {...form}>
                <div className="flex flex-col gap-y-6 w-full md:w-[431px]">
                    <FormInputText
                        className="sm:flex-wrap sm:gap-0"
                        name="email"
                        label={t('email')}
                        maxLength={255}
                        size="xxl"
                        required
                        placeholder="email@example.com"
                    />
                    <Button disabled={isLoading} className="mt-4" size="xxl" onClick={form.handleSubmit(onSubmit)}>
                        {t('send')}
                    </Button>
                    <div className="inline-flex justify-center mt-2">
                        <span>{t('remember_password')}</span>
                        <button
                            className="underline decoration-blue-400 text-blue-400 cursor-pointer font-bold"
                            onClick={onSignIn}
                        >
                            {t('login')}
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default withTrans(observer(ForgotPassword));
