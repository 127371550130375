import withTrans from '@/components/common/hocs/withTrans';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import { PAGE_SIZE, SURVEY_TYPE } from '@/constants/appConstants';
import useRefreshData from '@/hooks/useRefreshData';
import { PageProps } from '@/interfaces/props/page';
import { SurveyDataType } from '@/interfaces/survey';
import { convertFileNameCsv, handleErrorMessage, handleErrorNotFound } from '@/lib/helper';
import { CreateFileSurveyCSV, DeleteSurvey, GetSurveyList } from '@/modules/services/survey.service';
import { columns } from '@/pages/survey/SurveyColumns';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useCsvStore } from '@/store/csv/CsvStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TableProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const QuestionList = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const { user } = useAuthenStore();
    const { setLoading } = useGlobalStore();
    const [searchParams] = useSearchParams();
    const surveyType = searchParams.get(`survey_type`) || SURVEY_TYPE.GENERAL;
    const { refreshDataList, page, setPage } = useRefreshData({ queryKey: ['getSurveyList', user, surveyType] });

    const getQuestionList = async (page: number): Promise<{ dataSource: SurveyDataType[]; total: number }> => {
        if (user.id) {
            try {
                const response = await GetSurveyList({ page, per_page: PAGE_SIZE, survey_type: surveyType });
                const { data, meta }: any = response;
                const newData = data.map((item: any) => ({
                    ...item,
                    key: item.id,
                    number_respondent: `${item.answer_count}/${item.max_answer_number}`,
                }));
                return { dataSource: newData, total: meta.total_count };
            } catch (error) {
                return { dataSource: [], total: 0 };
            }
        }
        return null;
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getSurveyList', user, surveyType, page],
        queryFn: () => getQuestionList(page),
        placeholderData: keepPreviousData,
    });

    const onDownloadCsv = async () => {
        try {
            await CreateFileSurveyCSV(surveyType, true);
            addQueue({
                flag: 'question',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName:
                        surveyType === SURVEY_TYPE.SURVEY_61
                            ? t('survey.list_61_survey')
                            : `${t('survey.question')}${t('list')}`,
                }),
                url: `users/export_csv_survey?survey_type=${surveyType}`,
                urlDownload: `users/export_csv_survey/download?survey_type=${surveyType}`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onDetail = (record: SurveyDataType) => {
        navigate(`${record.key}`);
    };

    const onEdit = (record: SurveyDataType) => {
        navigate(`${record.key}/edit`);
    };

    const onChangeTable: TableProps<SurveyDataType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    const onCreate = () => {
        navigate(`create?survey_type=${surveyType}`);
    };

    const onDelete = async (key: string) => {
        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });
            if (res) {
                setLoading(true);
                await DeleteSurvey(key);
                refreshDataList();
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_063',
                callback: () => refreshDataList(),
            });
            if (isNotFound) return;
            handleErrorMessage({
                error: error?.errors?.survey?.[0] || '',
                callback: (msg) => msg.includes('MSG_063') && refreshDataList(),
                defaultMessage: 'common.MSG_041',
            });
        } finally {
            setLoading(false);
        }
    };

    const columnsTable = columns({
        t,
        onDetail,
        onDelete,
        onEdit,
        columnsKey: ['detail', 'due_date', 'created_at', 'title', 'action'],
    });

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName:
                                surveyType === SURVEY_TYPE.SURVEY_61
                                    ? t('survey.list_61_survey')
                                    : `${t('survey.question')}${t('list')}`,
                        },
                    ]}
                />
            }
            isDownLoadCsv={!!data?.dataSource?.length}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableBar
                hasSelect={false}
                isCreate
                onCreate={onCreate}
                textCreate={t(surveyType === SURVEY_TYPE.SURVEY_61 ? 'survey.create_61_survey' : 'create')}
            />
            <TableComponent
                className="mt-2"
                loading={isLoading}
                columns={columnsTable}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(QuestionList));
