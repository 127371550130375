import { PAGE_SIZE } from '@/constants/appConstants';
import { GetAllApplicationList, GetApplicationAcceptList } from '@/modules/services/application.service';
import { DataType } from '@/pages/application/ApplicationColumns';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const useGetApplications = ({
    page = 1,
    perPage = PAGE_SIZE,
    userId,
    residentId,
}: {
    page?: number;
    perPage?: number;
    userId?: string;
    residentId?: string;
}) => {
    const getApplicationAcceptList = async (page: number): Promise<{ data: DataType[]; total: number }> => {
        try {
            const response = userId
                ? await GetApplicationAcceptList(userId, { resident_id: residentId, page, per_page: perPage })
                : await GetAllApplicationList({ page, per_page: PAGE_SIZE });

            const { data, meta }: any = response;
            const newData = data.map((item: any) => ({
                ...item,
                key: item.id,
                application_service: {
                    welfare_taxi: item.welfare_taxi,
                    emergency_device: item.emergency_device,
                },
            }));

            return { data: newData, total: meta.total_count };
        } catch (error) {
            return {
                data: [],
                total: 0,
            };
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getApplicationList', userId, page],
        queryFn: () => getApplicationAcceptList(page),
    });

    const tableMeta = useMemo(() => {
        return {
            isDownLoadCsv: !!data?.data?.length,
            dataTable: data?.data || [],
            records: data?.total || 0,
        };
    }, [data]);

    return {
        dataSource: tableMeta.dataTable,
        total: tableMeta.records,
        isDownLoadCsv: tableMeta.isDownLoadCsv,
        isLoading,
    };
};

export default useGetApplications;
