import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageTitle from '@/components/common/pages/PageTitle';
import PageComponent from '@/components/common/pages/PageComponent';
import { useNavigate, useParams } from 'react-router';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { convertDetailSurvey, convertFileNameCsv } from '@/lib/helper';
import { toast } from '@/components/ui/use-toast';
import { useCsvStore } from '@/store/csv/CsvStore';
import TableComponent from '@/components/common/table/TableComponent';
import { ApplicationColumnsDetail } from '@/pages/reports/application/components/ApplicationColumnsDetail';
import { CreateFileAnswerCSV, GetDetailSurveyById, GetDetailSurveyResCSV } from '@/modules/services/survey.service';
import { useState } from 'react';
import { TableProps } from 'antd';

type TypeProps = {
    type: 'application';
} & WithTransProps;

const ApplicationDetail = (props: TypeProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const { addQueue } = useCsvStore();
    const [page, setPage] = useState(1);

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDetailReport', id, page],
        queryFn: () => {
            if (id) {
                return getDetailApplicationReport(id);
            }
        },
        placeholderData: keepPreviousData,
    });

    const title = data?.title || '';

    const onBack = () => {
        navigate(`/communication/reports/application`);
    };
    const getDetailApplicationReport = async (id: string) => {
        try {
            const { data: detail } = await GetDetailSurveyById(id);
            let newData: any = [];
            const titleColumn: any = {};
            const response = await GetDetailSurveyResCSV(id, { page, per_page: 10 });
            detail?.questions?.forEach((item: any, index: number) => {
                titleColumn[`question${index + 1}`] = item.title;
            });
            const { data, meta }: any = response;
            newData = convertDetailSurvey(data);
            return { dataSource: newData, total: meta.total_count, titleColumn, title: detail.title };
        } catch (error) {
            return null;
        }
    };

    const onDownloadCsvApplication = async () => {
        try {
            await CreateFileAnswerCSV(id);
            addQueue({
                flag: 'answer',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName: t('report.app_detail_title'),
                    subName: title,
                }),
                url: `users/surveys/${id}/export_csv_survey_response`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onChangeTable: TableProps<any>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    const renderTitle = (title: string) => {
        return <div className="whitespace-break-spaces">{title}</div>;
    };

    return (
        <PageComponent
            pageLoading={isLoading}
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: renderTitle(`${title}${t('report.app_detail_title')}`) }]}
                />
            }
            onBack={onBack}
            textBack={t('go_back')}
            onDownLoadCsv={onDownloadCsvApplication}
        >
            <TableComponent
                loading={isLoading}
                columns={ApplicationColumnsDetail({ t, title: data?.titleColumn || {} })}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};
export default withTrans(observer(ApplicationDetail));
