import { z } from 'zod';
import i18n from '@/modules/i18n';
import { ReportRoutes } from '@/interfaces/common/global';

const { t } = i18n;

export const ReportSchema = (type: ReportRoutes) =>
    z
        .object({
            google_map: z.object({
                address: z.any(),
                lng: z.any(),
                lat: z.any(),
            }),
            // .superRefine((val, ctx) => {
            //     const error = val.address;
            //     if (val.address && (!val.lat || !val.lng)) {
            //         ctx.addIssue({
            //             code: z.ZodIssueCode.custom,
            //             path: [''],
            //             message: t('not_location', { text: val.address }),
            //         });
            //     } else if (!error) {
            //         ctx.addIssue({
            //             code: z.ZodIssueCode.custom,
            //             path: [''],
            //             message: t('report.placeholder_search_address'),
            //         });
            //     }
            // }),
            assignee_id: z.any().superRefine((val, ctx) => {
                if (!val) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_002', { field: t('report.name') }),
                    });
                }
            }),
            category_id: z.any().superRefine((val, ctx) => {
                if (!val) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_002', { field: t('category.parent_category') }),
                    });
                }
            }),
            content: z.any().superRefine((val, ctx) => {
                if (!val) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_001', { field: t(`report.${type}_report_content`) }),
                    });
                }
            }),
            category_child_id: z.any(),
            attachments: z.any(),
            deleteIds: z.any(),
            isValidChild: z.any(),
            // attachments: z.array(z.object({ file: z.any() })).superRefine((val, ctx) => {
            //     const error = val.some((opt) => !!opt.file);
            //     if (!error) {
            //         ctx.addIssue({
            //             code: z.ZodIssueCode.custom,
            //             path: [''],
            //             message: t('common.MSG_061'),
            //         });
            //     }
            // }),
        })
        .superRefine((val, ctx) => {
            if (val.isValidChild && !val.category_child_id) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['category_child_id'],
                    message: t('common.MSG_002', { field: t('report.category_medium') }),
                });
            }
        });

export const AnswerApplicationDetailSchema = z.object({
    respondent: z.any(),
    text_answer: z.any(),
    options: z.object({
        id: z.string({
            required_error: t('common.MSG_001', { field: 'id' }),
        }),
    }),
});
