import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { FormField, FormMessage } from '@/components/ui/form';
import { ACCEPT_IMAGE_TYPE, ITEM, MAX_SIZE_FILE, SUPPORT_IMAGE_TYPE } from '@/constants/appConstants';
import { useFormContext } from 'react-hook-form';
import { handleConvertHEICToJEPG } from '@/lib/img-converter';
import { uploadFileAttachment } from '@/modules/services/attachment.service';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { Upload } from 'antd';
import { validateFile } from '@/lib/helper';
import { IUploadFile } from '@/interfaces/common/upload';
import '@/components/common/form/ImageUploadPreviewStyle.css';
import ConfigProvider from 'antd/es/config-provider';
import jaJP from 'antd/locale/ja_JP';
import { toast } from '@/components/ui/use-toast';
import { t } from 'i18next';

interface Props {
    dataFiles: {
        [key: number]: any;
    };
    attachmentType: string;
}

const ImageUploadPreview = forwardRef(({ dataFiles, attachmentType }: Props, ref) => {
    const { setLoading } = useGlobalStore();
    const {
        control,
        clearErrors,
        setValue,
        getValues,
        formState: { errors },
    } = useFormContext();

    const [files, setFiles] = useState<any>({});

    useEffect(() => {
        const filesData = getValues()?.attachments || [];
        if (!dataFiles || Object.keys(dataFiles).length < 1) {
            if (!filesData) {
                setFiles(dataFiles);
                return;
            }
            filesData.forEach((f: any) => {
                dataFiles[f.index] = {
                    fileList: [
                        {
                            uid: f.id,
                            name: f.name,
                            url: f.url || '/assets/default.jpg',
                            thumbUrl: f.url || '/assets/default.jpg',
                            status: 'done',
                            response: f,
                        },
                    ],
                };
            });
            setFiles(dataFiles);
        } else {
            setFiles(dataFiles);
        }
    }, []);

    const showUploadList = {
        showDownloadIcon: false,
        showRemoveIcon: true,
        showPreviewIcon: false,
    };

    const handleSetAttachments = (indexFile: number) => {
        const attachments: any[] = [];
        Object.keys(dataFiles).forEach(function (key) {
            if (Number(key) !== indexFile && dataFiles[Number(key)]) {
                const fileList = dataFiles[Number(key)].fileList;
                if (fileList && fileList.length > 0 && fileList[0].response) {
                    attachments.push(fileList[0]?.response);
                }
            }
        });
        setValue('attachments', attachments);
        setValue(
            'attachment_ids',
            (attachments || []).map((f: any) => {
                return f.id;
            })
        );
    };

    const handleChange = (file: any, indexFile: number) => {
        if (!dataFiles[indexFile]) {
            dataFiles[indexFile] = {
                fileList: [],
            };
        }

        if (file && file.fileList && file.fileList[0] && file.fileList[0].status === 'error') {
            dataFiles[indexFile].fileList = [];
        } else {
            dataFiles[indexFile].fileList = file.fileList;
        }
        handleSetAttachments(indexFile);
    };

    const uploadButton = (defaultName: string) => {
        return (
            <button style={{ border: 0, background: 'none' }} type="button" className="text-ip">
                <PlusOutlined /> {defaultName}
            </button>
        );
    };

    const beforeUpload = async (file: any) => {
        setLoading(true);
        const isValid = validateFile(file, SUPPORT_IMAGE_TYPE, MAX_SIZE_FILE);
        if (!isValid) {
            setLoading(false);
            file.status = 'error';
            return file;
        }
        const { fileContent, fileName, fileType } = await handleConvertHEICToJEPG(file);
        setLoading(false);
        return new File([fileContent as Blob], fileName, { type: fileType });
    };

    const customUploadImageRequest = async (options: any, index: number) => {
        const { onSuccess, onError, file } = options;
        if (!validateFile(file, SUPPORT_IMAGE_TYPE, MAX_SIZE_FILE)) {
            onError('Error');
        } else {
            setLoading(true);
            const formData = new FormData();
            formData.append('type', attachmentType);
            formData.append('attachment', file, file.name);
            formData.append('index', index.toString());
            try {
                const { data } = await uploadFileAttachment(formData);
                onSuccess(data);
                clearErrors('attachments');
            } catch (e) {
                toast({
                    description: t('common.MSG_044'),
                    variant: 'destructive',
                });
                onError({ e });
            } finally {
                setLoading(false);
            }
        }
    };

    const clearImage = (file: IUploadFile, indexFile: number) => {
        dataFiles[indexFile].fileList = [];
        handleSetAttachments(indexFile);
        return true;
    };

    useImperativeHandle(ref, () => ({}));

    return (
        <div className="flex flex-wrap justify-center items-center w-full img-upload-preview">
            <ConfigProvider locale={jaJP}>
                {ITEM.map(({ defaultName }, i) => {
                    return (
                        <div className={'w-1/3'} key={i}>
                            <Upload
                                listType="picture-card"
                                key={i}
                                showUploadList={showUploadList}
                                customRequest={(options) => customUploadImageRequest(options, i)}
                                beforeUpload={beforeUpload}
                                fileList={files?.[i]?.fileList || []}
                                accept={ACCEPT_IMAGE_TYPE}
                                multiple={false}
                                maxCount={1}
                                onChange={(file) => {
                                    handleChange(file, i);
                                }}
                                onRemove={(file) => {
                                    clearImage(file, i);
                                }}
                            >
                                {dataFiles?.[i]?.fileList.length ? null : uploadButton(defaultName)}
                            </Upload>
                        </div>
                    );
                })}
            </ConfigProvider>

            <div className="w-full flex justify-center">
                <FormField
                    name={'attachments'}
                    control={control}
                    render={() => {
                        return (
                            <>
                                {errors?.attachments?.type === 'focus' && (
                                    <FormMessage className="text-error text-start w-[600px]" />
                                )}
                            </>
                        );
                    }}
                />
            </div>
        </div>
    );
});
export default ImageUploadPreview;
