import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { ReactNode, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

type ModalComponentData = {
    visible: boolean;
    message?: ReactNode;
    title?: ReactNode;
    content?: ReactNode;
    footer?: ReactNode | null;
    cancelText?: string;
    okText?: string;
    loadingBtn?: boolean;
    wrapClass?: string;
    onOk: () => void;
    onCancel: () => void;
};

const ModalComponent = forwardRef<unknown, ModalComponentData>((props, ref) => {
    const { visible, message, title, content, footer, cancelText, okText, onOk, onCancel, loadingBtn, wrapClass } =
        props;
    const { t } = useTranslation();

    const renderFooter = () => {
        if (footer === null) {
            return <></>;
        }

        if (footer) {
            return footer;
        }

        return (
            <>
                <Button
                    variant="default"
                    size="xl"
                    className="text-ip h-[42px] min-w-44"
                    onClick={onOk}
                    disabled={loadingBtn}
                >
                    {okText || t('register')}
                </Button>
                <Button variant="outline" size="xl" className="text-ip h-[42px] min-w-44" onClick={onCancel}>
                    {cancelText || t('go_back')}
                </Button>
            </>
        );
    };

    useImperativeHandle(ref, () => ({}));

    return (
        <Dialog open={visible} onOpenChange={onCancel} modal={false}>
            <div className="fixed inset-0 z-50 bg-loadingbg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
            <DialogContent
                onPointerDownOutside={(e: Event) => e.preventDefault()}
                className={cn('sm:max-w-[90%] lg:max-w-[80%] xl:max-w-[60%] min-h-[300px]', wrapClass)}
            >
                <DialogHeader>
                    <DialogTitle className="!text-label">{title}</DialogTitle>
                    <DialogDescription className="text-ip">{message}</DialogDescription>
                </DialogHeader>
                <div className="min-h-[300px]">{content}</div>
                <DialogFooter className="flex-row gap-2 justify-center sm:justify-center">
                    {renderFooter()}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
});

export default ModalComponent;
