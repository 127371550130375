import FormInputText from '@/components/common/form/FormInputText';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import FormSelect from '@/components/common/form/FormSelect';
import ModalComponent from '@/components/common/pages/ModalComponent';
import { Form } from '@/components/ui/form';
import { toast } from '@/components/ui/use-toast';
import { FORMAT_DATE_TIME_SECONDS, optionEditStatus, optionStatus } from '@/constants/appConstants';
import useToggle from '@/hooks/useToggle';
import { convertOptionSelect, handleErrorMessage, handleErrorNotFound, optionPriority } from '@/lib/helper';
import { ProcessSchema } from '@/schema/applicationSchema';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from '@/lib/dayjs';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { z } from 'zod';
import { AxiosResponse } from 'axios';

type ProcessProps = {
    status: string;
    refreshData?: () => void;
    onCreateData?: any;
    onUpdateData?: any;
    onGetInitData?: (typeId: string, progressId: string) => Promise<AxiosResponse<any, any>>;
    initPriority?: string | null | undefined;
    progressesData?: any[];
};

const ProgressForm = forwardRef<unknown, ProcessProps>(
    ({ refreshData, status, onCreateData, onUpdateData, onGetInitData, initPriority, progressesData }, ref) => {
        const { t } = useTranslation();
        const { isShown, handleOpen, handleClose } = useToggle();
        const { user } = useAuthenStore();
        const { isLoading, setLoading } = useGlobalStore();
        const { id: typeId } = useParams();
        const [progressId, setProgressId] = useState<string>('');
        const [statusProcess, setStatusProcess] = useState<string>('');
        const [prevStatus, setPrevStatus] = useState<string>('');
        const form = useForm<z.infer<typeof ProcessSchema>>({
            resolver: zodResolver(ProcessSchema),
            defaultValues: {
                name: undefined,
                description: undefined,
                latest_updated_at: undefined,
                priority: initPriority || undefined,
                status: undefined,
            },
            mode: 'onChange',
        });

        const getDataDetail = async (progressId: string) => {
            try {
                setLoading(true);
                const { data } = await onGetInitData(typeId, progressId);
                setStatusProcess(data.status);
                form.reset({
                    ...data,
                    name: user.name,
                });
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            if (user) {
                form.setValue('name', user.name);
            }
        }, [user]);

        const progressesPriority = () => {
            return (
                (progressesData || [])?.filter((item: any) => {
                    return item && item.priority;
                }) || []
            );
        };

        useImperativeHandle(ref, () => {
            return {
                handleOpen: (id?: string, preStatus?: string) => {
                    handleOpen();
                    id && getDataDetail(id);
                    !id && form.setValue('priority', initPriority || undefined);
                    setProgressId(id || '');
                    setPrevStatus(preStatus || '');
                },
            };
        });

        const onSubmit = async (value: z.infer<typeof ProcessSchema>) => {
            try {
                setLoading(true);
                const data = {
                    latest_updated_at: dayjs().format(FORMAT_DATE_TIME_SECONDS),
                    description: value.description,
                    priority: value.priority,
                    status: value.status,
                };
                progressId ? await onUpdateData(typeId, progressId, data) : await onCreateData(typeId, data);

                toast({ description: t(progressId ? 'common.MSG_017' : 'common.MSG_015'), variant: 'success' });
                refreshData?.();
            } catch (error) {
                const isNotFound = handleErrorNotFound({
                    error,
                    message: 'common.MSG_036',
                    callback: () => refreshData?.(),
                });

                if (isNotFound) return;

                handleErrorMessage({
                    error: error?.errors?.progress?.[0] || '',
                    callback: () => refreshData?.(),
                    defaultMessage: 'common.MSG_038',
                });
            } finally {
                onCancel();
                setLoading(false);
            }
        };

        const disablePriority = () => {
            return (initPriority && !progressId) || (initPriority && progressId && progressesPriority().length > 1);
        };

        const onCancel = () => {
            form.reset({
                name: user.name,
                description: undefined,
                latest_updated_at: undefined,
                priority: undefined,
                status: undefined,
            });
            setProgressId('');
            handleClose();
        };

        const options = convertOptionSelect(
            progressId ? optionEditStatus[prevStatus][statusProcess] : optionStatus[status]
        );

        const renderContent = () => {
            return (
                <Form {...form}>
                    <div className="flex flex-col gap-y-4 max-w-[1000px] py-6">
                        <FormInputText
                            name="name"
                            disabled={true}
                            labelClass="min-w-[170px]"
                            label={t('application_accept.c_name')}
                            placeholder={t('common.MSG_001', { field: t('application_accept.c_name') })}
                            maxLength={255}
                        />
                        <FormInputTextarea
                            name="description"
                            labelClass="min-w-[170px]"
                            label={t('application_accept.content')}
                            placeholder={t('common.MSG_001', { field: t('application_accept.content') })}
                            maxLength={255}
                            autoFocus
                        />
                        <FormSelect
                            name="priority"
                            options={optionPriority}
                            label={t('application_accept.urgency')}
                            placeholder={t('common.MSG_002', { field: t('application_accept.urgency') })}
                            labelClass="min-w-[170px]"
                            hasSearch={false}
                            disabled={disablePriority()}
                        />
                        <FormSelect
                            name="status"
                            options={options}
                            label={t('application_accept.status')}
                            placeholder={t('common.MSG_002', { field: t('application_accept.status') })}
                            labelClass="min-w-[170px]"
                            hasSearch={false}
                        />
                    </div>
                </Form>
            );
        };

        return (
            <>
                {isShown && (
                    <ModalComponent
                        visible={isShown}
                        title={`${t('application_accept.process_report_list')}${t(progressId ? 'edit' : 'create_new')}`}
                        content={renderContent()}
                        onCancel={onCancel}
                        onOk={form.handleSubmit(onSubmit)}
                        okText={t(progressId ? 'update' : 'register')}
                        loadingBtn={isLoading}
                    />
                )}
            </>
        );
    }
);

export default observer(ProgressForm);
