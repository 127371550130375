import React from 'react';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { UserType } from '@/interfaces/auth';
import { SHOW_IPAD_SCREEN } from '@/constants/permissionConfig';

class AuthenStore {
    isAuthenticated = false;
    isIpad = false;
    user: UserType = null;
    timeSendEmail = '';
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'AuthenStore',
            properties: ['user', 'isIpad', 'timeSendEmail'],
            storage: window.localStorage,
        });
    }
    
    setAuthenticated = (isAuth: boolean) => {
        this.isAuthenticated = isAuth;
    };

    setUser = (user: any) => {
        let roles: string[] = [];
        this.setAuthenticated(!!user);

        Object.keys(user.roles).forEach((item: string) => {
            roles = [...roles, ...user.roles[item]];
        });

        this.user = { ...user, roles, role: user.roles };
        this.isIpad = SHOW_IPAD_SCREEN.some((el: string) => roles?.includes(el));
    };

    updateUser = (name: string) => {
        this.user = { ...this.user, name };
    };

    setTimeSendEmail = (time: string) => {
        this.timeSendEmail = time;
    };

    clearData = () => {
        this.setAuthenticated(false);
        localStorage.clear();
    };
}

export const authStoreInternal = new AuthenStore();
const storeContext = React.createContext(authStoreInternal);
export const useAuthenStore = (): AuthenStore => React.useContext(storeContext);
