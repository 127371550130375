/* eslint-disable no-irregular-whitespace */
import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE } from '@/constants/appConstants';
import { ColumnsProps } from '@/interfaces/common/global';
import { SurveyDataType, SurveyDetailType } from '@/interfaces/survey';
import TooltipComponent from '@/components/common/pages/ToolTip';

export const columns = ({
    t,
    onDetail,
    columnsKey,
    onEdit,
    onDelete,
    showFull,
}: ColumnsProps<SurveyDataType>): TableProps<SurveyDataType>['columns'] => {
    const allColumns: TableProps<SurveyDataType>['columns'] = [
        {
            title: t('visit_report.detail'),
            dataIndex: 'detail',
            key: 'detail',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return (
                    <Button
                        variant="default"
                        onClick={() => {
                            onDetail?.(record);
                        }}
                    >
                        {t('detail')}
                    </Button>
                );
            },
        },
        {
            title: t('survey.number_respondent'),
            dataIndex: 'number_respondent',
            key: 'number_respondent',
            width: 70,
            align: 'center',
        },
        {
            title: t('survey.deadline'),
            dataIndex: 'due_date',
            key: 'due_date',
            width: 100,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE) : ''),
        },
        {
            title: t('survey.response_request_date'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 100,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE) : ''),
        },
        {
            title: <div className="text-center">{t('survey.title')}</div>,
            dataIndex: 'title',
            key: 'title',
            width: 250,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces max-w-[350px] lg:max-w-[600px] xl:max-w-fit">
                            {value}
                        </div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 160,
            align: 'center',
            render: (_, record) => {
                const showButonEdit =
                    record.answer_count === 0 &&
                    dayjs().startOf('day').isBefore(dayjs(record?.due_date, FORMAT_DATE).endOf('day'));
                return (
                    <>
                        {showButonEdit && (
                            <Button
                                variant="update"
                                className="bg-green-500 border-grey m-1"
                                onClick={() => {
                                    onEdit?.(record);
                                }}
                            >
                                {t('edit')}
                            </Button>
                        )}
                        <Button
                            variant="delete"
                            className="bg-red-500 border-grey m-1"
                            onClick={() => {
                                onDelete?.(record.key);
                            }}
                        >
                            {t('delete')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const columnsDetails = ({
    t,
    title,
    columnsKey,
}: ColumnsProps<SurveyDetailType>): TableProps<SurveyDetailType>['columns'] => {
    const allColumns: TableProps<SurveyDetailType>['columns'] = [
        {
            title: t('survey.respondent'),
            dataIndex: 'user',
            key: 'user',
            width: 100,
            align: 'center',
            render: (_, record) => {
                return record?.user?.name;
            },
        },
        {
            title: t('survey.answer_date_time'),
            dataIndex: 'submit_date',
            key: 'submit_date',
            width: 100,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE) : ''),
        },
        {
            title: t('target_name'),
            dataIndex: 'target_name',
            key: 'target_name',
            width: 100,
            align: 'center',
            render: (_, record) => {
                return record?.respondent?.name;
            },
        },
        {
            title: <div className="text-center">{`${t('survey.question')}1　${title?.question1 || ''}`}</div>,
            dataIndex: 'answer1',
            key: 'answer1',
            width: 200,
            render: (value) => <div className="whitespace-break-spaces">{value}</div>,
        },
        {
            title: <div className="text-center">{`${t('survey.question')}2　${title?.question2 || ''}`}</div>,
            dataIndex: 'answer2',
            key: 'answer2',
            width: 200,
            render: (value) => <div className="whitespace-break-spaces">{value}</div>,
        },
        {
            title: <div className="text-center">{`${t('survey.question')}3　${title?.question3 || ''}`}</div>,
            dataIndex: 'answer3',
            key: 'answer3',
            width: 200,
            render: (value) => <div className="whitespace-break-spaces">{value}</div>,
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
