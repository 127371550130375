import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';
import { Params } from '@/interfaces/common/global';
import { AttendanceTypeReq } from '@/interfaces/meeting';

export const getListMeetings = async (params?: Params) => {
    return await apiServiceWithAuth.get('meetings', { params });
};

export const updateMeeting = async (body?: any, meetingId?: string) => {
    return await apiServiceWithAuth.patch(`meetings/${meetingId}`, { ...body });
};

export const createMeeting = async (body?: any) => {
    return await apiServiceWithAuth.post('meetings', { ...body });
};

export const getMeetingDetail = async (meetingId: string) => {
    return await apiServiceWithAuth.get(`meetings/${meetingId}`);
};

export const getMeetingDetailAttendances = async (meetingId: string) => {
    return await apiServiceWithAuth.get(`meetings/${meetingId}/attendances`);
};

export const createFileMeetingCsv = async () => {
    return await apiServiceWithAuth.post(`users/export_csv_meeting`);
};

export const deleteMeeting = async (meetingId: string) => {
    return await apiServiceWithAuth.delete(`meetings/${meetingId}`);
};

export const updateAttendanceMeeting = async (meetingId: string, body: AttendanceTypeReq) => {
    return await apiServiceWithAuth.patch(`meetings/${meetingId}/attendance`, body);
};

export const createCsvMeetingAttendance = async (meetingId: string) => {
    return await apiServiceWithAuth.post(`meetings/${meetingId}/export_csv_attendance`);
};
