import { TableProps } from 'antd/es/table';
import { ConfigProvider, Empty, PaginationProps, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE } from '@/constants/appConstants';
import { configTable } from '@/lib/helper';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';

const TableComponent = <T,>({
    dataSource,
    columns,
    loading,
    scroll,
    locale,
    pagination,
    className,
    onChange,
    ...props
}: TableProps<T>) => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState(dataSource);
    const total = pagination ? pagination['total'] : 0;
    const showPagination = total > PAGE_SIZE;

    useEffect(() => {
        setTableData(dataSource);
    }, [dataSource]);

    useEffect(() => {
        if (pagination && !dataSource.length && !loading) {
            onChange({ ...pagination, current: 1 }, null, null, null);
        }
    }, [pagination, dataSource, loading]);

    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>{t('previous')}</a>;
        }
        if (type === 'next') {
            return <a>{t('next')}</a>;
        }
        return originalElement;
    };

    const customerLocale = {
        ...locale,
        emptyText: (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<div className="text-ip text-foreground">{t('no_data')}</div>}
            />
        ),
    };
    const onChangeTable = (...args: any) => {
        setTableData([]);
        onChange(args[0], args[1], args[2], args[3]);
    };

    return (
        <ConfigProvider theme={configTable}>
            <Table
                className={cn('', className)}
                loading={loading}
                columns={columns}
                dataSource={tableData}
                locale={customerLocale}
                bordered
                onChange={onChangeTable}
                scroll={{ x: 'max-content', ...scroll }}
                pagination={
                    showPagination !== false && {
                        position: ['bottomCenter'],
                        itemRender,
                        showSizeChanger: false,
                        ...pagination,
                    }
                }
                {...props}
            />
        </ConfigProvider>
    );
};

export default TableComponent;
