import React, { useEffect } from 'react';
import useCountdown from '@/hooks/useCountDown';

type CountDownProp = {
    time: number;
    onHandle: () => void;
};

const CountDown: React.FC<CountDownProp> = ({ onHandle, time }) => {
    const { minutes, seconds, counter } = useCountdown(time);

    const minutesData = minutes < 10 ? `0${minutes}` : minutes;
    const secondsData = seconds < 10 ? `0${seconds}` : seconds;

    useEffect(() => {
        if (counter === 0 && time > 0) {
            onHandle();
        }
    }, [counter]);

    return (
        <>
            {!!counter && (
                <span className="ml-1">
                    ({minutesData}:{secondsData})
                </span>
            )}
        </>
    );
};

export default CountDown;
