import { ForgotPasswordReq, LoginReq, ResetPasswordReq } from '@/interfaces/auth';
import { Params } from '@/interfaces/common/global';
import { UpdatePasswordReq } from '@/interfaces/profile/password';
import { apiServiceNotAuth } from '@/modules/http/apiServiceNotAuth';
import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';

export const Login = async (params: LoginReq) => {
    return await apiServiceNotAuth.post('auth/login', params);
};

export const Logout = async () => {
    return await apiServiceWithAuth.delete('auth/logout');
};

export const UpdatePassword = async (params: UpdatePasswordReq) => {
    return await apiServiceWithAuth.patch('auth/update_password', params);
};

export const ForgotPasswordApi = async (params: ForgotPasswordReq) => {
    return await apiServiceNotAuth.post('auth/password', params);
};

export const ResetPasswordApi = async (params: ResetPasswordReq) => {
    return await apiServiceNotAuth.put('auth/password', params);
};

export const GetProfileUser = async () => {
    return await apiServiceWithAuth.get('users/get_current_user');
};

export const GetListUsers = async <T>(params?: Params & { role?: string; name?: string; order_by?: string }) => {
    return (await apiServiceWithAuth.get('users', { params })) as T;
};

export const VerifyToken = async (resetToken: string) => {
    return await apiServiceWithAuth.get(`auth/get_url_reset_password?reset_password_token=${resetToken}`);
};
