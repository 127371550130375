import { z } from 'zod';
import i18n from '@/modules/i18n';
import { TYPE_ANSWER } from '@/constants/appConstants';
import { createStringSchema } from '@/schema/authSchema';

const { t } = i18n;

export const QuestionsSchema = z.object({
    title: createStringSchema('survey.title'),
    due_date: createStringSchema('survey.deadline', 'select').nullable(),
    questions: z.array(
        z
            .object({
                title: createStringSchema('survey.question_content'),
                question_type: createStringSchema('survey.answer', 'select'),
                options: z.array(
                    z.object({
                        content: z.any(),
                    })
                ),
            })
            .superRefine((val, ctx) => {
                const error = !(val.question_type === TYPE_ANSWER.MULTIPLE_CHOICE
                    ? val.options.some((opt) => !!opt.content)
                    : true);

                if (error) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['options'],
                        message: t('survey.option_placeholder'),
                    });
                }
            })
    ),
});
export const QuestionDetailSchema = z.object({
    id: z.string({
        required_error: t('common.MSG_001', { field: 'id' }),
    }),
    index: z.number(),
    option: z.any(),
    question_type: z.string(),
    title: z.string(),
});
export const AnswerSubmitSchema = z.object({
    submit_date: z.string(),
    answers: z.array(
        z.object({
            question_id: z.string().max(255, {
                message: t('common.MSG_019', { maxLength: 255 }),
            }),
            text_answer: z.string(),
            options: z.any(),
        })
    ),
});
export const AnswerDetailSchema = z.object({
    respondent: z.any(),
    text_answer: z.any(),
    options: z.object({
        id: z.string({
            required_error: t('common.MSG_001', { field: 'id' }),
        }),
    }),
});

export const ParticipantSchema = z.object({
    required_responses: z
        .string({
            required_error: t('common.MSG_001', { field: t('survey.person_number') }),
        })
        .trim()
        .min(1, {
            message: t('common.MSG_001', { field: t('survey.person_number') }),
        }),
});
