import { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ACCEPT_IMAGE_TYPE, MAX_SIZE_FILE, SUPPORT_IMAGE_TYPE } from '@/constants/appConstants';
import { validateFile } from '@/lib/helper';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { handleConvertHEICToJEPG } from '@/lib/img-converter';
import type { UploadFile, UploadProps } from 'antd';
import { uploadFileAttachment } from '@/modules/services/attachment.service';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { IUploadFile } from '@/interfaces/common/upload';
import jaJP from 'antd/locale/ja_JP';
import ConfigProvider from 'antd/es/config-provider';
import { toast } from '@/components/ui/use-toast';

interface IProps {
    classContainer?: string;
    value?: any;
    hasError?: boolean;
    handleClearFile?: (file: IUploadFile) => any;
    onChange?: (e: any) => void;
    showPopupDelete?: boolean;
    attachmentType?: string;
    attachmentIndex?: number;
}

const UploadImage = forwardRef((props: IProps, ref) => {
    const { t } = useTranslation();
    const { onChange, handleClearFile, value, classContainer, attachmentIndex } = props;
    const { setLoading } = useGlobalStore();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const showUploadList = {
        showDownloadIcon: false,
        showRemoveIcon: true,
        showPreviewIcon: false,
    };

    useEffect(() => {
        if (value?.length) {
            const newFile: UploadFile = {
                uid: value[0].id,
                name: value[0].name,
                url: value[0].url || '/assets/default.jpg',
                thumbUrl: value[0].url || '/assets/default.jpg',
                status: 'done',
                response: value[0],
            };
            setFileList([newFile]);
        }
    }, [value]);

    const clearImage = async (e: IUploadFile) => {
        handleClearFile(e);
    };

    const beforeUpload = async (file: any) => {
        setLoading(true);
        const isValid = validateFile(file, SUPPORT_IMAGE_TYPE, MAX_SIZE_FILE);
        if (!isValid) {
            setLoading(false);
            file.status = 'error';
            return file;
        }
        const { fileContent, fileName, fileType } = await handleConvertHEICToJEPG(file);
        setLoading(false);
        return new File([fileContent as Blob], fileName, { type: fileType });
    };

    const customUploadImageRequest = async (options: any) => {
        const { onSuccess, onError, file } = options;
        if (!validateFile(file, SUPPORT_IMAGE_TYPE, MAX_SIZE_FILE)) {
            onError('Error');
        } else {
            setLoading(true);
            const formData = new FormData();
            formData.append('type', props.attachmentType);
            formData.append('attachment', file, file.name);
            formData.append('index', attachmentIndex ? attachmentIndex.toString() : 'null');
            try {
                const { data } = await uploadFileAttachment(formData);
                onChange(data);
                onSuccess(data);
            } catch (e) {
                toast({
                    description: t('common.MSG_044'),
                    variant: 'destructive',
                });
                onError({ e });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList && newFileList[0] && newFileList[0].status === 'error') {
            setFileList([]);
        } else {
            setFileList(newFileList);
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button" className="text-ip">
            <PlusOutlined /> {t('upload_btn')}
        </button>
    );

    useImperativeHandle(ref, () => ({}));

    return (
        <div>
            <ConfigProvider locale={jaJP}>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    showUploadList={showUploadList}
                    customRequest={customUploadImageRequest}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    accept={ACCEPT_IMAGE_TYPE}
                    multiple={false}
                    maxCount={1}
                    onRemove={clearImage}
                    className={classContainer}
                >
                    {fileList.length >= 1 ? null : uploadButton}
                </Upload>
            </ConfigProvider>
        </div>
    );
});

export default UploadImage;
