import FormInputText from '@/components/common/form/FormInputText';
import { FormLabel } from '@/components/ui/form';
import { InputProps } from '@/components/ui/input';
import { optionHomeNumber, optionPhoneNumber } from '@/constants/appConstants';
import { cn } from '@/lib/utils';
import { useMaskito } from '@maskito/react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

type Props = {
    labelClass?: string;
    className?: string;
    label?: React.ReactNode;
    labelRequired?: boolean;
    required?: boolean;
    prefixName?: string;
    isVertical?: boolean;
} & Omit<InputProps, 'label' | 'className'>;

const EmergencyForm = ({
    className,
    labelClass,
    label,
    prefixName = '',
    isVertical = false,
    required = true,
    labelRequired = false,
}: Props) => {
    const inputPhoneNumberRef = useMaskito({ options: optionPhoneNumber });
    const inputHomeNumberRef = useMaskito({ options: optionHomeNumber });

    return (
        <div className={cn('flex flex-wrap 2xl:gap-6 2xl:flex-nowrap w-full', className)}>
            <FormLabel
                className={cn('text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px]', labelClass)}
                required={labelRequired}
            >
                {label}
            </FormLabel>
            <div className="w-full">
                <div
                    className={cn('grid grid-cols-1 sm:grid-cols-2 gap-2 items-start', isVertical && 'sm:grid-cols-1')}
                >
                    <FormInputText
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name={`${prefixName ? prefixName + '.' : ''}name`}
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px] max-w-[200px]"
                        label={t('name')}
                        placeholder={t('common.MSG_001', { field: t('name') })}
                        maxLength={255}
                        required={required}
                    />
                    <FormInputText
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name={`${prefixName ? prefixName + '.' : ''}relationship`}
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px] max-w-[200px]"
                        label={t('relationship')}
                        placeholder={t('common.MSG_001', { field: t('relationship') })}
                        maxLength={255}
                        required={required}
                    />
                </div>
                <div
                    className={cn(
                        'grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2 items-start',
                        isVertical && 'sm:grid-cols-1'
                    )}
                >
                    <FormInputText
                        ref={inputPhoneNumberRef}
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name={`${prefixName ? prefixName + '.' : ''}phone`}
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px] max-w-[200px]"
                        label={t('emergency_info.contact')}
                        placeholder={t('common.MSG_001', { field: t('emergency_info.contact') })}
                        required={required}
                        maxLength={255}
                    />
                    <FormInputText
                        ref={inputHomeNumberRef}
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name={`${prefixName ? prefixName + '.' : ''}home_phone`}
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px]"
                        label={t('emergency_info.home_contact')}
                        placeholder={t('common.MSG_001', { field: t('emergency_info.home_contact') })}
                        maxLength={255}
                        required={required}
                    />
                </div>
                <FormInputText
                    className="sm:flex-wrap sm:gap-0 space-y-0 mt-2"
                    name={`${prefixName ? prefixName + '.' : ''}address`}
                    labelClass="text-[16px] md:h-[37px] lg:h-[37px] max-w-[200px]"
                    label={t('emergency_info.address')}
                    placeholder={t('common.MSG_001', { field: t('emergency_info.address') })}
                    required={required}
                    maxLength={255}
                />
            </div>
        </div>
    );
};

export default observer(EmergencyForm);
