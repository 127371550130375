import { useState } from 'react';

const useToggle = (initialValue?: boolean) => {
    const [isShown, setIsShown] = useState<boolean>(!!initialValue);

    const handleOpen = () => setIsShown(true);

    const handleClose = () => setIsShown(false);

    return {
        isShown,
        handleOpen,
        handleClose,
        setIsShown,
    };
};

export default useToggle;
