import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { ChangeEvent, useRef } from 'react';
import { TrashIcon, UploadIcon } from '@radix-ui/react-icons';
import { Input } from '@/components/ui/input';
import { useTranslation } from 'react-i18next';
import { validateFile } from '@/lib/helper';
import { Button } from '@/components/ui/button';

type Props = {
    name: string;
    classLabel?: string;
    className?: string;
    required?: boolean;
    label?: React.ReactNode;
    showError?: boolean;
    allowType: string[];
    maxFileSize: number;
    handleChangeFile?: () => void;
    type?: 'csv' | 'file';
};

const FormUploadDragDrop = ({
    name,
    className,
    classLabel,
    label,
    allowType,
    maxFileSize,
    type,
    handleChangeFile,
    showError = true,
    required = false,
}: Props) => {
    const { control } = useFormContext();
    const { t } = useTranslation();
    const refMethod = useRef<any>({});

    const fileRef = useRef<HTMLInputElement>(null);

    const handleFile = (files: FileList) => {
        if (files.length > 0) {
            handleChangeFile?.();
            const file = files[0];
            const isValid = validateFile(file, allowType, maxFileSize, type);
            if (!isValid) {
                fileRef.current.value = '';
                return;
            }

            refMethod.current.onChange(files.item(0));
        }
    };

    const getFileName = (event?: ChangeEvent<HTMLInputElement>) => {
        handleFile(event.target.files);
    };

    const handleSuffix = () => {
        handleChangeFile?.();
        fileRef.current.value = '';
        refMethod.current.onChange(undefined);
    };

    const handleDropFile = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        handleFile(e.dataTransfer.files);
    };

    return (
        <FormField
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => {
                refMethod.current.onChange = field.onChange;
                return (
                    <FormItem
                        className={cn(
                            'flex w-full flex-wrap sm:flex-nowrap items-center sm:gap-2 space-y-0',
                            className
                        )}
                    >
                        {label && (
                            <FormLabel
                                className={cn('text-pc flex min-w-fit items-center', classLabel)}
                                required={required}
                            >
                                {label}
                            </FormLabel>
                        )}

                        <div className="w-full">
                            <FormControl>
                                <label
                                    onDrop={handleDropFile}
                                    onDragOver={(e) => e.preventDefault()}
                                    htmlFor="dropzone-file"
                                    className={cn(
                                        'flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600',
                                        error && 'border-destructive'
                                    )}
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6 gap-6">
                                        <UploadIcon className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                                        <p className="mb-2 text-pc text-gray-500 dark:text-gray-400 border-des">
                                            {t('csv.drag_and_drop')}
                                        </p>
                                        <Button
                                            variant="outline"
                                            size="xl"
                                            className="text-ip h-[42px] w-[44] border-black"
                                            onClick={() => fileRef.current.click()}
                                        >
                                            {t('csv.select_file')}
                                        </Button>
                                    </div>
                                    <Input
                                        ref={fileRef}
                                        id="dropzone-file"
                                        type="file"
                                        className="hidden"
                                        onChange={(event) => getFileName(event)}
                                        accept=".csv"
                                    />
                                </label>
                            </FormControl>
                            {field.value && (
                                <Input
                                    suffix={<TrashIcon className="h-7 w-7 cursor-pointer mr-1" />}
                                    handleSuffixClick={handleSuffix}
                                    className={`my-4 h-[50px] text-pc w-full`}
                                    type="text"
                                    disabled
                                    value={field.value?.name}
                                />
                            )}
                            {showError && <FormMessage className="text-error" />}
                        </div>
                    </FormItem>
                );
            }}
        />
    );
};

export default FormUploadDragDrop;
