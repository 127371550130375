import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import dayjs from '@/lib/dayjs';
import { ColumnsProps } from '@/interfaces/common/global';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { ATTENDANCE_STATUS, FORMAT_DATE, FORMAT_DATE_TIME } from '@/constants/appConstants';
import { MeetingType } from '@/interfaces/meeting';
import { cn } from '@/lib/utils';

export const columnsMeetingAttendance = ({
    t,
    onEdit,
    onCreate,
    columnsKey,
    onDetail,
    showFull,
}: ColumnsProps<MeetingType>): TableProps<MeetingType>['columns'] => {
    const allColumns: TableProps<MeetingType>['columns'] = [
        {
            title: t('action'),
            dataIndex: 'detail',
            key: 'detail',
            width: 80,
            align: 'center',
            render: (_, record) => {
                const current = dayjs().format('YYYY/MM/DD HH:mm:ss');
                const isJoin = record.attendances?.[0]?.attendance_status !== ATTENDANCE_STATUS.NOT_YET;
                const meetingDate = dayjs(record.meeting_date).format(FORMAT_DATE);
                const isNotStart = dayjs(`${meetingDate} ${record.start_time}`).isAfter(dayjs(current));

                return (
                    <>
                        <Button
                            variant="default"
                            className="m-1"
                            onClick={() => {
                                onDetail?.(record.key);
                            }}
                        >
                            {t('detail')}
                        </Button>
                        {isNotStart && (
                            <Button
                                variant={isJoin ? 'update' : 'outline'}
                                className={cn(' border-grey m-1', isJoin && 'bg-green-500')}
                                onClick={() => {
                                    isJoin ? onEdit?.(record.key) : onCreate?.(record.key);
                                }}
                            >
                                {isJoin ? t('edit') : t('register')}
                            </Button>
                        )}
                    </>
                );
            },
        },
        {
            title: t('attendance_meeting.title'),
            dataIndex: 'name',
            key: 'name',
            width: 100,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value} antdTooltip>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('attendance_meeting.time'),
            dataIndex: 'meeting_date',
            key: 'meeting_date',
            width: 80,
            align: 'center',
            render: (_, record) => {
                const day = record.meeting_date ? dayjs(record.meeting_date).format(FORMAT_DATE) : '';
                return <span>{`${day} ${record.start_time}  ${record.end_time ? ' ~ ' + record.end_time : ''}`}</span>;
            },
        },
        {
            title: t('attendance_meeting.status'),
            dataIndex: 'status',
            key: 'status',
            width: 50,
            align: 'center',
            render: (_, record) => {
                return <>{t(record?.attendances?.[0]?.attendance_status || '')}</>;
            },
        },
        {
            title: t('attendance_meeting.last_time'),
            dataIndex: 'latest_updated_at',
            key: 'latest_updated_at',
            align: 'center',
            width: 70,
            render: (_, record) => {
                const date = record?.attendances?.[0]?.latest_updated_at || null;
                return <>{date ? dayjs(date).format(FORMAT_DATE_TIME) : ''}</>;
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
