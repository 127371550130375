import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { manualLink } from '@/constants/path';
import { DocumentComponentType } from '@/interfaces/common/global';
import { observer } from 'mobx-react-lite';

const DocumentComponent = (props: { type: DocumentComponentType } & WithTransProps) => {
    const { t, type } = props;

    const onClickManual = () => {
        window.open(`${process.env.REACT_APP_AWS_URL}${manualLink[type]}`, '_blank');
    };

    return (
        <PageComponent
            title={<PageTitle className="py-6" routes={[{ path: '', breadcrumbName: t('welfare.manual') }]} />}
            isDownLoadCsv={false}
        >
            <Button className="p-8 text-[21px] rounded-xl mt-4" variant="outline" onClick={onClickManual}>
                {t(`${type}.guide_button`)}
            </Button>
        </PageComponent>
    );
};

export default withTrans(observer(DocumentComponent));
