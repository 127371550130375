import { TableProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import useGetResidents from '@/hooks/useGetResidents';
import useGetUsers from '@/hooks/useGetUsers';
import { PageProps } from '@/interfaces/props/page';
import { CreateFileResidentCSV } from '@/modules/services/report.service';
import { DataType, columns } from '@/pages/visit-report/VisitReportColumns';
import { useCsvStore } from '@/store/csv/CsvStore';
import { PAGE_SIZE } from '@/constants/appConstants';
import { convertFileNameCsv } from '@/lib/helper';

const VisitReport = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const [selectItem, setSelectItem] = useState(null);
    const [page, setPage] = useState(1);
    const { dataSource, total, isDownLoadCsv, isLoading } = useGetResidents({ page, userId: selectItem?.value });

    const {
        options,
        setScrollOption,
        onHandleSearch,
        isLoading: isLoad,
    } = useGetUsers({
        role: APP_PERMISSION.COMMISSIONER,
        perPage: PAGE_SIZE,
    });

    const onDownloadCsv = async () => {
        try {
            await CreateFileResidentCSV(selectItem?.value);
            addQueue({
                flag: 'resident',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName: `${t('visit_report.title')}${t('list')}`,
                    subName: selectItem?.label,
                }),
                url: `users/export_csv_resident`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onDetail = (data: DataType) => {
        const userId = selectItem?.value || data.visitor_id;

        if (userId) {
            navigate(`${data.key}?userId=${userId}`);
        }
    };

    const columnsTable = columns({
        t,
        onDetail,
        columnsKey: [
            'detail',
            'visit_date',
            'situation',
            'visitor_name',
            'code',
            'name',
            'age',
            'address',
            'app_service',
            'situation_content',
        ],
    });
    const onChangeTable: TableProps<DataType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: `${t('visit_report.title')}${t('list')}` }]} />}
            isDownLoadCsv={isDownLoadCsv}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableBar
                options={options}
                onChangeSelect={(value) => {
                    const item = options.find((el) => el.value === value);
                    setPage(1);
                    setSelectItem(item);
                }}
                handleSearch={onHandleSearch}
                setScrollOption={setScrollOption}
                isLoad={isLoad}
            />
            <TableComponent
                loading={isLoading}
                columns={columnsTable}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(VisitReport));
