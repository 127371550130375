import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { Input, InputProps } from '@/components/ui/input';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

type FuncSuffixInput = (field: ControllerRenderProps<FieldValues, string>) => React.ReactNode;

type Props = {
    name: string;
    inputClassName?: string;
    labelClass?: string;
    className?: string;
    required?: boolean;
    showError?: boolean;
    sufixInput?: FuncSuffixInput | React.ReactNode;
    extra?: React.ReactNode;
    label?: React.ReactNode;
    regex?: RegExp;
} & Omit<InputProps, 'label' | 'className'>;

const FormInputText = forwardRef<HTMLInputElement, Props>(
    (
        {
            name,
            type,
            placeholder,
            extra,
            className,
            inputClassName,
            labelClass,
            label,
            sufixInput,
            showError = true,
            disabled = false,
            required = true,
            maxLength,
            size,
            regex,
            ...props
        },
        ref
    ) => {
        const { control } = useFormContext();
        const { t } = useTranslation();

        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-6', className)}>
                        {label && (
                            <FormLabel
                                className={cn(
                                    'text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px]',
                                    labelClass
                                )}
                                required={required}
                            >
                                {label}
                            </FormLabel>
                        )}
                        <div className="w-full">
                            <FormControl>
                                <Input
                                    {...props}
                                    value={field.value}
                                    ref={ref}
                                    type={type}
                                    maxLength={maxLength ?? 100}
                                    size={size || 'lg'}
                                    placeholder={
                                        placeholder ||
                                        t('common.MSG_001', {
                                            field: '',
                                        })
                                    }
                                    suffix={
                                        sufixInput && typeof sufixInput === 'function'
                                            ? sufixInput(field)
                                            : (sufixInput as React.ReactNode)
                                    }
                                    className={cn(
                                        disabled ? `max-w-full overflow-hidden text-ellipsis` : '',
                                        inputClassName
                                    )}
                                    onBlur={(event) => {
                                        const value = event.target.value;
                                        if (value?.trim() !== value) {
                                            field.onChange(value.trim());
                                        }
                                    }}
                                    onInput={(event) => {
                                        const value = event.currentTarget.value;
                                        field.onChange(regex ? value.replace(regex, '') : value);
                                    }}
                                    readOnly={disabled}
                                />
                            </FormControl>
                            {showError && <FormMessage className="text-error" />}
                        </div>
                        {extra}
                    </FormItem>
                )}
            />
        );
    }
);

export default FormInputText;
