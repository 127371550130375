import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { TFunction } from 'react-i18next';
import { AttendanceType, MeetingType } from '@/interfaces/meeting';
import TooltipComponent from '@/components/common/pages/ToolTip';
import dayjs from '@/lib/dayjs';
import { ATTENDANCE_STATUS, FORMAT_DATE } from '@/constants/appConstants';
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons';
import { APP_PERMISSION } from '@/constants/permissionConfig';

type ColumnsProps = {
    t: TFunction;
    onEdit?: (key: string) => void;
    onDelete?: (key: string) => void;
    onDetail?: (key: string) => void;
    columnsKey?: string[];
    onAttendance?: (key: string) => void;
    showFull?: boolean;
};

export const columnsTableMeeting = ({
    t,
    onEdit,
    columnsKey,
    onDelete,
    onDetail,
    showFull,
}: ColumnsProps): TableProps<MeetingType>['columns'] => {
    const allColumns: TableProps<MeetingType>['columns'] = [
        {
            title: t('meeting.meeting_list.detail'),
            dataIndex: 'detail',
            key: 'detail',
            width: 150,
            align: 'center',
            render: (_, record) => {
                return (
                    <Button
                        variant="default"
                        className="m-1 min-w-[116px]"
                        onClick={() => {
                            onDetail?.(record.key);
                        }}
                    >
                        {t('meeting.meeting_list.detail_meeting')}
                    </Button>
                );
            },
        },
        {
            title: t('meeting.meeting_list.name'),
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('meeting.meeting_list.date'),
            dataIndex: 'meeting_date',
            key: 'meeting_date',
            width: 100,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE) : ''),
        },
        {
            title: t('meeting.meeting_list.start_time'),
            dataIndex: 'start_time',
            key: 'start_time',
            width: 80,
            align: 'center',
        },
        {
            title: t('meeting.meeting_list.end_time'),
            dataIndex: 'end_time',
            key: 'end_time',
            width: 80,
            align: 'center',
        },
        {
            title: t('meeting.meeting_list.meeting_content'),
            dataIndex: 'content',
            key: 'content',
            width: 200,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 150,
            align: 'center',
            render: (_, record) => {
                // const isJoin = record.attendances.some((item) => item.attendance_status !== 'not_yet');
                // const meetingDate = dayjs(record.meeting_date).format(FORMAT_DATE);
                // const isStarted = dayjs(`${meetingDate} ${record.start_time}`).isBefore(dayjs());

                // if (isStarted || isJoin) {
                //     return null;
                // }
                return (
                    <>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey m-1"
                            onClick={() => {
                                onEdit?.(record.id);
                            }}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            variant="delete"
                            className="bg-red-500 border-grey m-1"
                            onClick={() => {
                                onDelete?.(record.id);
                            }}
                        >
                            {t('delete')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const attendanceColumns = ({ t, columnsKey }: ColumnsProps): TableProps<AttendanceType>['columns'] => {
    const allColumns: TableProps<AttendanceType>['columns'] = [
        {
            title: t('meeting.attendance.name'),
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (_, record) => {
                return <div className="whitespace-break-spaces overflow-hidden">{record?.user?.name}</div>;
            },
        },
        {
            title: t('meeting.attendance.role'),
            dataIndex: 'role',
            key: 'role',
            width: 80,
            align: 'center',
            render: (_, record) => {
                const role =
                    (record.user?.roles?.communication?.[0] === APP_PERMISSION.MAYOR
                        ? record.user?.district
                            ? `${record.user?.district} `
                            : ''
                        : '') + t(`report.${record.user?.roles?.communication?.[0]}`);

                return role;
            },
        },
        {
            title: t('meeting.attendance.status'),
            dataIndex: 'attendance_status',
            key: 'attendance_status',
            width: 80,
            align: 'center',
            render: (value) => {
                if (value === ATTENDANCE_STATUS.NOT_YET) return null;
                if (value === ATTENDANCE_STATUS.ATTEND) {
                    return (
                        <div className="flex justify-center items-center">
                            <CheckIcon height={36} width={36} className="text-lime-500" />
                        </div>
                    );
                }
                return (
                    <div className="flex justify-center items-center">
                        <Cross2Icon height={36} width={36} className="text-red-500" />
                    </div>
                );
            },
        },
        {
            title: t('meeting.attendance.reason'),
            dataIndex: 'reason',
            key: 'reason',
            width: 150,
            align: 'center',
            render: (_, record) => {
                return <div className="whitespace-break-spaces">{record.reason}</div>;
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
