import React, { useRef, useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GoogleMapView from '@/components/common/pages/GoogleMapView';
import { GOOGLE_MAPS_CONFIG } from '@/constants/appConstants';

type Props = {
    name?: string;
    labelClass?: string;
    className?: string;
    label?: React.ReactNode;
    required?: boolean;
    showError?: boolean;
};

const AutoCompleteInput: React.FC<any> = ({
    name,
    className,
    labelClass,
    label,
    showError = true,
    required = false,
}: Props) => {
    const { t } = useTranslation();

    const { isLoaded } = useJsApiLoader({
        ...GOOGLE_MAPS_CONFIG,
        libraries: ['places'],
    });
    const inputRef = useRef<HTMLInputElement>(null);
    const { control, setValue, setError, clearErrors, getValues } = useFormContext();
    const value = useWatch({ name });
    const childRef = useRef(null);

    // const value = watch(name);

    const mountedRef = useRef<boolean>(false);
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        if (isLoaded) {
            setInit(true);
            const autoComplete = new google.maps.places.Autocomplete(inputRef.current);
            autoComplete.addListener('place_changed', () => {
                const place = autoComplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    if (inputRef.current.value.trim()) {
                        setValue(name, {
                            address: inputRef.current.value,
                        });
                        setError(name, {
                            message: t('not_location', { text: inputRef.current.value || '' }),
                        });
                    } else {
                        setError(name, {
                            message: t('report.placeholder_search_address'),
                        });
                    }
                } else if (place.geometry.viewport || place.geometry.location) {
                    clearErrors(name);
                    childRef.current.setCurrentLocation(place.geometry.location);
                    childRef.current.setMarkers((prev: any) => [...prev, place.geometry.location.toJSON()]);
                    setValue(name, {
                        ...place.geometry.location.toJSON(),
                        address: place.formatted_address,
                    });
                }
            });
        }
    }, [isLoaded]);

    const searchLocation = async () => {
        const request = {
            query: inputRef.current.value || '明和町役職',
            fields: ['ALL'],
        };
        try {
            const results = await new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
                const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
                placesService.findPlaceFromQuery(request, (results, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                        resolve(results);
                    } else {
                        reject(new Error(t('not_location', { text: inputRef.current.value || '' })));
                    }
                });
            });

            const place = results[0];
            if (place?.geometry?.location) {
                childRef.current.setCurrentLocation(place.geometry.location);
                childRef.current.setMarkers([place.geometry.location.toJSON()]);

                return {
                    address: inputRef.current?.value,
                    ...place.geometry.location.toJSON(),
                };
            } else {
                throw new Error(t('not_location', { text: inputRef.current?.value || '' }));
            }
        } catch (error) {
            setError(name, {
                message: t('not_location', { text: inputRef.current?.value || '' }),
            });
            return null;
        }
    };

    const onBlur = (e: any) => {
        inputRef.current.value = e.target.value?.trim() || '';
        const currentValue = getValues(name) || {};
        setValue(name, {
            ...currentValue,
            address: inputRef.current.value?.trim(),
        });
    };

    const onChange = () => {
        if (inputRef.current.value.trim() === '') {
            clearErrors(name);
        }
        setValue(name, {
            address: inputRef.current.value,
        });
    };

    useEffect(() => {
        inputRef.current.value = value?.address || '';
        if (value && Object.keys(value).length > 0 && childRef.current && mountedRef.current === false && init) {
            childRef.current.setCurrentLocation({ lat: value.lat, lng: value.lng });
            childRef.current.setMarkers([{ lat: value.lat, lng: value.lng }]);
            mountedRef.current = true;
        }
    }, [value, init]);

    return (
        <FormField
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => {
                return (
                    <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-6', className)}>
                        <div>
                            {label && (
                                <FormLabel
                                    className={cn('text-pc flex min-w-fit items-center mt-2', labelClass)}
                                    required={required}
                                >
                                    {label}
                                </FormLabel>
                            )}
                        </div>
                        <div className="space-y-2 w-full">
                            <div>
                                <FormControl>
                                    <div
                                        className={cn(
                                            'border border-grey rounded-md flex',
                                            error && 'border-destructive'
                                        )}
                                    >
                                        <input
                                            name={'address'}
                                            className=" px-2 border-0 focus:outline-none focus:border-0 w-full rounded-lg"
                                            id="location"
                                            ref={inputRef}
                                            placeholder={t('report.placeholder_search_address')}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                        />
                                        <button
                                            onClick={async () => {
                                                const result = await searchLocation();
                                                if (result !== null) {
                                                    field.onChange(result);
                                                }
                                            }}
                                            className={' py-2 px-4 border-l border-grey w-[80px] min-w-[80px]'}
                                            type={'button'}
                                        >
                                            {t('report.search')}
                                        </button>
                                    </div>
                                </FormControl>
                                {showError && <FormMessage className="text-error" />}

                                <div className="mt-4 w-full">
                                    <GoogleMapView
                                        field={field}
                                        multipleMaker={false}
                                        loadCurrent={false}
                                        ref={childRef}
                                        zoom={16}
                                        height={'300px'}
                                        className={'justify-normal'}
                                    />
                                </div>
                            </div>
                        </div>
                    </FormItem>
                );
            }}
        />
    );
};
export default AutoCompleteInput;
