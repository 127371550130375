import { QuestionItem } from '@/interfaces/survey';

export type CategoryType = {
    key?: string;
    id?: string;
    content?: string;
    type?: Category;
    report_existed?: boolean;
    category_children?: any[];
    users?: any[];
};

export type CategoryTypeReq = {
    content?: string;
    type?: Category;
    user_ids?: any[];
    category_child_ids?: any[];
};

export type CategoryApplicationType = {
    key?: string;
    id?: string;
    due_date?: string;
    answer_request_date?: string;
    title?: string;
    answer_count?: number;
};

export type Category = 'Contact' | 'Request' | 'CommunicationApp';
export type CategoryParam = 'contact' | 'request' | 'communication-app';

export interface CategoryApplicationDetailType {
    key?: string;
    answer_count?: number;
    answer_request_date?: string;
    created_at: string;
    due_date: string | Date;
    id?: string;
    max_answer_number?: number;
    title?: string;
    was_answered?: boolean;
    questions: QuestionItem[];
    survey_type?: string;
}

export enum CATEGORY_TABS {
    PARENT = 'parent',
    CHILD = 'child',
}
