import axios, { AxiosInstance } from 'axios';
import { AXIOS_TIMEOUT, TOKEN_NAME, UNAUTHORIZE_URL } from '@/constants/appConstants';

class SetupAxios {
    api: AxiosInstance;
    constructor(baseURL: string, contentType?: string) {
        this.api = axios.create({
            baseURL: baseURL,
            headers: {
                'Content-Type': contentType || 'application/json',
            },
            timeout: AXIOS_TIMEOUT,
        });

        this.api.interceptors.request.use(
            (config) => {
                const accessToken = localStorage.getItem(TOKEN_NAME);
                if (accessToken?.length && accessToken !== 'null' && !UNAUTHORIZE_URL.includes(config.url)) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    config.headers!.Authorization = 'Bearer ' + accessToken;
                }

                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        this.api.interceptors.response.use(
            (response) => {
                return Promise.resolve(response.data);
            },
            (error) => {
                const isGetMethod = error?.config?.method === 'get';

                if ([401].includes(error.response?.status) && isGetMethod) {
                    localStorage.clear();
                    if (['/login'].indexOf(window.location.pathname) < 0) {
                        localStorage.setItem('authorize', 'false');
                        window.location.href = '/login';
                    }
                }
                if ([403].includes(error.response?.status) && isGetMethod) {
                    window.location.href = '/not-permission';
                }

                if ([404].includes(error.response?.status) && isGetMethod) {
                    window.location.href = '/not-found';
                }

                if (!error.response) {
                    return Promise.reject(error);
                }
                return Promise.reject(error.response.data);
            }
        );
    }

    public get = (url = '', config = {}) => {
        return this.api.get(url, config).then((response) => response);
    };

    public post = (url = '', body = {}, config = {}) => {
        return this.api.post(url, body, config).then((response) => response);
    };

    public patch = (url = '', body = {}, config = {}) => {
        return this.api.patch(url, body, config).then((response) => response);
    };

    public put = (url = '', body = {}, config = {}) => {
        return this.api.put(url, body, config).then((response) => response);
    };

    public delete = (url = '', config = {}) => {
        return this.api.delete(url, config).then((response) => response);
    };
}

export { SetupAxios };
