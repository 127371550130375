import { z } from 'zod';
import i18n from '@/modules/i18n';
import { SITUATION_ENUM } from '@/interfaces/visit-report';
import { createStringSchema } from '@/schema/authSchema';
const { t } = i18n;

export const EditResidentSchema = z.object({
    civil: createStringSchema('select_civil', 'select'),
    name: createStringSchema('resident.name'),
    birthday: z.string({ required_error: t('common.MSG_002', { field: t('resident.birthday') }) }).nullable(),
    address: createStringSchema('resident.address'),
    my_number: createStringSchema('resident.my_phone').min(14, t('common.MSG_048', { field: t('resident.my_phone') })),
    insured_number: z.any().superRefine((val, ctx) => {
        if (val && val.length < 13) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [''],
                message: t('common.MSG_022', { field: t('resident.insurance_number'), number: 11 }),
            });
        }
    }),
    welfare_taxi: z.any().nullable(),
    emergency_device: z.any().nullable(),
    habitat: z.any().nullable(),
    evacuation_operations: z.any().nullable(),
    remark: z.any(),
    family_hospital: createStringSchema('medical_info.regular_clinic'),
    care_manager: createStringSchema('medical_info.responsible_manager'),
    service: z
        .object({
            service_name_1: z.any(),
            service_name_2: z.any(),
            service_name_3: z.any(),
        })
        .superRefine((val, ctx) => {
            if (!val.service_name_1) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_1'],
                    message: t('common.MSG_001', { field: t('medical_info.service_name') }),
                });
            }

            if (!val.service_name_2) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_2'],
                    message: t('common.MSG_001', { field: t('medical_info.service_name') }),
                });
            }

            if (!val.service_name_3) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_3'],
                    message: t('common.MSG_001', { field: t('medical_info.service_name') }),
                });
            }
            if (
                val.service_name_1 &&
                (val.service_name_1 === val.service_name_2 || val.service_name_1 === val.service_name_3)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_1'],
                    message: t('common.MSG_073', { field: t('medical_info.service_name') }),
                });
            }
            if (
                val.service_name_2 &&
                (val.service_name_2 === val.service_name_1 || val.service_name_2 === val.service_name_3)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_2'],
                    message: t('common.MSG_073', { field: t('medical_info.service_name') }),
                });
            }
            if (
                val.service_name_3 &&
                (val.service_name_3 === val.service_name_1 || val.service_name_3 === val.service_name_2)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_3'],
                    message: t('common.MSG_073', { field: t('medical_info.service_name') }),
                });
            }
        }),
    note: createStringSchema('medical_info.remarks'),
    emergency_contact1: z.object({
        name: createStringSchema('name'),
        phone: createStringSchema('emergency_info.contact').superRefine((val, ctx) => {
            const isError = !val.startsWith('0');

            if (isError && val) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_074'),
                });
            } else if (val && val.length < 13) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_022', { field: t('emergency_info.contact'), number: 11 }),
                });
            }
        }),
        home_phone: createStringSchema('emergency_info.home_contact').superRefine((val, ctx) => {
            const isError = !val.startsWith('0');

            if (isError && val) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_074'),
                });
            } else if (val && val.length < 12) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_022', { field: t('emergency_info.home_contact'), number: 10 }),
                });
            }
        }),
        relationship: createStringSchema('relationship'),
        address: createStringSchema('emergency_info.address'),
    }),
    emergency_contact2: z.object({
        name: z.any(),
        phone: z.any().superRefine((val, ctx) => {
            if (val) {
                const isError = !val.startsWith('0');

                if (isError) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_074'),
                    });
                } else if (val.length < 13) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_022', { field: t('emergency_info.contact'), number: 11 }),
                    });
                }
            }
        }),
        home_phone: z.any().superRefine((val, ctx) => {
            if (val) {
                const isError = !val.startsWith('0');

                if (isError) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_074'),
                    });
                } else if (val.length < 12) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_022', { field: t('emergency_info.home_contact'), number: 10 }),
                    });
                }
            }
        }),
        relationship: z.any(),
        address: z.any(),
    }),
});

export const IPResidentSchema = z.object({
    name: createStringSchema('name'),
    birthday: z.string({ required_error: t('common.MSG_002', { field: t('resident.birthday') }) }).nullable(),
    address: createStringSchema('resident.address'),
    welfare_taxi: z.any().nullable(),
    emergency_device: z.any().nullable(),
    habitat: z.any().nullable(),
    evacuation_operations: z.any().nullable(),
    remark: z.any(),
    my_number: z.any(),
    family_hospital: createStringSchema('medical_info.regular_clinic'),
    care_manager: createStringSchema('medical_info.responsible_manager'),
    insured_number: z.any().superRefine((val, ctx) => {
        if (val && val.length < 13) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [''],
                message: t('common.MSG_022', { field: t('resident.insurance_number'), number: 11 }),
            });
        }
    }),
    service: z
        .object({
            service_name_1: z.any(),
            service_name_2: z.any(),
            service_name_3: z.any(),
        })
        .superRefine((val, ctx) => {
            if (!val.service_name_1) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_1'],
                    message: t('common.MSG_001', { field: t('medical_info.service_name') }),
                });
            }

            if (!val.service_name_2) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_2'],
                    message: t('common.MSG_001', { field: t('medical_info.service_name') }),
                });
            }

            if (!val.service_name_3) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_3'],
                    message: t('common.MSG_001', { field: t('medical_info.service_name') }),
                });
            }
            if (
                val.service_name_1 &&
                (val.service_name_1 === val.service_name_2 || val.service_name_1 === val.service_name_3)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_1'],
                    message: t('common.MSG_073', { field: t('medical_info.service_name') }),
                });
            }
            if (
                val.service_name_2 &&
                (val.service_name_2 === val.service_name_1 || val.service_name_2 === val.service_name_3)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_2'],
                    message: t('common.MSG_073', { field: t('medical_info.service_name') }),
                });
            }
            if (
                val.service_name_3 &&
                (val.service_name_3 === val.service_name_1 || val.service_name_3 === val.service_name_2)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['service_name_3'],
                    message: t('common.MSG_073', { field: t('medical_info.service_name') }),
                });
            }
        }),
    note: createStringSchema('medical_info.remarks'),
    emergency_contact1: z.object({
        name: createStringSchema('name'),
        phone: createStringSchema('emergency_info.contact').superRefine((val, ctx) => {
            const isError = !val.startsWith('0');

            if (isError && val) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_074'),
                });
            } else if (val && val.length < 13) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_022', { field: t('emergency_info.contact'), number: 11 }),
                });
            }
        }),
        home_phone: createStringSchema('emergency_info.home_contact').superRefine((val, ctx) => {
            const isError = !val.startsWith('0');

            if (isError && val) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_074'),
                });
            } else if (val && val.length < 12) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_022', { field: t('emergency_info.home_contact'), number: 10 }),
                });
            }
        }),
        relationship: createStringSchema('relationship'),
        address: createStringSchema('emergency_info.address'),
    }),
    emergency_contact2: z.object({
        name: z.any(),
        phone: z.any().superRefine((val, ctx) => {
            if (val) {
                const isError = !val.startsWith('0');

                if (isError) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_074'),
                    });
                } else if (val.length < 13) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_022', { field: t('emergency_info.contact'), number: 11 }),
                    });
                }
            }
        }),
        home_phone: z.any().superRefine((val, ctx) => {
            if (val) {
                const isError = !val.startsWith('0');

                if (isError) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_074'),
                    });
                } else if (val.length < 12) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [''],
                        message: t('common.MSG_022', { field: t('emergency_info.home_contact'), number: 10 }),
                    });
                }
            }
        }),
        relationship: z.any(),
        address: z.any(),
    }),
});

export const EditResidentReportSchema = z.object({
    visit_report: z
        .object({
            visit_date: z.string(),
            situation: z.enum(['normal', 'abnormal'], {
                required_error: t('common.MSG_002', { field: t('visit_report.visit_result') }),
            }),
            situation_content: z.any(),
        })
        .superRefine((val, ctx) => {
            const error = val.situation === SITUATION_ENUM.ABNORMAL && !val.situation_content;
            if (error) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['situation_content'],
                    message: t('common.MSG_001', { field: t('visit_report.response_content') }),
                });
            }
        }),
});

export const ImportCsvResidentSchema = z.object({
    civil: createStringSchema('select_civil', 'select'),
    file: z.any().superRefine((val, ctx) => {
        const error = !val;
        if (error) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [''],
                message: t('common.MSG_002', { field: t('csv.csv_import') }),
            });
        }
    }),
});
