import React from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlusIcon, Trash2Icon } from 'lucide-react';
import QuestionTypeForm from '@/pages/survey/question/form/QuestionTypeForm';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import FormInputText from '@/components/common/form/FormInputText';
import { renderLabel } from '@/components/common/pages/Element';

type QuestionFormContentProps = {
    name: string;
    control: Control<FieldValues, any>;
};

const QuestionFormContent = ({ name, control }: QuestionFormContentProps) => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    });

    const onAddQuestion = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (fields.length < 3) {
            append({
                title: '',
                question_type: 'text',
                options: [{ content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }],
            });
        }
    };

    const onDeleteQuestion = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
        e.preventDefault();
        if (fields.length > 1) {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });

            if (res) {
                remove(index);
            }
        }
    };

    const renderExtra = (index: number) => {
        return (
            <>
                {fields.length >= 2 && (
                    <div className="flex justify-end">
                        <Button
                            type="button"
                            variant="outline"
                            size="icon"
                            className="px-2"
                            title={t('survey.delete_question')}
                            onClick={(e) => onDeleteQuestion(e, index)}
                        >
                            <Trash2Icon className="hover:opacity-50" height={24} width={24} />
                        </Button>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {fields.map((item, index) => {
                return (
                    <Card key={item.id} className="px-4 py-6 gap-6">
                        <FormInputText
                            name={`${name}.${index}.title`}
                            label={renderLabel(index + 1, t('survey.question_content'))}
                            maxLength={255}
                            extra={renderExtra(index)}
                            placeholder={t('common.MSG_001', {
                                field: t('survey.question_content'),
                            })}
                        />
                        <QuestionTypeForm name={`${name}.${index}`} numberQuestion={index + 1} />
                    </Card>
                );
            })}
            {fields.length < 3 && (
                <Button size="lg" className="w-[130px]" onClick={onAddQuestion}>
                    <PlusIcon height={20} width={20} /> {t('survey.add_question')}
                </Button>
            )}
        </>
    );
};

export default QuestionFormContent;
