import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import useGetResidents from '@/hooks/useGetResidents';
import { PageProps } from '@/interfaces/props/page';
import { ResidentType } from '@/interfaces/resident';
import { columnsCommissionner } from '@/pages/resident/ResidentColumns';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const IPResidentList = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { user } = useAuthenStore();

    const [page, setPage] = useState(1);
    const { dataSource, total, isLoading } = useGetResidents({ page, userId: user.id });

    const onEdit = (key: string, visistorId: string, visitorName: string) => {
        navigate(`${key}`, {
            state: { userId: visistorId, userName: visitorName },
        });
    };

    const onDetail = (key: string, visistorId: string, visitorName: string) => {
        navigate(`detail/${key}`, {
            state: { userId: visistorId, userName: visitorName },
        });
    };

    const onChangeTable: TableProps<ResidentType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('resident.title')}${t('list')}` }]}
                    className="py-6"
                />
            }
            isDownLoadCsv={false}
        >
            <TableComponent
                loading={isLoading}
                columns={columnsCommissionner({ t, onEdit, onDetail })}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(IPResidentList));
