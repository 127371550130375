import { UpdateProfileReq } from '@/interfaces/profile/password';
import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';
import { Params } from '@/interfaces/common/global';

export const UpdateUser = async (userId: string, data: UpdateProfileReq) => {
    return await apiServiceWithAuth.patch(`users/${userId}`, data);
};

export const GetListUsers = async <T>(
    params?: Params & { role?: string; order_by?: string; is_show_current_user?: boolean }
) => {
    return (await apiServiceWithAuth.get('users', { params })) as T;
};

export const CreateUser = async (body?: any) => {
    return await apiServiceWithAuth.post('users', body);
};

export const GetUserById = async (userId: string) => {
    return await apiServiceWithAuth.get(`users/${userId}`);
};

export const DeleteUser = async (userId: string) => {
    return await apiServiceWithAuth.delete(`users/${userId}`);
};

export const RestoreUser = async (userId: string) => {
    return await apiServiceWithAuth.patch(`users/${userId}/restore`);
};

export const GetListDistricts = async (params: { district?: string }) => {
    return await apiServiceWithAuth.get(`districts`, { params });
};
