import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import useGetResidents from '@/hooks/useGetResidents';
import { PageProps } from '@/interfaces/props/page';
import { DataType } from '@/pages/application/ApplicationColumns';
import { columnsResidentReport } from '@/pages/resident/ResidentColumns';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TableProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const IPApplicationResidentList = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { user } = useAuthenStore();
    const [page, setPage] = useState(1);
    const { dataSource, total, isLoading } = useGetResidents({
        page,
        userId: user.id,
        orderBy: 'latest_updated_at desc',
    });

    const onDetail = (key: string) => {
        navigate(`${key}`);
    };

    const columnsTable = columnsResidentReport({
        t,
        onDetail,
        columnsKey: ['detail', 'code', 'name', 'age', 'address', 'app_service'],
    });
    const onChangeTable: TableProps<DataType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: `${t('application_accept.ip_title')}` }]} />}
            isDownLoadCsv={false}
        >
            <TableComponent
                loading={isLoading}
                columns={columnsTable}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(IPApplicationResidentList));
