import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import MeetingForm from '@/pages/meeting/form/MeetingForm';
import { MeetingSchema } from '@/schema/meetingSchema';
import { toast } from '@/components/ui/use-toast';
import dayjs from '@/lib/dayjs';
import { getMeetingDetail, updateMeeting } from '@/modules/services/meeting.service';
import { FORMAT_DATE_REQ, FORMAT_TIME } from '@/constants/appConstants';
import React, { useState } from 'react';
import _ from 'lodash';
import { Meeting } from '@/interfaces/meeting';
import { handleErrorMessage, handleErrorNotFound } from '@/lib/helper';
import { useQueryClient } from '@tanstack/react-query';

const EditMeeting = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(true);
    const { id } = useParams();
    const [meeting, setMeeting] = React.useState<Meeting>();
    const queryClient = useQueryClient();

    const getDetailMeetingById = async (id: string) => {
        try {
            setLoading(true);
            const response = await getMeetingDetail(id);
            const { data }: any = response;
            const usersId = data?.attendances?.map((item: any) => item?.user?.id) || [];

            const meeting = {
                user_ids: usersId,
                meeting_date: data.meeting_date,
                name: data.name,
                start_time: dayjs(data.start_time),
                end_time: data.end_time ? dayjs(data.end_time) : null,
                content: data.content,
            };
            setMeeting(meeting);
            return meeting;
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const form = useForm<z.infer<typeof MeetingSchema>>({
        resolver: zodResolver(MeetingSchema),
        mode: 'onChange',
        defaultValues: async () => await getDetailMeetingById(id),
    });
    const onBackMenu = () => {
        navigate(`/communication/meeting`);
    };
    const onSubmit = async () => {
        try {
            setLoading(true);
            const data = form.getValues();
            const meeting = {
                name: data.name,
                meeting_date: dayjs(data.meeting_date).format(FORMAT_DATE_REQ),
                start_time: data.start_time.format(FORMAT_TIME),
                end_time: data.end_time ? data.end_time.format(FORMAT_TIME) : null,
                content: data.content,
                user_ids: data.user_ids,
            };
            // !data.end_time && delete meeting.end_time;
            const body = {
                meeting,
            };
            if (id) {
                await updateMeeting(body, id);
                toast({
                    description: t('common.MSG_017'),
                    variant: 'success',
                });
                onBackMenu();
            }
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_071',
                callback: () => onBackMenu(),
            });

            const message = error?.errors?.meeting?.[0] || '';
            handleErrorMessage({
                error: message,
                callback: () => {
                    if (message.includes('MSG_072')) {
                        queryClient.refetchQueries({ queryKey: ['getUserList'] });
                    }
                },
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            pageLoading={isLoading}
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('meeting.edit_meeting') }]} />}
            pageFooter={<></>}
        >
            {!_.isEmpty(meeting) && (
                <Form {...form}>
                    <MeetingForm type={'edit'} />
                    <div className="flex gap-4 justify-center mt-8">
                        <Button
                            type="submit"
                            variant="default"
                            size="xl"
                            className="text-[19px] h-[42px] w-44"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={isLoading}
                        >
                            {t('update')}
                        </Button>
                        <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBackMenu}>
                            {t('go_back')}
                        </Button>
                    </div>
                </Form>
            )}
        </PageComponent>
    );
};
export default withTrans(observer(EditMeeting));
