import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { Button } from '@/components/ui/button';
import { FormProvider, useForm } from 'react-hook-form';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { toast } from '@/components/ui/use-toast';
import { useGlobalStore } from '@/store/global/GlobalStore';
import dayjs from '@/lib/dayjs';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { FormField, FormLabel, FormMessage } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import InformationResident from '@/components/common/pages/InformationResident';
import GroupButton from '@/components/common/pages/GroupButton';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { SelectComponent } from '@/components/common/form/components/SelectComponent';
import { useGetPhrasesOptions } from '@/hooks/useGetPhrases';
import { SelectOptionsItem } from '@/interfaces/common/global';
import { ApplicationSchema } from '@/schema/applicationSchema';
import { useGetDetailResident } from '@/hooks/useGetResidents';
import { FORMAT_DATE_TIME_SECONDS, STATUS_APPLICATION_ACCEPTED } from '@/constants/appConstants';
import {
    GetDetailApplicationByResident,
    createRequestApplication,
    updateRequestApplication,
} from '@/modules/services/application.service';
import { ApplicationReqType } from '@/interfaces/application';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { handleErrorMessage } from '@/lib/helper';

type Props = {
    type: 'edit' | 'create';
};

const IPRequestApp = ({ type }: Props) => {
    const { t } = useTranslation();
    const { residentId, id } = useParams();
    const selectRef = useRef(null);
    const { user } = useAuthenStore();
    const { options, onHandleSearch, setScrollOption } = useGetPhrasesOptions({ type: 'welfare' });
    const { resident } = useGetDetailResident({ id: residentId, userId: user.id });

    const navigate = useNavigate();
    if (
        ([
            STATUS_APPLICATION_ACCEPTED.PENDING,
            STATUS_APPLICATION_ACCEPTED.CONFIRMED,
            STATUS_APPLICATION_ACCEPTED.RESPONDING,
        ].includes(resident?.last_welfare_app_status) ||
            (STATUS_APPLICATION_ACCEPTED.COMPLETED === resident?.last_welfare_app_status &&
                resident?.emergency_device &&
                resident?.welfare_taxi)) &&
        type === 'create'
    ) {
        navigate('/not-permission');
    }

    const form = useForm<z.infer<typeof ApplicationSchema>>({
        resolver: zodResolver(ApplicationSchema),
        mode: 'onChange',
        defaultValues: {
            welfare_app: {
                emergency_device: false,
                welfare_taxi: false,
                habitat: false,
                evacuation_operations: false,
                reason: '',
                isService: false,
            },
        },
    });

    const getDetailRequest = async (id: string) => {
        try {
            const { data } = await GetDetailApplicationByResident(id, residentId);
            if (data.status !== STATUS_APPLICATION_ACCEPTED.PENDING) {
                navigate('/not-permission');
                return;
            }
            form.setValue('welfare_app', {
                emergency_device: data.emergency_device,
                welfare_taxi: data.welfare_taxi,
                habitat: data.habitat,
                evacuation_operations: data.evacuation_operations,
                reason: data.reason,
                isService: false,
            });

            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const { isFetching: isLoad, data: dataDetail } = useQuery({
        queryKey: ['getDetailRequest', id],
        queryFn: () => {
            if (id && type === 'edit') {
                return getDetailRequest(id);
            } else {
                return false;
            }
        },
    });

    const { isLoading, setLoading } = useGlobalStore();
    const { watch, handleSubmit, clearErrors, setValue, control } = form;
    const { welfare_app } = watch();

    const handleSubmitForm = async (data: z.infer<typeof ApplicationSchema>) => {
        try {
            setLoading(true);
            const newData: ApplicationReqType = {
                welfare_app: {
                    emergency_device: data.welfare_app.emergency_device,
                    welfare_taxi: data.welfare_app.welfare_taxi,
                    reason: data.welfare_app.reason,
                    habitat: data.welfare_app.habitat,
                    evacuation_operations: data.welfare_app.evacuation_operations,
                    progress:
                        type === 'create'
                            ? {
                                  description: '新規登録',
                                  latest_updated_at: dayjs().format(FORMAT_DATE_TIME_SECONDS),
                              }
                            : {
                                  latest_updated_at: dayjs().format(FORMAT_DATE_TIME_SECONDS),
                                  id: dataDetail?.progresses?.[0]?.id,
                              },
                },
            };
            type === 'edit'
                ? await updateRequestApplication(id, newData)
                : await createRequestApplication(user.id, residentId, newData);
            toast({ description: t(type === 'edit' ? 'common.MSG_017' : 'common.MSG_015'), variant: 'success' });
            onBack();
        } catch (error) {
            handleErrorMessage({
                error: error?.errors?.welfare_app?.[0] || '',
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    const onBack = () => {
        navigate(`/welfare/commissioner/applications/${residentId}`);
    };

    const onChangeSelect = (value: SelectOptionsItem) => {
        const reason = `${welfare_app.reason || ''}${value.label as string}`;
        setValue('welfare_app.reason', reason.slice(0, 255));
        form.clearErrors('welfare_app.reason');
        selectRef.current.onClearValue();
    };

    return (
        <PageComponent
            pageLoading={isLoad}
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: `${t(
                                type === 'edit' ? 'application_accept.request_detail' : 'application_accept.request_app'
                            )}`,
                        },
                    ]}
                    className="py-6"
                />
            }
            isDownLoadCsv={false}
            pageFooter={
                <div className="flex items-center justify-center gap-4 mt-4">
                    <Button
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        disabled={isLoading}
                        onClick={handleSubmit(handleSubmitForm)}
                    >
                        {t(type === 'edit' ? 'update' : 'register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <FormProvider {...form}>
                <InformationResident data={resident} />
                <div className="flex flex-col gap-6 pt-4">
                    <FormField
                        control={control}
                        name="welfare_app.isService"
                        render={({ fieldState: { error } }) => (
                            <div className="flex flex-col">
                                <FormLabel className={'text-pc flex min-w-fit items-center pb-[20px]'} required={true}>
                                    {t('application_accept.desired_app_service')}
                                </FormLabel>
                                <GroupButton
                                    buttons={[
                                        {
                                            label: t('application_accept.taxi'),
                                            onClick: () => {
                                                if (!resident?.welfare_taxi) {
                                                    clearErrors('welfare_app.isService');
                                                    setValue('welfare_app.welfare_taxi', !welfare_app.welfare_taxi);
                                                }
                                            },
                                            className: cn(
                                                'py-[30px]',
                                                welfare_app.welfare_taxi && 'bg-lime-500',
                                                error && 'border-destructive',
                                                resident?.welfare_taxi && 'bg-gray-400 hover:cursor-default'
                                            ),
                                        },
                                        {
                                            label: t('application_accept.emergency_device'),
                                            onClick: () => {
                                                if (!resident?.emergency_device) {
                                                    clearErrors('welfare_app.isService');
                                                    setValue(
                                                        'welfare_app.emergency_device',
                                                        !welfare_app.emergency_device
                                                    );
                                                }
                                            },
                                            className: cn(
                                                'py-[30px]',
                                                welfare_app.emergency_device && 'bg-lime-500',
                                                error && 'border-destructive',
                                                resident?.emergency_device && 'bg-gray-400 hover:cursor-default'
                                            ),
                                        },
                                        {
                                            label: t('habitat'),
                                            onClick: () => {
                                                if (!resident?.habitat) {
                                                    clearErrors('welfare_app.isService');
                                                    setValue('welfare_app.habitat', !welfare_app.habitat);
                                                }
                                            },
                                            className: cn(
                                                'py-[30px]',
                                                welfare_app.habitat && 'bg-lime-500',
                                                error && 'border-destructive',
                                                resident?.habitat && 'bg-gray-400 hover:cursor-default'
                                            ),
                                        },
                                        {
                                            label: t('evacuation_operations'),
                                            onClick: () => {
                                                if (!resident?.evacuation_operations) {
                                                    clearErrors('welfare_app.isService');
                                                    setValue(
                                                        'welfare_app.evacuation_operations',
                                                        !welfare_app.evacuation_operations
                                                    );
                                                }
                                            },
                                            className: cn(
                                                'py-[30px]',
                                                welfare_app.evacuation_operations && 'bg-lime-500',
                                                error && 'border-destructive',
                                                resident?.evacuation_operations && 'bg-gray-400 hover:cursor-default'
                                            ),
                                        },
                                    ]}
                                />
                                <FormMessage className="text-error" />
                            </div>
                        )}
                    />

                    <FormLabel className={'text-pc flex min-w-fit items-center '} required={true}>
                        {t('application_accept.reason_app')}
                    </FormLabel>
                    <SelectComponent
                        handleSearch={onHandleSearch}
                        setScrollOption={setScrollOption}
                        ref={selectRef}
                        label={t('application_accept.select_phrase')}
                        options={options}
                        onChangeSelect={onChangeSelect}
                        hasGetDataOptions={true}
                        showValue={false}
                        width="!w-[450px]"
                    />
                    <FormInputTextarea
                        required={true}
                        name={'welfare_app.reason'}
                        className="flex flex-col items-start sm:gap-3"
                        label={''}
                        placeholder={t('common.MSG_001', { field: t('application_accept.reason_app') })}
                        maxLength={255}
                    />
                </div>
            </FormProvider>
        </PageComponent>
    );
};
export default withTrans(observer(IPRequestApp));
