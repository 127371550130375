import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { GetDetailVisitReport } from '@/modules/services/report.service';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditResidentSchema } from '@/schema/residentSchema';
import { ResidentService } from '@/modules/services';
import dayjs from '@/lib/dayjs';
import ResidentForm from '@/pages/resident/form/ResidentForm';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { useSearchParams } from 'react-router-dom';
import {
    convertStringMyNumber,
    handleErrorMessage,
    handleErrorNotFound,
    removeSpaceMyNumber,
    convertStringPhone,
    removeStringPhone,
} from '@/lib/helper';
import { initData } from '@/pages/resident/CreateResident';
import { INSURANCE_NUMBER, HOME_PHONE } from '@/lib/regex';

const ResidentDetail = (props: PageProps) => {
    const { t } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get(`userId`);

    const form = useForm<z.infer<typeof EditResidentSchema>>({
        resolver: zodResolver(EditResidentSchema),
        defaultValues: {
            ...initData,
        },
        mode: 'all',
    });

    const onBack = () => {
        navigate(`/welfare/residents`);
    };

    const onSubmit = async () => {
        try {
            setLoading(true);
            const req = form.getValues();
            const my_number = removeSpaceMyNumber(req.my_number);
            const insured_number = removeSpaceMyNumber(req.insured_number);
            const data = {
                ...req,
                my_number,
                insured_number,
                birthday: dayjs(req.birthday).format('YYYY-MM-DD'),
                service_name_1: req.service?.service_name_1 || '',
                service_name_2: req.service?.service_name_2 || '',
                service_name_3: req.service?.service_name_3 || '',
                emergency_contacts: [
                    {
                        ...req.emergency_contact1,
                        phone: removeStringPhone(req.emergency_contact1.phone),
                        home_phone: removeStringPhone(req.emergency_contact1.home_phone),
                    },
                    {
                        ...req.emergency_contact2,
                        phone: removeStringPhone(req.emergency_contact2.phone),
                        home_phone: removeStringPhone(req.emergency_contact2.home_phone),
                    },
                ],
            };
            await ResidentService.UpdateResident(userId, id, { resident: data });
            if (data) {
                toast({ description: t('common.MSG_017'), variant: 'success' });
                onBack();
            }
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_056',
                callback: () => onBack(),
            });

            if (isNotFound) return;

            handleErrorMessage({
                error: error?.errors?.resident?.[0] || '',
                message: t(
                    error?.errors?.resident?.[0].includes('common')
                        ? error?.errors?.resident?.[0]
                        : `common.${error?.errors?.resident?.[0]}`,
                    {
                        field: t('resident.my_phone'),
                    }
                ),
                callback: (msg) => msg.includes('MSG_056') && onBack(),
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    const getDetailResident = async () => {
        try {
            setLoading(true);
            const res = await GetDetailVisitReport(userId, id);
            form.reset({
                ...res.data,
                civil: res.data.visitor_id,
                my_number: convertStringMyNumber(res.data.my_number),
                insured_number: convertStringMyNumber(res.data.insured_number, INSURANCE_NUMBER),
                service: {
                    service_name_1: res.data.service_name_1 || '',
                    service_name_2: res.data.service_name_2 || '',
                    service_name_3: res.data.service_name_3 || '',
                },
                emergency_contact1: {
                    ...res.data.emergency_contacts[0],
                    phone: convertStringPhone(res.data.emergency_contacts[0].phone),
                    home_phone: convertStringPhone(res.data.emergency_contacts[0].home_phone, HOME_PHONE),
                },
                emergency_contact2: res.data.emergency_contacts[1]
                    ? {
                          address: undefined,
                          name: undefined,
                          relationship: undefined,
                          ...res.data.emergency_contacts[1],
                          phone: convertStringPhone(res.data.emergency_contacts[1]?.phone),
                          home_phone: convertStringPhone(res.data.emergency_contacts[1]?.home_phone || '', HOME_PHONE),
                      }
                    : {
                          address: undefined,
                          name: undefined,
                          relationship: undefined,
                          home_phone: undefined,
                          phone: undefined,
                      },
                family_hospital: res.data.family_hospital || '',
                care_manager: res.data.care_manager || '',
                note: res.data.note || '',
            });

            return res.data;
        } catch (error) {
            console.log(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const { isFetching: isLoad, data } = useQuery({
        queryKey: ['getDetailResident', id],
        queryFn: () => {
            if (id) return getDetailResident();
        },
        placeholderData: keepPreviousData,
    });

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('resident.title')}${t('edit')}` }]}
                    className="py-6"
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('update')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
            pageLoading={isLoad}
        >
            <Form {...form}>
                <ResidentForm type="edit" user={{ id: data?.visitor_id, name: data?.visitor_name }} />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(ResidentDetail));
