import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { TFunction } from 'react-i18next';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { Category, CategoryType } from '@/interfaces/category';

type ColumnsProps = {
    t: TFunction;
    onEdit?: (key: string) => void;
    onDelete?: (key: string) => void;
    columnsKey?: string[];
    showFull?: boolean;
    type?: Category;
};

export const columns = ({
    t,
    onEdit,
    columnsKey,
    onDelete,
    showFull,
}: ColumnsProps): TableProps<CategoryType>['columns'] => {
    const allColumns: TableProps<CategoryType>['columns'] = [
        {
            title: t('category.parent_category'),
            dataIndex: 'content',
            key: 'content',
            width: 200,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('category.child_category'),
            dataIndex: 'category_child',
            key: 'category_child',
            width: 200,
            render: (_, record) => {
                const children = record.category_children;
                const value = children?.map((item) => item.content).join('/ ');
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 100,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey mr-2"
                            onClick={() => {
                                onEdit?.(record.id);
                            }}
                        >
                            {t('edit')}
                        </Button>
                        {!record.report_existed && (
                            <Button
                                variant="delete"
                                className="bg-red-500 border-grey"
                                onClick={() => {
                                    onDelete?.(record.id);
                                }}
                            >
                                {t('delete')}
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const mediumTableColumn = ({
    t,
    onEdit,
    columnsKey,
    onDelete,
    showFull,
}: ColumnsProps): TableProps<CategoryType>['columns'] => {
    const allColumns: TableProps<CategoryType>['columns'] = [
        {
            title: t('category.child_category'),
            dataIndex: 'content',
            key: 'content',
            width: 200,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('category.department'),
            dataIndex: 'category_child_users',
            key: 'category_child_users',
            width: 200,
            render: (_, record) => {
                const value = record.users.map((item) => item.name).join('/ ');
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 150,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey mr-2"
                            onClick={() => {
                                onEdit?.(record.id);
                            }}
                        >
                            {t('edit')}
                        </Button>
                        {!record.report_existed && (
                            <Button
                                variant="delete"
                                className="bg-red-500 border-grey"
                                onClick={() => {
                                    onDelete?.(record.id);
                                }}
                            >
                                {t('delete')}
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
