import React from 'react';
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { HeaderProps } from '@/interfaces/common/header';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { observer } from 'mobx-react-lite';
import { useAuthenStore } from '@/store/auth/AuthStore';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { useLocation, useNavigate } from 'react-router';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { PATH } from '@/constants/path';
import { MODULE_MENU } from '@/constants/menu';
import { APP_PERMISSION } from '@/constants/permissionConfig';

const ListItem = React.forwardRef<React.ElementRef<'button'>, React.ComponentPropsWithoutRef<'button'>>(
    ({ className, title, children, ...props }, ref) => {
        return (
            <li>
                <NavigationMenuLink asChild>
                    <Button
                        ref={ref}
                        variant="ghost"
                        className={cn(
                            'w-full block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
                            className
                        )}
                        {...props}
                    >
                        {children || <div className="text-sm font-medium leading-none">{title}</div>}
                    </Button>
                </NavigationMenuLink>
            </li>
        );
    }
);
ListItem.displayName = 'ListItem';

type ProfileHeaderProps = {
    className?: string;
    type?: string;
    name?: string;
    role?: string;
    descriptions?: string;
    onLogout: () => void;
    onClickProfile: () => void;
};

export const ProfileHeader = ({
    type = '',
    name = '',
    onLogout,
    onClickProfile,
    className,
    descriptions,
}: ProfileHeaderProps) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(
                'gap-2 md:flex ',
                type === 'row' ? 'hidden' : 'flex flex-wrap items-center justify-center',
                className
            )}
        >
            <span>{t('hello')}</span>
            {descriptions && <span>{t(descriptions)}</span>}
            <TooltipComponent title={name} antdTooltip>
                <button
                    className={cn(
                        'underline decoration-blue-400 text-blue-400 cursor-pointer max-w-[120px] md:max-w-[150px] xl:max-w-[400px] overflow-hidden text-ellipsis inline-block whitespace-nowrap',
                        type === 'col' && '!max-w-[300px]'
                    )}
                    onClick={onClickProfile}
                >
                    {name}
                </button>
            </TooltipComponent>
            <span>さん</span>
            <button className="underline cursor-pointer" onClick={onLogout}>
                {t('logout')}
            </button>
        </div>
    );
};

const Header = ({ onLogout, headerTitle = '', components = [], type }: HeaderProps & WithTransProps) => {
    const { user } = useAuthenStore();
    const { pathname } = useLocation();
    const { setPrevious } = useGlobalStore();
    const { module } = useGlobalStore();
    const navigate = useNavigate();
    const handleClickProfile = () => {
        if (pathname !== '/profile') {
            navigate('/profile');
            pathname !== '/update-password' && setPrevious({ profile: pathname });
        }
    };

    const onClickHeaderTitle = () => {
        if (pathname === PATH.PROFILE || pathname === PATH.UPDATE_PASSWORD) {
            return navigate('/');
        }
        switch (module) {
            case MODULE_MENU.WELFARE: {
                navigate('/welfare');
                return;
            }
            case MODULE_MENU.COMMUNICATION: {
                navigate('/communication');
                return;
            }
            case MODULE_MENU.ACCOUNT: {
                navigate('/');
                return;
            }
            default:
                return navigate('/');
        }
    };

    const renderHeaderTitle = (className?: string, descriptions?: string) => {
        return (
            <ProfileHeader
                type={type}
                name={user?.name || user?.email}
                onLogout={onLogout}
                onClickProfile={handleClickProfile}
                className={className}
                descriptions={descriptions}
            />
        );
    };

    const renderHeaderHorizontal = () => {
        return (
            <div className="flex justify-between items-center top-0 left-0 right-0 fixed py-4 min-[890x]:py-6 px-4 min-[890px]:px-6 min-[1390px]:px-20 z-10 bg-white shadow-md">
                <button
                    className="font-bold text-ip min-[540px]:text-pc min-[890px]:text-[25px] xl:text-title"
                    onClick={onClickHeaderTitle}
                >
                    {headerTitle}
                </button>
                {renderHeaderTitle('text-ip min-[890px]:text-pc')}
                <div className="md:hidden">
                    <NavigationMenu>
                        <NavigationMenuList>
                            <NavigationMenuItem>
                                <NavigationMenuTrigger className="text-ip">
                                    <span
                                        className={cn(
                                            'underline decoration-blue-400 text-blue-400 cursor-pointer max-w-[100px] hidden min-[540px]:inline-block overflow-hidden text-ellipsis whitespace-nowrap'
                                        )}
                                    >
                                        {user?.name || user?.email}
                                    </span>
                                </NavigationMenuTrigger>
                                <NavigationMenuContent>
                                    <ul className="grid w-[190px] gap-3 p-4 flex-col">
                                        {components.map((comp) => (
                                            <ListItem key={comp.title} title={comp.title} onClick={comp.onClick}>
                                                {comp.children}
                                            </ListItem>
                                        ))}
                                    </ul>
                                </NavigationMenuContent>
                            </NavigationMenuItem>
                        </NavigationMenuList>
                    </NavigationMenu>
                </div>
            </div>
        );
    };

    const renderHeaderVertical = () => {
        const description =
            pathname === '/communication'
                ? user.roles.includes(APP_PERMISSION.MAYOR)
                    ? 'role_text.mayor'
                    : user.roles.includes(APP_PERMISSION.REPRESENTATIVE)
                    ? 'role_text.representative'
                    : ''
                : '';
        return (
            <div className="flex flex-col justify-center items-center mt-8 md:mt-16 xl:mt-4 gap-6 md:gap-8 py-2 lg:py-6 px-4 md:px-6 xl:px-20 ">
                <button className="font-bold text-pc md:text-title_ip xl:text-title" onClick={onClickHeaderTitle}>
                    {headerTitle}
                </button>
                {renderHeaderTitle('', description)}
            </div>
        );
    };

    return <>{type === 'row' ? renderHeaderHorizontal() : renderHeaderVertical()}</>;
};

export default withTrans(observer(Header));
