import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { IPResidentSchema } from '@/schema/residentSchema';
import { ResidentReq } from '@/interfaces/resident';
import { ResidentService } from '@/modules/services';
import { useAuthenStore } from '@/store/auth/AuthStore';
import dayjs from '@/lib/dayjs';
import { useGlobalStore } from '@/store/global/GlobalStore';
import IPResidentForm from '@/pages/resident/form/IPResidentForm';
import { FORMAT_DATE_REQ } from '@/constants/appConstants';
import { handleErrorMessage, removeSpaceMyNumber, removeStringPhone } from '@/lib/helper';
import { initData } from '@/pages/resident/CreateResident';

const IPCreateResident = (props: PageProps) => {
    const { t } = props;
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();

    const form = useForm<z.infer<typeof IPResidentSchema>>({
        resolver: zodResolver(IPResidentSchema),
        defaultValues: {
            ...initData,
        },
        mode: 'onChange',
        shouldFocusError: true,
    });

    const onBack = () => {
        navigate(`/welfare`);
    };

    const onSubmit = async () => {
        if (!user.id) return;

        try {
            setLoading(true);
            const req = form.getValues();
            const insured_number = removeSpaceMyNumber(req.insured_number);
            const body: ResidentReq = {
                resident: {
                    ...req,
                    insured_number,
                    birthday: dayjs(req.birthday).format(FORMAT_DATE_REQ),
                    service_name_1: req.service?.service_name_1 || '',
                    service_name_2: req.service?.service_name_2 || '',
                    service_name_3: req.service?.service_name_3 || '',
                    emergency_contacts: [
                        {
                            ...req.emergency_contact1,
                            phone: removeStringPhone(req.emergency_contact1.phone),
                            home_phone: removeStringPhone(req.emergency_contact1.home_phone),
                        },
                        {
                            ...req.emergency_contact2,
                            phone: removeStringPhone(req.emergency_contact2.phone),
                            home_phone: removeStringPhone(req.emergency_contact2.home_phone),
                        },
                    ],
                },
            };

            const res = await ResidentService.CreateResident(user.id, body);
            if (res) {
                toast({ description: t('common.MSG_015'), variant: 'success' });
                navigate(`/welfare/commissioner/residents`);
            }
        } catch (error) {
            const message = error?.errors?.resident?.[0] || '';
            handleErrorMessage({
                error: message,
                message: t(message.includes('common') ? message : `common.${message}`, {
                    field: t('resident.my_phone'),
                }),
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('resident.title')}${t('register')}` }]}
                    className="py-6"
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <IPResidentForm />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(IPCreateResident));
