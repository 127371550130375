import withTrans from '@/components/common/hocs/withTrans';
import GroupButton from '@/components/common/pages/GroupButton';
import InformationResident from '@/components/common/pages/InformationResident';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import ProgressDetail from '@/components/common/pages/ProgressDetail';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { RequestAppDetaiRes } from '@/interfaces/application';
import { PageProps } from '@/interfaces/props/page';
import { ResidentData } from '@/interfaces/resident';
import { cn } from '@/lib/utils';
import { GetDetailApplicationByResident } from '@/modules/services/application.service';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';

const IPRequestAppDetail = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { residentId, id } = useParams();

    const getDetailRequest = async (id: string) => {
        try {
            const { data } = await GetDetailApplicationByResident(id, residentId);
            return data as RequestAppDetaiRes;
        } catch (error) {
            return null;
        }
    };

    const { data, isFetching: isLoading } = useQuery({
        queryKey: ['getDetailRequest', id],
        queryFn: () => {
            if (id) {
                return getDetailRequest(id);
            } else {
                return null;
            }
        },
    });

    const onBack = () => {
        navigate(`/welfare/commissioner/applications/${residentId}`);
    };

    return (
        <PageComponent
            pageLoading={isLoading}
            title={
                <PageTitle
                    className="py-6"
                    routes={[{ path: '', breadcrumbName: `${t('application_accept.request_app_detail')}` }]}
                />
            }
            isDownLoadCsv={false}
            textBack={t('go_back')}
            onBack={onBack}
        >
            <ProgressDetail
                status={data?.status}
                priority={data?.last_pro_priority}
                isLoading={isLoading}
                progress={data?.progresses || []}
            />
            <Separator className="my-8 bg-grey" />
            <InformationResident data={data?.resident as ResidentData} />
            <div className="flex flex-col mt-4 gap-4 cursor-default">
                <Label className="text-pc font-medium">{t('resident.created_app_service')}</Label>
                <GroupButton
                    buttons={[
                        {
                            label: t('application_accept.taxi'),
                            className: cn('hover:cursor-default py-[30px]', data?.welfare_taxi && 'bg-lime-500'),
                        },
                        {
                            label: t('application_accept.emergency_device'),
                            className: cn('hover:cursor-default py-[30px]', data?.emergency_device && 'bg-lime-500'),
                        },
                        {
                            label: t('habitat'),
                            className: cn('hover:cursor-default py-[30px]', data?.habitat && 'bg-lime-500'),
                        },
                        {
                            label: t('evacuation_operations'),
                            className: cn(
                                'hover:cursor-default py-[30px]',
                                data?.evacuation_operations && 'bg-lime-500'
                            ),
                        },
                    ]}
                />
            </div>
            <div className="flex flex-col mt-4 gap-4 cursor-default">
                <Label className="text-pc font-medium">{t('application_accept.reason_app')}</Label>
                <Textarea
                    rows={5}
                    className={cn('text-ip border-grey hover:cursor-text disabled:cursor-text')}
                    readOnly={true}
                    value={data?.reason || ''}
                />
            </div>
        </PageComponent>
    );
};

export default withTrans(observer(IPRequestAppDetail));
