import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import PageComponent from '@/components/common/pages/PageComponent';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { ReportSchema } from '@/schema/reportSchema';
import { useNavigate, useParams } from 'react-router';
import { toast } from '@/components/ui/use-toast';
import { getContactReportDetail, updateContacReport } from '@/modules/services/contact.service';
import { getRequestReportDetail, updateRequestReport } from '@/modules/services/request.service';
import { getReportApplicationDetail, updateApplicationReport } from '@/modules/services/application.report.service';
import { CATEGORIES, REPORT_ROUTES, STATUS_APPLICATION_ACCEPTED } from '@/constants/appConstants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { convertDataReportDetail, convertReportDataSub, handleErrorMessage } from '@/lib/helper';
import ReportForm from '@/pages/reports/form/ReportForm';
import { ReportPageProps } from '@/interfaces/report';

const functionData = {
    [REPORT_ROUTES.CONTACT]: {
        get: getContactReportDetail,
        update: updateContacReport,
    },
    [REPORT_ROUTES.REQUEST]: {
        get: getRequestReportDetail,
        update: updateRequestReport,
    },
    [REPORT_ROUTES.COMMUNICATION_APP]: {
        get: getReportApplicationDetail,
        update: updateApplicationReport,
    },
};

const EditReport = ({ t, categoryType }: ReportPageProps) => {
    const { isLoading, setLoading } = useGlobalStore();
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const type = REPORT_ROUTES[categoryType];
    const schema = ReportSchema(type);

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            assignee_id: undefined,
            category_id: undefined,
            content: undefined,
            category_child_id: undefined,
            google_map: {},
            attachments: [
                { file: undefined },
                { file: undefined },
                { file: undefined },
                { file: undefined },
                { file: undefined },
            ],
        },
        mode: 'onChange',
    });

    const onSubmit = async () => {
        try {
            setLoading(true);
            const value = form.getValues();
            const formData: any = convertReportDataSub({
                name: `report_${type}`,
                value,
                type: 'edit',
                progresses: data?.progresses,
            });
            await functionData[type].update(id, formData);
            toast({ description: t('common.MSG_017'), variant: 'success' });
            onBack();
        } catch (error) {
            const isError = handleErrorMessage({
                error: error?.errors?.[`report_${type}`]?.[0],
            });

            if (isError) return;

            const isErrorAssignee = handleErrorMessage({
                error: error?.errors?.assignee?.[1] || '',
                callback: () => onBack(),
            });

            if (isErrorAssignee) return;

            handleErrorMessage({
                error: error?.errors?.category?.[1] || '',
                defaultMessage: 'common.MSG_038',
                callback: () => {
                    form.setValue('category_id', undefined);
                    queryClient.refetchQueries({ queryKey: ['getCategoryOptions', '', 1, CATEGORIES[categoryType]] });
                },
            });
        } finally {
            setLoading(false);
        }
    };

    const onBack = () => {
        navigate(`/communication/reports/${type}`);
    };

    const getDetailDataReport = async (id: string) => {
        try {
            setLoading(true);
            const res = await functionData[type].get(id);

            if (res?.data?.status !== STATUS_APPLICATION_ACCEPTED.PENDING) {
                navigate('/not-permission');
                return null;
            }
            const data: any = convertDataReportDetail(res.data);
            if (data) {
                form.reset({
                    ...data,
                });
            }
            return res.data;
        } catch (error) {
            console.log(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const { isFetching: isLoad, data } = useQuery({
        queryKey: ['getDetailReport', id],
        queryFn: () => {
            if (id) return getDetailDataReport(id);
        },
    });

    return (
        <PageComponent
            pageLoading={isLoad}
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: t(`report.${type}_title_edit`),
                        },
                    ]}
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-[19px] h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('update')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <ReportForm
                    categoryType={categoryType}
                    type="edit"
                    defaultAssignee={{ value: data?.assignee?.id, label: data?.assignee?.name }}
                    defaultCategory={{ value: data?.category?.id, label: data?.category?.content }}
                    defaultCategoryChild={{ value: data?.category_child?.id, label: data?.category_child?.content }}
                />
            </Form>
        </PageComponent>
    );
};
export default withTrans(observer(EditReport));
