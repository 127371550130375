import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import App from '@/App';
import { Toaster } from '@/components/ui/toaster';
import Loading, { LoadingComponent } from '@/components/ui/loading';
import i18n from '@/modules/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@/styles/global.css';
import { PopupConfirm } from '@/components/common/pages/ModalConfirm';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<LoadingComponent />}>
                        <App />
                    </Suspense>
                </QueryClientProvider>
                <Toaster />
                <Loading />
                <PopupConfirm />
            </BrowserRouter>
        </I18nextProvider>
    </React.StrictMode>
);
