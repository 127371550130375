import { MenuProps } from '@/interfaces/common/menu';

const MenuCommon = ({ menuTitle = '', children }: MenuProps) => {
    return (
        <div className="flex flex-col">
            <div className="py-4 text-label">{menuTitle}</div>
            <div className="md:max-w-max flex flex-wrap gap-x-6 gap-y-4 py-6 border-t border-t-black">{children}</div>
        </div>
    );
};

export default MenuCommon;
