import React from 'react';
import { BreadscrumbList, BreadscumbItem } from '@/interfaces/common/global';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';

type PageTitleProps = {
    routes: BreadscrumbList;
    className?: string;
    separator?: string;
    extra?: React.ReactNode;
};

type BreadcrumbProps = {
    routes: BreadscrumbList;
    separator?: string;
    itemRender?: (
        route: BreadscumbItem,
        params: string,
        routes: Array<BreadscumbItem>,
        paths: Array<string>
    ) => React.ReactNode;
};

const itemRender = (route: BreadscumbItem, params: string, routes: Array<BreadscumbItem>, paths: Array<string>) => {
    const last = routes.indexOf(route) === routes.length - 1;

    return last || !route.path ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const Breadcrumb = ({ routes, itemRender, separator }: BreadcrumbProps) => {
    const paths: string[] = [];

    return (
        <ol className="flex flex-wrap ">
            {routes.map((item, index: number) => {
                paths.push(item.path);
                return (
                    <React.Fragment key={index}>
                        <li>{itemRender(item, '', routes, paths)}</li>
                        {index < routes.length - 1 && (
                            <li className="px-2 breadcrumb-separator">{separator ?? '  '}</li>
                        )}
                    </React.Fragment>
                );
            })}
        </ol>
    );
};

const PageTitle: React.FC<PageTitleProps> = (props) => {
    const { routes, className, separator, extra } = props;
    return (
        <div className={cn('text-[23px] font-medium py-4 flex justify-between whitespace-break-spaces', className)}>
            <Breadcrumb itemRender={itemRender} routes={routes} separator={separator} />
            {extra && extra}
        </div>
    );
};

export default PageTitle;
