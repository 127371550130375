import { useMemo, useState } from 'react';
import dayjs from '@/lib/dayjs';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parseQueryParams } from '@/lib/url';
import withTrans from '@/components/common/hocs/withTrans';
import { PageProps } from '@/interfaces/props/page';
import { Button } from '@/components/ui/button';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { toast } from '@/components/ui/use-toast';
import { ForgotPasswordApi } from '@/modules/services/auth.service';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TIME_SEND_EMAIL } from '@/constants/appConstants';
import CountDown from '@/components/common/pages/CountDown';

const ForgotPasswordSuccess = (props: PageProps) => {
    const { t } = props;
    const { isLoading, setLoading } = useGlobalStore();
    const { timeSendEmail, setTimeSendEmail } = useAuthenStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const [triggerTime, setTriggerTime] = useState<string>('');

    const location = useLocation();

    const emailUrl = searchParams?.get('email') || '';
    const time = searchParams?.get('time') || '';
    const email = useMemo(() => decodeURIComponent(emailUrl || ''), [emailUrl]);

    const diffTime = TIME_SEND_EMAIL - Math.floor(dayjs().unix() - Number(timeSendEmail || time || ''));

    const disabled = useMemo(
        () => (timeSendEmail || time ? diffTime > 0 : true),
        [triggerTime, timeSendEmail, diffTime, time]
    );

    const onResend = async () => {
        if (emailUrl) {
            try {
                setLoading(true);
                await ForgotPasswordApi({ user: { email } });
                let query = parseQueryParams(location);
                query = {
                    ...query,
                    email,
                    time: dayjs().unix(),
                };
                setTimeSendEmail(dayjs().unix().toString());
                setSearchParams(query);
                toast({ description: t('common.MSG_024', { email }), variant: 'success' });
            } catch (error) {
                toast({ description: t('common.MSG_100'), variant: 'destructive' });
            } finally {
                setLoading(false);
            }
        }
    };

    const onHandleCountDown = () => {
        setTriggerTime(dayjs().unix().toString());
    };

    return (
        <div className="flex h-screen flex-col items-center justify-center gap-6 sm:gap-9 max-w-[750px] m-auto whitespace-pre-line p-4">
            <div className="text-title font-bold">{t('app_title')}</div>
            <div className="text-center break-all">{t('reset_password_description', { email })}</div>
            <CountDown time={diffTime >= 0 ? diffTime : 0} onHandle={onHandleCountDown} />
            <Button
                type="submit"
                disabled={isLoading || disabled}
                className="mt-6 w-[300px]  md:w-[431px]"
                onClick={onResend}
                size="xxl"
            >
                {t('resend')}
            </Button>
        </div>
    );
};
export default withTrans(observer(ForgotPasswordSuccess));
