import { FormControl, FormField, FormLabel } from '@/components/ui/form';
import { ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { CheckedState } from '@radix-ui/react-checkbox';

type Props = {
    name: string;
    label: React.ReactNode;
    disabled?: boolean;
    className?: string;
    onCheckboxChange?: (checked: CheckedState, field: ControllerRenderProps<FieldValues, string>) => void;
    classNameCheckBox?: string;
    classNameLabel?: string;
    defaultChecked?: boolean
};

const FormInputCheckbox = ({
    name,
    label,
    className,
    classNameLabel,
    classNameCheckBox,
    disabled = false,
    onCheckboxChange,
}: Props) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <div className={cn('flex gap-2 items-center', className)}>
                    <FormControl>
                        <Checkbox
                            className={classNameCheckBox}
                            checked={field.value}
                            onCheckedChange={(checked) => {
                                field.onChange(checked);
                                onCheckboxChange?.(checked, field);
                            }}
                            disabled={disabled}
                        />
                    </FormControl>
                    <FormLabel htmlFor={name} className={cn('text-error font-normal', classNameLabel)}>
                        {label}
                    </FormLabel>
                </div>
            )}
        />
    );
};

export default FormInputCheckbox;
