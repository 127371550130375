import withTrans from '@/components/common/hocs/withTrans';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { UpdatePasswordSchema } from '@/schema/authSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { PageProps } from '@/interfaces/props/page';
import { z } from 'zod';
import { ProfileChangePassword, UpdatePasswordReq } from '@/interfaces/profile/password';
import { AuthService } from '@/modules/services';
import { useNavigate } from 'react-router';
import { toast } from '@/components/ui/use-toast';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { useGlobalStore } from '@/store/global/GlobalStore';
import FormInputText from '@/components/common/form/FormInputText';
import { observer } from 'mobx-react-lite';
import { ALPHA_NUMERIC_REGEX, NOT_NUMBER } from '@/lib/regex';
import { useEffect } from 'react';

const UpdatePasswordComponent = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isLoading, setLoading, previous } = useGlobalStore();

    const form = useForm<z.infer<typeof UpdatePasswordSchema>>({
        mode: 'onChange',
        resolver: zodResolver(UpdatePasswordSchema),
        defaultValues: {
            current_password: '',
            password: '',
            password_confirmation: '',
        },
    });

    const onBack = () => {
        navigate(previous.updatePass);
    };

    const onSubmit = async (req: ProfileChangePassword) => {
        try {
            setLoading(true);
            const body: UpdatePasswordReq = { user: req };
            const res = await AuthService.UpdatePassword(body);
            if (res) {
                toast({ description: t('common.MSG_013'), variant: 'success' });
            }
        } catch (error) {
            const errors = error?.errors?.current_password || [];
            if (errors.length) {
                toast({
                    description: t('common.MSG_067', { fieldName: t('change_pass.current_pass') }),
                    variant: 'destructive',
                });
                return;
            }
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const subscription = form.watch((value, { name }) => {
            if (name === 'password' || name === 'password_confirmation') {
                !!value.password_confirmation && form.trigger(['password', 'password_confirmation']);
            }
        });

        // Cleanup the subscription on unmount.
        return () => subscription.unsubscribe();
    }, [form.watch, form.trigger]);

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('change_password') }]} className="py-6" />}
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px]  w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('change_pass.change')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px]  w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <div className="flex flex-col justify-center gap-y-4 max-w-[1000px]">
                    <FormInputText
                        regex={ALPHA_NUMERIC_REGEX}
                        labelClass="min-w-[220px]"
                        name="current_password"
                        placeholder={t('common.MSG_001', { field: t('change_pass.current_pass') })}
                        label={t('change_pass.current_pass')}
                        type="password"
                    />
                    <FormInputText
                        regex={NOT_NUMBER}
                        labelClass="min-w-[220px]"
                        name="password"
                        placeholder={t('common.MSG_001', { field: t('change_pass.new_pass') })}
                        label={t('change_pass.new_pass')}
                        type="password"
                        maxLength={4}
                    />
                    <FormInputText
                        regex={ALPHA_NUMERIC_REGEX}
                        labelClass="min-w-[220px]"
                        name="password_confirmation"
                        placeholder={t('common.MSG_001', { field: t('change_pass.confirm_pass') })}
                        label={t('change_pass.confirm_pass')}
                        type="password"
                        maxLength={20}
                    />
                </div>
            </Form>
        </PageComponent>
    );
};
export default withTrans(observer(UpdatePasswordComponent));
