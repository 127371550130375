import { GetUserType, Result, SelectOptionsItem } from '@/interfaces/common/global';
import { GetListUsers } from '@/modules/services/auth.service';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ScrollOptions } from '@/interfaces/resident';
import { PAGE_SIZE } from '@/constants/appConstants';
import { debounce } from 'lodash';
import { APIResponse } from '@/interfaces/common/response';
import { User } from '@/interfaces/account';
import { GetListDistricts } from '@/modules/services/user.service';

const useGetUsers = ({
    role = '',
    page = 1,
    perPage = PAGE_SIZE,
    defaultOption = [],
}: GetUserType & { defaultOption?: SelectOptionsItem[] }) => {
    const [results, setResults] = useState<Result>({ options: [...defaultOption], total: 0 });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [scrollOption, setScrollOption] = useState<ScrollOptions>({
        page: page,
        total: 0,
        perPage: perPage,
    });

    const onHandleSearch = debounce((value: string) => {
        setSearchTerm(value);
        setScrollOption((prev) => ({
            ...prev,
            page: page,
            perPage: perPage,
        }));
    }, 500);

    const getListUsers = async () => {
        try {
            const { data, meta } = await GetListUsers<APIResponse<User[]>>({
                role,
                per_page: scrollOption.perPage,
                order_by: 'name asc updated_at desc',
                page: scrollOption.page,
                ...(searchTerm && { name: searchTerm }),
            });
            const dataResponse: SelectOptionsItem[] = data.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setScrollOption((prev) => ({ ...prev, total: meta.total_count }));
            setResults((prev) => {
                return {
                    ...prev,
                    options: scrollOption.page === 1 ? dataResponse : [...prev.options, ...dataResponse],
                };
            });

            return true;
        } catch (error) {
            setResults({
                total: 0,
                options: [],
            });

            return false;
        }
    };

    const { isFetching: isLoading } = useQuery({
        queryKey: ['getOptionUsers', searchTerm, scrollOption.page],
        queryFn: () => getListUsers(),
    });

    return {
        options: results.options,
        total: results.total,
        setScrollOption,
        onHandleSearch,
        isLoading,
    };
};

export const useGetDataDistricts = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const onHandleSearch = debounce((value: string) => {
        setSearchTerm(value);
    }, 500);
    const getListDistricts = async () => {
        try {
            const { data } = await GetListDistricts({ district: searchTerm });
            const newData = data
                ? data.map((item: { id: string; district: string }) => ({ label: item.district, value: item.district }))
                : [];
            return newData;
        } catch (error) {
            return [];
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getOptionsDistricts', searchTerm],
        queryFn: () => getListDistricts(),
    });

    return {
        options: data,
        isLoading: isLoading,
        onHandleSearch,
    };
};

export default useGetUsers;
