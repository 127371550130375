import { z } from 'zod';
import { createStringSchema } from '@/schema/authSchema';
import i18n from '@/modules/i18n';

const { t } = i18n;

export const MeetingSchema = z.object({
    name: createStringSchema('meeting.form_meeting.name'),
    meeting_date: createStringSchema('meeting.form_meeting.date', 'select').nullable(),
    end_time: z.any(),
    //.superRefine((val, ctx) => {
    // const error = !val;
    // if (error) {
    //     ctx.addIssue({
    //         code: z.ZodIssueCode.custom,
    //         path: [''],
    //         message: t('common.MSG_002', { field: t('meeting.form_meeting.end') }),
    //     });
    // }
    // }),
    start_time: z.any().superRefine((val, ctx) => {
        const error = !val;
        if (error) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [''],
                message: t('common.MSG_002', { field: t('meeting.form_meeting.start') }),
            });
        }
    }),
    content: z.any(),
    user_ids: z.any().superRefine((val, ctx) => {
        const error = !val || val?.length === 0;
        if (error) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [''],
                message: t('common.MSG_002', { field: t('meeting.form_meeting.attendee_selection') }),
            });
        }
    }),
});

export const AttendanceSchema = z.object({
    attendance: z.object({
        attendance_status: z.enum(['attend', 'absent'], {
            required_error: t('common.MSG_002_2'),
            invalid_type_error: t('common.MSG_002_2'),
        }),
        reason: z.any(),
    }),
    // .superRefine((val, ctx) => {
    //     const error = val.attendance_status === ATTENDANCE_STATUS.ABSENT && !val.reason;
    //     if (error) {
    //         ctx.addIssue({
    //             code: z.ZodIssueCode.custom,
    //             path: ['reason'],
    //             message: t('common.MSG_001', { field: t('attendance_meeting.reason') }),
    //         });
    //     }
    // }),
});
