import { z } from 'zod';
import i18n from '@/modules/i18n';
import { NUMBER_PASSWORD, REGEX_EMAIL } from '@/lib/regex';

const { t } = i18n;
type TypeName = 'select' | 'input';

export const createStringSchema = (fieldName: string, type: TypeName = 'input') =>
    z
        .string({
            required_error: t(type === 'input' ? 'common.MSG_001' : 'common.MSG_002', { field: t(fieldName) }),
        })
        .trim()
        .min(1, {
            message: t(type === 'input' ? 'common.MSG_001' : 'common.MSG_002', { field: t(fieldName) }),
        });

const EmailSchema = z.object({
    email: createStringSchema('email')
        .email({ message: t('common.MSG_003') })
        .refine((value) => REGEX_EMAIL.test(value), { message: t('common.MSG_003') }),
});

const PasswordSchema = z.object({
    password: createStringSchema('password')
        .min(4, {
            message: t('common.MSG_011'),
        })
        .superRefine((val, ctx) => {
            const isValid = NUMBER_PASSWORD.test(val);

            if (!isValid) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: t('common.MSG_011'),
                });
            }
        }),
});

const PasswordConfirmSchema = z.object({
    password_confirmation: createStringSchema('confirm_password'),
});

export const ForgotEmailSchema = EmailSchema;

export const LoginSchema = EmailSchema.extend({
    password: createStringSchema('password'),
});

export const ResetPasswordSchema = PasswordSchema.merge(PasswordConfirmSchema).refine(
    (data) => data.password === data.password_confirmation,
    {
        message: t('common.MSG_012'),
        path: ['password_confirmation'],
    }
);

export const UpdateProfileSchema = z.object({
    user_name: createStringSchema('account_name'),
    email: z.any(),
    roles: z.any(),
});

export const UpdatePasswordSchema = z
    .object({
        current_password: createStringSchema('change_pass.current_pass'),
        password: createStringSchema('change_pass.new_pass').min(4, {
            message: t('common.MSG_011'),
        }),
        password_confirmation: createStringSchema('change_pass.confirm_pass'),
    })
    .superRefine((val, ctx) => {
        const isValid = val.password === val.password_confirmation;
        const isValidPass = NUMBER_PASSWORD.test(val.password);

        if (!isValidPass) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['password'],
                message: t('common.MSG_011'),
            });
        }

        if (!isValid) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['password_confirmation'],
                message: t('common.MSG_012'),
            });
        }
    });
