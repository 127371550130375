import { z } from 'zod';
import i18n from '@/modules/i18n';
import { createStringSchema } from '@/schema/authSchema';
import {TYPE_ANSWER} from "@/constants/appConstants";

const { t } = i18n;

export const ProcessSchema = z.object({
    name: createStringSchema('application_accept.c_name'),
    description: createStringSchema('application_accept.content'),
    latest_updated_at: z.any(),
    priority: createStringSchema('application_accept.urgency', 'select'),
    status: createStringSchema('application_accept.status', 'select'),
});

export const QuestionsApplicationSchema = z.object({
    title: createStringSchema('category.application.form.title'),
    due_date: createStringSchema('category.application.form.deadline', 'select').nullable(),
    questions: z.array(
        z
            .object({
                title: createStringSchema('category.application.form.question_content'),
                question_type: createStringSchema('category.application.form.answer', 'select'),
                options: z.array(
                    z.object({
                        content: z.any(),
                    })
                ),
            })
            .superRefine((val, ctx) => {
                const error = !(val.question_type === TYPE_ANSWER.MULTIPLE_CHOICE
                    ? val.options.some((opt) => !!opt.content)
                    : true);

                if (error) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['options'],
                        message: t('common.MSG_001', { field: t('category.application.form.option_placeholder') }),
                    });
                }
            })
    ),
});

export const ApplicationSchema = z.object({
    welfare_app: z
        .object({
            emergency_device: z.boolean(),
            welfare_taxi: z.boolean(),
            evacuation_operations: z.boolean(),
            habitat: z.boolean(),
            reason: z.string(),
            isService: z.boolean(),
        })
        .superRefine((val, ctx) => {
            const error = val.emergency_device || val.welfare_taxi || val.habitat || val.evacuation_operations;
            if (!error) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['isService'],
                    message: t('common.MSG_002', { field: t('application_accept.desired_app_service') }),
                });
            }
            if (!val.reason) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['reason'],
                    message: t('common.MSG_001', { field: t('application_accept.reason_app') }),
                });
            }
        }),
});
