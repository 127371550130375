import { toast } from '@/components/ui/use-toast';
import { CSVQueue } from '@/interfaces/common/csv';
import { downloadFile } from '@/lib/download';
import { CheckFileCSV, DownloadCsv } from '@/modules/services/report.service';
import { useQuery } from '@tanstack/react-query';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const useDownLoadCsv = (queue: CSVQueue[], removeQueue: (data: CSVQueue) => void) => {
    const { t } = useTranslation();
    const [initDown, setInitDown] = useState(0);

    const DownLoadCsv = async () => {
        const newQueue = toJS(queue);
        setInitDown(initDown + 1);
        if (newQueue.length) {
            const checkFiles = newQueue.map((item) => {
                return new Promise((resolve, reject) => {
                    if (initDown === 1) {
                        CheckFileCSV(item.url)
                            .then(async (res) => {
                                const { data } = res;
                                if (data.is_downloadable === null) setInitDown(0);
                                if (data.is_downloadable) {
                                    try {
                                        const file: any = await DownloadCsv(item.urlDownload || `${item.url}/download`);
                                        resolve(item);
                                        downloadFile({ file, fileName: item.fileName });
                                        toast({ description: t('common.MSG_039'), variant: 'success' });
                                    } catch (error) {
                                        toast({ description: t('common.MSG_040'), variant: 'destructive' });
                                        reject(new Error('error'));
                                        setInitDown(0);
                                    } finally {
                                        setInitDown(0);
                                    }
                                } else {
                                    reject(new Error('error'));
                                }
                            })
                            .catch(() => reject(new Error('error')));
                    }
                });
            });

            Promise.allSettled(checkFiles).then((result) => {
                const fulfilled = result.filter((item: any) => item.status === 'fulfilled');
                fulfilled.forEach((item: any) => removeQueue(item.value));
            });
        }
    };

    useQuery({
        queryKey: ['download_csv', queue],
        queryFn: () => {
            DownLoadCsv();
            return 'success';
        },
        refetchInterval: () => (queue.length > 0 ? 2000 : false),
        refetchIntervalInBackground: true,
    });
};

export default useDownLoadCsv;
