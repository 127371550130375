import { Params } from '@/interfaces/common/global';
import { ProgressDataSubmit, ReportReqType } from '@/interfaces/report';
import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';

export const getRequestReportList = async (params?: Params) => {
    return await apiServiceWithAuth.get(`users/report_requests`, { params });
};

export const createRequestReport = async (payload: { report_request: ReportReqType }) => {
    return await apiServiceWithAuth.post('users/report_requests', payload);
};

export const updateRequestReport = async (id: string, payload: { report_request: ReportReqType }) => {
    return await apiServiceWithAuth.patch(`/users/report_requests/${id}`, payload);
};

export const getRequestReportDetail = async (id: string) => {
    return await apiServiceWithAuth.get(`users/report_requests/${id}`);
};

export const createFileRequestReportCsv = async () => {
    return await apiServiceWithAuth.post(`users/export_csv_report_request`);
};

export const createProgressReportRequest = async (requestId: string, body: ProgressDataSubmit) => {
    return await apiServiceWithAuth.post(`users/report_requests/${requestId}/progresses`, body);
};

export const getDetailProgressReportRequest = async (requestId: string, progressId: string) => {
    return await apiServiceWithAuth.get(`users/report_requests/${requestId}/progresses/${progressId}`);
};

export const updateProgressReportRequest = async (requestId: string, progressId: string, body: ProgressDataSubmit) => {
    return await apiServiceWithAuth.patch(`users/report_requests/${requestId}/progresses/${progressId}`, body);
};

export const deleteProgressReportRequest = async (requestId: string, progressId: string) => {
    return await apiServiceWithAuth.delete(`users/report_requests/${requestId}/progresses/${progressId}`);
};

export const createFileRequestProgressCsv = async (requestId: string) => {
    return await apiServiceWithAuth.post(`users/report_requests/${requestId}/export_csv_report_request_progress`);
};

export const getRequestReportListByUser = async (userId: string, params?: Params) => {
    return await apiServiceWithAuth.get(`users/${userId}/report_requests`, { params });
};
export const getReportApplicationList = async (params?: Params & { survey_type?: string }) => {
    return await apiServiceWithAuth.get(`users/surveys`, { params });
};
export const getReportApplicationById = async (surveyId: string) => {
    return await apiServiceWithAuth.get(`users/surveys/${surveyId}`);
};
export const updateQuestionApplicationResponses = async (surveyId: string, data: any) => {
    return await apiServiceWithAuth.post(`users/surveys/${surveyId}/responses`, data);
};
