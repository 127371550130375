import { useState } from 'react';
import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { PageProps } from '@/interfaces/props/page';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import useGetResidents from '@/hooks/useGetResidents';
import { columnsResidentReport } from '@/pages/resident/ResidentColumns';
import { ResidentType } from '@/interfaces/resident';

const IPResidentReportList = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { user } = useAuthenStore();
    const [page, setPage] = useState(1);
    const { dataSource, total, isLoading } = useGetResidents({ page, userId: user.id });

    const onShowDetail = (key: string) => {
        navigate(`${key}?userId=${user.id}`);
    };

    const columnsTable = columnsResidentReport({
        t,
        columnsKey: ['report', 'visit_date', 'code', 'name', 'age', 'address', 'app_service'],
        onDetail: onShowDetail,
    });
    const onChangeTable: TableProps<ResidentType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    className="py-6"
                    routes={[{ path: '', breadcrumbName: `${t('resident.title')}${t('resident.report_list')}` }]}
                />
            }
            isDownLoadCsv={false}
        >
            <TableComponent
                loading={isLoading}
                columns={columnsTable}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(IPResidentReportList));
