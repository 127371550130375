import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import { SurveyDetailType } from '@/interfaces/survey';
import { convertDetailSurvey, convertFileNameCsv } from '@/lib/helper';
import { CreateFileAnswerCSV, GetDetailSurveyById, GetDetailSurveyResCSV } from '@/modules/services/survey.service';
import { columnsDetails } from '@/pages/survey/SurveyColumns';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useCsvStore } from '@/store/csv/CsvStore';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TableProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { SURVEY_TYPE } from '@/constants/appConstants';

type TypeProps = {
    type: 'general' | 'survey_61';
} & WithTransProps;

const SurveyDetail = (props: TypeProps) => {
    const { t, type } = props;
    const { addQueue } = useCsvStore();
    const { user } = useAuthenStore();
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const title = location.state?.title || '';

    const renderTitle = () => {
        return <div className="whitespace-break-spaces">{title}</div>;
    };

    const getSurveyList = async (
        page: number
    ): Promise<{ dataSource: SurveyDetailType[]; total: number; titleColumn: any }> => {
        if (user.id && id) {
            try {
                const response = await GetDetailSurveyResCSV(id, { page, per_page: 10 });
                const { data: detail } = await GetDetailSurveyById(id);
                const titleColumn: any = {};
                detail?.questions?.forEach((item: any, index: number) => {
                    titleColumn[`question${index + 1}`] = item.title;
                });
                const { data, meta }: any = response;
                const newData: any = convertDetailSurvey(data);
                return { dataSource: newData, total: meta.total_count, titleColumn };
            } catch (error) {
                return null;
            }
        }
        return null;
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getSurveyDetail', user, page],
        queryFn: () => getSurveyList(page),
        placeholderData: keepPreviousData,
    });

    const onDownloadCsv = async () => {
        try {
            await CreateFileAnswerCSV(id);
            addQueue({
                flag: 'answer',
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName:
                        type === SURVEY_TYPE.SURVEY_61 ? t('survey.61_survey_answer') : t('survey.question_answer'),
                    subName: title,
                }),
                url: `users/surveys/${id}/export_csv_survey_response`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onChangeTable: TableProps<SurveyDetailType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    const onBack = () => {
        navigate(
            type === SURVEY_TYPE.SURVEY_61
                ? `/welfare/surveys?survey_type=${SURVEY_TYPE.SURVEY_61}`
                : `/welfare/surveys`
        );
    };

    const columnsTable = useMemo(() => {
        return columnsDetails({
            t,
            title: data?.titleColumn || {},
            columnsKey:
                type === SURVEY_TYPE.SURVEY_61
                    ? ['user', 'submit_date', 'target_name', 'answer1', 'answer2', 'answer3']
                    : ['user', 'submit_date', 'answer1', 'answer2', 'answer3'],
        });
    }, [data?.titleColumn]);

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName:
                                type === SURVEY_TYPE.SURVEY_61
                                    ? t('survey.61_survey_answer')
                                    : t('survey.question_answer'),
                        },
                        { path: '', breadcrumbName: renderTitle() },
                    ]}
                />
            }
            isDownLoadCsv={!!data?.dataSource.length}
            onDownLoadCsv={onDownloadCsv}
            textBack={t('go_back')}
            onBack={onBack}
        >
            <TableComponent
                className="mt-2"
                loading={isLoading}
                columns={columnsTable}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(SurveyDetail));
