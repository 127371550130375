import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ApplicationCategoryForm from '@/pages/categories/application/components/ApplicationForm';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE, FORMAT_DATE_REQ, TYPE_ANSWER } from '@/constants/appConstants';
import { convertDataQuestionSurvey, handleErrorMessage, handleErrorNotFound } from '@/lib/helper';
import { GetDetailCategoryApplication, UpdateCategoryApplication } from '@/modules/services/category.service';
import { CategoryApplicationDetailType } from '@/interfaces/category';
import { QuestionsApplicationSchema } from '@/schema/applicationSchema';

const EditCategory = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const { id } = useParams();

    const formApplication = useForm<CategoryApplicationDetailType>({
        resolver: zodResolver(QuestionsApplicationSchema),
        defaultValues: {
            id: undefined,
            title: undefined,
            due_date: undefined,
            questions: [
                {
                    title: undefined,
                    question_type: 'text',
                    options: [
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                        { content: undefined },
                    ],
                },
            ],
        },
        mode: 'onChange',
        shouldFocusError: true,
    });

    const onSubmit = async (value: any) => {
        try {
            setLoading(true);
            const newData = {
                ...value,
                due_date: dayjs(value.due_date).format(FORMAT_DATE_REQ),
                questions: value.questions.map((item: any) => ({
                    ...item,
                    options:
                        item.question_type === TYPE_ANSWER.TEXT ? [] : item.options.filter((el: any) => !!el.content),
                })),
            };

            await UpdateCategoryApplication(id, { survey: newData });
            toast({
                description: t('common.MSG_017'),
                variant: 'success',
            });
            onBackMenu();
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_063',
                callback: () => onBackMenu(),
            });
            if (isNotFound) return;

            handleErrorMessage({
                error: error?.errors?.survey?.[0] || '',
                callback: (msg) => msg.includes('MSG_063') && onBackMenu(),
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };
    const onBackMenu = () => {
        navigate(`/communication/categories/application`);
    };
    const getDetailCategoryApplication = async () => {
        try {
            setLoading(true);
            const res = await GetDetailCategoryApplication(id);
            const { data }: { data: CategoryApplicationDetailType } = res;

            if (
                data.answer_count !== 0 ||
                !dayjs().startOf('day').isBefore(dayjs(data?.due_date, FORMAT_DATE).endOf('day'))
            ) {
                navigate('/not-permission');
            }
            const newData: any = convertDataQuestionSurvey(data);
            if (data) {
                formApplication.reset(newData);
            }
            return data;
        } catch (error) {
            console.log(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const { isFetching: isLoad } = useQuery({
        queryKey: ['getDetailCategoryApplication', id],
        queryFn: () => {
            if (id) return getDetailCategoryApplication();
        },
        placeholderData: keepPreviousData,
    });

    return (
        <PageComponent
            pageLoading={isLoad}
            title={
                <PageTitle routes={[{ path: '', breadcrumbName: t(`category.application_edit`) }]} className="py-6" />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        onClick={formApplication.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('update')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...formApplication}>
                <ApplicationCategoryForm />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(EditCategory));
