import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'react-use';
import withTrans from '@/components/common/hocs/withTrans';
import { Form } from '@/components/ui/form';
import { LoginReq, UserLogin } from '@/interfaces/auth';
import { PageProps } from '@/interfaces/props/page';
import { AuthService } from '@/modules/services';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { PATH } from '@/constants/path';
import { LoginSchema } from '@/schema/authSchema';
import { TOKEN_NAME } from '@/constants/appConstants';
import { toast } from '@/components/ui/use-toast';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { useAuthenStore } from '@/store/auth/AuthStore';
import AppIconSvg from '@/assets/icons/app_icon.svg';
import FormInputText from '@/components/common/form/FormInputText';
import { ALPHA_NUMERIC_REGEX } from '@/lib/regex';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { validateToken } from '@/lib/token';
import { GetProfileUser } from '@/modules/services/auth.service';
import { useEffect } from 'react';

const SignInComponent = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const { setAuthenticated, isAuthenticated } = useAuthenStore();
    const [authToken, setAuthToken] = useLocalStorage(TOKEN_NAME, null, { raw: true });

    const authenticated = validateToken(authToken) || isAuthenticated;

    const form = useForm<z.infer<typeof LoginSchema>>({
        resolver: zodResolver(LoginSchema),
        defaultValues: {
            email: undefined,
            password: undefined,
        },
        mode: 'onChange',
    });

    useEffect(() => {
        if (localStorage.getItem('authorize')) {
            toast({ description: t('common.MSG_009'), variant: 'destructive' });
            localStorage.removeItem('authorize');
        }
    }, []);

    const getProfile = async () => {
        if (authenticated) {
            try {
                await GetProfileUser();
                navigate('/');
                return true;
            } catch (error) {
                return false;
            }
        }
        return false;
    };

    useQuery({ queryKey: ['getProfile'], queryFn: getProfile });

    const onSubmit = async (req: UserLogin) => {
        try {
            setLoading(true);
            const body: LoginReq = { user: req };
            const isExistToken = localStorage.getItem(TOKEN_NAME);
            const authenticatedEmail = JSON.parse(localStorage.getItem('AuthenStore'))?.user?.email;
            let response: AxiosResponse<any> = null;
            if (!isExistToken || authenticatedEmail !== req.email) {
                response = await AuthService.Login(body);
            }
            setAuthToken(!isExistToken || authenticatedEmail !== req.email ? response?.data?.token : isExistToken);
            setAuthenticated(true);
            toast({ description: t('common.MSG_006'), variant: 'success' });
            navigate(PATH.HOME);
        } catch (error) {
            const message = error?.error?.message;
            if (message?.includes('MSG')) {
                toast({
                    description: t(message.includes('common') ? message : `common.${message}`),
                    variant: 'destructive',
                });
                return;
            }
            toast({ description: t('common.MSG_004'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    const handleFogotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="flex h-screen flex-col items-center justify-center gap-6 sm:gap-9 w-[464px] m-auto whitespace-pre-line p-4">
            <img src={AppIconSvg} className="w-[112px] sm:w-[155px]" alt="forgot password" />
            <div className="text-title font-bold mb-9 text-center">{t('app_title')}</div>
            <Form {...form}>
                <div className="flex flex-col gap-y-8 w-full ">
                    <FormInputText
                        className="sm:flex-wrap sm:gap-0"
                        name="email"
                        label={t('email')}
                        maxLength={255}
                        size="xxl"
                        required
                        placeholder="email@example.com"
                    />
                    <FormInputText
                        regex={ALPHA_NUMERIC_REGEX}
                        className="sm:flex-wrap sm:gap-0"
                        type="password"
                        name="password"
                        label={t('password')}
                        maxLength={20}
                        size="xxl"
                        required
                        placeholder={t('placehoder_password')}
                    />
                    <Button disabled={isLoading} className="mt-3" size="xxl" onClick={form.handleSubmit(onSubmit)}>
                        {t('login')}
                    </Button>
                    <div className="inline-flex gap-x-1 justify-center text-pc">
                        <span>{t('forgot_password')}</span>
                        <button
                            className="underline decoration-blue-400 text-blue-400 cursor-pointer font-bold"
                            onClick={handleFogotPassword}
                        >
                            {t('forgot_link')}
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default withTrans(observer(SignInComponent));
