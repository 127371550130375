import { observer } from 'mobx-react-lite';
import { FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import FormSelect from '@/components/common/form/FormSelect';
import { useTranslation } from 'react-i18next';
import FormUploadImage from '@/components/common/form/FormUpload';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { useEffect, useRef } from 'react';
import CommonMap from '@/components/common/form/CommonMap';
import { useGetPhrasesOptions } from '@/hooks/useGetPhrases';
import useGetUsers from '@/hooks/useGetUsers';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import { useGetCategoryOptions, useGetCategoryChildOptions } from '@/hooks/useGetCategories';
import { CategoriesItem, SelectOptionsItem } from '@/interfaces/common/global';
import { ATTACHMENT_TYPES, CATEGORIES, REPORT_ROUTES } from '@/constants/appConstants';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SelectComponent } from '@/components/common/form/components/SelectComponent';
import { convertOptions } from '@/lib/helper';
import { IUploadResp } from '@/interfaces/common/upload';

type Props = {
    type?: 'created' | 'edit';
    categoryType?: CategoriesItem;
    defaultCategory?: SelectOptionsItem;
    defaultCategoryChild?: SelectOptionsItem;
    defaultAssignee?: SelectOptionsItem;
};

const ReportForm = ({
    type,
    defaultCategory,
    defaultAssignee,
    defaultCategoryChild,
    categoryType = 'CONTACT',
}: Props) => {
    const { t } = useTranslation();
    const selectRef = useRef(null);
    const phrases = useGetPhrasesOptions({ type: 'communication' });

    const usersData = useGetUsers({
        role:
            categoryType === 'CONTACT'
                ? APP_PERMISSION.MAYOR
                : `${APP_PERMISSION.MAYOR},${APP_PERMISSION.REPRESENTATIVE}`,
    });

    const ctype = REPORT_ROUTES[categoryType];

    const { clearErrors, setValue, getValues, control, watch } = useFormContext();

    const { fields } = useFieldArray({
        control,
        name: 'attachments',
    });

    // useEffect(() => {
    //     const subscription = watch((value, { name: fieldName }) => {
    //         if (fieldName?.includes('file') && fieldName?.includes('attachments')) {
    //             const isError = !value.attachments.some((item: any) => !!item.file);
    //             isError
    //                 ? setError('attachments', {
    //                       message: t('common.MSG_061'),
    //                   })
    //                 : clearErrors('attachments');
    //         }
    //         if (fieldName?.includes('category_child_id')) {
    //             const isValue = value.category_child_id;
    //             !isValue && clearErrors('category_child_id');
    //         }
    //     });
    //     return () => subscription.unsubscribe();
    // }, [watch]);

    const categories = useGetCategoryOptions({ type: CATEGORIES[categoryType], orderBy: 'created_at desc' });

    const categoryId = watch('category_id');

    const child = useGetCategoryChildOptions({
        categoryType: CATEGORIES[categoryType],
        categoryId,
        isByUser: true,
    });

    useEffect(() => {
        setValue('isValidChild', child.mountRef.current);
    }, [child.mountRef.current]);

    const onChangeSelect = (value: SelectOptionsItem) => {
        const reason = `${getValues('content') || ''}${value.label as string}`;
        setValue('content', reason.slice(0, 255));
        clearErrors('content');
        selectRef.current.onClearValue();
    };

    const handleUpload = async (fileUploaded: IUploadResp, name: string) => {
        try {
            setValue(name, [fileUploaded]);
            return [fileUploaded];
        } catch (error) {
            return false;
        }
    };

    const handleClearFile = (name: string) => {
        try {
            setValue(name, undefined);
            return undefined;
        } catch (error) {
            return false;
        }
    };

    const onChangeParentCategory = () => {
        child.mountRef.current = false;
        setValue('category_child_id', null);
    };

    return (
        <div className="md:grid grid-cols-1 xl:grid-cols-2 gap-x-6 gap-y-4 mt-6 2xl:gap-x-[120px]">
            <div className="flex flex-col gap-4 ">
                <FormSelect
                    disabled={type === 'edit'}
                    name="assignee_id"
                    required={true}
                    placeholder={t('common.MSG_002', { field: t('report.name') })}
                    options={convertOptions({ options: usersData.options || [], defaultOption: defaultAssignee })}
                    labelClass="min-w-[200px] xl:min-w-[170px]"
                    label={t('report.name')}
                    handleSearch={usersData.onHandleSearch}
                    setScrollOption={usersData.setScrollOption}
                    isLoading={usersData.isLoading}
                    className="sm:flex-wrap md:flex-nowrap"
                />
                <FormSelect
                    name="category_id"
                    required={true}
                    placeholder={t('common.MSG_002', { field: t('category.parent_category') })}
                    options={convertOptions({ options: categories.options || [], defaultOption: defaultCategory })}
                    handleSearch={categories.onHandleSearch}
                    setScrollOption={categories.setScrollOption}
                    isLoading={categories.isLoading}
                    onChangeSelect={onChangeParentCategory}
                    labelClass="min-w-[200px] xl:min-w-[170px]"
                    label={t('category.parent_category')}
                    className="sm:flex-wrap md:flex-nowrap"
                />
                {child.mountRef.current && (
                    <FormSelect
                        name="category_child_id"
                        required={true}
                        placeholder={t('common.MSG_002', { field: t('report.category_medium') })}
                        options={convertOptions({
                            options: child.options || [],
                            defaultOption: getValues('category_child_id') ? defaultCategoryChild : null,
                        })}
                        handleSearch={child.onHandleSearch}
                        setScrollOption={child.setScrollOption}
                        isLoading={child.isLoading}
                        labelClass="min-w-[200px] xl:min-w-[170px]"
                        label={t('report.category_medium')}
                        className="sm:flex-wrap md:flex-nowrap"
                    />
                )}
                <CommonMap
                    name={'google_map'}
                    label={t('report.address1')}
                    labelClass="min-w-[200px] xl:min-w-[170px]"
                    className="sm:flex-wrap md:flex-nowrap"
                    required={false}
                />
            </div>
            <div className="flex flex-col gap-4">
                <FormField
                    name="attachments"
                    control={control}
                    render={({ fieldState: { error } }) => (
                        <FormItem className="flex items-start flex-wrap md:flex-nowrap gap-4 h-fit mt-2 space-x-2">
                            <FormLabel className="text-pc mt-2 min-w-[200px] xl:min-w-[170px]">
                                {t('report.image')}
                            </FormLabel>
                            <div className="w-full">
                                <div className="flex flex-wrap gap-3">
                                    {fields.map((item, index) => (
                                        <FormUploadImage
                                            handleUpload={(event: any) =>
                                                handleUpload(event, `attachments.${index}.file`)
                                            }
                                            hasError={!!error}
                                            key={item.id}
                                            name={`attachments.${index}.file`}
                                            showPopupDelete={false}
                                            attachmentType={ATTACHMENT_TYPES[categoryType]}
                                            attachmentIndex={index}
                                            handleClearFile={() => {
                                                handleClearFile(`attachments.${index}.file`);
                                            }}
                                        />
                                    ))}
                                </div>
                                <FormMessage className="text-error col-span-2" />
                            </div>
                        </FormItem>
                    )}
                />
                <div>
                    <FormItem className="flex flex-wrap md:flex-nowrap items-center md:gap-6">
                        <FormLabel
                            className={'text-pc flex min-w-[200px] xl:min-w-[170px] items-center '}
                            required={true}
                        >
                            {t(`report.${ctype}_report_content`)}
                        </FormLabel>
                        <div className="w-full">
                            <SelectComponent
                                handleSearch={phrases.onHandleSearch}
                                setScrollOption={phrases.setScrollOption}
                                ref={selectRef}
                                label={t('application_accept.select_phrase')}
                                options={phrases.options}
                                onChangeSelect={onChangeSelect}
                                hasGetDataOptions={true}
                                showValue={false}
                            />
                        </div>
                    </FormItem>
                    <FormInputTextarea
                        className="md:ml-[224px] xl:ml-[194px] mt-4"
                        rows={5}
                        required={false}
                        name={'content'}
                        placeholder={t('common.MSG_001', { field: t(`report.${ctype}_report_content`) })}
                        maxLength={255}
                    />
                </div>
            </div>
        </div>
    );
};
export default observer(ReportForm);
