import TableComponent from '@/components/common/table/TableComponent';
import { Label } from '@/components/ui/label';
import { STATUS_APPLICATION_ACCEPTED } from '@/constants/appConstants';
import { ProgessDataType } from '@/interfaces/application';
import { processColumns } from '@/pages/application/ApplicationColumns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type ProgressDetailProps = {
    status: string;
    titleColumns?: string;
    priority: string;
    isShowPriority?: boolean;
    isLoading?: boolean;
    progress: ProgessDataType[];
};

const ProgressDetail = ({
    status,
    priority,
    progress,
    isLoading,
    titleColumns,
    isShowPriority = true,
}: ProgressDetailProps) => {
    const { t } = useTranslation();

    const columnsTable = processColumns({
        t,
        columnsKey: ['latest_updated_at', 'creator', 'description', 'status'],
        showFull: true,
        titleColumns,
    });

    return (
        <div className="mt-4 progress-detail">
            {status !== STATUS_APPLICATION_ACCEPTED.PENDING && isShowPriority && (
                <Label className="text-pc font-medium">
                    {t('application_accept.urgency')}
                    {priority && (
                        <span className="ml-6 p-2 border rounded-lg inline-block text-center border-grey min-w-[100px]">
                            {t(priority)}
                        </span>
                    )}
                </Label>
            )}
            <TableComponent
                className="mt-6"
                loading={isLoading}
                columns={columnsTable}
                dataSource={progress || []}
                pagination={false}
            />
        </div>
    );
};

export default observer(ProgressDetail);
