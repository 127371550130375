import { PAGE_SIZE } from '@/constants/appConstants';
import { PhrasesType } from '@/interfaces/phrases';
import { CategoryService } from '@/modules/services';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { ScrollOptions } from '@/interfaces/resident';
import { debounce } from 'lodash';
import { PhrasesComponentType, Result, SelectOptionsItem } from '@/interfaces/common/global';

export const useGetPhrasesOptions = ({
    page = 1,
    perPage = PAGE_SIZE,
    type,
}: {
    type: PhrasesComponentType;
    page?: number;
    perPage?: number;
}) => {
    const [results, setResults] = useState<Result>({ options: [], total: 0 });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [scrollOption, setScrollOption] = useState<ScrollOptions>({
        page: page,
        total: 0,
        perPage: perPage,
    });

    const onHandleSearch = debounce((value: string) => {
        setSearchTerm(value);
        setScrollOption((prev) => ({
            ...prev,
            page: page,
            perPage: perPage,
        }));
    }, 500);

    const getListPhrasesOptions = async () => {
        try {
            const { data, meta }: any = await CategoryService.getListPhrases(type, {
                per_page: scrollOption.perPage,
                page: scrollOption.page,
                ...(searchTerm && { content: searchTerm }),
            });
            const dataResponse = data.map((item: any) => ({
                value: item.id,
                label: item.content,
            })) as SelectOptionsItem[];

            setScrollOption((prev) => ({ ...prev, total: meta.total_count }));
            setResults((prev) => {
                return {
                    ...prev,
                    options: scrollOption.page === 1 ? dataResponse : [...prev.options, ...dataResponse],
                };
            });
            return true;
        } catch (error) {
            setResults({
                total: 0,
                options: [],
            });

            return false;
        }
    };

    const { isFetching: isLoading } = useQuery({
        queryKey: ['getPhraseOptions', searchTerm, scrollOption.page],
        queryFn: () => getListPhrasesOptions(),
    });

    return {
        options: results.options,
        total: results.total,
        onHandleSearch,
        setScrollOption,
        isLoading,
    };
};

const useGetPhrases = ({
    userId,
    type,
    page = 1,
    perPage = PAGE_SIZE,
}: {
    type: PhrasesComponentType;
    page?: number;
    perPage?: number;
    userId?: string;
}) => {
    const getPhrasesList = async (page: number): Promise<{ dataSource: PhrasesType[]; total: number }> => {
        if (!userId) return { dataSource: [], total: 0 };

        try {
            const res = await CategoryService.getListPhrases(type, { page, per_page: perPage });
            const { data, meta }: any = res;
            return {
                dataSource: data.map((item: PhrasesType) => ({ ...item, key: item.id })),
                total: meta.total_count,
            };
        } catch (error) {
            return { dataSource: [], total: 0 };
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getPhrasesList', page],
        queryFn: () => getPhrasesList(page),
    });

    const tableMeta = useMemo(() => {
        return {
            isDownLoadCsv: !!data?.dataSource?.length,
            dataTable: data?.dataSource || [],
            records: data?.total || 0,
        };
    }, [data]);

    return {
        dataSource: tableMeta.dataTable,
        total: tableMeta.records,
        isDownLoadCsv: tableMeta.isDownLoadCsv,
        isLoading,
    };
};

export default useGetPhrases;
