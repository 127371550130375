import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { PhrasesType } from '@/interfaces/phrases';
import { TFunction } from 'react-i18next';
import TooltipComponent from '@/components/common/pages/ToolTip';

type ColumnsProps = {
    t: TFunction;
    onEdit?: (key: string) => void;
    onDelete?: (key: string) => void;
    columnsKey?: string[];
    showFull?: boolean;
};

export const columns = ({
    t,
    onEdit,
    columnsKey,
    onDelete,
    showFull,
}: ColumnsProps): TableProps<PhrasesType>['columns'] => {
    const allColumns: TableProps<PhrasesType>['columns'] = [
        {
            title: <div className="text-center">{t('fixed_phrases')}</div>,
            dataIndex: 'content',
            key: 'content',
            width: 500,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 150,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="update"
                            className="bg-green-500 border-grey mr-2"
                            onClick={() => {
                                onEdit?.(record.id);
                            }}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            variant="delete"
                            className="bg-red-500 border-grey"
                            onClick={() => {
                                onDelete?.(record.id);
                            }}
                        >
                            {t('delete')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
