'use client';

import * as React from 'react';
import * as PortalPrimitive from '@radix-ui/react-portal';

import { cn } from '@/lib/utils';

const Portal = React.forwardRef<
    React.ElementRef<typeof PortalPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof PortalPrimitive.Root>
>(({ className, ...props }, ref) => (
    <PortalPrimitive.Root
        ref={ref}
        className={cn(
            'flex items-center justify-center fixed w-screen h-screen top-0 left-0 right-0 bottom-0 bg-loadingbg z-[1000]',
            className
        )}
        {...props}
    />
));

Portal.displayName = PortalPrimitive.Root.displayName;

export { Portal };
