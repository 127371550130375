import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { ApplicationType } from '@/interfaces/report';
import dayjs from '@/lib/dayjs';
import { ColumnsProps } from '@/interfaces/common/global';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { FORMAT_DATE } from '@/constants/appConstants';

export const applicationColumns = ({
    t,
    columnsKey,
    onDetail,
    showFull,
}: ColumnsProps<ApplicationType>): TableProps<ApplicationType>['columns'] => {
    const allColumns: TableProps<ApplicationType>['columns'] = [
        {
            title: t('detail'),
            dataIndex: 'detail',
            key: 'detail',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return (
                    <Button
                        variant="default"
                        className="m-1"
                        onClick={() => {
                            onDetail?.(record);
                        }}
                    >
                        {t('detail')}
                    </Button>
                );
            },
        },
        {
            title: t('report.application.numberOfRespondents'),
            dataIndex: 'numberOfRespondents',
            key: 'numberOfRespondents',
            width: 70,
            align: 'center',
            render: (_, record) => {
                return <span>{record.answer_count + '/' + record.max_answer_number}</span>;
            },
        },
        {
            title: t('report.application.replyDeadline'),
            dataIndex: 'replyDeadline',
            key: 'replyDeadline',
            align: 'center',
            width: 70,
            render: (_, record) => {
                return <span>{dayjs(record.due_date).format(FORMAT_DATE)}</span>;
            },
        },
        {
            title: t('report.application.responseRequestDate'),
            dataIndex: 'responseRequestDate',
            key: 'responseRequestDate',
            width: 80,
            render: (_, record) => {
                return <span>{dayjs(record.answer_request_date).format(FORMAT_DATE)}</span>;
            },
        },
        {
            title: t('report.application.title'),
            dataIndex: 'title',
            key: 'title',
            width: 150,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
