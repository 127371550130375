// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-upload-preview .ant-upload-list .ant-upload-select,
.img-upload-preview .ant-upload-list .ant-upload-list-item-container {
    width: 100% !important;
    height: 200px !important;
}

.img-upload-preview .ant-upload-list-picture-card {
    display: flex;
    justify-content: center;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/form/ImageUploadPreviewStyle.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;IACtB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".img-upload-preview .ant-upload-list .ant-upload-select,\n.img-upload-preview .ant-upload-list .ant-upload-list-item-container {\n    width: 100% !important;\n    height: 200px !important;\n}\n\n.img-upload-preview .ant-upload-list-picture-card {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
