import FormInputText from '@/components/common/form/FormInputText';
import ModalComponent from '@/components/common/pages/ModalComponent';
import { Form } from '@/components/ui/form';
import { toast } from '@/components/ui/use-toast';
import { GetDetailSurveyById, UpdateParticipant } from '@/modules/services/survey.service';
import { ParticipantSchema } from '@/schema/surveySchema';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useMaskito } from '@maskito/react';
import { useEffect } from 'react';
import { handleErrorMessage, handleErrorNotFound } from '@/lib/helper';

type Props = {
    visible: boolean;
    surveyId: string;
    onCancel?: () => void;
    refreshData?: () => void;
};

const ParticipantForm = ({ visible, surveyId, onCancel, refreshData }: Props) => {
    const { t } = useTranslation();
    const { isLoading, setLoading } = useGlobalStore();

    const inputNumberRef = useMaskito({ options: { mask: [/[1-9]/, /\d/, /\d/] } });

    const form = useForm<z.infer<typeof ParticipantSchema>>({
        resolver: zodResolver(ParticipantSchema),
        defaultValues: {
            required_responses: undefined,
        },
        mode: 'all',
        shouldFocusError: true,
    });

    const onSubmit = async (data: z.infer<typeof ParticipantSchema>) => {
        try {
            setLoading(false);
            await UpdateParticipant(surveyId, {
                participation_status: { required_responses: Number(data.required_responses) },
            });
            toast({
                description: t('common.MSG_015'),
                variant: 'success',
            });
            onHandleCancel();
            refreshData?.();
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_063',
                callback: () => {
                    onHandleCancel();
                    refreshData?.();
                },
            });
            if (isNotFound) return;

            handleErrorMessage({
                error: error?.errors?.required_responses?.[0] || '',
                callback: () => {
                    onHandleCancel();
                    refreshData?.();
                },
                defaultMessage: 'common.MSG_038',
            });
        }
    };

    const onHandleCancel = () => {
        form.reset();
        onCancel?.();
    };

    const renderContent = () => {
        return (
            <Form {...form}>
                <FormInputText
                    ref={inputNumberRef}
                    name="required_responses"
                    className="flex-col items-start sm:gap-0 "
                    labelClass="min-w-[170px] lg:h-[40px] md:h-[40px]"
                    label={t('survey.person_number')}
                    placeholder={t('common.MSG_001', { field: t('survey.person_number') })}
                    maxLength={3}
                />
            </Form>
        );
    };

    const getInitData = async () => {
        try {
            setLoading(true);
            const { data } = await GetDetailSurveyById(surveyId);
            if (data) {
                form.setValue(
                    'required_responses',
                    data?.max_answer_number ? data.max_answer_number.toString() : undefined
                );
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (visible && surveyId) {
            getInitData();
        }
    }, [visible, surveyId]);

    return (
        <div>
            {visible && (
                <ModalComponent
                    wrapClass="xl:max-w-[40%]"
                    visible={visible}
                    title={t('survey.person_setting')}
                    okText={t('survey.save')}
                    cancelText={t('cancel')}
                    content={renderContent()}
                    onCancel={onHandleCancel}
                    loadingBtn={isLoading}
                    onOk={form.handleSubmit(onSubmit)}
                />
            )}
        </div>
    );
};

export default observer(ParticipantForm);
