import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuthenStore } from '@/store/auth/AuthStore';

type ProtectedRouterProps = {
    element: JSX.Element;
    roles: string[];
};

const ProtectedRoute = ({ element, roles }: ProtectedRouterProps) => {
    const { user } = useAuthenStore();

    const hasRole = roles && user?.roles && user.roles.some((role) => roles.includes(role));

    if (!hasRole) {
        return <Navigate to="/not-permission" replace={true} state={{ from: window.location.pathname }} />;
    }

    return element;
};

export default observer(ProtectedRoute);
