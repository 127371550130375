'use client';

import { Portal } from '@/components/ui/portal';
import { SpinIcon } from '@/components/common/svg';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '@/store/global/GlobalStore';

export const LoadingComponent = () => (
    <Portal>
        <SpinIcon className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-foreground" />
    </Portal>
);

export default observer(function Loading() {
    const { isLoading } = useGlobalStore();

    return <>{isLoading && <LoadingComponent />}</>;
});
