import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { SelectComponent } from '@/components/common/form/components/SelectComponent';
import { cn } from '@/lib/utils';
import { SelectOptionsItem } from '@/interfaces/common/global';
import { Dispatch, SetStateAction } from 'react';
import { ScrollOptions } from '@/interfaces/resident';

type Props = {
    name: string;
    placeholder?: string;
    className?: string;
    selectClass?: string;
    labelClass?: string;
    required?: boolean;
    disabled?: boolean;
    hasSearch?: boolean;
    extra?: React.ReactNode;
    label?: React.ReactNode;
    setScrollOption?: Dispatch<SetStateAction<ScrollOptions>>;
    handleSearch?: (value: string) => void;
    isLoading?: boolean;
    options?: SelectOptionsItem[];
    onChangeSelect?: any;
};

const FormSelect = ({
    name,
    placeholder,
    hasSearch,
    label,
    extra,
    className,
    selectClass,
    labelClass,
    options,
    setScrollOption,
    handleSearch,
    isLoading,
    disabled = false,
    required = true,
    onChangeSelect,
}: Props) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            render={({ field, fieldState }) => (
                <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-6', className)}>
                    {label && (
                        <FormLabel
                            className={cn('text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px]', labelClass)}
                            required={required}
                        >
                            {label}
                        </FormLabel>
                    )}
                    <div className="w-full">
                        <FormControl>
                            <SelectComponent
                                isLoad={isLoading}
                                className={cn('h-[50px] placeholder:truncate', selectClass)}
                                disabled={disabled}
                                defaultValue={field.value}
                                onChangeSelect={(value) => {
                                    field.onChange(value);
                                    if (onChangeSelect) {
                                        onChangeSelect(value);
                                    }
                                }}
                                label={placeholder}
                                options={options}
                                hasError={!!fieldState.invalid}
                                hasSearch={hasSearch}
                                setScrollOption={setScrollOption}
                                handleSearch={handleSearch}
                            />
                        </FormControl>
                        <FormMessage className="text-error" />
                    </div>
                    {extra}
                </FormItem>
            )}
        />
    );
};

export default FormSelect;
