import { PropsWithChildren } from 'react';
import { cn } from '@/lib/utils';
import PageFooter from '@/components/common/pages/PageFooter';
import { LoadingComponent } from '@/components/ui/loading';

type PageComponentProps = PropsWithChildren<{
    title?: React.ReactNode;
    className?: string;
    isDownLoadCsv?: boolean;
    isShowFooter?: boolean;
    pageFooter?: React.ReactNode;
    onDownLoadCsv?: () => void;
    textBack?: string;
    onBack?: () => void;
    pageLoading?: boolean;
}>;

export default function PageComponent({
    children,
    title,
    className,
    isDownLoadCsv,
    pageFooter,
    onDownLoadCsv,
    textBack,
    onBack,
    pageLoading,
    isShowFooter = true,
}: PageComponentProps) {
    return (
        <>
            <div className={cn('pt-4 pb-8 lg:px-2 xl:px-16', className)}>
                {title}
                {children}
                {isShowFooter &&
                    (pageFooter ?? (
                        <PageFooter
                            isDownLoadCsv={isDownLoadCsv}
                            onDownLoadCsv={onDownLoadCsv}
                            textBack={textBack}
                            onBack={onBack}
                        />
                    ))}
            </div>
            {pageLoading && <LoadingComponent />}
        </>
    );
}
