import { searchLocation } from '@/modules/services/application.report.service';
import { useFormContext } from 'react-hook-form';
import { GOOGLE_MAPS_CONFIG } from '@/constants/appConstants';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import _ from 'lodash';

const defaultCurrent = { lat: 36.2112794, lng: 139.5342841 };

const LocationInfo = (props: any) => {
    const { location, loadedMap, setLocation, setLoadedMap, showPopupOverrideMap, setShowPopupOverrideMap, ...rest } =
        props;
    const { id } = useParams();
    const { t } = useTranslation();
    const { setValue, getValues } = useFormContext();
    const mapRef = useRef<any>(null);
    const [center, setCenter] = useState<google.maps.LatLngLiteral>();

    const { isLoaded } = useJsApiLoader({
        ...GOOGLE_MAPS_CONFIG,
        libraries: ['places'],
    });

    const setFormData = async (data: { lat: number | null; lng: number | null; address: string }) => {
        const { lat, lng, address } = data;
        setValue('latitude', lat);
        setValue('longitude', lng);
        setValue('address', address);
    };

    const onLoad = async () => {
        try {
            if (!loadedMap) {
                let confirmResult: boolean;
                if (id && !showPopupOverrideMap) {
                    confirmResult = (await showModalConfirm({
                        message: t('common.MSG_062'),
                        type: 'default',
                    })) as boolean;
                    setShowPopupOverrideMap(confirmResult ? 'override' : 'none');
                }
                if (confirmResult || !id) {
                    const initLocation: google.maps.LatLngLiteral = await getCurrentLocation();
                    if (initLocation) {
                        const address = await getAddress(initLocation.lat, initLocation.lng);
                        setFormData({ ...initLocation, address: address });
                    } else {
                        setFormData({ lat: null, lng: null, address: '' });
                    }
                    setLocation(initLocation ? initLocation : { lat: null, lng: null });
                }
                setLoadedMap(true);
            }
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    const getAddress = async (latitude: number, longitude: number): Promise<string> => {
        try {
            if (!latitude || !longitude) throw new Error('invalid params');
            const {
                data: { results },
            } = await searchLocation(latitude, longitude);
            return results[0]?.formatted_address ?? '';
        } catch (error) {
            return '';
        }
    };

    const getCurrentLocation = async () => {
        try {
            const position = await new Promise<any>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: 1500 });
            });
            const { longitude, latitude } = position.coords;
            return { lng: longitude, lat: latitude };
        } catch (error) {
            return undefined;
        }
    };
    const onUnmount = () => {
        setCenter(undefined);
        mapRef.current = null;
    };

    const calculatorCenter = () => {
        const { lat, lng } = center;
        return lat && lng ? center : defaultCurrent;
    };

    useEffect(() => {
        if (loadedMap) {
            // eslint-disable-next-line no-empty
            if (id) {
                if (showPopupOverrideMap === 'override') {
                    setCenter(_.cloneDeep(location));
                }
                if (showPopupOverrideMap === 'none') {
                    setCenter(_.cloneDeep({ lat: getValues('latitude'), lng: getValues('longitude') }));
                }
            } else {
                setCenter(_.cloneDeep(location));
            }
        } else if (!loadedMap && !center && showPopupOverrideMap === 'override' && id) {
            setCenter(_.cloneDeep(defaultCurrent));
            setFormData({ lat: null, lng: null, address: '' });
            setLocation(defaultCurrent);
        }
    }, [location, id, loadedMap, showPopupOverrideMap]);

    return (
        <div className="flex flex-col justify-center items-center py-8 px-12 w-full">
            <div className={`flex items-center rounded-md overflow-hidden`} ref={mapRef}>
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={{ width: '600px', height: '500px' }}
                        center={center || defaultCurrent}
                        zoom={18}
                        onClick={false}
                        onLoad={onLoad}
                        options={{ scrollwheel: false }}
                        onUnmount={onUnmount}
                        {...rest}
                        children={center && <Marker position={calculatorCenter()} />}
                    />
                )}
            </div>
        </div>
    );
};
export default LocationInfo;
