import qs from 'qs';
import { Location } from 'history';

export function parseQueryParams(location: Location) {
    if (location?.search) {
        const tmp = qs.parse(location.search.slice(1));
        const query: any = {};

        Object.keys(tmp).forEach((key) => {
            if (tmp[key] !== undefined) {
                query[key] = tmp[key];
            }
        });
        return query;
    }
    return {};
}
export function stringifyQueryParams(query: any) {
    if (typeof query === 'object') {
        return `?${qs.stringify(query)}`;
    }
    return '?';
}
