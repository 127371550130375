import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { useNavigate, useParams } from 'react-router';
import dayjs from '@/lib/dayjs';
import {
    createCsvMeetingAttendance,
    getMeetingDetail,
    getMeetingDetailAttendances,
} from '@/modules/services/meeting.service';
import { FORMAT_DATE, FORMAT_TIME } from '@/constants/appConstants';
import { useQuery } from '@tanstack/react-query';
import { Item, renderText } from '@/components/common/pages/Element';
import TableComponent from '@/components/common/table/TableComponent';
import { attendanceColumns } from './Columns';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { convertFileNameCsv } from '@/lib/helper';
import { useCsvStore } from '@/store/csv/CsvStore';

const DetailMeeting = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { addQueue } = useCsvStore();

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDetailMeeting'],
        queryFn: () => {
            if (id) return getDetailMeetingById(id);
        },
    });

    const getDetailMeetingById = async (id: string) => {
        try {
            const response = await getMeetingDetail(id);
            const { data: attandances }: any = await getMeetingDetailAttendances(id);
            const { data }: any = response;
            return {
                attendances: attandances.map((item: any) => ({ ...item, key: item.id })),
                meeting_date: data.meeting_date ? dayjs(data.meeting_date).format(FORMAT_DATE) : '',
                name: data.name,
                start_time: dayjs(data.start_time)?.format(FORMAT_TIME) || '',
                end_time: data.end_time ? dayjs(data.end_time)?.format(FORMAT_TIME) : '',
                content: data.content || '',
            };
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const onBackMenu = () => {
        navigate(`/communication/meeting`);
    };

    const onDownloadCsv = async () => {
        try {
            await createCsvMeetingAttendance(id);
            addQueue({
                flag: 'meeting_detail',
                status: 'pending',
                url: `meetings/${id}/export_csv_attendance`,
                fileName: convertFileNameCsv({
                    prefixName: t('meeting.detail_meeting'),
                }),
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    return (
        <PageComponent
            pageLoading={isLoading}
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('meeting.detail_meeting') }]} />}
            isDownLoadCsv={!!data?.attendances?.length}
            onDownLoadCsv={onDownloadCsv}
            textBack={t('go_back')}
            onBack={onBackMenu}
        >
            <div className="flex flex-col gap-4 mt-6 ">
                <Item label={t('meeting.attendance.meeting_name')} className="whitespace-break-spaces gap-3">
                    {renderText(data?.name)}
                </Item>
                <Item label={t('meeting.form_meeting.date')} className="whitespace-break-spaces gap-3">
                    {renderText(data?.meeting_date)}
                </Item>
                <Item label={t('meeting.form_meeting.time')} className="whitespace-break-spaces items-center gap-3">
                    <div className="flex items-center gap-x-4 w-full">
                        <Item
                            label={t('meeting.form_meeting.start')}
                            labelClass="min-w-[50px] xl:min-w-[50px]"
                            className="flex-nowrap"
                        >
                            {renderText(data?.start_time)}
                        </Item>
                        {data?.end_time && (
                            <>
                                <div className="text-[34px] h-[32px] flex items-center">~</div>
                                <Item
                                    label={t('meeting.form_meeting.end')}
                                    labelClass="min-w-[50px] xl:min-w-[50px]"
                                    className="flex-nowrap"
                                >
                                    {renderText(data?.end_time)}
                                </Item>
                            </>
                        )}
                    </div>
                </Item>
                <Item label={t('meeting.form_meeting.meeting_content')} className="whitespace-break-spaces gap-3">
                    <Textarea
                        value={data?.content || ''}
                        readOnly={true}
                        rows={5}
                        className="text-pc disabled:cursor-text"
                    />
                </Item>
                <Item label={t('meeting.attendance.attendees')} className="gap-3 mt-4">
                    <TableComponent
                        className="w-full"
                        loading={isLoading}
                        columns={attendanceColumns({ t })}
                        dataSource={data?.attendances || []}
                        pagination={false}
                    />
                </Item>
            </div>
        </PageComponent>
    );
};
export default withTrans(observer(DetailMeeting));
