import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export interface WithTransProps {
    t: TFunction;
}

function withTrans<T>(WrappedComponent: React.ComponentType<T>) {
    return (props: Omit<T, 'i18n' | 't'>) => {
        const { t, i18n } = useTranslation();
        return <WrappedComponent {...(props as T & WithTransProps)} i18n={i18n} t={t} />;
    };
}

export default withTrans;
