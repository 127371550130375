import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import PageComponent from '@/components/common/pages/PageComponent';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@/components/ui/use-toast';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { columnsTableAccount } from '@/pages/account/form/AccountListColumns';
import { AccountDataType, User } from '@/interfaces/account';
import { DeleteUser, GetListUsers, RestoreUser } from '@/modules/services/user.service';
import { TableProps } from 'antd';
import { ResidentType } from '@/interfaces/resident';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { useGlobalStore } from '@/store/global/GlobalStore';
import TableBar from '@/components/common/table/TableBar';
import { APIResponse } from '@/interfaces/common/response';
import { ERROR_RESPONSE } from '@/constants/appConstants';
import { handleErrorNotFound } from '@/lib/helper';

const AccountList = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const { user } = useAuthenStore();
    const [page, setPage] = useState(1);
    const { setLoading } = useGlobalStore();
    const queryClient = useQueryClient();

    const { data, isFetching: isLoad } = useQuery({
        queryKey: ['getUserList', page],
        queryFn: () => getUserList(page),
    });

    const getUserList = async (page: number): Promise<{ dataSource: AccountDataType[]; total: number }> => {
        if (user) {
            try {
                setLoading(true);
                const response = await GetListUsers<APIResponse<User[]>>({
                    role: '',
                    page,
                    per_page: 10,
                });
                const { data, meta }: any = response;
                const dataDone = data.filter((e: AccountDataType) => e.id !== user.id);
                const newData = dataDone.map((item: AccountDataType, index: number) => {
                    let account_no = meta.total_count - index - (page - 1) * 10;
                    if (dataDone.length === 9) account_no -= 1;
                    return {
                        key: item.id,
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        account_no: account_no.toString().padStart(4, '0'),
                        communication: item.roles.communication[0],
                        welfare: item.roles.welfare[0],
                        was_deleted: item.was_deleted,
                        district: item.district || '',
                    };
                });
                return { dataSource: newData, total: meta.total_count };
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };
    const onBack = () => {
        navigate(`/`);
    };

    const onEdit = (key: string) => {
        const item = data.dataSource.find((el: AccountDataType) => {
            return el.id === key;
        });
        navigate(`/accounts/update/${key}`, { state: item });
    };

    const onRefreshData = () => {
        queryClient.refetchQueries({ queryKey: ['getUserList', page] });
    };

    const onDelete = async (key: string) => {
        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
                textOk: t('delete'),
                textCancel: t('cancel'),
            });
            if (res) {
                setLoading(true);
                await DeleteUser(key);
                onRefreshData();
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_069',
                defaultMessage: 'common.MSG_041',
                callback: () => onRefreshData(),
            });
        } finally {
            setLoading(false);
        }
    };

    const onCreate = () => {
        navigate(`/accounts/create`);
    };

    const onRestore = async (key: string) => {
        try {
            const res = await showModalConfirm({
                title: t('warning'),
                message: t('common.MSG_026'),
                type: 'default',
                textOk: t('restore'),
                textCancel: t('cancel'),
            });
            if (res) {
                setLoading(true);
                await RestoreUser(key);
                onRefreshData();
                toast({ description: t('common.MSG_030'), variant: 'success' });
            }
        } catch (error) {
            const message = error?.error?.reason;
            if (message === ERROR_RESPONSE.NOT_FOUND) {
                toast({ description: t('common.MSG_070'), variant: 'destructive' });
                return;
            }
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    const onChangeTable: TableProps<ResidentType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('account.account_list.title') }]} />}
            isDownLoadCsv={false}
            textBack={t('go_back')}
            onBack={onBack}
        >
            <TableBar hasSelect={false} onCreate={onCreate} isCreate textCreate={t('create_new')} />
            <TableComponent
                loading={isLoad}
                columns={columnsTableAccount({
                    t,
                    onEdit,
                    onDelete,
                    onRestore,
                })}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};
export default withTrans(observer(AccountList));
