import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { useNavigate } from 'react-router';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { toast } from '@/components/ui/use-toast';
import { columnsTableMeeting } from '@/pages/meeting/components/Columns';
import { useCsvStore } from '@/store/csv/CsvStore';
import { useQuery } from '@tanstack/react-query';
import { createFileMeetingCsv, deleteMeeting, getListMeetings } from '@/modules/services/meeting.service';
import { MeetingType } from '@/interfaces/meeting';
import dayjs from '@/lib/dayjs';
import { FORMAT_TIME } from '@/constants/appConstants';
import { TableProps } from 'antd';
import { ResidentType } from '@/interfaces/resident';
import { convertFileNameCsv, handleErrorNotFound } from '@/lib/helper';
import useRefreshData from '@/hooks/useRefreshData';

const MeetingList = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const { user } = useAuthenStore();
    const { setLoading } = useGlobalStore();
    const { addQueue } = useCsvStore();

    const { page, setPage, refreshDataList } = useRefreshData({ queryKey: ['getMeetingList'] });

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getMeetingList', page],
        queryFn: () => getMeetingList(page),
    });

    const getMeetingList = async (page: number): Promise<{ dataSource: MeetingType[]; total: number }> => {
        try {
            setLoading(true);
            const response = await getListMeetings({ page, per_page: 10 });
            const { data, meta }: any = response;
            const newData = data
                .filter((e: MeetingType) => e.id !== user.id)
                .map((item: MeetingType) => {
                    return {
                        key: item.id,
                        id: item.id,
                        name: item.name,
                        meeting_date: item.meeting_date,
                        start_time: dayjs(item.start_time).format(FORMAT_TIME),
                        end_time: item.end_time ? dayjs(item.end_time).format(FORMAT_TIME) : null,
                        content: item.content,
                        attendances: item.attendances,
                    };
                });
            return { dataSource: newData, total: meta.total_count };
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const onEdit = (key: string) => {
        const item = data.dataSource.find((el: MeetingType) => {
            return el.id === key;
        });
        navigate(`update/${key}`, { state: item });
    };

    const onDetail = (key: string) => {
        const item = data.dataSource.find((el: MeetingType) => {
            return el.id === key;
        });
        navigate(`detail/${key}`, { state: item });
    };

    const onCreate = () => {
        navigate(`create`);
    };

    const onDelete = async (id: string) => {
        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });
            if (res) {
                setLoading(true);
                await deleteMeeting(id);
                refreshDataList();
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_071',
                callback: () => refreshDataList(),
                defaultMessage: 'common.MSG_041',
            });
        } finally {
            setLoading(false);
        }
    };

    const onChangeTable: TableProps<ResidentType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    const onDownloadCsv = async () => {
        try {
            setLoading(true);
            await createFileMeetingCsv();
            addQueue({
                flag: 'meeting',
                status: 'pending',
                url: `users/export_csv_meeting`,
                fileName: convertFileNameCsv({
                    prefixName: t('meeting.meeting_list.title'),
                }),
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('meeting.meeting_list.title') }]} />}
            isDownLoadCsv={!!data?.total}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableBar hasSelect={false} onCreate={onCreate} isCreate textCreate={t('create_new')} />
            <TableComponent
                loading={isLoading}
                columns={columnsTableMeeting({ t, onEdit, onDelete, onDetail })}
                dataSource={data?.dataSource || []}
                onChange={onChangeTable}
                pagination={{
                    total: data?.total || 0,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(MeetingList));
