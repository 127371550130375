import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { useEffect, useState } from 'react';
import { toast } from '@/components/ui/use-toast';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import IPSurveysQuestionForm from '@/pages/survey/ipad/form/IPSurveysQuestionForm';
import { Button } from '@/components/ui/button';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE_REQ } from '@/constants/appConstants';
import { convertOptionAnswer, handleErrorMessage, handleErrorNotFound } from '@/lib/helper';
import { getReportApplicationById, updateQuestionApplicationResponses } from '@/modules/services/request.service';
import { AnswerApplicationDetailSchema } from '@/schema/reportSchema';
import { backLink } from '@/constants/path';

const IPComApplicationQuestion = (props: PageProps) => {
    const { t } = props;
    const { isLoading, setLoading } = useGlobalStore();
    const { user } = useAuthenStore();
    const { id } = useParams();
    const [submitBtn, setSubmitBtn] = useState(false);
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState<any[]>([]);
    const [index, setIndex] = useState(0);

    const { isFetching: isLoad, data } = useQuery({
        queryKey: ['getReportApplicationByID', id],
        queryFn: () => getReportApplicationByID(),
    });

    const formAnswer = useForm<z.infer<typeof AnswerApplicationDetailSchema>>({
        resolver: zodResolver(AnswerApplicationDetailSchema),
        mode: 'all',
    });

    const getReportApplicationByID = async () => {
        if (!user.id || !id) return;
        try {
            setLoading(true);
            const { data: detail } = await getReportApplicationById(id);

            const now = dayjs(dayjs().startOf('day'));
            const dueDate = dayjs(detail?.due_date).endOf('day');

            if (detail?.questions.length <= 0 || now.isAfter(dueDate)) {
                navigate('/not-permission');
                return;
            }
            setQuestions(detail.questions || []);
            return { ...detail };
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const onBackMenu = () => {
        navigate(`${backLink[user?.role?.communication?.[0]] || ''}/applications`);
    };

    const handleAnswer = (currentData: any, formValues: any, type: 'next' | 'back') => {
        const isValid = true;
        const commonErrorMessage = (message: string) =>
            type === 'next'
                ? {
                      type: 'custom',
                      message: message,
                  }
                : null;

        const multipleChoiceAnswer = () => {
            const optionsArray = convertOptionAnswer(formValues.options);

            if (optionsArray.length === 0 && type === 'next') {
                formAnswer.setError(
                    'options',
                    commonErrorMessage(t('common.MSG_002', { field: t('report.ip_application.answer') }))
                );
                return false;
            }

            return {
                text_answer: '',
                question_id: currentData.id,
                options: formValues.options,
            };
        };

        const textAnswer = () => {
            if (!formValues.text_answer && type === 'next') {
                formAnswer.setError(
                    'text_answer',
                    commonErrorMessage(t('common.MSG_001', { field: t('report.ip_application.answer') }))
                );
                return false;
            }

            return {
                question_id: currentData.id,
                ...formValues,
            };
        };

        const answer = currentData.question_type === 'multiple_choice' ? multipleChoiceAnswer() : textAnswer();

        if (!answer || !isValid) return false;
        return answer;
    };

    const handleSetData = (type: 'next' | 'back') => {
        const currentData = data.questions[index];
        const answer = handleAnswer(currentData, formAnswer.getValues(), type);

        if (!answer) return false;

        let newAnswers = [...answers];
        const answerIndex = newAnswers.findIndex((item: any) => item.question_id === answer.question_id);

        if (answerIndex >= 0) {
            newAnswers[answerIndex] = answer;
        } else {
            newAnswers = [...newAnswers, answer];
        }

        formAnswer.reset({ respondent: formAnswer.getValues().respondent });
        return newAnswers;
    };

    const onSubmit = async () => {
        const newAnswers = handleSetData('next');

        if (!newAnswers) return;
        const body = {
            response: {
                submit_date: dayjs().format(FORMAT_DATE_REQ),
                answers: newAnswers.map((item) => ({
                    ...item,
                    options: convertOptionAnswer(item.options),
                })),
            },
        };

        try {
            setLoading(true);
            const res = await updateQuestionApplicationResponses(id, body);
            if (res) {
                toast({
                    description: t('common.MSG_015'),
                    variant: 'success',
                });
                onBackMenu();
            }
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_063',
                callback: () => onBackMenu(),
            });
            if (isNotFound) return;

            handleErrorMessage({
                error:
                    error?.errors?.survey?.[0] ||
                    error?.errors?.required_responses?.[0] ||
                    error?.errors?.submit_date?.[0] ||
                    '',
                callback: () => onBackMenu(),
                defaultMessage: 'common.MSG_063',
            });

            onBackMenu();
        } finally {
            setLoading(false);
        }
    };

    const onNextAnswer = async () => {
        if (!data?.questions || index >= data.questions.length) {
            return;
        }
        const newAnswers = handleSetData('next');
        if (!newAnswers) return;
        setAnswers(newAnswers);
        formAnswer.reset(newAnswers?.[index + 1] || {});
        setIndex(index + 1);
    };

    useEffect(() => {
        setSubmitBtn(data?.questions?.length === index + 1);
    }, [data?.questions?.length, index]);

    const onBack = () => {
        if (index === 0) {
            onBackMenu();
            return;
        }
        const newAnswers = handleSetData('back');

        if (!newAnswers) return;
        setAnswers(newAnswers);
        formAnswer.reset(newAnswers[index - 1]);
        setIndex(index - 1);
    };

    return (
        <PageComponent
            pageLoading={isLoad}
            title={<PageTitle routes={[{ path: '', breadcrumbName: `${data?.title || ''}` }]} className="py-6" />}
            isDownLoadCsv={false}
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-[19px] h-[42px] w-44"
                        onClick={submitBtn ? onSubmit : onNextAnswer}
                        disabled={isLoading}
                    >
                        {t(submitBtn ? 'submit' : 'next')}
                    </Button>
                </div>
            }
        >
            <Form {...formAnswer}>
                <IPSurveysQuestionForm index={index} onSubmit={onSubmit} data={questions} />
            </Form>
        </PageComponent>
    );
};
export default withTrans(observer(IPComApplicationQuestion));
