import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import {
    accountRoutes,
    commissionerRoutes,
    communicationRoutes,
    mayorRoutes,
    representativeRoutes,
    welfareRoutes,
} from '@/modules/routers/routes';
import { ItemRoutes } from '@/interfaces/common/global';

const NotFoundPage = lazy(() => import('@/pages/404'));
const SignInPage = lazy(() => import('@/pages/signin'));
const MainLayout = lazy(() => import('@/layouts/main'));
const HomePage = lazy(() => import('@/pages/home'));
const AuthLayout = lazy(() => import('@/layouts/auth'));
const ProfileFormPage = lazy(() => import('@/pages/profile'));
const UpdatePasswordPage = lazy(() => import('@/pages/profile/update-password'));
const ForgotPassword = lazy(() => import('@/pages/forgot-password/ForgotPassword'));
const NotPermission = lazy(() => import('@/pages/403'));
const PrivateRoute = lazy(() => import('@/modules/routers/PrivateRoute'));
const ResetPassword = lazy(() => import('@/pages/forgot-password/ResetPassword'));
const ForgotPasswordSuccess = lazy(() => import('@/pages/forgot-password/ForgotPasswordSuccess'));
const LinkExpried = lazy(() => import('@/pages/forgot-password/LinkExpried'));
const ProtectedRoute = lazy(() => import('@/modules/routers/ProtectedRoute'));

const createProtectedRoute = (item: ItemRoutes) => ({
    path: item.path,
    element: <ProtectedRoute key={item.path} element={item.element} roles={item.roles} />,
});

const communicationChildren = [
    ...communicationRoutes.map(createProtectedRoute),
    ...mayorRoutes.map(createProtectedRoute),
    ...representativeRoutes.map(createProtectedRoute),
];

const welfareChildren = [...welfareRoutes.map(createProtectedRoute), ...commissionerRoutes.map(createProtectedRoute)];
const accountChildren = [...accountRoutes.map(createProtectedRoute)];

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <PrivateRoute element={<MainLayout />} />,
        children: [
            {
                path: '',
                index: true,
                element: <HomePage />,
            },
            {
                path: 'communication',
                children: communicationChildren,
            },
            {
                path: 'welfare',
                children: welfareChildren,
            },
            {
                path: 'profile',
                element: <ProfileFormPage />,
            },
            {
                path: 'update-password',
                element: <UpdatePasswordPage />,
            },
            {
                path: 'accounts',
                children: accountChildren,
            },
        ],
    },
    {
        path: 'login',
        element: <SignInPage />,
    },
    {
        path: 'forgot-password',
        element: <AuthLayout />,
        children: [
            { path: '', element: <ForgotPassword />, index: true },
            { path: 'success', element: <ForgotPasswordSuccess />, index: true },
        ],
    },
    {
        path: 'reset-password',
        element: <ResetPassword />,
    },
    {
        path: 'link-expired',
        element: <LinkExpried />,
    },
    {
        path: 'not-permission',
        element: <NotPermission />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
];
