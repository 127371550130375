import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { ReportType } from '@/interfaces/report';
import dayjs from '@/lib/dayjs';
import { ColumnsProps } from '@/interfaces/common/global';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { FORMAT_DATE_TIME } from '@/constants/appConstants';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import { ColumnsToolTip } from '@/components/common/pages/Element';

export const columns = ({
    t,
    onEdit,
    columnsKey,
    onDetail,
    showFull,
    title,
}: ColumnsProps<ReportType>): TableProps<ReportType>['columns'] => {
    const allColumns: TableProps<ReportType>['columns'] = [
        {
            title: t('action'),
            dataIndex: 'detail',
            key: 'detail',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button
                            variant="default"
                            className="m-1"
                            onClick={() => {
                                onDetail?.(record.key);
                            }}
                        >
                            {t('detail')}
                        </Button>
                        {record.status === 'pending' && (
                            <Button
                                variant="update"
                                className="bg-green-500 border-grey m-1"
                                onClick={() => {
                                    onEdit?.(record.key);
                                }}
                            >
                                {t('edit')}
                            </Button>
                        )}
                    </>
                );
            },
        },
        {
            title: t('report.no'),
            dataIndex: 'code',
            key: 'code',
            width: 70,
            align: 'center',
        },
        {
            title: t('report.report_date'),
            dataIndex: 'latest_updated_at',
            key: 'latest_updated_at',
            align: 'center',
            width: 70,
            render: (_, record) => {
                return <span>{dayjs(record.latest_updated_at).format(FORMAT_DATE_TIME)}</span>;
            },
        },
        {
            title: t('report.category'),
            dataIndex: 'category',
            key: 'category',
            width: 80,
            render: (_, record) => {
                const value = record?.category_child || record?.category;
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('report.content'),
            dataIndex: 'content',
            key: 'content',
            width: 150,
            render: (value) => {
                if (showFull) {
                    return value;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t(`report.${title}_reporter_position`),
            dataIndex: 'reporter_position',
            key: 'reporter_position',
            align: 'center',
            width: 70,
            render: (_, record) => {
                const role =
                    (record.assignee?.roles?.communication?.[0] === APP_PERMISSION.MAYOR
                        ? `${record.assignee?.district || ''} `
                        : '') + t(`report.${record.assignee?.roles?.communication?.[0]}`);

                return <ColumnsToolTip value={role} />;
            },
        },
        {
            title: t(`report.${title}_reporter_name`),
            dataIndex: 'reporter_name',
            key: 'reporter_name',
            width: 80,
            render: (_, record) => {
                if (showFull) {
                    return record.assignee?.name;
                }
                return (
                    <TooltipComponent title={record.assignee?.name}>
                        <div className="ellipsis whitespace-break-spaces">{record.assignee?.name}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('report.urgency'),
            dataIndex: 'priority',
            key: 'priority',
            width: 60,
            align: 'center',
            render: (value) => t(value),
        },
        {
            title: t('report.status'),
            dataIndex: 'status',
            key: 'status',
            width: 60,
            align: 'center',
            render: (value) => t(value),
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
