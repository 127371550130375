import { TFunction } from 'react-i18next';
import { CheckIcon } from '@radix-ui/react-icons';

export const renderApplicationServiceColumns = (t: TFunction) => [
    {
        title: t('application'),
        key: 'application_service',
        children: [
            {
                title: t('taxi'),
                dataIndex: 'welfare_taxi',
                key: 'welfare_taxi',
                width: 80,
                align: 'center',
                render: (value: boolean) => {
                    return value ? (
                        <div className="flex justify-center items-center">
                            <CheckIcon height={36} width={36} className="text-lime-500" />
                        </div>
                    ) : null;
                },
            },
            {
                title: t('emergency_device'),
                dataIndex: 'emergency_device',
                key: 'emergency_device',
                width: 80,
                align: 'center',
                render: (value: boolean) => {
                    return value ? (
                        <div className="flex justify-center items-center">
                            <CheckIcon height={36} width={36} className="text-lime-500" />
                        </div>
                    ) : null;
                },
            },
            {
                title: t('habitat'),
                dataIndex: 'habitat',
                key: 'habitat',
                width: 80,
                align: 'center',
                render: (value: boolean) => {
                    return value ? (
                        <div className="flex justify-center items-center">
                            <CheckIcon height={36} width={36} className="text-lime-500" />
                        </div>
                    ) : null;
                },
            },
            {
                title: t('evacuation_operations'),
                dataIndex: 'evacuation_operations',
                key: 'evacuation_operations',
                width: 80,
                align: 'center',
                render: (value: boolean) => {
                    return value ? (
                        <div className="flex justify-center items-center">
                            <CheckIcon height={36} width={36} className="text-lime-500" />
                        </div>
                    ) : null;
                },
            },
        ],
    },
];

export const renderEachAppServiceColumns = (t: TFunction) => [
    {
        title: t('app_service'),
        key: 'app_service',
        width: 100,
        render: (_: boolean, record: any) => {
            const isService =
                record?.habitat || record?.evacuation_operations || record?.emergency_device || record?.welfare_taxi;
            return isService ? (
                <div className="flex justify-center items-center">
                    <CheckIcon height={36} width={36} className="text-lime-500" />
                </div>
            ) : null;
        },
    },
];
