import { PageProps } from '@/interfaces/props/page';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditAccountSchema } from '@/schema/accountSchema';
import AccountForm from '@/pages/account/form/AccountForm';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { CreateUser, RestoreUser } from '@/modules/services/user.service';
import { toast } from '@/components/ui/use-toast';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { ERROR_RESPONSE } from '@/constants/appConstants';

const CreateAccount = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const { user } = useAuthenStore();
    const { isLoading, setLoading } = useGlobalStore();

    const onBackMenu = () => {
        navigate(`/accounts`);
    };

    const form = useForm<z.infer<typeof EditAccountSchema>>({
        resolver: zodResolver(EditAccountSchema),
        defaultValues: {
            name: undefined,
            profile: undefined,
            roles: undefined,
        },
        mode: 'onChange',
    });

    const onRestore = async (key: string) => {
        try {
            const res = await showModalConfirm({
                title: t('warning'),
                message: t('common.MSG_029'),
                type: 'default',
                textOk: t('restore'),
                textCancel: t('cancel'),
            });
            if (res) {
                setLoading(true);
                await RestoreUser(key);
                onBackMenu();
                toast({ description: t('common.MSG_030'), variant: 'success' });
            }
        } catch (error) {
            const message = error?.error?.reason;
            if (message === ERROR_RESPONSE.NOT_FOUND) {
                toast({ description: t('common.MSG_070'), variant: 'destructive' });
                return;
            }
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async () => {
        if (!user.id) return;
        try {
            setLoading(true);
            const data = form.getValues();
            const role_communication = Object.keys(data.communication).filter(
                (key) => data.communication[key] === true
            );
            const role_welfare = Object.keys(data.welfare).filter((key) => data.welfare[key] === true);
            const body = {
                user: {
                    email: data.email,
                    profile: {
                        name: data.name,
                        district: data.district,
                    },
                    roles: [...role_communication, ...role_welfare],
                },
            };
            const res = await CreateUser(body);
            if (res) {
                toast({
                    description: t('common.MSG_015'),
                    variant: 'success',
                });
                onBackMenu();
            }
        } catch (error) {
            const message: string = error?.errors?.email?.[0];

            if (message?.includes('MSG')) {
                if (message?.includes('MSG_029')) {
                    error.errors.id?.[0] && onRestore(error.errors.id?.[0]);
                    return;
                } else {
                    toast({
                        description: t(message.includes('common') ? message : `common.${message}`),
                        variant: 'destructive',
                    });
                    return;
                }
            }
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t('account.create_new_account.title') }]} />}
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-[19px] h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <AccountForm />
            </Form>
        </PageComponent>
    );
};
export default withTrans(observer(CreateAccount));
