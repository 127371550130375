import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE_TIME } from '@/constants/appConstants';
import { ColumnsProps } from '@/interfaces/common/global';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { renderEachAppServiceColumns, renderApplicationServiceColumns } from '@/components/common/table/Columns';
export interface DataType {
    key: string;
    name: string;
    situation: string;
    age: number;
    visit_date: string;
    building: string;
    code: string;
    welfare_taxi: boolean;
    emergency_device: boolean;
    situation_content: string;
    visitor_id?: string;
    visitor_name?: string;
    habitat?: boolean;
    evacuation_operations?: boolean;
}

export const columns = ({
    t,
    onDetail,
    columnsKey,
    showFull,
}: ColumnsProps<DataType>): TableProps<DataType>['columns'] => {
    const allColumns: TableProps<DataType>['columns'] = [
        {
            title: t('visit_report.detail'),
            dataIndex: 'detail',
            key: 'detail',
            width: 80,
            align: 'center',
            render: (_, record) => {
                return (
                    <Button
                        variant="default"
                        onClick={() => {
                            onDetail?.(record);
                        }}
                    >
                        {t('detail')}
                    </Button>
                );
            },
        },
        {
            title: t('visit_report.report_date'),
            dataIndex: 'visit_date',
            key: 'visit_date',
            width: 110,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE_TIME) : ''),
        },
        {
            title: t('visit_report.situation'),
            dataIndex: 'situation',
            key: 'situation',
            width: 100,
            align: 'center',
            render: (value) => t(value),
        },
        {
            title: <div className="text-center">{t('visit_report.select_civil')}</div>,
            dataIndex: 'visitor_name',
            key: 'visitor_name',
            width: 100,
            align: 'start',
            render: (value) => {
                if (showFull) {
                    return <div className="whitespace-break-spaces">{value}</div>;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('visit_report.no'),
            dataIndex: 'code',
            key: 'code',
            width: 80,
            align: 'center',
        },
        {
            title: <div className="text-center">{t('visit_report.name')}</div>,
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align: 'start',
            render: (value) => {
                if (showFull) {
                    return <div className="whitespace-break-spaces">{value}</div>;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
        {
            title: t('visit_report.age'),
            dataIndex: 'age',
            key: 'age',
            width: 80,
            align: 'center',
        },
        {
            title: t('visit_report.address'),
            dataIndex: 'address',
            key: 'address',
            width: 170,
            render: (value) => {
                if (showFull) {
                    return <div className="whitespace-break-spaces">{value}</div>;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces w-fit">{value}</div>
                    </TooltipComponent>
                );
            },
        },

        ...renderEachAppServiceColumns(t),
        ...renderApplicationServiceColumns(t),
        {
            title: t('visit_report.latest_support'),
            dataIndex: 'situation_content',
            key: 'situation_content',
            width: 200,
            render: (value) => {
                if (showFull) {
                    return <div className="whitespace-break-spaces">{value}</div>;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const columnSituation = ({
    t,
    columnsKey,
    showFull,
}: ColumnsProps<DataType>): TableProps<DataType>['columns'] => {
    const allColumns: TableProps<DataType>['columns'] = [
        {
            title: t('visit_report.reporting_date'),
            dataIndex: 'visit_date',
            key: 'visit_date',
            width: 80,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE_TIME) : ''),
        },
        {
            title: t('visit_report.situation_status'),
            dataIndex: 'situation',
            key: 'situation',
            align: 'center',
            width: 80,
            render: (value) => t(value),
        },
        {
            title: t('visit_report.correspondence'),
            dataIndex: 'situation_content',
            key: 'situation_content',
            width: 200,
            render: (value) => {
                if (showFull) {
                    return <div className="whitespace-break-spaces">{value}</div>;
                }
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces">{value}</div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
