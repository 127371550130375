import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { Button } from '@/components/ui/button';
import { FormProvider, useForm } from 'react-hook-form';
import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { CreateVisitReport } from '@/modules/services/report.service';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditResidentReportSchema } from '@/schema/residentSchema';
import { useTranslation } from 'react-i18next';
import { toast } from '@/components/ui/use-toast';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { SITUATION_ENUM, VisitReportUpdate, VisitorData } from '@/interfaces/visit-report';
import { PageProps } from '@/interfaces/props/page';
import { AxiosResponse } from 'axios';
import dayjs from '@/lib/dayjs';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { FormField, FormLabel, FormMessage } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import InformationResident from '@/components/common/pages/InformationResident';
import GroupButton from '@/components/common/pages/GroupButton';
import { useGetDetailResident } from '@/hooks/useGetResidents';
import { FORMAT_DATE_TIME_SECONDS } from '@/constants/appConstants';
import { useSearchParams } from 'react-router-dom';

const IPCreateResidentReport: React.FC<PageProps> = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get(`userId`);
    const navigate = useNavigate();
    const form = useForm<z.infer<typeof EditResidentReportSchema>>({
        resolver: zodResolver(EditResidentReportSchema),
        mode: 'onChange',
        defaultValues: {
            visit_report: {
                visit_date: dayjs().format(FORMAT_DATE_TIME_SECONDS),
                situation: undefined,
                situation_content: undefined,
            },
        },
    });
    const { isLoading, setLoading } = useGlobalStore();
    const { watch, handleSubmit, clearErrors, control } = form;
    const { visit_report } = watch();

    const { resident } = useGetDetailResident({ id, userId: userId });

    const updateVisitReport = async (data: VisitReportUpdate) => {
        try {
            setLoading(true);
            const { data: dataResponse }: AxiosResponse<VisitorData> = await CreateVisitReport(userId, id, data);
            if (dataResponse) {
                toast({ description: t('common.MSG_015'), variant: 'success' });
                onBack();
            }
        } catch (error) {
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitForm = async (data: VisitReportUpdate) => {
        const { visit_report } = data;
        updateVisitReport({
            visit_report: {
                situation: visit_report.situation,
                situation_content: visit_report.situation_content,
                visit_date: dayjs().format(FORMAT_DATE_TIME_SECONDS),
            },
        } as VisitReportUpdate);
    };

    const onBack = () => {
        navigate('/welfare/commissioner/visit-reports');
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('visit_report.create_visit_report')}` }]}
                    className="py-6"
                />
            }
            isDownLoadCsv={false}
            isShowFooter={false}
        >
            <FormProvider {...form}>
                <InformationResident data={resident} />
                <div className="flex flex-col gap-6 pt-4">
                    <FormField
                        control={control}
                        name="visit_report.situation"
                        render={({ field, fieldState: { error } }) => (
                            <div className="flex flex-col">
                                <FormLabel className={'text-pc flex min-w-fit items-center pb-[20px]'} required={true}>
                                    {t('visit_report.visit_result')}
                                </FormLabel>
                                <GroupButton
                                    buttons={[
                                        {
                                            label: t('visit_report.normal'),
                                            onClick: () => {
                                                clearErrors('visit_report.situation_content');
                                                field.onChange(SITUATION_ENUM.NORMAL);
                                            },
                                            className: cn(
                                                visit_report.situation === SITUATION_ENUM.NORMAL && 'bg-lime-500',
                                                error && 'border-destructive'
                                            ),
                                        },
                                        {
                                            label: t('visit_report.abnormal'),
                                            onClick: () => field.onChange(SITUATION_ENUM.ABNORMAL),
                                            className: cn(
                                                visit_report.situation === SITUATION_ENUM.ABNORMAL && 'bg-lime-500',
                                                error && 'border-destructive'
                                            ),
                                        },
                                    ]}
                                />
                                <FormMessage className="text-error" />
                            </div>
                        )}
                    />
                    <FormInputTextarea
                        required={visit_report.situation === SITUATION_ENUM.ABNORMAL}
                        name={'visit_report.situation_content'}
                        className="flex flex-col items-start sm:gap-3"
                        label={t('visit_report.response_content')}
                        placeholder={t('common.MSG_001', { field: t('visit_report.response_content') })}
                        maxLength={255}
                    />
                    <div className="flex items-center justify-center gap-4">
                        <Button
                            size="xl"
                            className="text-ip h-[42px] w-44"
                            disabled={isLoading}
                            onClick={handleSubmit(handleSubmitForm)}
                        >
                            {t('register')}
                        </Button>
                        <Button size="xl" variant="outline" className="text-ip h-[42px] w-44" onClick={onBack}>
                            {t('go_back')}
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </PageComponent>
    );
};
export default withTrans(observer(IPCreateResidentReport));
