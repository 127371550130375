'use client';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { Select } from 'antd';
import { Checkbox } from '@/components/ui/checkbox';
import { OptionProps, SelectProps } from 'antd/es/select';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { CheckIcon } from '@/components/common/svg';
import './select.css';

type Props = {
    isLoading?: boolean;
    name: string;
    labelClass?: string;
    placeholder: string;
    className?: string;
    required?: boolean;
    showError?: boolean;
    extra?: React.ReactNode;
    label?: React.ReactNode;
    options: OptionProps[];
    initOptions?: OptionProps[];
    onHandleChange?: (item: string, checked: boolean) => void;
} & SelectProps;

const FormSelectMultipleValue = forwardRef<HTMLInputElement, Props>(
    (
        {
            isLoading,
            name,
            placeholder,
            extra,
            className,
            labelClass,
            label,
            onHandleChange,
            showError = true,
            required = true,
            options,
            onSearch,
            initOptions,
            maxTagCount = 9,
            maxTagTextLength = 20,
            optionFilterProp = 'label',
            ...props
        },
        ref
    ) => {
        const { control } = useFormContext();
        const { t } = useTranslation();

        const initData = initOptions || options;

        const handleChange = (item: OptionProps, field: any) => {
            const isExit = field.value.find((e: any) => e.value === item.value);
            if (isExit) {
                field.onChange(field.value.filter((e: OptionProps) => e.value !== item.value));
            } else {
                field.onChange([...field.value, item]);
            }

            onHandleChange?.(item.value, !isExit);
        };

        return (
            <FormField
                control={control}
                name={name}
                render={({ field, fieldState: { error } }) => (
                    <FormItem className={cn('flex flex-wrap sm:flex-nowrap sm:gap-6', className)}>
                        {label && (
                            <FormLabel
                                className={cn(
                                    'text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px]',
                                    labelClass
                                )}
                                required={required}
                            >
                                {label}
                            </FormLabel>
                        )}
                        <div className="w-full">
                            <FormControl>
                                <Select
                                    {...props}
                                    loading={isLoading}
                                    className={cn(
                                        'min-h-[50px] sm:col-span-1 col-span-12 text-left font-normal border-grey border rounded-md text-ip overflow-hidden',
                                        error ? 'border-destructive' : 'border-grey'
                                    )}
                                    ref={ref}
                                    onSearch={onSearch}
                                    mode="multiple"
                                    size={'large'}
                                    value={field.value}
                                    placeholder={placeholder}
                                    style={{ width: '100%' }}
                                    onDeselect={(value) => {
                                        field.onChange(field.value.filter((e: OptionProps) => e.value !== value));
                                        onHandleChange?.(value, false);
                                    }}
                                    maxTagCount={maxTagCount}
                                    maxTagTextLength={maxTagTextLength}
                                    optionFilterProp={optionFilterProp}
                                    dropdownRender={() => (
                                        <div className="flex flex-col  max-h-[400px] overflow-y-auto">
                                            {options.length ? (
                                                <>
                                                    <div
                                                        className="flex items-center space-x-1 py-4 px-4 "
                                                        onClick={() => {
                                                            field.onChange(
                                                                initData.length === field.value?.length ? [] : initData
                                                            );
                                                            onHandleChange?.(
                                                                'all',
                                                                initData.length !== field.value?.length
                                                            );
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={!!(field.value?.length === initData.length)}
                                                        />
                                                        <label
                                                            htmlFor="terms"
                                                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                                        >
                                                            {t('category.select_all')}
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        {options.map((item, i) => (
                                                            <div
                                                                className={cn(
                                                                    'flex justify-between items-center rounded-sm px-2',
                                                                    field.value?.find(
                                                                        (e: OptionProps) => e.value === item.value
                                                                    )
                                                                        ? 'custom-select-item-selected'
                                                                        : 'custom-select-item'
                                                                )}
                                                                key={i}
                                                                onClick={() => handleChange(item, field)}
                                                            >
                                                                <TooltipComponent title={item.label}>
                                                                    <div
                                                                        className={
                                                                            'text-[15px] max-w-[130px] md:max-w-[300px] ellipsis whitespace-break-spaces option py-[5px] pl-4'
                                                                        }
                                                                    >
                                                                        {item.label}
                                                                    </div>
                                                                </TooltipComponent>
                                                                <span className="hidden custom-select-check-icon">
                                                                    <CheckIcon />
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="h-[100px] flex items-center justify-center text-ip">
                                                    {t('blank')}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </FormControl>
                            {showError && <FormMessage className="text-error" />}
                        </div>
                        {extra}
                    </FormItem>
                )}
            />
        );
    }
);

export default FormSelectMultipleValue;
