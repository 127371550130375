import {t} from "i18next";

export function getLookupLabel (lookupName: any, code: any, key: any) {
    const result = lookupName.find(({ value } : any ) => {
        if (typeof value=== 'function') {
            return value(code)
        }
        return value === code
    })
    return t(result ? result[key || 'label'] : '' )
}

export const WasAnswered = [
    { value: true, label: ('survey.ipad.answer_status.true') },
    { value: false, label: ('survey.ipad.answer_status.false') },
]
