interface DownloadFileData {
    file: any;
    fileName?: string;
}

export const downloadFile = ({ fileName, file }: DownloadFileData) => {
    const blob = file;
    const url = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', fileName);
    element.style.visibility = 'hidden';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const formatBytesBinary = (bytes: number) => {
    if (!+bytes) return 0;
    const k = 1024;
    return bytes / Math.pow(k, 2);
};

export const ALLOW_EXTENSION = '.jpg, .jpeg, .png, .heic';
export const MAX_FILE_SIZE_UPLOAD = 30;
export const validateFileType = (file: any) => {
    const extension = /[.]/.exec(file?.name) ? /[^.]+$/.exec(file?.name) : undefined;
    return (
        extension[0] &&
        (ALLOW_EXTENSION.includes(extension[0]) || ALLOW_EXTENSION.toLocaleUpperCase().includes(extension[0]))
    );
};
