import type { TableProps } from 'antd/es/table';
import { Button } from '@/components/ui/button';
import { TFunction } from 'react-i18next';
import { QuestionSurveyDetailType } from '@/interfaces/survey';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE } from '@/constants/appConstants';
import { getLookupLabel, WasAnswered } from '@/modules/functions/LookupUtils';
import TooltipComponent from '@/components/common/pages/ToolTip';

type ColumnsProps = {
    t: TFunction;
    onAnswer?: (key: string) => void;
    onSetting?: (key: string) => void;
    columnsKey?: string[];
    showFull?: boolean;
};

export const columnsTableIPSurvey = ({
    t,
    onAnswer,
    columnsKey,
    showFull,
}: ColumnsProps): TableProps<QuestionSurveyDetailType>['columns'] => {
    const allColumns: TableProps<QuestionSurveyDetailType>['columns'] = [
        {
            title: t('survey.ipad.answer'),
            dataIndex: 'was_answered',
            key: 'was_answered',
            width: 100,
            align: 'center',
            render: (data, record) => {
                const now = dayjs(dayjs().startOf('day'));
                const dueDate = dayjs(record?.due_date).endOf('day');

                if (!data && record?.questions.length > 0 && !now.isAfter(dueDate)) {
                    return <Button onClick={() => onAnswer?.(record.id)}>{t('survey.ipad.answer')}</Button>;
                }
                return null;
            },
        },
        {
            title: t('survey.ipad.answer_status.title'),
            dataIndex: 'was_answered',
            key: 'was_answered',
            width: 120,
            align: 'center',
            render: (data) => getLookupLabel(WasAnswered, data, ''),
        },
        {
            title: t('survey.ipad.answer_deadline'),
            dataIndex: 'due_date',
            key: 'due_date',
            width: 120,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE) : ''),
        },
        {
            title: t('survey.ipad.question_content'),
            dataIndex: 'title',
            key: 'title',
            width: 250,
            render: (value) => {
                if (showFull) return <div className="whitespace-break-spaces">{value}</div>;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces max-w-[350px] lg:max-w-[600px] xl:max-w-fit">
                            {value}
                        </div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};

export const columnsTableIPSurvey61 = ({
    t,
    onAnswer,
    onSetting,
    columnsKey,
    showFull,
}: ColumnsProps): TableProps<QuestionSurveyDetailType>['columns'] => {
    const allColumns: TableProps<QuestionSurveyDetailType>['columns'] = [
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: 140,
            align: 'center',
            render: (_, record) => {
                const now = dayjs(dayjs().startOf('day'));
                const dueDate = dayjs(record?.due_date).endOf('day');

                const isShowSetting = !record.max_answer_number || (!!record.max_answer_number && !record.answer_count);
                const isShowAnswer =
                    !!record.max_answer_number &&
                    (!record.answer_count || record.answer_count < record.max_answer_number);

                if (!now.isAfter(dueDate)) {
                    return (
                        <>
                            {isShowSetting && (
                                <Button
                                    variant="outline"
                                    className="m-1 w-[65px]"
                                    onClick={() => {
                                        onSetting?.(record.key);
                                    }}
                                >
                                    {t('setting')}
                                </Button>
                            )}

                            {isShowAnswer && (
                                <Button
                                    variant="default"
                                    className="m-1 w-[65px]"
                                    onClick={() => onAnswer?.(record.id)}
                                >
                                    {t('survey.ipad.answer')}
                                </Button>
                            )}
                        </>
                    );
                }
                return null;
            },
        },
        {
            title: t('survey.ipad.answer_status.title'),
            dataIndex: 'was_answered',
            key: 'was_answered',
            width: 80,
            align: 'center',
            render: (_, record) => {
                if (!record.max_answer_number) {
                    return null;
                }
                return (
                    <span>
                        {record.answer_count} / {record.max_answer_number}
                    </span>
                );
            },
        },
        {
            title: t('survey.ipad.answer_deadline'),
            dataIndex: 'due_date',
            key: 'due_date',
            width: 120,
            align: 'center',
            render: (value) => (value ? dayjs(value).format(FORMAT_DATE) : ''),
        },
        {
            title: t('survey.ipad.question_content'),
            dataIndex: 'title',
            key: 'title',
            width: 250,
            render: (value) => {
                if (showFull) return <div className="whitespace-break-spaces">{value}</div>;
                return (
                    <TooltipComponent title={value}>
                        <div className="ellipsis whitespace-break-spaces max-w-[350px] lg:max-w-[600px] xl:max-w-fit">
                            {value}
                        </div>
                    </TooltipComponent>
                );
            },
        },
    ];

    const columns = columnsKey ? allColumns.filter((item) => columnsKey.includes(item.key as string)) : allColumns;

    return columns;
};
