import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { GOOGLE_MAPS_CONFIG } from '@/constants/appConstants';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { useTranslation } from 'react-i18next';
import { showModalErrorLocation } from './ModalErrorLocation';

interface Props {
    zoom?: number;
    multipleMaker?: boolean;
    width?: string;
    height?: string;
    className?: string;
    loadCurrent?: boolean;
    field?: any;
    isView?: boolean;
    onCallback?: (data: google.maps.LatLngLiteral) => void;
    defaultPos?: google.maps.LatLngLiteral;
}

const defaultCurrent = { lat: 36.2112794, lng: 139.5342841 };

const GoogleMapView = forwardRef<any, Props>(
    (
        {
            zoom,
            width,
            height,
            field,
            className,
            isView,
            onCallback,
            defaultPos = defaultCurrent,
            multipleMaker = true,
            loadCurrent = true,
            ...rest
        },
        ref: any
    ) => {
        const { isLoaded } = useJsApiLoader({
            ...GOOGLE_MAPS_CONFIG,
            libraries: ['places'],
        });
        const { t } = useTranslation();
        const [markers, setMarkers] = React.useState<google.maps.LatLngLiteral[]>(defaultPos ? [defaultPos] : []);
        const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral>(defaultPos);

        const onLoadMap = async () => {
            try {
                if (loadCurrent && navigator.geolocation) {
                    let initLocation: google.maps.LatLngLiteral = await getCurrentLocation();
                    if (defaultPos) {
                        const confirm = await showModalConfirm({ message: t('common.MSG_062'), type: 'default' });
                        if (!confirm) {
                            initLocation = defaultPos;
                        }
                        if (confirm && !initLocation) {
                            initLocation = { lat: null, lng: null };
                            await showModalErrorLocation({
                                title: t('msg_error_location.title'),
                                message: t('msg_error_location.content'),
                                type: 'error',
                            });
                        }
                    }
                    if (initLocation) {
                        onCallback(initLocation);
                        setCurrentLocation(initLocation);
                        setMarkers([initLocation]);
                    }
                }
            } catch (error) {
                await showModalErrorLocation({
                    type: 'error',
                    title: t('msg_error_location.title'),
                    message: t('msg_error_location.content'),
                });
            }
        };

        const getCurrentLocation = async () => {
            try {
                const currentPos: any = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

                return {
                    lng: currentPos?.coords?.longitude || null,
                    lat: currentPos?.coords?.latitude || null,
                };
            } catch (error) {
                // eslint-disable-next-line no-console
            }
        };

        const handleMaker = (e: any) => {
            if (multipleMaker) {
                setMarkers((prev) => [...prev, e.latLng.toJSON()]);
            } else {
                setMarkers([e.latLng.toJSON()]);
                const latLng = new google.maps.LatLng(e.latLng.toJSON());
                const geocoder = new google.maps.Geocoder();
                if (latLng) {
                    geocoder.geocode({ location: latLng }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                const formattedAddress = results[0].formatted_address;
                                field?.onChange({
                                    ...e.latLng.toJSON(),
                                    address: formattedAddress,
                                });
                            }
                        }
                    });
                }
            }
        };

        useImperativeHandle(ref, () => ({
            setMarkers,
            setCurrentLocation,
        }));

        return (
            <div className={`flex items-center rounded-md overflow-hidden ${className}`}>
                {isLoaded && (
                    <GoogleMap
                        ref={ref}
                        mapContainerStyle={{ width: width ?? '100%', height: height ?? 'calc(100vh - 100px)' }}
                        center={currentLocation || defaultCurrent}
                        zoom={zoom ?? 20}
                        onClick={!isView && handleMaker}
                        onLoad={onLoadMap}
                        options={{ scrollwheel: !!currentLocation }}
                        {...rest}
                    >
                        {markers.map((pos, i) => (
                            <MarkerF key={i} position={pos} />
                        ))}
                    </GoogleMap>
                )}
            </div>
        );
    }
);
export default GoogleMapView;
