import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { Form } from '@/components/ui/form';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { PhrasesSchema } from '@/schema/phrasesSchema';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { CategoryService } from '@/modules/services';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { PhrasesComponentType } from '@/interfaces/common/global';
import { handleErrorNotFound } from '@/lib/helper';

const EditPhrases = (props: { type: PhrasesComponentType } & WithTransProps) => {
    const { t, type } = props;
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const { id } = useParams();
    const { isLoading, setLoading } = useGlobalStore();

    const form = useForm<z.infer<typeof PhrasesSchema>>({
        resolver: zodResolver(PhrasesSchema),
        defaultValues: {
            content: undefined,
        },
        mode: 'all',
    });

    const onBackMenu = () => {
        navigate(`/${type}/phrases`);
    };

    const getDetailPhrases = async () => {
        if (!user.id) return;

        try {
            setLoading(true);
            const res = await CategoryService.getDetailPhrases(type, id);
            form.reset({
                ...res.data,
            });
            return true;
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async () => {
        if (!user.id) return;
        try {
            setLoading(true);
            const req = form.getValues();
            const data = { ...req };
            await CategoryService.updatePhrases(type, id, { [`${type}_sample_text`]: data });
            if (data) {
                toast({ description: t('common.MSG_017'), variant: 'success' });
                navigate(`/${type}/phrases`);
            }
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_025',
                callback: () => onBackMenu(),
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    const { isFetching: isLoad } = useQuery({
        queryKey: ['getDetailPhrases', id],
        queryFn: () => getDetailPhrases(),
        placeholderData: keepPreviousData,
    });

    return (
        <PageComponent
            pageLoading={isLoad}
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('fixed_phrases')}${t('edit')}` }]}
                    className="py-6"
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('update')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <FormInputTextarea
                    className="py-4 mt-4 md:w-[790px]"
                    name="content"
                    labelClass="min-w-[135px]"
                    label={t('fixed_phrases')}
                    placeholder={t('common.MSG_001', { field: t('fixed_phrases') })}
                    maxLength={255}
                />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(EditPhrases));
