import { FormControl, FormField, FormLabel, FormMessage } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
type Props = {
    className?: string;
    onCheckboxChange?: any;
    classNameCheckBox?: string;
    classNameLabel?: string;
    options?: any[];
    name: string;
};

const FormCheckboxAnswer = ({
    name,
    className,
    classNameLabel,
    classNameCheckBox,
    onCheckboxChange,
    options,
}: Props) => {
    const { control, clearErrors } = useFormContext();

    const checkboxElements = (error: boolean) =>
        options.map((item: any, key: any) => {
            return (
                <FormField
                    control={control}
                    name={`${name}.${item?.id || key}`}
                    key={key}
                    render={({ field }) => (
                        <div
                            className={cn(
                                `flex gap-2 items-center whitespace-break-spaces ${field.value ? 'bg-lime-500' : ''}`,
                                error && '!border-destructive',
                                className
                            )}
                        >
                            <FormControl>
                                <Checkbox
                                    className={classNameCheckBox}
                                    id={item?.id || key}
                                    checked={field.value}
                                    onCheckedChange={(checked) => {
                                        field.onChange(checked);
                                        error && clearErrors('options');
                                        // Trigger the onCheckboxChange prop
                                        if (onCheckboxChange) {
                                            onCheckboxChange(checked, field);
                                        }
                                    }}
                                    disabled={item?.disable || false}
                                />
                            </FormControl>
                            <FormLabel
                                htmlFor={item?.id || key}
                                className={cn('text-error font-normal', classNameLabel)}
                            >
                                {item.content || item.title}
                            </FormLabel>
                        </div>
                    )}
                />
            );
        });
    return (
        <FormField
            name={name}
            control={control}
            render={({ fieldState: { error } }) => (
                <>
                    {checkboxElements(!!error)}
                    <div className="col-span-2">
                        <FormMessage className="text-error" />
                    </div>
                </>
            )}
        />
    );
};

export default FormCheckboxAnswer;
