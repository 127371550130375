import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import withTrans from '@/components/common/hocs/withTrans';
import { Form } from '@/components/ui/form';
import { PageProps } from '@/interfaces/props/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { ResetPasswordSchema } from '@/schema/authSchema';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { toast } from '@/components/ui/use-toast';
import { ResetPasswordApi, VerifyToken } from '@/modules/services/auth.service';
import { ResetPasswordForm } from '@/interfaces/auth';
import FormInputText from '@/components/common/form/FormInputText';
import { ALPHA_NUMERIC_REGEX, NOT_NUMBER } from '@/lib/regex';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const ResetPassword = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const [searchParams] = useSearchParams();

    const form = useForm<z.infer<typeof ResetPasswordSchema>>({
        resolver: zodResolver(ResetPasswordSchema),
        defaultValues: {
            password: undefined,
            password_confirmation: undefined,
        },
        mode: 'onChange',
    });

    const token = searchParams?.get('reset_password_token') || '';

    const verifyToken = async (token?: string) => {
        try {
            await VerifyToken(token);
        } catch (error) {
            if (error.code === 400) {
                navigate('/link-expired');
            }
        }
    };

    useQuery({
        queryKey: ['verifyToken', token],
        queryFn: () => verifyToken(token),
    });

    useEffect(() => {
        const subscription = form.watch((value, { name }) => {
            if (name === 'password' || name === 'password_confirmation') {
                !!value.password_confirmation && form.trigger(['password', 'password_confirmation']);
            }
        });

        // Cleanup the subscription on unmount.
        return () => subscription.unsubscribe();
    }, [form.watch, form.trigger]);

    const onSubmit = async (data: ResetPasswordForm) => {
        if (!token) {
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
            return;
        }
        try {
            setLoading(true);
            await ResetPasswordApi({ user: { ...data, reset_password_token: token } });
            toast({ description: t('common.MSG_013'), variant: 'success' });
            navigate('/login');
        } catch (error) {
            const message = error?.errors?.reset_password_token?.[0];
            if (message === 'token_expired') {
                navigate('/link-expired');
                return;
            }
            toast({ description: t('common.MSG_038'), variant: 'destructive' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex h-screen flex-col items-center justify-center gap-6 sm:gap-9 max-w-[517px] m-auto whitespace-pre-line p-4">
            <div className="text-title font-bold">{t('app_title')}</div>
            <div className="text-center mt-4">{t('enter_password')}</div>
            <Form {...form}>
                <div className="flex flex-col gap-y-6 w-full md:w-[450px]">
                    <FormInputText
                        regex={NOT_NUMBER}
                        className="sm:flex-wrap sm:gap-0"
                        name="password"
                        label={t('new_password')}
                        maxLength={4}
                        placeholder={t('common.MSG_001', { field: t('new_password') })}
                        size="xxl"
                        type="password"
                        required
                    />
                    <FormInputText
                        regex={ALPHA_NUMERIC_REGEX}
                        className="sm:flex-wrap sm:gap-0"
                        name="password_confirmation"
                        label={t('confirm_password')}
                        maxLength={20}
                        placeholder={t('common.MSG_001', { field: t('confirm_password') })}
                        size="xxl"
                        type="password"
                        required
                    />
                    <Button disabled={isLoading} className="mt-4" size="xxl" onClick={form.handleSubmit(onSubmit)}>
                        {t('register')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
export default withTrans(observer(ResetPassword));
