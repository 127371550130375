export const APP_PERMISSION = {
    LOCAl_GOV_COMMUNICATION: 'local_gov_communication',
    MAYOR: 'mayor',
    REPRESENTATIVE: 'representative',
    LOCAL_GOV_WELFARE: 'local_gov_welfare',
    COMMISSIONER: 'commissioner',
};

export const SHOW_IPAD_SCREEN = [APP_PERMISSION.COMMISSIONER, APP_PERMISSION.MAYOR, APP_PERMISSION.REPRESENTATIVE];

export const ROLE_PERMISSION = {
    LOCAl_GOV_COMMUNICATION: 'communication.local_gov_communication',
    MAYOR: 'communication.mayor',
    REPRESENTATIVE: 'communication.representative',
    LOCAL_GOV_WELFARE: 'welfare.local_gov_welfare',
    COMMISSIONER: 'welfare.commissioner',
};

export const GET_USER_BY_ROLE = {
    [APP_PERMISSION.LOCAl_GOV_COMMUNICATION]: `local_gov_communication,representative,mayor`,
    [APP_PERMISSION.LOCAL_GOV_WELFARE]: `local_gov_welfare,commissioner`,
};
