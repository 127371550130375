import withTrans from '@/components/common/hocs/withTrans';
import { Button } from '@/components/ui/button';
import { PageProps } from '@/interfaces/props/page';
import { useNavigate } from 'react-router';

const NotFoundComponent = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const onBack = () => {
        navigate('/');
    };
    return (
        <div className="flex flex-col items-center justify-center h-screen gap-4">
            <h1 className="lg:text-7xl text-4xl">404</h1>
            <p>{t('notfound')}</p>
            <Button size="lg" className="w-[200px] text-ip" onClick={onBack}>
                {t('back_to_top')}
            </Button>
        </div>
    );
};
export default withTrans(NotFoundComponent);
