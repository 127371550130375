import React from 'react';
import { makeAutoObservable } from 'mobx';
import { CSVQueue } from '@/interfaces/common/csv';

class CsvStore {
    queue: CSVQueue[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    addQueue = (data: CSVQueue) => {
        const index = this.queue.findIndex(
            (item) => item.url === data.url && item.flag === data.flag && item.status === data.status
        );

        if (index < 0) {
            this.queue = [...this.queue, data];
        }
    };

    removeQueue = (data: CSVQueue) => {
        const index = this.queue.findIndex((item) => item.url === data.url && item.flag === data.flag);

        if (index >= 0) {
            this.queue.splice(index, 1);
        }
    };
}

export const csvStoreInternal = new CsvStore();
const storeContext = React.createContext(csvStoreInternal);
export const useCsvStore = (): CsvStore => React.useContext(storeContext);
