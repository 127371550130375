import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PageProps } from '@/interfaces/props/page';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { WrapInformationResident, renderItem } from '@/components/common/pages/Element';
import { useGetDetailResident } from '@/hooks/useGetResidents';
import { useAuthenStore } from '@/store/auth/AuthStore';
import GroupButton from '@/components/common/pages/GroupButton';
import { cn } from '@/lib/utils';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import dayjs from '@/lib/dayjs';
import { FORMAT_DATE } from '@/constants/appConstants';
import { convertStringPhone } from '@/lib/helper';
import { HOME_PHONE } from '@/lib/regex';

const IPDetailResident: React.FC<PageProps> = () => {
    const { t } = useTranslation();
    const { user } = useAuthenStore();
    const { id } = useParams();
    const navigate = useNavigate();
    const { resident, isLoading } = useGetDetailResident({ id, userId: user.id });

    const onBack = () => {
        navigate('/welfare/commissioner/residents');
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('resident.title')}${t('detail')}` }]}
                    className="py-6"
                />
            }
            isDownLoadCsv={false}
            textBack={t('go_back')}
            onBack={onBack}
            pageLoading={isLoading}
        >
            <WrapInformationResident>
                {renderItem({ label: t('name'), value: resident?.name || '', wrapClass: 'gap-0' })}
                {renderItem({
                    label: t('resident.birthday'),
                    value: resident?.birthday ? dayjs(resident.birthday).format(FORMAT_DATE) : '',
                    wrapClass: 'gap-0',
                })}
                {renderItem({ label: t('resident.address'), value: resident?.address, wrapClass: 'gap-0' })}
                {resident?.emergency_contacts?.map((item, index) => {
                    return (
                        <div key={index} className="flex flex-col gap-3">
                            <span className="text-pc font-medium">{t(`emergency_info.person_${index + 1}`)}</span>
                            {renderItem({
                                label: t('name'),
                                value: item?.name || '',
                                wrapClass: 'gap-0',
                                labelClass: 'text-[16px]',
                            })}
                            {renderItem({
                                label: t('relationship'),
                                value: item?.relationship || '',
                                wrapClass: 'gap-0',
                                labelClass: 'text-[16px]',
                            })}
                            {renderItem({
                                label: t('emergency_info.contact'),
                                value: convertStringPhone(item?.phone),
                                wrapClass: 'gap-0',
                                labelClass: 'text-[16px]',
                            })}
                            {renderItem({
                                label: t('emergency_info.home_contact'),
                                value: convertStringPhone(item?.home_phone, HOME_PHONE),
                                wrapClass: 'gap-0',
                                labelClass: 'text-[16px]',
                            })}
                            {renderItem({
                                label: t('emergency_info.address'),
                                value: item?.address || '',
                                wrapClass: 'gap-0',
                                labelClass: 'text-[16px]',
                            })}
                        </div>
                    );
                })}

                <div className="flex flex-col gap-3">
                    <span className="text-pc font-medium">{t(`medical_info.title`)}</span>
                    {renderItem({
                        label: t('medical_info.regular_clinic'),
                        value: resident?.family_hospital || '',
                        wrapClass: 'gap-0',
                        labelClass: 'text-[16px]',
                    })}
                    {renderItem({
                        label: t('medical_info.responsible_manager'),
                        value: resident?.care_manager || '',
                        wrapClass: 'gap-0',
                        labelClass: 'text-[16px]',
                    })}
                    {renderItem({
                        label: t('medical_info.service_name'),
                        value: resident?.service_name_1,
                        wrapClass: 'gap-0',
                        labelClass: 'text-[16px]',
                    })}
                    {renderItem({
                        label: t('medical_info.service_name'),
                        value: resident?.service_name_2,
                        wrapClass: 'gap-0',
                        labelClass: 'text-[16px]',
                    })}
                    {renderItem({
                        label: t('medical_info.service_name'),
                        value: resident?.service_name_3,
                        wrapClass: 'gap-0',
                        labelClass: 'text-[16px]',
                    })}
                    <div className="flex flex-col cursor-default">
                        <Label className="text-[16px] font-medium">{t('resident.remarks')}</Label>
                        <Textarea className={cn('text-pc')} value={resident?.note} isView />
                    </div>
                </div>
            </WrapInformationResident>
            <div className="flex flex-col gap-4 pt-4">
                <div className="flex flex-col gap-[2px]">
                    <Label className="text-pc font-medium">{t('resident.created_app_service')}</Label>
                    <GroupButton
                        buttons={[
                            {
                                label: t('resident.taxi'),
                                className: cn(
                                    'py-[30px] hover:cursor-default',
                                    resident?.welfare_taxi && 'bg-lime-500'
                                ),
                            },
                            {
                                label: t('resident.emergency_device'),
                                className: cn(
                                    'py-[30px] hover:cursor-default',
                                    resident?.emergency_device && 'bg-lime-500'
                                ),
                            },
                            {
                                label: t('habitat'),
                                className: cn('py-[30px] hover:cursor-default', resident?.habitat && 'bg-lime-500'),
                            },
                            {
                                label: t('evacuation_operations'),
                                className: cn(
                                    'py-[30px] hover:cursor-default',
                                    resident?.evacuation_operations && 'bg-lime-500'
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="flex flex-col gap-[2px] cursor-default">
                    <Label className="text-pc font-medium">{t('resident.remarks')}</Label>
                    <Textarea className={cn('text-pc')} value={resident?.remark} isView />
                </div>
            </div>
        </PageComponent>
    );
};
export default withTrans(observer(IPDetailResident));
