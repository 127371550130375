import { observer } from 'mobx-react-lite';
import { QuestionSurveyDetailType } from '@/interfaces/survey';
import { useTranslation } from 'react-i18next';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { FormItem, FormLabel } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { parseInt } from 'lodash';
import FormCheckboxAnswer from '@/components/common/form/FormCheckboxAnswer';

type Props = {
    onSubmit: (value: QuestionSurveyDetailType) => void;
    data: any;
    index: any;
};

const IPSurveysQuestionMultiple = ({ onSubmit, data, index }: Props) => {
    const { t } = useTranslation();
    const { handleSubmit } = useFormContext();
    if (data && Object.keys(data).length > 0) {
        const QuestionElement = Object.keys(data).map((key) => {
            const currentItem = data[key];
            return (
                <div key={currentItem.id}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            {parseInt(key) === index && (
                                <div>
                                    <FormLabel required className="text-pc" id="index">
                                        {t('survey.question')}
                                        {parseInt(key) + 1}
                                    </FormLabel>
                                    <div id="question" className="mt-6 whitespace-break-spaces">
                                        {currentItem.title}
                                    </div>
                                </div>
                            )}
                            {currentItem.question_type === 'text' && parseInt(key) === index && (
                                <FormItem>
                                    <div id={'question-text' + currentItem.id} className="mt-8 text-[23px]">
                                        <FormInputTextarea
                                            key={currentItem.id}
                                            required={false}
                                            name={'text_answer'}
                                            placeholder={t('survey.ipad.question.text_area_placeholder')}
                                            maxLength={255}
                                        />
                                    </div>
                                </FormItem>
                            )}
                            {currentItem.question_type === 'multiple_choice' && parseInt(key) === index && (
                                <FormItem className="grid grid-cols-2 gap-4 space-y-0 mt-8">
                                    <FormCheckboxAnswer
                                        name={'options'}
                                        options={currentItem.options || []}
                                        className="h-24 justify-center rounded-2xl border border-solid border-black"
                                        classNameCheckBox="hidden w-full h-full"
                                        classNameLabel=" text w-full h-full justify-center hover:cursor-pointer text-pc px-2"
                                    />
                                </FormItem>
                            )}
                        </div>
                    </form>
                </div>
            );
        });
        return <>{QuestionElement}</>;
    }
};
export default observer(IPSurveysQuestionMultiple);
