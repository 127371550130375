import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Button } from '@/components/ui/button';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { useGlobalStore } from '@/store/global/GlobalStore';

type Props = {
    textBack?: string;
    onBack?: () => void;
    isDownLoadCsv?: boolean;
    onDownLoadCsv?: () => void;
};

const PageFooter = (props: Props & WithTransProps) => {
    const { t, onDownLoadCsv, textBack, onBack, isDownLoadCsv = true } = props;
    const navigate = useNavigate();
    const { module } = useGlobalStore();

    const onBackMenu = () => {
        navigate(`/${module}`);
    };

    return (
        <div className="flex gap-4 justify-center mt-8">
            {isDownLoadCsv && (
                <Button
                    variant="outline"
                    size="xl"
                    className="text-ip h-[42px] min-w-44"
                    onClick={() => onDownLoadCsv?.()}
                >
                    {t('home.download_csv')}
                </Button>
            )}
            <Button variant="default" size="xl" className="text-ip h-[42px] min-w-44" onClick={onBack ?? onBackMenu}>
                {textBack || t('home.back_menu')}
            </Button>
        </div>
    );
};

export default withTrans(observer(PageFooter));
