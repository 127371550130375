import FormInputText from '@/components/common/form/FormInputText';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { FormLabel } from '@/components/ui/form';
import { InputProps } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

type Props = {
    labelClass?: string;
    className?: string;
    label?: React.ReactNode;
    required?: boolean;
    labelRequired?: boolean;
    isVertical?: boolean;
} & Omit<InputProps, 'label' | 'className'>;

const MedicalForm = ({
    className,
    labelClass,
    label,
    isVertical = false,
    required = false,
    labelRequired = false,
}: Props) => {
    return (
        <div className={cn('flex flex-wrap 2xl:gap-6 2xl:flex-nowrap w-full', className)}>
            <FormLabel
                className={cn('text-pc flex min-w-fit items-center lg:h-[58px] md:h-[58px]', labelClass)}
                required={labelRequired}
            >
                {label}
            </FormLabel>
            <div className="w-full">
                <div
                    className={cn('grid grid-cols-1 sm:grid-cols-2 gap-2 items-start', isVertical && 'sm:grid-cols-1')}
                >
                    <FormInputText
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name="family_hospital"
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px] max-w-[200px]"
                        label={t('medical_info.regular_clinic')}
                        placeholder={t('common.MSG_001', { field: t('medical_info.regular_clinic') })}
                        maxLength={255}
                        required={true}
                    />
                    <FormInputText
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0 "
                        inputClassName="w-full"
                        name="care_manager"
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px] max-w-[200px]"
                        label={t('medical_info.responsible_manager')}
                        placeholder={t('common.MSG_001', { field: t('medical_info.responsible_manager') })}
                        maxLength={255}
                        required={true}
                    />
                </div>
                <div
                    className={cn(
                        'grid grid-cols-1 sm:grid-cols-3 gap-2 mt-2 items-start',
                        isVertical && 'sm:grid-cols-1'
                    )}
                >
                    <FormInputText
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name="service.service_name_1"
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px] max-w-[200px]"
                        label={t('medical_info.service_name')}
                        placeholder={t('common.MSG_001', { field: t('medical_info.service_name') })}
                        required={required}
                        maxLength={255}
                    />
                    <FormInputText
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name="service.service_name_2"
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px]"
                        label={t('medical_info.service_name')}
                        placeholder={t('common.MSG_001', { field: t('medical_info.service_name') })}
                        maxLength={255}
                        required={required}
                    />
                    <FormInputText
                        className="cols-span-1 sm:flex-wrap sm:gap-0 space-y-0"
                        inputClassName="w-full"
                        name="service.service_name_3"
                        labelClass="text-[16px] md:h-[37px] lg:h-[37px]"
                        label={t('medical_info.service_name')}
                        placeholder={t('common.MSG_001', { field: t('medical_info.service_name') })}
                        maxLength={255}
                        required={required}
                    />
                </div>
                <FormInputTextarea
                    className="sm:flex-wrap sm:gap-0 space-y-0"
                    required={required}
                    name="note"
                    labelClass="text-[16px] md:h-[37px] lg:h-[37px]"
                    label={t('medical_info.remarks')}
                    placeholder={t('common.MSG_001', { field: t('medical_info.remarks') })}
                    rows={5}
                    maxLength={255}
                />
            </div>
        </div>
    );
};

export default observer(MedicalForm);
