import { Command, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { SelectOptionsItem } from '@/interfaces/common/global';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { CheckIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import useInfinityScroll from '@/hooks/useInfinityScroll';
import { ScrollOptions } from '@/interfaces/resident';
import { SpinIcon, ClearIcon } from '@/components/common/svg';

interface Props {
    options: SelectOptionsItem[];
    value: string;
    isShowSearch: boolean;
    setValue: (data: SelectOptionsItem) => void;
    onChange: (data: string | SelectOptionsItem) => void;
    setOpen: (data: boolean) => void;
    handleSearch?: (value: string) => void;
    hasGetDataOptions?: boolean;
    isLoading?: boolean;
    showValue?: boolean;
    setScrollOption?: Dispatch<SetStateAction<ScrollOptions>>;
}

const SelectedContent = (props: Props) => {
    const {
        options,
        value,
        isShowSearch,
        setValue,
        onChange,
        setOpen,
        handleSearch,
        setScrollOption,
        isLoading,
        showValue,
        hasGetDataOptions,
    } = props;
    const { t } = useTranslation();
    const lastItemRef = useRef<HTMLDivElement>(null);

    const [searchValue, setSearchValue] = useState<string>('');

    const onScroll = () => {
        setScrollOption?.((prev) => {
            const { page, total, perPage } = prev;
            const conditionScroll = page * perPage < total;
            if (conditionScroll) {
                return {
                    ...prev,
                    page: page + 1,
                };
            }
            return prev;
        });
    };

    const onSearch = (val: string) => {
        setSearchValue(val);
        handleSearch?.(val);
    };

    useInfinityScroll(lastItemRef, onScroll, !!searchValue);

    return (
        <Command shouldFilter={false}>
            {isShowSearch && (
                <div className="relative">
                    <CommandInput
                        value={searchValue}
                        placeholder={t('search')}
                        onValueChange={onSearch}
                        className="h-9 text-[16px] pr-[28px]"
                    />
                    {!!searchValue && (
                        <span
                            className=" text-center absolute top-[8px] right-4 hover:cursor-pointer hover:opacity-75"
                            onClick={() => {
                                setSearchValue('');
                                handleSearch?.('');
                            }}
                        >
                            <ClearIcon />
                        </span>
                    )}
                </div>
            )}
            {options.length ? (
                <div className="relative">
                    <CommandGroup className="max-h-[300px] overflow-y-auto">
                        {options.map((option: any) => (
                            <CommandItem
                                key={option?.value}
                                onSelect={() => {
                                    setValue(option?.value === value ? null : option);
                                    onChange?.(
                                        option?.value === value ? undefined : hasGetDataOptions ? option : option?.value
                                    );
                                    setOpen(false);
                                }}
                                value={option.value}
                                className="text-[16px] cursor-pointer hover:bg-accent hover:text-accent-foreground"
                            >
                                <TooltipComponent title={option?.label} antdTooltip>
                                    <div className="max-w-[290px] whitespace-break-spaces ellipsis">
                                        {option?.label}
                                    </div>
                                </TooltipComponent>
                                {showValue && (
                                    <CheckIcon
                                        className={cn(
                                            'ml-auto h-6 w-6',
                                            value === option?.value ? 'opacity-100' : 'opacity-0'
                                        )}
                                    />
                                )}
                            </CommandItem>
                        ))}
                        {<CommandItem ref={lastItemRef} />}
                    </CommandGroup>
                    {isLoading && (
                        <div className="absolute flex justify-center items-center bg-loadingbg top-[0] w-full h-full">
                            <SpinIcon className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-foreground" />
                        </div>
                    )}
                </div>
            ) : (
                <div className="p-8 flex items-center justify-center text-center text-[16px]">
                    {isLoading ? (
                        <SpinIcon className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-foreground" />
                    ) : (
                        <>{searchValue ? t('blank') : t('no_data')}</>
                    )}
                </div>
            )}
        </Command>
    );
};

export default SelectedContent;
