import { useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { routes } from '@/modules/routers';

const App = () => {
    const element = useRoutes(routes);
    return element;
};

export default observer(App);
