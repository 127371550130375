import { observer } from 'mobx-react-lite';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { ResidentData } from '@/interfaces/resident';
import { WrapInformationResident, renderItem } from '@/components/common/pages/Element';

type Props = {
    data: ResidentData;
};

const InformationResident = (props: Props & WithTransProps) => {
    const { t, data } = props;

    return (
        <WrapInformationResident>
            <div className=" grid grid-cols-2 gap-6">
                {renderItem({ label: t('name'), value: data?.name })}
                {renderItem({ label: t('resident.age'), value: `${data?.age || 0}歳` })}
            </div>
            {renderItem({ label: t('resident.address'), value: data?.address || '' })}
        </WrapInformationResident>
    );
};

export default withTrans(observer(InformationResident));
