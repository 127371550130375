import withTrans from '@/components/common/hocs/withTrans';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { PAGE_SIZE } from '@/constants/appConstants';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import useGetResidents from '@/hooks/useGetResidents';
import useGetUsers from '@/hooks/useGetUsers';
import useToggle from '@/hooks/useToggle';
import { SelectOptionsItem } from '@/interfaces/common/global';
import { PageProps } from '@/interfaces/props/page';
import { ResidentType } from '@/interfaces/resident';
import { convertFileNameCsv, handleErrorNotFound } from '@/lib/helper';
import { CreateFileResidentCSVDetail, DeleteResident } from '@/modules/services/report.service';
import { columns } from '@/pages/resident/ResidentColumns';
import { useCsvStore } from '@/store/csv/CsvStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import ImportCsv from '@/pages/import-csv';
import { PopupError } from '@/components/common/pages/ModalErrorLocation';
import useRefreshData from '@/hooks/useRefreshData';
import { useQueryClient } from '@tanstack/react-query';

const ResidentComponent = (props: PageProps) => {
    const { t } = props;
    const navigate = useNavigate();
    const { isShown, handleOpen, handleClose } = useToggle();
    const { addQueue } = useCsvStore();
    const { setLoading } = useGlobalStore();
    const [selectItem, setSelectItem] = useState(null);
    const queryClient = useQueryClient();

    const { options, setScrollOption, onHandleSearch } = useGetUsers({
        role: APP_PERMISSION.COMMISSIONER,
        perPage: PAGE_SIZE,
    });

    const { refreshDataList, page, setPage } = useRefreshData({
        queryKey: ['getResidentList'],
    });

    const { dataSource, isDownLoadCsv, total, isLoading } = useGetResidents({ page, userId: selectItem?.value });

    const onDownloadCsv = async () => {
        try {
            await CreateFileResidentCSVDetail(selectItem?.value);
            addQueue({
                flag: 'resident',
                status: 'pending',
                url: `users/export_csv_resident_detail`,
                fileName: convertFileNameCsv({
                    prefixName: `${t('resident.title')}${t('list')}`,
                    subName: selectItem?.label,
                }),
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onEdit = (key: string, visitorId: string) => {
        const userId = selectItem?.value || visitorId;

        navigate(`${key}?userId=${userId}`);
    };

    const onDelete = async (data: ResidentType) => {
        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });
            if (res) {
                setLoading(true);
                await DeleteResident(data.visitor_id, data.key);
                refreshDataList([selectItem?.value]);
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_056',
                callback: () => refreshDataList([selectItem?.value]),
                defaultMessage: 'common.MSG_041',
            });
        } finally {
            setLoading(false);
        }
    };

    const onCreate = () => {
        navigate(`create`, { state: { userId: selectItem?.value } });
    };

    const onChangeTable: TableProps<ResidentType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    const onRefreshData = () => {
        queryClient.refetchQueries({ queryKey: ['getResidentList', page] });
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: `${t('resident.title')}${t('list')}` }]}
                    className="py-6"
                />
            }
            isDownLoadCsv={isDownLoadCsv}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableBar
                options={options}
                onChangeSelect={(value) => {
                    const item = options.find((el: SelectOptionsItem) => el.value === value);
                    setSelectItem(item);
                    setPage(1);
                }}
                isCreate={true}
                onCreate={onCreate}
                handleSearch={onHandleSearch}
                setScrollOption={setScrollOption}
            >
                <Button
                    variant="outline"
                    size="xl"
                    className="text-ip h-[42px] w-[44] border-black"
                    onClick={handleOpen}
                >
                    {t('resident.import_csv')}
                </Button>
            </TableBar>
            <TableComponent
                loading={isLoading}
                columns={columns({ t, onEdit, onDelete })}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
            {isShown && <ImportCsv isShown={isShown} handleClose={handleClose} refreshData={onRefreshData} />}
            <PopupError />
        </PageComponent>
    );
};

export default withTrans(observer(ResidentComponent));
