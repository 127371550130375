import { FormControl, FormField, FormLabel, FormMessage } from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { Dispatch, SetStateAction } from 'react';
import { ScrollOptions } from '@/interfaces/resident';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/es/select';
import './select.css';

type Props = {
    name: string;
    placeholder?: string;
    className?: string;
    selectClass?: string;
    labelClass?: string;
    required?: boolean;
    disabled?: boolean;
    hasSearch?: boolean;
    extra?: React.ReactNode;
    label?: React.ReactNode;
    isLoading?: boolean;
    options?: any[];
    onChangeSelect?: any;
    customOption?: any;
    setScrollOption?: Dispatch<SetStateAction<ScrollOptions>>;
    children?: React.ReactNode;
} & SelectProps;

const FormMultipleSelect = ({
    name,
    placeholder,
    label,
    extra,
    className,
    selectClass,
    labelClass,
    options,
    isLoading,
    hasSearch = true,
    disabled = false,
    required = true,
    onFocus,
    onBlur,
    onSearch,
    children,
    maxTagCount = 9,
    maxTagTextLength = 20,
    optionFilterProp = 'label',
    filterOption,
    onDropdownVisibleChange,
    onDeselect,
    onChange: onSelectChange,
    tagRender,
    ...props
}: Props) => {
    const { control } = useFormContext();
    const { t } = useTranslation();

    return (
        <div className={cn('grid sm:grid-cols-[200px_1fr] grid-cols-12 gap-x-6', className)}>
            {label && (
                <FormLabel
                    className={cn(
                        'sm:col-span-1 col-span-12 text-pc lg:h-[58px] md:h-[58px] max-w-[200px]',
                        labelClass
                    )}
                    required={required}
                >
                    {label}
                </FormLabel>
            )}
            <FormField
                control={control}
                name={name}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                        <FormControl>
                            <Select
                                {...props}
                                loading={isLoading}
                                className={cn(
                                    'min-h-[50px] sm:col-span-1 col-span-12 text-left font-normal border-grey border rounded-md text-ip overflow-hidden',
                                    error && 'border-destructive',
                                    selectClass
                                )}
                                disabled={disabled}
                                mode="multiple"
                                value={value}
                                defaultValue={value}
                                placeholder={placeholder}
                                showSearch={hasSearch}
                                autoClearSearchValue={true}
                                onChange={(value: string[], option: DefaultOptionType | DefaultOptionType[]) => {
                                    onChange(value);
                                    onSelectChange(value, option);
                                }}
                                maxTagCount={maxTagCount}
                                maxTagTextLength={maxTagTextLength}
                                options={options}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                optionFilterProp={optionFilterProp}
                                filterOption={filterOption}
                                onDeselect={onDeselect}
                                onDropdownVisibleChange={onDropdownVisibleChange}
                                notFoundContent={
                                    <div className="p-8 flex items-center justify-center text-center text-[16px]">
                                        {t('blank')}
                                    </div>
                                }
                                tagRender={tagRender}
                            >
                                {children}
                            </Select>
                        </FormControl>
                        <FormMessage className="text-error sm:col-span-1 col-span-12 sm:col-start-2" />
                        {extra}
                    </>
                )}
            />
        </div>
    );
};

export default FormMultipleSelect;
