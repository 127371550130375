import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { VariantButton } from '@/components/ui/button';
import { createRef, forwardRef, memo, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModalConfirmType = {
    message?: string;
    title?: string;
    type?: 'default' | 'delete' | 'error';
};

const TYPES: { [key: string]: string } = {
    default: 'default',
    delete: 'destructive',
};

const ModalErrorLocation = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('default');
    const [title, setTitle] = useState('');

    const [promiseCallback, setPromiseCallback] = useState(null);

    const onConfirm = () => {
        promiseCallback.resolve(true);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({
        open({ message, title, type }: ModalConfirmType) {
            setMessage(message);
            setTitle(title);
            setVisible(true);
            setType(type || 'default');
            return new Promise((resolve, reject) => {
                setPromiseCallback({ resolve, reject });
            });
        },
    }));

    return (
        <AlertDialog open={visible}>
            <AlertDialogContent className="rounded-[20px] !w-[600px] px-4 pt-[1.5rem] gap-[0.3rem] pb-[0.5rem]">
                <AlertDialogHeader className="flex justify-center items-center px-[auto]">
                    <AlertDialogTitle className="text-[21px] text-center w-[350px]">{title}</AlertDialogTitle>
                    <div className="flex flex-col">
                        {message.split('\n').map((item, i) => (
                            <AlertDialogDescription key={i} className="text-[16px] text-start">
                                {item}
                            </AlertDialogDescription>
                        ))}
                    </div>
                </AlertDialogHeader>
                <span className="border-b-[1px] mt-4"></span>
                <AlertDialogFooter className="flex-row !justify-center items-center gap-3">
                    <AlertDialogAction
                        onClick={onConfirm}
                        className={'text-pc bg-transparent hover:!bg-transparent text-[#5f5ff3] w-full'}
                        variant={TYPES[type] as VariantButton['variant']}
                    >
                        {t('ok')}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
});

export default ModalErrorLocation;

type BasePopup = {
    open: (data: ModalConfirmType) => string | undefined;
};
const modalErrorRef = createRef<BasePopup>();
export const PopupError = memo(() => <ModalErrorLocation ref={modalErrorRef} />);
export const showModalErrorLocation = (data: ModalConfirmType): Promise<unknown> => {
    return Promise.resolve(modalErrorRef.current?.open(data));
};
