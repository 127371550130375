import { Params } from '@/interfaces/common/global';
import { ResidentReq } from '@/interfaces/resident';
import { VisitReportUpdate } from '@/interfaces/visit-report';
import { apiServiceWithAuth, apiServiceWithAuthFormData } from '@/modules/http/apiServiceWithAuth';

export const GetVisitedReportList = async (userId: string, params?: Params & { order_by?: string }) => {
    return await apiServiceWithAuth.get(`users/${userId}/residents`, { params });
};

export const GetResidents = async (params: { page: number; per_page: number }) => {
    return await apiServiceWithAuth.get(`users/residents`, { params });
};

export const GetDetailVisitReport = async (userId: string, id: string) => {
    return await apiServiceWithAuth.get(`users/${userId}/residents/${id}`);
};

export const CreateVisitReport = async (userId: string, id: string, data: VisitReportUpdate) => {
    return await apiServiceWithAuth.post(`users/${userId}/residents/${id}/visit_reports`, data);
};

export const GetListVisitReportHistory = async (userId: string, id: string, params: Params & { is_all?: boolean }) => {
    return await apiServiceWithAuth.get(`users/${userId}/residents/${id}/visit_reports`, { params });
};

export const CreateFileResidentCSV = async (userId?: string) => {
    return await apiServiceWithAuth.post(`users/export_csv_resident`, {
        visitor_id: userId,
    });
};

export const CreateFileResidentCSVDetail = async (userId?: string) => {
    return await apiServiceWithAuth.post(`users/export_csv_resident_detail`, {
        visitor_id: userId,
    });
};

export const CreateFileVisitReportCSV = async (userId: string, id: string) => {
    return await apiServiceWithAuth.post(`users/${userId}/residents/${id}/export_csv_visit_report`);
};

export const CheckFileResidentCSV = async (userId: string) => {
    return await apiServiceWithAuth.get(`users/${userId}/export_csv_resident`);
};

export const CheckFileCSV = async (url: string) => {
    return await apiServiceWithAuth.get(url);
};

export const DownLoadResidentCSV = async (userId: string) => {
    return await apiServiceWithAuth.get(`users/${userId}/export_csv_resident/download`, { responseType: 'blob' });
};

export const DownloadCsv = async (url: string) => {
    return await apiServiceWithAuth.get(`${url}`, { responseType: 'blob' });
};

export const CreateResident = async (userId: string, params: ResidentReq) => {
    return await apiServiceWithAuth.post(`users/${userId}/residents`, params);
};

export const UpdateResident = async (userId: string, id: string, params: ResidentReq) => {
    return await apiServiceWithAuth.patch(`users/${userId}/residents/${id}`, params);
};

export const DeleteResident = async (userId: string, id: string) => {
    return await apiServiceWithAuth.delete(`users/${userId}/residents/${id}`);
};

export const downloadTemplate = async (params: { template: string }) => {
    return await apiServiceWithAuth.get(`csv_template/download`, { params, responseType: 'blob' });
};

export const importCsvResidents = async (userId: string, body: FormData) => {
    return await apiServiceWithAuthFormData.post(`users/${userId}/import_csv_resident`, body);
};

export const getErrorCsvResidents = async (userId: string) => {
    return await apiServiceWithAuthFormData.get(`users/${userId}/import_csv_resident`);
};
