import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { PropsWithChildren } from 'react';
import { Tooltip as AntdTooltip } from 'antd';

type TooltipProps = PropsWithChildren & {
    title: React.ReactNode;
    antdTooltip?: boolean;
};

const TooltipComponent = ({ title, antdTooltip = false, children }: TooltipProps) => {
    return (
        <>
            {antdTooltip ? (
                <AntdTooltip
                    title={title}
                    placement="top"
                    color="white"
                    arrow={false}
                    overlayInnerStyle={{
                        color: 'hsl(var(--popover-foreground))',
                        fontSize: 16,
                        whiteSpace: 'break-spaces',
                        maxHeight: '350px',
                        overflowY: 'auto',
                    }}
                >
                    {children}
                </AntdTooltip>
            ) : (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="text-left">{children}</TooltipTrigger>
                        <TooltipContent className="max-w-[400px] text-base whitespace-break-spaces overflow-y-auto max-h-[350px] border-b-0">
                            <p>{title}</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            )}
        </>
    );
};

export default TooltipComponent;
