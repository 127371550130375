import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { useNavigate, useParams } from 'react-router';
import dayjs from '@/lib/dayjs';
import { getMeetingDetail, updateAttendanceMeeting } from '@/modules/services/meeting.service';
import { useQuery } from '@tanstack/react-query';
import { ATTENDANCE_STATUS, FORMAT_DATE, FORMAT_TIME } from '@/constants/appConstants';
import { Item, renderText } from '@/components/common/pages/Element';
import { MeetingType } from '@/interfaces/meeting';
import { useForm } from 'react-hook-form';
import { useAuthenStore } from '@/store/auth/AuthStore';
import z from 'zod/lib';
import { zodResolver } from '@hookform/resolvers/zod';
import { AttendanceSchema } from '@/schema/meetingSchema';
import GroupButton from '@/components/common/pages/GroupButton';
import { Form, FormField, FormMessage } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { toast } from '@/components/ui/use-toast';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { backLink } from '@/constants/path';
import { handleErrorNotFound } from '@/lib/helper';

type Props = {
    type: 'create' | 'edit';
} & WithTransProps;

const IPChangeAttendance = ({ t, type }: Props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuthenStore();
    const { setLoading, isLoading } = useGlobalStore();

    const form = useForm<z.infer<typeof AttendanceSchema>>({
        resolver: zodResolver(AttendanceSchema),
        defaultValues: {
            attendance: {
                attendance_status: undefined,
                reason: undefined,
            },
        },
        mode: 'onChange',
    });

    const attendance_status = form.watch('attendance.attendance_status');

    const { isFetching: isLoad, data } = useQuery({
        queryKey: ['getDetailMeeting'],
        queryFn: () => {
            if (id) return getDetailMeetingById();
        },
    });

    const getDetailMeetingById = async (): Promise<MeetingType> => {
        try {
            const response = await getMeetingDetail(id);
            const { data }: any = response;
            const current = dayjs().format('YYYY/MM/DD HH:mm:ss');
            const attendances = data?.attendances?.filter((item: any) => item?.user?.id === user.id) || [];
            const isJoin = attendances[0]?.attendance_status !== ATTENDANCE_STATUS.NOT_YET;
            const meetingDate = dayjs(data?.meeting_date).format(FORMAT_DATE) || '';
            const startTime = dayjs(data?.start_time)?.format(FORMAT_TIME) || '';
            const isNotStart = dayjs(`${meetingDate} ${startTime}`).isAfter(dayjs(current));

            if (!isNotStart || (type === 'create' && isJoin) || (type === 'edit' && !isJoin)) {
                navigate('/not-permission');
                return null;
            }

            if (type === 'edit') {
                form.reset({
                    attendance: {
                        attendance_status:
                            attendances?.[0]?.attendance_status !== ATTENDANCE_STATUS.NOT_YET
                                ? attendances?.[0]?.attendance_status
                                : undefined,
                        reason: attendances?.[0]?.reason || undefined,
                    },
                });
            }
            return {
                attendances,
                meeting_date: data.meeting_date ? dayjs(data.meeting_date).format(FORMAT_DATE) : '',
                name: data.name,
                start_time: data.start_time ? dayjs(data.start_time).format(FORMAT_TIME) : '',
                end_time: data.end_time ? dayjs(data.end_time).format(FORMAT_TIME) : '',
                content: data.content || '',
            };
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const onBackMenu = () => {
        navigate(`${backLink[user?.role?.communication?.[0]] || ''}/attendance-meetings`);
    };

    const onSubmit = async (value: z.infer<typeof AttendanceSchema>) => {
        try {
            setLoading(true);
            const data = {
                attendance: {
                    attendance_status: value?.attendance?.attendance_status,
                    reason:
                        value?.attendance?.attendance_status === ATTENDANCE_STATUS.ABSENT
                            ? value?.attendance?.reason
                            : '',
                },
            };

            await updateAttendanceMeeting(id, data);
            toast({ description: t(type === 'edit' ? 'common.MSG_017' : 'common.MSG_015'), variant: 'success' });
            onBackMenu();
        } catch (error) {
            handleErrorNotFound({
                error,
                message: 'common.MSG_071',
                callback: () => onBackMenu(),
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageComponent
            pageLoading={isLoad}
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: t(
                                type === 'create'
                                    ? 'attendance_meeting.title_register'
                                    : 'attendance_meeting.title_edit'
                            ),
                        },
                    ]}
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-[19px] h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t(type === 'create' ? 'register' : 'update')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <div className="flex flex-col gap-6 mt-6 ">
                <Item label={t('attendance_meeting.date_time')}>
                    {renderText(
                        `${data?.meeting_date || ''} ${data?.start_time || ''}${
                            data?.end_time ? ' ~ ' + data.end_time : ''
                        }`
                    )}
                </Item>
                <Item className="whitespace-break-spaces" label={t('attendance_meeting.meeting_name')}>
                    {renderText(data?.name)}
                </Item>
                <Form {...form}>
                    <FormField
                        control={form.control}
                        name="attendance.attendance_status"
                        render={({ field, fieldState: { error } }) => (
                            <div className="flex flex-col mt-4">
                                <GroupButton
                                    buttons={[
                                        {
                                            label: t('attend'),
                                            onClick: () =>
                                                field.onChange(
                                                    field.value === ATTENDANCE_STATUS.ATTEND
                                                        ? undefined
                                                        : ATTENDANCE_STATUS.ATTEND
                                                ),
                                            className: cn(
                                                'py-[60px]',
                                                error && 'border-destructive',
                                                field.value === ATTENDANCE_STATUS.ATTEND && 'bg-lime-500'
                                            ),
                                        },
                                        {
                                            label: t('absent'),
                                            onClick: () =>
                                                field.onChange(
                                                    field.value === ATTENDANCE_STATUS.ABSENT
                                                        ? undefined
                                                        : ATTENDANCE_STATUS.ABSENT
                                                ),
                                            className: cn(
                                                'py-[60px]',
                                                field.value === ATTENDANCE_STATUS.ABSENT && 'bg-lime-500',
                                                error && 'border-destructive'
                                            ),
                                        },
                                    ]}
                                />
                                <FormMessage className="text-error" />
                            </div>
                        )}
                    />
                    {attendance_status === ATTENDANCE_STATUS.ABSENT && (
                        <FormInputTextarea
                            required={false}
                            name="attendance.reason"
                            className="flex-col items-start sm:gap-0"
                            labelClass="min-w-[170px]"
                            label={t('attendance_meeting.reason')}
                            placeholder={t('common.MSG_001', { field: t('attendance_meeting.reason') })}
                            inputClassName="text-pc"
                            rows={5}
                            maxLength={255}
                        />
                    )}
                </Form>
            </div>
        </PageComponent>
    );
};
export default withTrans(observer(IPChangeAttendance));
